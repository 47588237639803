import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PAGE_MODES } from './../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-tooltip-category',
  templateUrl: './tooltip-category.component.html',
  styleUrls: ['./tooltip-category.component.scss']
})
export class TooltipCategory implements OnInit {
  
  @Output('dirty')  formDirty = new EventEmitter<boolean>()

  selectedTooltipCatList = [];
  mode = PAGE_MODES.HOME;
  userInfo: any;
  
  constructor(protected googleTagManagerService: GoogleTagManagerService
  ) { this.userInfo = JSON.parse(sessionStorage.getItem("user"));}

  ngOnInit() {
  }

  getSelectedTooltipCat(childEmmitedEvent) {
    this.selectedTooltipCatList = childEmmitedEvent;
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME || this.mode == PAGE_MODES.DELETE;
  }

  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
  }


  enableEditMode() {
    this.changePageMode(PAGE_MODES.EDIT);  
   
  }


  enableHomePageMode() {
    this.changePageMode(PAGE_MODES.HOME);
  }

  enableDeleteMode() {
    this.changePageMode(PAGE_MODES.DELETE);
  }

  changePageMode(pageMode) {
    this.mode = pageMode;
    if(this.mode == PAGE_MODES.HOME){
      this.selectedTooltipCatList = [];
    }
  }

  isFormDirty(isDirty: boolean) {
    this.formDirty.emit(isDirty);
  }


}
