import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

const BASE_API_URL = `${ environment.api }/pfgroup`;

@Injectable({
  providedIn: 'root'
})
export class PortfolioGroupService {

  constructor(private http:HttpClient) {  }

  getPortfolioGroupData(obj: any) {
    return this.http.post(`${BASE_API_URL}/getPortfoliosPerGroup`, obj);
  };
  getChartData(obj: any) {
    return this.http.post(`${BASE_API_URL}/getChartData`, obj);
  };

}
