// Modules.
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
// Misc.
import { environment } from 'environments/environment';
import { User } from 'app/types';

const BASE_API_URL = `${ environment.api }/user`;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    userInfo: BehaviorSubject<string>;
    userInfo1: any;

    constructor(
        private http: HttpClient,
    ){};

    async get(gpid: string): Promise<User> {
        var userData=await this.http.get<User>(`${ BASE_API_URL }/${ gpid }`).toPromise();
        this.userInfo = new BehaviorSubject('default message');
        this.userInfo1 = this.userInfo.asObservable();
        return userData;
    }

}
