import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef,MatDialogConfig} from '@angular/material';
import { ApiErrorDialogComponent } from 'app/common/components/dialogs/apiError-dialog/apiError-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  public dialogConfig = new MatDialogConfig();
  constructor(
      public dialog: MatDialog,
  ) {}

  /**
   * @name handleError
   * @description method to handle error scenarios
   * @param  error - error message string received
   */
  handleError(DialogTitle: string, DialogMessage: string, ButtonLabel: string) {
    console.log
   return this.showErrorDialog(DialogTitle, DialogMessage, 'Ok');

  }

  showErrorDialog(errorMessage: string, title: string, buttonLabel: string) {

    this.dialogConfig.autoFocus = true;
    this.dialogConfig.height = '50%';
    this.dialogConfig.width = '50%';
    this.dialogConfig.minWidth = '500';
    this.dialogConfig.data = {
    title: title,
    message: errorMessage || '',
    buttonLabel: buttonLabel
    };
let dialogRef = this.dialog.open(ApiErrorDialogComponent,this.dialogConfig);

       dialogRef.afterClosed().subscribe((res: any) => {
         if (res.status) {
           this.dialog.closeAll();
         }
       });
    }

}
