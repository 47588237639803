import { GlobalElementsService } from "./../../../../global-elements/services/global-elements.service";
import { MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from "@angular/core";
import { SharedDataService } from "../../../services/shared-data.service";
import { StoreService } from "../../../../old/_services/_store.service";
import { UserService } from "../../../services/_user.service";
import { forEach,differenceBy, result } from "lodash";

@Component({
  selector: "app-edit-user-dialog",
  templateUrl: "./edit-user-dialog.component.html",
  styleUrls: ["./edit-user-dialog.component.scss"],
})
export class EditUserDialogComponent implements OnInit {
  //TODO: Move all User related dialog into a single component
  message: string = "Saving User... Please Wait";
  exitEditUserForm: boolean = false;
  userInfo = JSON.parse(sessionStorage.getItem("user"));
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
    public userService: UserService,
    private sharedDataService: SharedDataService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.editUser();
  }

  editUser() {
    const selectedMarket = this.store.get("market");
    console.log(this.data.userInfo.markets);
    console.log(this.userInfo.markets);
    this.data.selectedMarket = selectedMarket;
    const results = this.userInfo.markets.filter(({ id: id1 }) => !this.data.userInfo.markets.some(({ marketId: id2 }) => id2 === id1));
    
    
    this.globalElementsService.editUser(this.data).subscribe(
      () => {
        this.message = "Successfully saved User";
        console.log("this.userInfo", this.userInfo);
        // const myDifferences = differenceBy(this.userInfo.markets, this.data.userInfo.markets, 'value')
        const results = this.userInfo.markets.filter(({ id: id1 }) => !this.data.userInfo.markets.some(({ marketId: id2 }) => id2 === id1));
        if (this.userInfo.gpid === this.data.userInfo.gpid) {
          this.userService.get(this.data.userInfo.gpid).then((user) => {
            if(this.data.userInfo.markets.length > this.userInfo.markets.length){
              this.sharedDataService.setUserInfo(user);
              this.store.set("user", JSON.stringify(user));
              console.log("Inside Market add condition");
            }
            if(this.data.userInfo.markets.length > this.userInfo.markets.length && results.length > 0){
              const isTrue = results.map((el) => {
                if(el.id == selectedMarket || el.id == this.userInfo.defaultMarket){
                  console.log(this.data.userInfo.markets);
                  let foundDefaultMarket = false;
                    this.data.userInfo.markets.forEach((market) => {
                      if (market.marketId === Number(user.defaultMarket)) {
                        foundDefaultMarket = true;
                      }
                    });

                    if (foundDefaultMarket) {
                      this.store.set("market", user.defaultMarket);
                      this.sharedDataService.setMarket(
                        user.defaultMarket
                      );
                    } 
                    else {
                      console.log("Inside Else");
                    }
                }
              })
            } 
            else {
              const isTrue = results.map((el) => {
                if(el.id == selectedMarket || el.id == this.userInfo.defaultMarket){
                  console.log(this.data.userInfo.markets);
                  let foundDefaultMarket = false;
                    this.data.userInfo.markets.forEach((market) => {
                      if (market.marketId === Number(user.defaultMarket)) {
                        foundDefaultMarket = true;
                      }
                    });

                    if (foundDefaultMarket) {
                      this.store.set("market", user.defaultMarket);
                      this.sharedDataService.setMarket(
                        user.defaultMarket
                      );
                    } 
                    else {
                      console.log("Inside Else");
                    }
                }
              })
              
            }
            this.sharedDataService.setUserInfo(user);
            this.store.set("user", JSON.stringify(user));
          });
        }
        this.exitEditUserForm = true;
      },
      () => {
        this.message = "Error Saving User.";
      }
    );
  }

  // Quit the edit form on clicking OK
  handleOk() {
    this.exitEditUserForm
      ? this.globalElementsService.setSidePanelCancelEvent()
      : null;
  }
}
