import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-elements',
  templateUrl: './global-elements.component.html',
  styleUrls: ['./global-elements.component.scss']
})
export class GlobalElementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
