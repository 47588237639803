import { accessToken } from "./../interfaces/common";
import { Injectable } from "@angular/core";
import { NgZone } from "@angular/core";
import {
  OktaAuth,
  OktaAuthOptions,
  TokenManager,
  AccessToken,
  IDToken,
  UserClaims,
  TokenParams,
  Tokens,
} from "@okta/okta-auth-js";
import { UserService } from "app/old/_services/_user.service";
import { CookieService } from "ngx-cookie-service";
import { StoreService } from "app/old/_services/_store.service";
import { SharedDataService } from "./shared-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "environments/environment";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  userInfo: any;
  oktaAuth = new OktaAuth({
    issuer: `${environment.oktaIssuer}`,
    clientId: `${environment.oktaClientId}`,
    redirectUri: `${environment.oktaRedirectUri}`,
    scopes: ["openid", "profile", "email", "offline_access"],
    tokenManager: {
    autoRenew: true,
    autoRemove: false,
    },

    storageManager: {
      token: {
        storageType: "sessionStorage",
        secure: true,
      },
      cache: {
        storageType: "sessionStorage",
        secure: true,
      },
      transaction: {
        storageType: "sessionStorage",
        secure: true,
      },
     
    },
    
  });
  tokenManager: TokenManager = this.oktaAuth.tokenManager;
  accessToken: AccessToken;
  constructor(
    private sharedDataService: SharedDataService,
    private userService: UserService,
    private cookieService: CookieService,
    private store: StoreService,
    private route: ActivatedRoute,
    public router: Router,
    private ngZone: NgZone
  ) {}
  async handleAuthentication() {
    try {
      const tokens =
        (await this.oktaAuth.handleLoginRedirect()) as unknown as Tokens;
      if (tokens) {
        this.oktaAuth.tokenManager.setTokens(tokens);
      } else {
        console.error("No tokens returned from handleLoginRedirect");
      }
    } catch (error) {
      console.error("Error handling authentication:", error);
    }
  }

  getToken(): Promise<string | null> {
    return this.oktaAuth.tokenManager
      .get("accessToken")
      .then((token) => {
        return this.ngZone.run(() => {
          if (token) {
            console.log("Access Token Found:");
            return token.accessToken;
          } else {
            console.log("No access token found");
            return null;
          }

        });
      })
      .catch((error) => {
        return this.ngZone.run(() => {
          console.error("Error getting access token", error);
          return null;
        });
      });
  }
   // Handle the login flow
  async login() {
    //  oktaAuth.signInWithRedirect();
    await this.oktaAuth.token.getWithRedirect();
    //getWithRedirect
  }

  // Handle the callback after login
  async handleCallback() {
    try {
      const tokens = await this.oktaAuth.token.parseFromUrl();
      console.log("token 130::");
      this.oktaAuth.tokenManager.setTokens(tokens.tokens);
      console.log("  this.oktaAuth.tokenManager::", this.oktaAuth.tokenManager);
      const userInfo = await this.oktaAuth.token.getUserInfo(
        tokens.tokens.accessToken,
        tokens.tokens.idToken
      );
      let gpid = userInfo.gpid;
      const user = userInfo;
      //this.sharedDataService.setUserInfo(user);
      console.log("auth service 143::", user);
      this.store.set("user", JSON.stringify(user));
      this.updateUserSettings(gpid);
      return {
        gpid: gpid,
        oktaAccessToken: tokens.tokens.accessToken.accessToken,
      };
    } catch (error) {
      console.error("Error handling the callback:", error);
      return null;
    }
  }
  // Check authentication state on page load
  async checkAuthState() {
    let loginSuccess = false;
    if (this.oktaAuth.token.isLoginRedirect()) {
   //   let response = await this.handleCallback();
      loginSuccess = true;
      return await this.handleCallback();
      //return response;
    } else {
      // Check if user is already authenticated
      this.oktaAuth.tokenManager
        .get("accessToken")
        .then((accessToken) => {
          if (accessToken && !this.isTokenExpired(accessToken)) {
            console.log("User is authenticated inside checkAuthState else");
            loginSuccess = true;
            return this.oktaAuth.token.getUserInfo();
          } else {
            // window.location.replace('/')
            // console.log('User is not authenticated');;
            loginSuccess = false;
            throw new Error("User not authenticated");
          }
        })
        .then((userInfo) => {
          // console.log("useInfo3",userInfo)
          // console.log('User is authenticated');
          let gpid = userInfo.gpid;
          const user = userInfo;
          // this.sharedDataService.setUserInfo(user);
          console.log("auth service:184::", user);
          //  this.store.set("user", JSON.stringify(user));
          loginSuccess = true;
          this.updateUserSettings(gpid);
          return { gpid: gpid, oktaAccessToken: this.accessToken.accessToken };
        })
        .catch(() => {
          // window.location.replace('/');
          // console.log('User is not authenticated');
          if (!loginSuccess) {
            this.login();
            return null;
          }
        });
    }
  }

  isTokenExpired(tokenObject: any): boolean {
    if (!tokenObject || !tokenObject.expiresAt) {
      return true;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    return tokenObject.expiresAt < currentTime;
  }
  updateUserSettings(gpid) {
    let gpidVal = gpid;
    this.userService.get(gpidVal).then((user: any) => {
      if (user) {
        this.userInfo = user;
        this.sharedDataService.setUserInfo(user);
        console.log("auth Service 216::", user);
        this.store.set("user", JSON.stringify(user));
        this.cookieService.set("userEmail", user.email);
        this.cookieService.set("gpid", user.gpid);
        if (user.routingPage == "Y") {
          this.router.navigate(["/appRouting"]);
        } else {
          this.navigateToDefault();
        }
      }
    });
  }

  navigateToDefault() {
    const { defaultLanding } = this.userInfo;
    const dftLndList = defaultLanding.split(",");
    const defLanLength = dftLndList.length;

    switch (defLanLength) {
      case 1:
        this.navigateToMarket();
        break;
      case 2:
        this.navigateToPortfolio(dftLndList[defLanLength - 1]);
        break;
      case 3:
        this.navigateToPlan(dftLndList[defLanLength - 1]);
        break;

      default:
        console.error("Default Landing not found");
        break;
    }
  }

  navigateToMarket() {
    this.router.navigate([`/market/`]);
  }
  navigateToPortfolio(portfolioId) {
    if (portfolioId) {
      this.router.navigate([`/portfolio/${portfolioId}`]);
    }
  }

  navigateToPlan(planId) {
    if (planId) {
      this.router.navigate([`/plan/${planId}`]);
    }
  }
}
