import {
  STATUS_LIST_TYPES,
  ACCESS_LEVEL,
  USER_ACCESS_LEVEL,
  AIRTHMETIC_OPERARTORS,
  ROLES,
} from "app/constants/app-constants";
import * as moment from "moment";
import { SharedDataService } from "app/common/services/shared-data.service";

//TODO: @Thiru: Rewrite, get from constants, reduce number of if else.
export const getCompletedAndFinalizedUsers = (
  oldStatus,
  currentStatus,
  formData?
) => {
  let completedByUserId = null;
  let finalizedByUserID = null;
  const USER_INFO = JSON.parse(sessionStorage.getItem("user"));
  if (
    (!oldStatus || oldStatus == STATUS_LIST_TYPES.DRAFT) &&
    (!currentStatus || currentStatus == STATUS_LIST_TYPES.DRAFT)
  ) {
    completedByUserId = null;
    finalizedByUserID = null;
  } else if (
    (!oldStatus || oldStatus == STATUS_LIST_TYPES.DRAFT) &&
    (!currentStatus || currentStatus == STATUS_LIST_TYPES.COMPLETE)
  ) {
    completedByUserId = USER_INFO.id;
    finalizedByUserID = null;
  } else if (
    oldStatus == STATUS_LIST_TYPES.COMPLETE ||
    currentStatus == STATUS_LIST_TYPES.REPLAN
  ) {
    completedByUserId = formData ? formData.completedByUserId : null;
    finalizedByUserID = USER_INFO.id;
  } else if (
    oldStatus == STATUS_LIST_TYPES.REPLAN ||
    currentStatus == STATUS_LIST_TYPES.COMPLETE
  ) {
    completedByUserId = formData ? formData.completedByUserId : null;
    finalizedByUserID = USER_INFO.id;
  }

  return { completedByUserId, finalizedByUserID };
};

export const getDocsFromSharepoint = async (path, serviceName) => {
  let docData = [];
  let data: any = await serviceName.getDocuments(path).toPromise(); //.subscribe((docsDetails: Array<any>) => {
  if (data) {
    docData = data.reduce((acc, cv) => {
      const { results } = cv.Cells;
      let docObj: any = {};
      docObj.title = results.find((el) => el.Key == "Title").Value || "N/A";
      docObj.author = results.find((el) => el.Key == "Author").Value || "N/A";
      docObj.path =
        results.find((el) => el.Key == "OriginalPath").Value || "N/A";
      const parentPath = results
        .find((el) => el.Key == "ParentLink")
        .Value.split("/");
      docObj.folder = parentPath.pop();
      const date = results.find((el) => el.Key == "LastModifiedTime").Value;
      docObj.modifiedDt = moment(date).isValid()
        ? moment(date).format("ll")
        : "N/A";
      docObj.fileExt =
        results.find((el) => el.Key == "FileExtension").Value || "N/A";
      docObj.size = results.find((el) => el.Key == "Size").Value || "N/A";
      docObj.PictureThumbnailURL =
        results.find((el) => el.Key == "PictureThumbnailURL").Value || "N/A";
      docObj.fileName =
        docObj.path.substring(
          docObj.path.lastIndexOf("/") + 1,
          docObj.path.lastIndexOf(".")
        ) || "N/A";
      acc.push(docObj);
      return acc;
    }, []);
  }
  return docData;
};
export const getDocsFromSharepointFilesAPI = async (path, serviceName) => {
  let docData = [];
  let data: Array<any> = await serviceName
    .getDocumentsWithFilesAPI(path)
    .toPromise(); //.subscribe((docsDetails: Array<any>) => {
  if (data) {
    const docsDetailsArr = data["results"];
    docData = docsDetailsArr.reduce((acc, cv) => {
      const results = cv;
      let docObj: any = {};
      docObj.title = results.Name || "N/A";
      docObj.author = "";
      docObj.path =
        "https://pepsico.sharepoint.com" + results.ServerRelativeUrl;
      docObj.folder = "";
      const date = results.TimeLastModified || "N/A";
      docObj.modifiedDt = moment(date).isValid()
        ? moment(date).format("ll")
        : "N/A";
      // docObj.modifiedDtLL = moment(date).isValid() ? moment(date).format('MM/DD/YYYY hh:mm:ss') : 'N/A';
      docObj.modifiedDtUnix = moment(date).isValid()
        ? moment(date).unix()
        : "N/A";
      docObj.fileExt = results.Name ? results.Name.split(".")[1] : "N/A";
      docObj.size = "";
      docObj.fileName = results.Name || "N/A";
      acc.push(docObj);
      return acc;
    }, []);
  }
  return docData;
};
export const getDocsFromSharepointSubFolderFilesAPI = async (
  path,
  serviceName
) => {
  let docData = [];
  let data: Array<any> = await serviceName
    .getDocumentFromSubFolderAPI(path)
    .toPromise(); //.subscribe((docsDetails: Array<any>) => {
  if (data) {
    const docsDetailsArr = data["results"];
    docData = docsDetailsArr.reduce((acc, cv) => {
      return acc;
    }, []);
  }
  return docData;
};

export const getGpId = () => {
  let userInfo = JSON.parse(sessionStorage.getItem("user"));
  return (userInfo && userInfo.gpid) || null;
};

export const returnAccessflag = (accessType, refrenceId): any => {
  let result = false;
  let userInfo = JSON.parse(sessionStorage.getItem("user"));
  let earlierMarketId = sessionStorage.getItem("market");
  let getObj = userInfo[accessType]
    ? userInfo[accessType].find((el) => el.id == refrenceId)
    : null;
  let marketChange = false;
  let marketId = null;
  if (getObj) {
    marketId = ACCESS_LEVEL.MARKET !== accessType ? getObj.marketId : getObj.id;
    let userAccessObj = userInfo.userAccessByMarket.find(
      (el) => el.marketId == marketId
    );
    if (
      userAccessObj.userAccess === USER_ACCESS_LEVEL.BRAND &&
      ACCESS_LEVEL.PLAN !== accessType
    ) {
      return false;
    }
    sessionStorage.setItem("market", marketId);
    marketChange = marketId != earlierMarketId;
    /* if(marketChange) {
    const sharedDataServiceObj = new SharedDataService();
     sharedDataServiceObj.setMarket(marketId);
     sharedDataServiceObj.setCurrencySignByMarket(marketId);
    }*/
    result = true;
  }
  return { result, marketChange, marketId };
};

export const getFileBuffer = (file) => {
  return new Promise((resolve, reject) => {
    var myReader: FileReader = new FileReader();
    myReader.readAsArrayBuffer(file);
    myReader.onload = function (e) {
      resolve(myReader.result);
    };
    myReader.onerror = function (e) {
      //deferred.reject(e.target.error);
    };
  });
};
export const isValidUserAccess = (
  rolecondition,
  arthmeticOperator,
  portfolioId = null,
  planId = null
) => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  let userAccessObj = userInfo.userAccessByMarket.find((el) =>
    el.marketId == sessionStorage.getItem("market")
      ? sessionStorage.getItem("market")
      : userInfo.defaultMarket
  );
  if (
    userAccessObj &&
    userAccessObj.userAccess === USER_ACCESS_LEVEL.PORTFOLIO &&
    portfolioId
  ) {
    const userRoleId = userInfo.portfolios.find((el) => el.id == portfolioId)
      ? userInfo.portfolios.find((el) => el.id == portfolioId).roleId
      : ROLES.VIEWER;
    return AIRTHMETIC_OPERARTORS[arthmeticOperator](userRoleId, rolecondition);
  } else if (
    userAccessObj &&
    userAccessObj.userAccess === USER_ACCESS_LEVEL.PORTFOLIO &&
    planId
  ) {
    const userRoleId = userInfo.plans.find((el) => el.id == planId)
      ? userInfo.plans.find((el) => el.id == planId).roleId
      : ROLES.VIEWER;
    return AIRTHMETIC_OPERARTORS[arthmeticOperator](userRoleId, rolecondition);
  } else if (
    userAccessObj &&
    userAccessObj.userAccess === USER_ACCESS_LEVEL.BRAND &&
    planId
  ) {
    const userRoleId = userInfo.plans.find((el) => el.id == planId)
      ? userInfo.plans.find((el) => el.id == planId).roleId
      : ROLES.VIEWER;
    return AIRTHMETIC_OPERARTORS[arthmeticOperator](userRoleId, rolecondition);
  } else {
    return AIRTHMETIC_OPERARTORS[arthmeticOperator](
      userInfo.roleId,
      rolecondition
    );
  }
};
export const isValidUserMarketAccess = () => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  let isValidMarketAccess = false;
  if (sessionStorage.getItem("market") == "3") {
    let userAccessObj = userInfo.userAccessByMarket.find((el) => {
      el.marketId == sessionStorage.getItem("market")
        ? sessionStorage.getItem("market")
        : userInfo.defaultMarket;
      if (
        el.marketId == sessionStorage.getItem("market") &&
        userInfo.roleId != 5
      ) {
        isValidMarketAccess = true;
      }
    });
  }
  return isValidMarketAccess;
};
