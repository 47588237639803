import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IHttpApiAppConfig } from './interface/ihttp-api-app-config';

@Injectable({
  providedIn: 'root'
})
export class HttpApiConfigService {
  private configuration!: IHttpApiAppConfig;
  public init(): Observable<IHttpApiAppConfig> {
    this.configuration = environment.config as IHttpApiAppConfig;
    return of(this.configuration);
  }

  public getConfiguration(): IHttpApiAppConfig {
    return this.configuration;
  }
}
