import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';


@Component({
  selector: 'app-warning-message-brand-mapping-dialog',
  templateUrl: './warning-message-brand-mapping-dialog.component.html',
  styleUrls: ['./warning-message-brand-mapping-dialog.component.scss']
})
export class WarningMessageBrandMappingDialogComponent implements OnInit {
  message:string;
  isDeactivationConfirmed:boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private globalElementsService: GlobalElementsService,
  private dialogRef: MatDialogRef<WarningMessageBrandMappingDialogComponent>) { }

  ngOnInit() {
    console.log(this.data.type);
    if(this.data.type == 'DEACTIVATE'){
      this.message = 'Are you sure you want to deactivate this brand mapping ?';
    } else {
      this.message = 'Are you sure you want to activate this brand mapping ?';
    }
  }
  deactivateMapping(){
    if(this.data.type == 'DEACTIVATE'){
      this.message = "Deactivating mapping please wait...";
    } else {
      this.message = "Activating mapping please wait...";
    }
    this.globalElementsService.deactivateBrandMapping(this.data).subscribe((res) => {
      if(this.data.type == 'DEACTIVATE'){
        this.message = "Mapping deactivated successfully and moved to bottom in the list!";
        this.isDeactivationConfirmed = true;
      } else {
        this.message = "Mapping activated successfully and moved to top in the list!";
        this.isDeactivationConfirmed = true;
      }
    })
  }
  handleClose(){
    this.dialogRef.close({status:200});
  }

}
