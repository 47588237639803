import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IPlans } from 'app/common/interfaces/portfolio';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { getCompletedAndFinalizedUsers } from 'app/common/utils/common-util';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { IPortfolioBody } from '../../common/interfaces/portfolio';
import { IPfAllocOverview, IPfAllocPlanData, IpfAllocPlanSave } from '../../common/interfaces/portfolioAllocation';
import { TeamMembersDialogComponent } from './../../common/components/dialogs/team-members-dialog/team-members-dialog.component';
import { IApproverList, IBreadcrumb, ITeamMembers, ITeamMembersReq } from './../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES, FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, STATUS_LIST, STATUS_LIST_TYPES ,ONLY_REPLAN_STATUS_LIST, FINANCIAL_VALUES } from './../../constants/app-constants';
import { PortfolioService } from './../services/portfolio.service';
import {MultiselectAutocompleteComponent} from 'app/common/components/multiselect-autocomplete/multiselect-autocomplete.component';
import { returnAccessflag, isValidUserAccess } from 'app/common/utils/common-util';
import { ACCESS_LEVEL } from 'app/constants/app-constants'
import { StoreService } from '../../old/_services/_store.service';
import { SendNotificationsDialogComponent } from './../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component';
import { IMarketCurrency } from 'app/common/interfaces/market';
@Component({
  selector: 'app-portfolio-allocation',
  templateUrl: './portfolio-allocation.component.html',
  styleUrls: ['./portfolio-allocation.component.scss']
})
export class PortfolioAllocationComponent implements OnInit {

  @ViewChild('approveListDialog', { static: true }) approveListDialog: TemplateRef<any>;

  pfApprover: FormControl = new FormControl("", Validators.required);
  paramsId: number;
  portfolioName: string;
  workingYear: number;
  pathDetails: IBreadcrumb;
  overviewData: IPfAllocOverview;
  plans: Array<IPlans>;
  portfolioApproverList: Array<IApproverList>
  oldStatus: string;
  pfStatusList: Array<string> = STATUS_LIST;
  pfStatus: string = STATUS_LIST_TYPES.DRAFT;
  USER_INFO = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');
  pfEditableStatus: boolean = true;
  pfApproverList: Array<IApproverList>;
  selectedPfApprovers: Array<IApproverList> = [];
  statusUpdateRes: string = "";
  isStatusConfirm: boolean = false;
  isLoading: boolean = false;
  dataSource = new MatTableDataSource<any>();
  plannedROI: number = 0;
  totalAllocated: number = 0;
  plannedWMONR: number = 0;
  displayedColumns: string[] = ["planName", "aBudgetInput", "prevYearBudget", "estWMONR", "estROI", "projectedSOV", "estEffectiveness", "estRevenue"];
  isOvwBudgetEditMode: boolean = false;
  isOvwMetricsEditMode: boolean = false;
  owBudgetVal;
  owROIVal;
  owWMONRVal;
  portfolioAllocationPlanData = [];
  finalPlannedROI: number = 0;
  numeratorROI: number = 0;
  denominatorROI: number = 0;
  denominator: number = 0;
  numerator: number = 0;
  final: number = 0;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  diffOfROI: number = 0;
  diffOfEff: number = 0;
  financialValue:string = '';


  constructor(
    private portfolioService: PortfolioService,
    private route: ActivatedRoute,
    private sharedData: SharedDataService,
    public router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private globalElementsService: GlobalElementsService,
    private store: StoreService,
  ) { }

  ngOnInit() {
    this.sharedData.getPlanningYear.subscribe((res: number) => {
      if (res != this.workingYear) {
        this.workingYear = res;
        if (this.paramsId) {
          this.getportfolioData();
          this.getPlanData();
        }
      }
    })

    this.route.params.subscribe(params => {
      const portfolioId = params['id'];
      this.paramsId = portfolioId && Number(portfolioId);

      //get year from param
      const year = params['year'];
      if(year && parseInt(year)) {
        this.store.set('planningYear', parseInt(year));
        this.sharedData.setPlanningYear(parseInt(year));
        this.workingYear = parseInt(year);
      }
      const returnAccessFlagResult = returnAccessflag(ACCESS_LEVEL.PORTFOLIO, portfolioId)
      if(!returnAccessFlagResult.result) {
         this.router.navigate(['/accessDenied']);
      }
      
      if(returnAccessFlagResult.marketChange && returnAccessFlagResult.marketId) {
        this.globalElementsService.getCurrencyExchange(returnAccessFlagResult.marketId).subscribe((_res:IMarketCurrency) => {
          if(_res) {
              const xchangeRate = Number(_res['conversionFactor']);
              sessionStorage.setItem('currencySign', _res['currencySign']);
              this.sharedData.setMarket(returnAccessFlagResult.marketId);
              this.sharedData.setCurrencyView(_res['currencyCode']);
              this.sharedData.setCurrencySymbolSource(_res['currencySign'] ? _res['currencySign'] : '$');
              this.sharedData.setCurrencyExchangeRate(xchangeRate);
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.navigate([this.router.url]);
          }
      }, (err) => {
          
      });
    }
  });
    this.getportfolioData();
    this.getPlanData();
    this.getFinancialValueForPortfolio();
  }
  /*........... functionality Methods........ */
  updatePfStatus(event) {
    const isMatched = this.isFormApprover();
    // if(!isMatched){
    //   this.dialog.open(this.approveListDialog, {
    //     width: '500px',
    //     disableClose: true
    //   });
    //   this.updatePortfolioAllocation();
    // }
    // else
    // {
      if(event.value == STATUS_LIST_TYPES.COMPLETE)
      {
        this.dialog.open(this.approveListDialog, {
          width: '500px',
          disableClose: true
        });
      }
      else if(event.value == STATUS_LIST_TYPES.REPLAN && !isMatched){
        this.dialog.open(this.approveListDialog, {
          width: '500px',
          disableClose: true
        });
      }
      else if(event.value == STATUS_LIST_TYPES.DRAFT){
        this.updatePortfolioAllocation();
      }
      else if(event.value == STATUS_LIST_TYPES.FINAL && isMatched){
        this.updatePortfolioAllocation();
      }
      else if(event.value == STATUS_LIST_TYPES.REPLAN && isMatched){
        this.updatePortfolioAllocation();
      }
      
    // }
  }

  getFinancialValueForPortfolio(){
    const postObj = {
      portfolioId: this.paramsId,
      year: this.workingYear
    }
    this.portfolioService.getFinancialValueForPortfolio(postObj).subscribe(response => {
      const fValue = response['portfolioData']['financialValue'];
      this.financialValue = FINANCIAL_VALUES[fValue];
    })
  }

  pfApproverValid(){
    return this.selectedPfApprovers.length > 0 ? true: false;
  }

  closeDialog() {
    this.getportfolioData();
    this.dialog.closeAll();
    this.isStatusConfirm = false;
  }
  isFormApprover(){
    let approversIds: Array<any> = [];
    if (this.overviewData && this.overviewData['approversList']) {
      approversIds = this.overviewData['approversList'].split(',');
    }

    let isMatched = false;

    // if (this.USER_INFO.roleId <= 3) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }



    approversIds.forEach(id => {
      if (this.USER_INFO.id == id) {
        isMatched = true;
      }
    });
    return isMatched;
  }
  updateStatusList() {
    let approversIds: Array<any> = [];
    if (this.overviewData && this.overviewData['approversList']) {
      approversIds = this.overviewData['approversList'].split(',');
    }

    let isMatched = false;

    // if (this.USER_INFO.roleId <= 3) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }



    approversIds.forEach(id => {
      if (this.USER_INFO.id == id) {
        isMatched = true;
      }
    });

    if(this.returnAccess('==', 5) || this.returnAccess('==', 6)){
      this.pfEditableStatus = false;
      this.pfStatusList = STATUS_LIST;
    }
      else if ((this.pfStatus === STATUS_LIST_TYPES.DRAFT || this.pfStatus == null ) && ( this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfStatusList = MINIFIED_STATUS_LIST;
      this.pfEditableStatus = true;
    } else if ((isMatched && this.pfStatus === STATUS_LIST_TYPES.COMPLETE) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfEditableStatus = true;
      this.pfStatusList = STATUS_LIST;
    } else if ((isMatched && this.pfStatus === STATUS_LIST_TYPES.FINAL) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfEditableStatus = true;
      this.pfStatusList = FINAL_STATUS_LIST;
    } else if ((isMatched && this.pfStatus === STATUS_LIST_TYPES.REPLAN) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfEditableStatus = true;
      this.pfStatusList = FINAL_STATUS_LIST;
    } else if ((!isMatched && this.pfStatus === STATUS_LIST_TYPES.FINAL) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfEditableStatus = true;
      this.pfStatusList = FINAL_STATUS_LIST;
    } else if ((!isMatched && this.pfStatus === STATUS_LIST_TYPES.COMPLETE) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfEditableStatus = true;
      this.pfStatusList = MINIFIED_STATUS_LIST;
    } else if ((!isMatched && this.pfStatus === STATUS_LIST_TYPES.REPLAN) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.pfEditableStatus = true;
      this.pfStatusList = ONLY_REPLAN_STATUS_LIST;
    }
  }

  pfApproverChange(apprvData: Array<IApproverList>) {
    this.selectedPfApprovers = apprvData;
  }

  sendData(){
    return this.pfApproverList;
  }
  
  selectChange = (event: any) => {
    this.selectedPfApprovers = event.data;
  };

  updateOverviewBudget() {
    if (this.isOvwBudgetEditMode) {
      this.updatePortfolioAllocation()
    }
    this.isOvwBudgetEditMode = !this.isOvwBudgetEditMode;
  }


  updateOverviewMetrics() {

    if (this.isOvwMetricsEditMode) {
      this.updatePortfolioAllocation()
    }
    this.isOvwMetricsEditMode = !this.isOvwMetricsEditMode;
  }

  /*........... Service/API calls........ */

  getportfolioData() {

    this.overviewData = null;
    let portfolioObj: IPortfolioBody = {
      portfolioId: this.paramsId,
      year: this.workingYear
    }
    this.portfolioService.getPortfolioAllocationPerYear(portfolioObj).subscribe((res: IPfAllocOverview) => {
      if (res) {
        this.portfolioName = res.portfolioName
        this.overviewData = res;
        this.selectedTeamMembers = this.overviewData.teamMembers ? this.overviewData.teamMembers.split(',').map(el => Number(el)) : [];
        this.owBudgetVal = Number(this.overviewData.aWMBudget).toLocaleString();
        this.owROIVal = Number(this.overviewData.aTROI).toLocaleString();
        this.owWMONRVal = Number(this.overviewData.aTWMONR).toLocaleString();

        this.pfStatus = res.referenceCode || STATUS_LIST_TYPES.DRAFT;
        this.oldStatus = res.referenceCode || null;
        this.pathDetails = {
          lastNodeName: 'Portfolio Allocation',
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.PORTFOLIO_ALLOCATION
        }
      }

      this.getApproversList();
      this.getTeamMembersList();
    });

    this.diffOfROI = Number(this.plannedROI- this.owROIVal)/this.owROIVal;

    if (!isFinite(this.diffOfROI)) {

      this.diffOfROI = null;
    }

  }

  getPlanData() {
    const planObj = {
      portfolioId: this.paramsId,
      year: this.workingYear
    }
    this.totalAllocated = 0;
    this.numeratorROI = 0;
    this.denominator = 0;
    this.final = 0;
    this.finalPlannedROI = 0;
    this.plannedROI = 0;
    this.numerator = 0;
    this.portfolioService.getPlanData(planObj).subscribe((res: Array<IPfAllocPlanData>) => {
      if(res){
        res.forEach(el => {
          el.planName = el.planName ? el.planName.toLocaleUpperCase() : '';
        })
        res = res.sort((a, b)=> a.planName >= b.planName ? 1 : -1);
      }
      const planData = res || [];

      let totalRevenue = 0;
      let prevYrRevenue = 0;

      planData.forEach(plan => {

        this.totalAllocated += Number(plan.aBudgetInput) || 0;
        totalRevenue += Number(plan.estRevenue) || 0;
        //  this.plannedROI = this.totalAllocated ? totalRevenue / this.totalAllocated : 0;
        this.numeratorROI += Number(plan.estROI * plan.aBudgetInput);
        this.numerator += Number(plan.estEffectiveness * plan.aBudgetInput);

        this.denominator += Number(plan.aBudgetInput);
        this.final = this.numerator / this.denominator;
        this.finalPlannedROI = this.numeratorROI / this.denominator;
        this.plannedROI = this.totalAllocated ? totalRevenue / this.totalAllocated : 0;

        this.plannedROI = this.totalAllocated ? totalRevenue / this.totalAllocated : 0;
        this.numeratorROI += Number(plan.estROI * plan.aBudgetInput);
        this.numerator += Number(plan.estEffectiveness * plan.aBudgetInput);

        this.denominator += Number(plan.aBudgetInput);
        this.final =  this.numerator/this.denominator;
        this.finalPlannedROI = this.numeratorROI/this.denominator;
      
        prevYrRevenue += Number(plan.prevYearNetRev);
        this.plannedWMONR = prevYrRevenue ? this.totalAllocated / prevYrRevenue : 0;

      })
      //this.portfolioAllocationPlanData = res.portfolioAllocationPlanData;
      this.dataSource = new MatTableDataSource<IPfAllocPlanData>(res);
    });
  }


  addTeamMembers(){
    
    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
      teamMembers : this.teamMembersList,
      selectedMembers : this.selectedTeamMembers
      },
      height: '400px',
      width: '500px',
      disableClose:true
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
     // this.updateTeamMembers()
      this.updatePortfolioAllocation();
      this.generateTeamMemeberAvatars(); 
  
    });
  }

  generateTeamMemeberAvatars(){
    if(this.teamMembersList){
      const selectedUsers = this.teamMembersList.filter(tm => this.selectedTeamMembers.includes(tm.userId));
      this.selectedTeamMembersAvatar = selectedUsers.map(user => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        }
     
        return {
          initials,
          avatar
        }
      })
    }
    
  }
  sendMessageToCollaborators(){
    let data = this.constructPfOverviewPostObj();
    let snDialogRef;
      snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
        data: {
          data :data,
          type:FORM_REFERENCE_TYPES.PORTFOLIO_ALLOCATION,
          },
        height: '150px',
        width: '500px',
        disableClose:true
      });

    snDialogRef.afterClosed().subscribe((res: any) => {
    });
    
  }


  getTeamMembersList(){
    let teamMemberReq : ITeamMembersReq = {
      referenceId: this.paramsId,
      referenceType: FORM_REFERENCE_TYPES.PORTFOLIO,
      year: this.workingYear
    }

    this.globalElementsService.getTeamMembersList(teamMemberReq).subscribe((res:Array<ITeamMembers>)=>{
      this.teamMembersList = res ? res.sort((a, b)=> a.name >= b.name ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.teamMembersList){
        this.teamMembersList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.userId);
          if (!isDuplicate) {
            uniqueIds.push(el.userId);
        
            return true;
          }
          return false;
        })
      }
      this.generateTeamMemeberAvatars();
    })
    
  }

  getApproversList() {
    let obj = {
      referenceId: this.paramsId,
      referenceType: FORM_REFERENCE_TYPES.PORTFOLIO,
      year: this.workingYear
    }
    this.globalElementsService.getApproversList(obj).subscribe((res: Array<IApproverList>) => {
      this.pfApproverList = res;
      this.updateStatusList();
      const approvers = this.overviewData['approversList'] ? this.overviewData['approversList'].split(',') : [];
      this.selectedPfApprovers = [];
      approvers.forEach(id => {
        const pfApprover = this.pfApproverList.find((el: any) => el.userId == id);
        if (pfApprover) {
          this.selectedPfApprovers.push(pfApprover);
        }
      });
      this.pfApprover.setValue(this.selectedPfApprovers);
    })
  }


  updatePortfolioAllocation() {
    const ovwData = this.constructPfOverviewPostObj();
    this.portfolioService.updatePortfolioAllocation(ovwData).subscribe((res: any) => {
      this.isStatusConfirm = true;
      this.statusUpdateRes = res.message;
      this.getportfolioData();
    });
  }

  



  updatePlanBudget(plan, newBudget) {
    newBudget = newBudget.replace(/,/g, '');
    const planData = this.constructPlanBudgetPostObj(plan.portfolioAllocPlanId, Number(newBudget));
    this.portfolioService.updatePortfolioAllocationPlan(planData).subscribe((res: any) => {
      this.getPlanData();
    })
  }



  constructPlanBudgetPostObj(portfolioAllocPlanId, newBudget) {
    const planAllocation: IpfAllocPlanSave = {
      aBudgetInput: newBudget,
      portfolioAllocPlanId,
      portfolioId: this.overviewData.portfolioId,
      year: this.overviewData.year
    }

    return planAllocation
  }

  constructPfOverviewPostObj() {
    const approversIds = this.selectedPfApprovers && this.selectedPfApprovers.map((obj: IApproverList) => obj.userId);
    const { completedByUserId, finalizedByUserID } = getCompletedAndFinalizedUsers(this.oldStatus, this.pfStatus, this.overviewData);
    const scenarioData = {
      allocationId: this.overviewData.allocationId,
      portfolioId: this.overviewData.portfolioId,
      year: this.overviewData.year,
      alertURL: `/portfolioAllocation/${this.paramsId}/${this.workingYear}`,
      referenceCode: this.pfStatus,
      aWMBudget: this.isOvwBudgetEditMode ? parseFloat(this.owBudgetVal.toString().replace(/,/g, '')) : this.overviewData.aWMBudget,
      aTROI: this.isOvwMetricsEditMode ? parseFloat(this.owROIVal.toString().replace(/,/g, '')) : this.overviewData.aTROI,
      aTWMONR: this.isOvwMetricsEditMode ? parseFloat(this.owWMONRVal.toString().replace(/,/g, '')) : this.overviewData.aTWMONR,
      teamMembers: this.selectedTeamMembers.toString(),
      completedByUserId: completedByUserId,
      finalizedByUserID: finalizedByUserID,
      approversList: approversIds ? approversIds.toString() : '',
      currentUserID: this.USER_INFO.id
    }
    return scenarioData;
  }
  CommaFormatted(event) {
    if(event.which >= 37 && event.which <= 40) return;
   
    if (event.target.value) {
      event.target.value = event.target.value.replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
   
   numberCheck (args) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }
  }
  CalculateROI(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owROIVal = a.join(".");
    }
  }
  CalculateowWMONRVal(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owWMONRVal = a.join(".");
    }
  }
  CalculateBudget(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owBudgetVal = a.join(".");
    }
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId,_oprator, this.paramsId);
  }

}
