import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PortfolioService } from '../services/portfolio.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from '../../common/utils/common-util';
import { DASHBOARD_MESSAGE } from '../../constants/app-constants';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-portfolio-rto-dashboard',
  templateUrl: './portfolio-rto-dashboard.component.html',
  styleUrls: ['./portfolio-rto-dashboard.component.scss']
})
export class portfolioPerformanceDashboardComponent implements OnInit {

  @Input() elementDetails;
  
  portfolioRTODashIframe;
  portfolioId: string;
  portfolioName: string;
  isLoading: boolean = false;
  message: string;

  constructor(
    private sanitizer: DomSanitizer,
    private portfolioService : PortfolioService,
    protected googleTagManagerService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    this.isLoading = true;
   
    if (this.elementDetails.portfoliodata) {
      this.portfolioName = this.elementDetails.portfoliodata.portfolioName;
      this.portfolioId = this.elementDetails.portfoliodata.portfolioId;
      this.getPortfolioDashboardUrl();
    } else {
      this.message = DASHBOARD_MESSAGE.DATORAMA_SUCCESS.replace("##PLANNAME##", this.portfolioName);
      this.isLoading = false;
    } 
    this.googleTagManagerService.pushTag(
            {
              event: `custom_${environment.environmentName}_Event`,
              eventCategory: `PortFolio_Performance_Dashboard`,
              eventAction: "PageView",
              eventLable: `PortFolio Performance Dashboard Page View`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
            });
  }

  getPortfolioDashboardUrl() {
    const urlObj = { ...this.elementDetails.cPerfData }
      this.portfolioService.getPortfolioDashboardUrl(this.portfolioId.toString()).subscribe((res) => {
      if (res && res['rtoLink']) {
        this.portfolioRTODashIframe = this.sanitizer.bypassSecurityTrustResourceUrl(res['rtoLink']);
      }
      this.message = DASHBOARD_MESSAGE.DATORAMA_SUCCESS.replace("##PLANNAME##", this.portfolioName);
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.message = DASHBOARD_MESSAGE.ERROR;
    });
  }

  ngOnDestroy(): void {

        this.googleTagManagerService.pushTag(
          {
            event: `custom_${environment.environmentName}_Event`,
            eventCategory: `Campaign_Performance_Dashboard`,
            eventAction: "PageExit",
            eventLable: `Campaign Performance Dashboard Page Exit`,
            customeEvent: `custom_${environment.environmentName}_Event`,
            userId: getGpId(),
            user_id: getGpId()
          });
       }

}
