import { BrandManagementService } from './../../../../global-elements/services/brand-management.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-brand-management-dialogs',
  templateUrl: './brand-management-dialogs.component.html',
  styleUrls: ['./brand-management-dialogs.component.scss']
})
export class BrandManagementDialogsComponent implements OnInit {

  message: string = '';
  isErr: boolean = false;
  isDelMode:boolean = false;
  isScenarioMode:boolean = false;
  isScenarioDelConfirmed:boolean = false;
  errMsg: string = ''
  isDelConfirmed: boolean = false;
  apiResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private brandManagementService: BrandManagementService
  ) { }

  ngOnInit() {
    this.isDelMode = this.data.type == 'delete';
    this.isErr = false;
    this.errMsg = '';
    switch (this.data.type) {
      case 'save':
        this.saveModule();
        break;

      case 'update':
        this.updateModule();
        break;


      case 'delete':
        this.message = "Are you sure you want to delete?"
        if (this.data.children) {
          this.cannotDelete();
        }
        break;


    }

  }

  updateModule() {
    this.isErr = false;
    const { apiData, module } = this.data;
    switch (module) {

      case 'market':
        this.message = "Saving Market... Please Wait";
        this.brandManagementService.saveMarket(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Market updated successfully."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Market.`;

        });
        break;


      case 'marketGroup':
        this.message = "Saving Market Group... Please Wait";
        this.brandManagementService.saveMarketGroup(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saved Market Group"
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Market Group.`;
        });

        break;

      case 'portfolio':
        this.message = "Saving Portfolio... Please Wait";
        this.brandManagementService.savePortfolio(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully saved Portfolio."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Portfolio.`;
        });
        break;

      case 'portfolioGroup':
        this.message = "Saving Portfolio Group... Please Wait!!";
        this.brandManagementService.savePortfolioGroup(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully saved Portfolio Group."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Portfolio Group.`;
        });
        break;


      case 'brand_program':
        this.message = "Saving Brand... Please Wait";
        this.brandManagementService.editBrand(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully saved Brand under PROGRAM level."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = 'Error Saving brand';
        });
        break;


      case 'brand_plan':

        this.brandManagementService.getUpfrontAllocationByPlan(apiData).subscribe(res => {
          // this.apiResponse = res;
          if(res['status'] == true ){
            this.message = "Unselecting a Sub Brand will hide all scenarios configured under that Sub Brand in the UI. Please confirm to continue."
            this.isScenarioMode = true

          }
          else{
            // this.message = "Upfront not existed"
              this.message = "Saving Brand... Please Wait";
              this.brandManagementService.editBrand(apiData).subscribe(res => {
                this.apiResponse = res;
                this.message = "Successfully saved Brand under PLAN level."
              }, err => {
                this.apiResponse = err;
                if (err.status == 476) {
                  this.isErr = true;
                  this.errMsg = err.error.message;
                }

                this.message = 'Error Saving brand';
              });
          }
          


        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = 'Error Saving brand';
        });
        
      
        break;


      default:
        break;
    }
  }

  saveModule() {
    this.isErr = false;
    const { apiData, module } = this.data;
    switch (module) {
      case 'market':
        this.message = "Saving Market... Please Wait";
        this.brandManagementService.saveMarket(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully added a new Market."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Market.`;

        });
        break;

      case 'marketGroup':
        this.message = "Saving Market Group... Please Wait";
        this.brandManagementService.saveMarketGroup(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully added a new Market Group."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Market Group.`;
        });

        break;

      case 'portfolio':
        this.message = "Saving Portfolio... Please Wait";
        this.brandManagementService.savePortfolio(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully added a new Portfolio."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Portfolio.`;
        });
        break;

      case 'portfolioGroup':
        this.message = "Saving Portfolio Group... Please Wait";
        this.brandManagementService.savePortfolioGroup(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully added a new Portfolio Group."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Portfolio Group.`;
        });
        break;


      case 'brand_program':
        this.message = "Saving Brand... Please Wait";
        this.brandManagementService.saveBrandAsProgram(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully added a new Brand under PROGRAM level."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = 'Error Saving brand';
        });
        break;


      case 'brand_plan':
        this.message = "Saving Brand... Please Wait";
        this.brandManagementService.saveBrandAsPlan(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully added a new Brand under PLAN level."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = 'Error Saving brand';
        });
        break;


      default:
        break;
    }
  }




  initiateDelete() {
    this.isErr = false;
    this.isDelConfirmed = true;
    const { apiData, module } = this.data;


    switch (module) {
      case 'market':
        this.message = "Deleting Market(s)...";
        this.brandManagementService.deleteMarket(apiData).subscribe(res => {
          this.message = "Successfully Deleted Market(s)";
          this.apiResponse = res;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Market(s).`;
        })
        break;

      case 'marketGroup':
        this.message = "Deleting Market Group(s)...";
        this.brandManagementService.deleteMarketGroups(apiData).subscribe(res => {
          this.message = "Successfully Deleted Market Group(s)";
          this.apiResponse = res;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Market Group(s).`;
        })
        break;



      case 'portfolio':
        this.message = "Deleting Portfolio(s)...";
        this.brandManagementService.deletePortfolios(apiData).subscribe(res => {
          this.message = "Successfully Deleted Portfolio(s)";
          this.apiResponse = res;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Portfolio(s).`;
        })
        break;



      case 'portfolioGroup':
        this.message = "Deleting Portfolio Group(s)...";
        this.brandManagementService.deletePortfolioGroups(apiData).subscribe(res => {
          this.message = "Successfully Deleted Portfolio Group(s)";
          this.apiResponse = res;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Portfolio Group(s).`;
        })
        break;


      case 'brand':
        this.message = "Deleting Brand(s)...";
        this.brandManagementService.deleteBrands(apiData).subscribe(res => {
          this.message = "Successfully Deleted Brand(s)";
          this.apiResponse = res;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Brand(s).`;
        })
        break;

      default:
        break;
    }

  }

  initiateDeleteSubBrand(){
    this.isScenarioDelConfirmed = true;
    const { apiData, module } = this.data;
    this.message = "Deleting Sub-Brand... Please Wait";
    this.brandManagementService.editBrand(apiData).subscribe(res => {
      this.apiResponse = res;
      this.message = "Successfully saved Brand under PLAN level."          

    }, err => {
      this.apiResponse = err;
      if (err.status == 476) {
        this.isErr = true;
        this.errMsg = err.error.message;
      }
      this.message = 'Error Saving brand';
    });
  }
  
  cannotDelete() {
    this.isDelConfirmed = true;

    const { childrenList, moduleNames, moduleCount } = this.data.children;
    const single_msg_suffix = "has associations ##associations## that must be removed before it can be deleted. Please remove associated entries and try again.";
    const multi_msg_suffix = "cannot be deleted as they have several associations that must be removed before they can be deleted. Please remove associated entries and try again.";
    let suffix = multi_msg_suffix;

    if (moduleCount == 1) {
      suffix = single_msg_suffix.replace('##associations##', `[${childrenList[0]}, ${childrenList[1]}...]`)
    }

    this.message = `${moduleNames} ${suffix}`;
  }

}
