import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProgramService2 } from 'app/program/services/program.service';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { isValidUserAccess } from 'app/common/utils/common-util'

@Component({
  selector: 'app-program-dialog',
  templateUrl: './program-dialog.component.html',
  styleUrls: ['./program-dialog.component.scss']
})
export class ProgramDialogComponent implements OnInit {
  message: string = '';
  isErr: boolean = false;
  errMsg: string = '';
  apiResponse;
  assets: any;
  campaignIdeaList;
  campIdea;
  userInfo;
  isDelMode: boolean;
  isDelConfirmed: boolean;
  delYtxt:string = 'Yes';
  delNtxt:string = 'No';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private programService: ProgramService2,
    private dialogRef: MatDialogRef<ProgramDialogComponent>,
    private globalElementsService: GlobalElementsService,
  ) { this.userInfo = JSON.parse(sessionStorage.getItem("user"));}

  ngOnInit() {
    this.isErr = false;
    this.errMsg = '';
    switch (this.data.mode) {
      case 'save':
        this.saveData();
        break;

      case 'update':
        this.updateData();
        break;

      case 'delete':
        this.validateDelete();
        break;

      case 'editLock':
        this.displayLockMsg();
        break;

      case 'openCreativeXPopup':
        this.displayCreativeXPopupkMsg();
        break;

    }
    this.getCampaignDAMLogos();
    if(this.data.type=='import'){
      this.campaignIdeaList=this.data.campaignIdeaList
    }
  }
 
  importCampaignIdea(){    
    this.dialogRef.close(this.campIdea);
  }

  selectLogo(sel: any) {
    // this.assets.forEach(element => {
    //   delete element.selected;
    //   if (element.webImage == sel.webImage) {
    //     element.selected = true;
    //     this.selectedLogoImg = sel.webImage;
    //     this.campaignIdeaForm.patchValue({
    //       cmpLogo: sel.webImage
    //     });
    //   }
    // });
    this.dialogRef.close({ img: sel.webImage })
  }
  getCampaignDAMLogos() {
    this.programService.getCampaignDAMLogos().subscribe((res: any) => {
      this.assets = res;
    });
  }

  close(){
    this.dialogRef.close();
  }
  
  updateData() {
    this.isErr = false;
    const { apiData, type } = this.data;
    switch (type) {

      case 'updateOppBrief':
        this.message = "Saving Opportunity Brief Information... Please Wait!!";
        this.programService.editOppourtinyBrief(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saved Opportunity Brief information."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error in saving Opportunity Brief information..`;
        });

        break;

      case 'updateCampaignIdea':
        this.message = "Saving Campaign Idea... Please Wait";
        this.programService.editCampaignIdea(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saving Campaign Idea."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error in saving Campaign Idea..`;
        });

        break;

        case 'editCommsTask':
          this.message = "Updating Comms Task... Please Wait!!";
          this.programService.editCommsTask(apiData).subscribe(res => {
            this.apiResponse = res;
            this.message = "Successfully updated  Comms Task."
          }, err => {
            this.apiResponse = err;
            if (err.status == 476) {
              this.isErr = true;
              this.errMsg = err.error.message;
            }
  
            this.message = `Error in updating  Comms Task..`;
          });
  
          break;

      default:
        break;
    }
  }

  displayLockMsg(){
    const { displayMsg } = this.data;
    this.message = displayMsg
  }
  
  displayCreativeXPopupkMsg(){
    this.message = "Thank you for entering your campaign information. As you plan channel level detail, please keep in mind that creative testing with Ada Digital Intelligence (powered by CreativeX) should be completed before the campaign airs and you should only publish creatives that pass on all the Brilliant Basic guidelines";
  }


  saveData() {
    this.isErr = false;
    const { apiData, type } = this.data;
    switch (type) {
      case 'saveCampaignIdea':
        this.message = "Saving Campaign Idea... Please Wait";
        this.programService.saveCampaignIdea(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saved Campaign Idea."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error in saving Campaign Idea..`;
        });

        break;

        case 'saveCommsTask':
          this.message = "Saving Comms Task... Please Wait!!";
          this.programService.addCommsTask(apiData).subscribe(res => {
            this.apiResponse = res;
            this.message = "Successfully saved  Comms Task."
          }, err => {
            this.apiResponse = err;
            if (err.status == 476) {
              this.isErr = true;
              this.errMsg = err.error.message;
            }
  
            this.message = `Error in saving  Comms Task..`;
          });
  
          break;
        
      default:
        break;
    }
  }

  validateDelete() {
    this.isErr = false;
    const { type } = this.data;
    switch (type) {
      case 'deleteCampaign':
        this.validateDeleteCampaign();
        break;


      case 'campaignIdea':
        this.delNtxt = 'Cancel';
        this.delYtxt = 'Clear Form'
        this.message = "Are you sure you want to clear Campaign Idea?";
        this.isDelMode = true;
        this.isDelConfirmed = false;
        break;

      case 'clearOptyBrief':
        this.delNtxt = 'Cancel';
        this.delYtxt = 'Clear Form'
        this.message = "Are you sure you want to clear Opportunity Brief?";
        this.isDelMode = true;
        this.isDelConfirmed = false;
        break;



      case 'deleteCommstask':
        this.delNtxt = 'OK';
        this.delYtxt = 'Confirm Deletion'
        this.message = "By deleting this Comms Task, you will be deleting all Touchpoints created within.   Please review the Touchpoints associated with the Comms Task before selecting Confirm.";
        this.isDelMode = true;
        this.isDelConfirmed = false;
        break;
    }
  }

  initiateDelete(){ 
    this.isErr = false;
    const { apiData, type } = this.data;
    switch (type) {
      
      case 'deleteCampaign':
        this.message = "Deleting Campaign...";
        this.programService.deleteCampaign(apiData).subscribe(res => {
          this.message = "Successfully Deleted Campaign.";
          this.apiResponse = res;
          this.isDelConfirmed = true;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.isDelConfirmed = true;
          this.message = `Error Deleting Campaign.`;
        })
        break;

      case 'campaignIdea':
   
        this.message = "Clearing Campaign Idea.";
        this.programService.clearCampaignIdeaForm({ formId : apiData }).subscribe(res => {
          this.message = "Successfully Cleared Campaign Idea.";
          this.apiResponse = res;
          this.isDelConfirmed = true;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.isDelConfirmed = true;
          this.message = `Error clearing Campaign Idea.`;
        })

        break;


        case 'clearOptyBrief':
        this.message = "Clearing Opportunity Brief.";
        this.programService.clearOpportunityBriefForm({ formId: apiData }).subscribe(res => {
          this.message = "Successfully Cleared Opportunity Brief.";
          this.apiResponse = res;
          this.isDelConfirmed = true;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.isDelConfirmed = true;
          this.message = `Error clearing Opportunity Brief.`;
        })

        break;


        



      case 'deleteCommstask':
        this.message = "Deleting Comms Task...";
        this.programService.deleteCommsTask(apiData).subscribe(res => {
          this.message = "Successfully Deleted Comms Task.";
          this.apiResponse = res;
          this.isDelConfirmed = true;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.isDelConfirmed = true;
          this.message = `Error Deleting Comms Task.`;
        })
        break;

    }
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator, null, this.data && this.data.planId ? this.data.planId : null)
  }
  validateDeleteCampaign() {
    const { apiData } = this.data;
    this.message = "Validating Campaign data. Please wait..";
    this.programService.validateDeleteCampaign(apiData).subscribe((res: any) => {
      if (res && res.isValid) {
        this.initiateDelete();
      } else {
        this.isDelConfirmed = true;
        this.message = "A Campaign with a comms task(s) associated to it cannot be deleted.   You must first delete the comms task(s)."
      }
    }, err => {
      if (err.status == 476) {
        this.isErr = true; 
        this.errMsg = err.error.message;
      }
      this.isDelConfirmed = true;
      this.message = `Error in validating Campaign data.`;
    })
  }

}
