import { Component, OnInit, Input } from '@angular/core';
import { GlobalElementsService } from '../../services/global-elements.service';
import { MatDialog } from '@angular/material';
import { CancelDialogComponent } from 'app/common/components/dialogs/cancel-dialog/cancel-dialog.component';
import { SharedDataService } from 'app/common/services/shared-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() isParentDirty: any

  isDisableSave: boolean = true;
  showSave: boolean = false;
  isWide: boolean = false;

  constructor(
    public globalElementsService: GlobalElementsService,
    public dialog: MatDialog,
    private sharedData: SharedDataService,
  ) { }



  ngOnInit() {

    this.globalElementsService.getShowSaveBtn.subscribe((showSave: boolean) => this.showSave = showSave);

    this.globalElementsService.getSidePanelSaveEnableState.subscribe((shouldEnable: boolean) => {
      this.isDisableSave = !shouldEnable;
    })
    this.sharedData.sideNavSelection.subscribe(res => {
      if(res){
        this.isWide = res['id'] == 17 || res['id'] == 18 || res['id'] == 24 ? true : false ;   
       }
    });  
  }

  handleSave() {
    this.globalElementsService.setSidePanelSaveEvent();
  }

  handleCancel() {
    if (this.isParentDirty) {
      let dialogRef;

      dialogRef = this.dialog.open(CancelDialogComponent, {
        minWidth: '500px'
      });

      dialogRef.afterClosed().subscribe((res: any) => {
        if (res.status) {
          this.dialog.closeAll();
          this.globalElementsService.setSidePanelCancelEvent();
        }
      });
    }

    else {
      this.globalElementsService.setSidePanelCancelEvent();

    }

  }


}
