import { Component, OnInit, Input } from '@angular/core';
import { GlobalElementsService } from '../../services/global-elements.service';
import { MatDialog } from '@angular/material';
import { CancelDialogComponent } from 'app/common/components/dialogs/cancel-dialog/cancel-dialog.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() sideNavElement;
  @Input() isParentDirty: any

  constructor(public globalElementsService: GlobalElementsService,
    public router: Router,
    public dialog: MatDialog,) { }

  ngOnInit() {
  }
  handleCancel() {
    // if (this.isParentDirty) {
    //   let dialogRef;

    //   dialogRef = this.dialog.open(CancelDialogComponent, {
    //     minWidth: '500px',
    //   });

    //   dialogRef.afterClosed().subscribe((res: any) => {
    //     if (res.status) {
    //       this.dialog.closeAll();
    //       this.globalElementsService.setSidePanelCancelEvent();
    //     }
    //   });
    // } 
    // else {
      this.globalElementsService.setSidePanelCancelEvent();
      this.router.navigate([`/appRouting`]);
    // }
  }

}
