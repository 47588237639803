import { Injectable, NgZone } from '@angular/core';
import { CoreService } from '../core.service';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor( private coreService: CoreService,public zone: NgZone) {}
  show() {
    this.coreService.loader.isLoading = true;
    }

  hide() {
    this.coreService.loader.isLoading = false;
  }
}
