import { Component, OnInit } from '@angular/core';
import { PAGE_MODES } from './../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-brand-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {


  selectedBrandList = [];
  mode = PAGE_MODES.HOME;
  userInfo;

  constructor(protected googleTagManagerService: GoogleTagManagerService) { this.userInfo = JSON.parse(sessionStorage.getItem("user")); }

  ngOnInit() {


  }

  getSelectedBrand(childEmmitedEvent) {
    this.selectedBrandList = childEmmitedEvent;
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME || this.mode == PAGE_MODES.DELETE;
  }

  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "Add Brand",
      eventLable: `Global Elements New Brand click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }



  enableEditMode() {
    this.changePageMode(PAGE_MODES.EDIT);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "Edit Brand",
      eventLable: `Global Elements Edit Brand click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  enableHomePageMode() {
    this.changePageMode(PAGE_MODES.HOME);
  }

  enableDeleteMode() {
    this.changePageMode(PAGE_MODES.DELETE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "Delete Brand",
      eventLable: `Global Elements Delete Brand click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  changePageMode(pageMode) {
    this.mode = pageMode
  }

}
