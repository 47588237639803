import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { MatDialog } from '../../../../../../node_modules/@angular/material';
import { BrandManagementDialogsComponent } from '../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { IBrandsMDM, IBrandEdit } from '../../../../common/interfaces/brandManagement';
import { IBrandSave, ISubbrandsPlansMDM } from './../../../../common/interfaces/brandManagement';
import { BRAND_LEVEL, PAGE_MODES } from './../../../../constants/app-constants';
import { BrandManagementService } from './../../../services/brand-management.service';
import { take } from '../../../../../../node_modules/rxjs/operators';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { SPACE} from '@angular/cdk/keycodes';


@Component({
  selector: 'app-enhance-brand',
  templateUrl: './enhance-brand.component.html',
  styleUrls: ['./enhance-brand.component.scss']
})

export class EnhanceBrandComponent implements OnInit, OnDestroy {
  @Input() pageMode;
  @Input() editData;
  @Output() changePageModeEmitter = new EventEmitter
  @Output() brandSelectionEvent = new EventEmitter
  
  @ViewChild('logoUploadDialog', { static: true }) logoUploadDialog: TemplateRef<any>;

  userInfo = JSON.parse(sessionStorage.getItem('user'));  

  @Output('dirty')
  formDirty = new EventEmitter<boolean>()

  isEditMode = false;
  brandForm: FormGroup;
  displayName: string;
  marketsList = [];
  brandLevels = BRAND_LEVEL;
  brandsMDMList: Array<IBrandsMDM> = [];
  masterbrandsMDMList: Array<IBrandsMDM> = [];
  subBrandsList: Array<ISubbrandsPlansMDM> = [];
  selectedSubBrandList: Array<string> = [];
  selectedBrandLevel: string;
  portfoliosList = [];
  plansList: any;
  selectedLogoImg: any;
  assets: any;
  isBrandPROGRAMlevel: boolean = true;
  saveBtnSubscription: Subscription;
  isCustomBrand: boolean = false;
  isBrandNameUnq: boolean = false;
  customBrandName: string;
  customSubBrandsList:Array<string> = [];
  canvasSubBrandsWithDeleteData = [];
  search:any;
  isBrandClicked: boolean = false;
  showOverViewTooltip:boolean = false;
  toolTipData:string;
  selectedMarketTooltip = sessionStorage.getItem('market');
  isLoading:boolean = false;
  isLoadingMessage:string;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }


  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !==6);

    this.globalElementsService.setSaveEnableState(false);
    this.globalElementsService.getTooltipData('SubBrand', this.selectedMarketTooltip).subscribe((res_n: any) => {
      if(res_n.data && res_n.data[0]) {
        this.toolTipData = res_n.data[0]["tooltipText"];
      }
    });

    this.displayName = 'Create New Brand'
    this.isEditMode = this.pageMode == PAGE_MODES.EDIT;
    this.getMDMBrands();
    this.getMarkets();
    this.iniitializebrandForm();
    // this.getAllAssets();
    this.brandForm.valueChanges.subscribe(() => {
      const isFormValid = this.isCustomBrand ? (this.isBrandNameUnq && this.brandForm.valid) : this.brandForm.valid;
      this.globalElementsService.setSaveEnableState(isFormValid);
      this.formDirty.emit(this.brandForm.dirty);
    })


    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());

    if (this.isEditMode) {
      this.displayName = 'Edit Brand'
      this.updateBrandForm();
    }
    
    // Disable the form on View Only mode

    this.userInfo.roleId === 6 ? this.brandForm.disable() : null;



  }
  
  iniitializebrandForm() {
    this.brandForm = this.fb.group({
      brandLevel: [BRAND_LEVEL.PROGRAM, Validators.required],
      brand: [, Validators.required],
      brandLogo: [''],
      marketId: [null, Validators.required],
      portfolioId: [null, Validators.required],
      planId: [null],
      subBrands: [null],
      customBrandName: [null],
      customSubBrand:[null]
    })
  }

  /*........... functionality Methods........ */


  brandLevelChange(brandLevel: string) {
    this.selectedBrandLevel = BRAND_LEVEL[brandLevel];
    this.isBrandPROGRAMlevel = this.selectedBrandLevel === BRAND_LEVEL.PROGRAM;
    if (this.isBrandPROGRAMlevel) {
      this.brandForm.get("planId").patchValue(null);
      this.brandForm.get("planId").setValidators([Validators.required]);
      this.brandForm.get("planId").updateValueAndValidity();

      this.brandForm.get("subBrands").clearValidators();
    } else {
      this.brandForm.get("subBrands").setValidators([Validators.required]);
      this.brandForm.get("subBrands").updateValueAndValidity();

      this.brandForm.get("planId").clearValidators();
    }

  }

  brandNameChange(brandName: string) {
    this.subBrandsList = [];
    this.patchFormWithSubBrands();
    this.getMDMsubBrands(brandName);
  }
  sendData(){
    return this.brandsMDMList;
  }
  getMDMBrandsData(brandName:string){
    this.brandForm.patchValue({
      brand: brandName
    });
    this.brandNameChange(brandName);
  }
  getClickEvent(event){
    this.isBrandClicked = true;
  }
  sendSelectedValue(){
    return this.brandForm.get('brand').value ? this.brandForm.get('brand').value: null;

  }
  showBrandRequiredError(){
    if(this.isBrandClicked){
      return this.formErrorHandeling('brand','required');
    } else {
      return false;
    }
  }

  isBrandNameUnique() {
    this.brandForm.get("brand").clearValidators();
    this.brandForm.get("subBrands").clearValidators();
    this.brandForm.get("subBrands").updateValueAndValidity();
    this.brandForm.get("brand").updateValueAndValidity();
    let {customBrandName} = this.brandForm.value;
    customBrandName= customBrandName && customBrandName.toLowerCase();
    //check if brandName exists in MDM list
    this.isBrandNameUnq = !(this.brandsMDMList.find(brand=>brand.mdmBrandName.toLowerCase()==customBrandName));

    const error = !this.isBrandNameUnq ? {notUnique : true} : null;
   this.brandForm.get("customBrandName").setErrors(error)
   
    //check if brandName exists in canvas brands list
    //console.log('Brand Name', brandName);
  }

  isSubBrandSelected(subBrand) {
    // return this.selectedSubBrandList.includes(subBrand.mdmSubBrandName);
    // if(subBrand.isDeleted || this.selectedSubBrandList.filter(e => e === subBrand.mdmSubBrandName).length == 0 )
    //   return false;
    // else
    //   return true;
    let isDeletedValue = this.canvasSubBrandsWithDeleteData.find(element => element.displayName == subBrand)? this.canvasSubBrandsWithDeleteData.find(element => element.displayName == subBrand).isDeleted : true;    
    if(isDeletedValue || this.selectedSubBrandList.includes(subBrand.mdmSubBrandName) )
      return false;
    else
      return true;    
  }

  addSubBrandToForm(subBrandName: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedSubBrandList.push(subBrandName);
    } else {
      this.selectedSubBrandList = this.selectedSubBrandList.filter(name => name !== subBrandName);
    }

    this.patchFormWithSubBrands();
  }

  addSubBrandToFormInit(subBrandName: string, isChecked: boolean) {
    this.canvasSubBrandsWithDeleteData = this.editData[0].subBrandsWithDeleteData || [];
    if (isChecked) {
      let existingData = this.canvasSubBrandsWithDeleteData.find(element => element.displayName == subBrandName);
      if(existingData && existingData.isDeleted == true){}        
      else  
        this.selectedSubBrandList.push(subBrandName);
    } else {
      this.selectedSubBrandList = this.selectedSubBrandList.filter(name => name !== subBrandName);
    }
    this.patchFormWithSubBrands();
  }


  patchFormWithSubBrands() {
    this.brandForm.patchValue({
      subBrands: this.selectedSubBrandList
    });
  }



  marketChange(mktId: number) {
    this.brandForm.get("portfolioId").patchValue(null);
    this.brandForm.get("planId").patchValue(null);
    this.portfoliosList = [];
    this.getPortfoliosByMkt(mktId);
  }

  portfolioChange(portfId: number) {
    this.brandForm.get("planId").patchValue(null);
    this.plansList = [];
    this.getPlanByPortfolio(portfId);
  }

  openImgUploadDialog() {
    this.isLoading = true;
    this.isLoadingMessage = 'Loading data please wait...';
    this.assets = [];
    this.dialog.open(this.logoUploadDialog, {
      height: '700px',
      width: '800px',
      disableClose: true
    });
    this.getAllAssets(this.brandForm);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  selectLogo(sel: any) {
    this.assets.forEach(element => {
      delete element.selected;
      if (element.webImage == sel.webImage) {
        element.selected = true;
        this.selectedLogoImg = sel.webImage;
        this.brandForm.patchValue({
          brandLogo: sel.webImage
        });
      }
    });
    this.closeDialog();
  }

  updateBrandForm() {

    const brandData = this.editData[0];

    this.getPortfoliosByMkt(brandData.marketId);
    this.getPlanByPortfolio(brandData.portfolioId);
    this.selectedLogoImg = brandData.brandWebImageURL;
    this.brandLevelChange(brandData.brandType);
    this.brandNameChange(brandData.brandName);

    this.brandForm.patchValue({
      brandLevel: brandData.brandType,
      brandLogo: brandData.brandWebImageURL,
      marketId: brandData.marketId,
      brand: brandData.brandName,
      portfolioId: brandData.portfolioId,
      planId: brandData.planId
    });

    if (brandData.brandType == this.brandLevels.PLAN) {
      brandData.subBrands.forEach(sb => this.addSubBrandToFormInit(sb, true));
      // brandData.subBrands.forEach(sb => this.addSubBrandToForm(sb.displayName, true));
    } 

  }

  onSubmit() {
 
    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        type: this.isEditMode ? 'update' : 'save',
        module: this.isBrandPROGRAMlevel ? 'brand_program' : 'brand_plan',
        apiData: this.isEditMode ? this.constructBrandEditObj() : this.constructBrandObj()
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {   
      this.backToHome();
    });
  }

  addSubBrands() {
    const { customSubBrand } = this.brandForm.value;
    if(customSubBrand){
      // this.isCustomBrand = true;
      this.customSubBrandsList.push(customSubBrand);
      this.brandForm.get("customSubBrand").patchValue(null); 
      this.brandForm.get("subBrands").clearValidators();
      this.brandForm.get("subBrands").updateValueAndValidity();
    }
}


  backToHome() {
    this.brandSelectionEvent.emit([]);   
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "Brand Back Button",
      eventLable: `Global Elements Back click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  formErrorHandeling(control: string, error: string) {
        return this.brandForm.controls[control].hasError(error);
  }


  /*........... Service/API calls........ */

  getMarkets() {
    this.brandManagementService.getAllMarkets().subscribe((res: any) => {
      this.marketsList = res.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
    });
  }

  getMDMBrands() {
    this.brandManagementService.getBrandsFromMDM().subscribe((res: Array<IBrandsMDM>) => {
      this.brandsMDMList = res ? res.sort((a, b)=> a.mdmBrandName >= b.mdmBrandName ? 1 : -1) : [];
      this.masterbrandsMDMList = res ? res.sort((a, b)=> a.mdmBrandName >= b.mdmBrandName ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.brandsMDMList){
        this.brandsMDMList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.mdmBrandName);
          if (!isDuplicate) {
            uniqueIds.push(el.mdmBrandName);
        
            return true;
          }
          return false;
        })
      }
    })
  }

  getMDMsubBrands(mdmBrandName: string) {
    this.brandManagementService.getSubBrandFromMDM(mdmBrandName).subscribe((res: Array<ISubbrandsPlansMDM>) => {
      this.subBrandsList = res;
      this.canvasSubBrandsWithDeleteData = this.editData[0].subBrandsWithDeleteData || [];
      const canvasSubBrands = this.editData[0].subBrands || [];
      
        const mdmSubBrands = this.editData[0].allSubBrands || [];
        const allSubBrands = canvasSubBrands.concat(mdmSubBrands);
        let uniqueArr = [];

        // for(let i of allSubBrands) {
        //     // if(uniqueArr.indexOf(i) === -1) {     
        //         uniqueArr.push(i);
        //     }
        //     // uniqueArr.findIndex(i => i.hello === "stevie")
        // }
        // const key = 'displayName';

        // uniqueArr = [...new Map(allSubBrands.map(item =>
        //   [item[key], item])).values()];
        for(let i of allSubBrands) {
            // if(uniqueArr.filter(e => e.displayName === i.displayName).length == 0) {     
            //   uniqueArr.push(i);
            if(uniqueArr.indexOf(i) === -1) {
              uniqueArr.push(i);
            }
        }
        const customSubBrandslist = [];
        uniqueArr.map((sb, idx) => {
          const custmBrand = {
            mdmBrandId: idx,
            mdmSubBrandName: sb
            // mdmSubBrandName: sb.displayName ? sb.displayName : sb ,
            // isDeleted: sb.isDeleted ? sb.isDeleted: false
          }
          customSubBrandslist.push(custmBrand)
        })

        this.subBrandsList = customSubBrandslist;


        const customBrand = {
          mdmBrandId: null,
          mdmBrandName: this.editData[0].brandName
        }
        this.brandsMDMList.push(customBrand);
    })
  }

  getPortfoliosByMkt(mktId) {
    this.globalElementsService.getPortfoliosByMkt(mktId).subscribe((res: any) => {
      this.portfoliosList = res.portfolios;
    });
  }

  getPlanByPortfolio(portfolioId: number) {
    this.brandManagementService.getPlanByPortfolio(portfolioId).subscribe((res: any) => {
      this.plansList = res.plans;
    })
  }

  getAllAssets(brandValues) {
    console.log(brandValues.value.brand);
    const brandName = brandValues.value.brand;
    this.globalElementsService.getAllAssets(brandName).subscribe((res: any) => {
      this.assets = res;
      if(this.assets.length == 0){
        this.isLoading = true;
        this.isLoadingMessage = 'Sorry No data available';
      } else {
        this.isLoading = false;
      }
      
    });
  }

  constructBrandObj(): IBrandSave {
    const formValues = this.brandForm.value;

    let brand: IBrandSave = {
      brandLevel: this.brandForm.value.brandLevel,
      brandInfo: {
        name: this.isCustomBrand ? formValues.customBrandName : formValues.brand,
        displayName: this.isCustomBrand ? formValues.customBrandName : formValues.brand,
        webImage: formValues.brandLogo,
        thumbNail: formValues.brandLogo,
        portfolioId: formValues.portfolioId,
        marketId: formValues.marketId,
        ...(this.isBrandPROGRAMlevel ? {
          planId: formValues.planId
        } : {
            subBrands: [...(this.isCustomBrand ? this.customSubBrandsList : formValues.subBrands)]
          }
        )
      }
    }

    return brand;

  }


  constructBrandEditObj(): IBrandEdit {
    const formValues = this.brandForm.value;
    const oldData = this.editData[0];
    let deletedSubBrands: Array<string> = [];
    let newSubBrands: Array<string> = [];
    let existingSubBrands: Array<string> = [];

    if (!this.isBrandPROGRAMlevel) {
      let oldSubBrands=[];
      oldData.subBrands.forEach(element => {
        let data = this.canvasSubBrandsWithDeleteData.find(el => el.displayName == element);
        if (data && data.isDeleted != true)
          oldSubBrands.push(element);        
      });


      // const oldSubBrands = oldData.subBrands;
      // this.canvasSubBrandsWithDeleteData.find(element => element.displayName == subBrand)


      const updatedSubBrands = [...formValues.subBrands,...this.customSubBrandsList];
      // const updatedSubBrands = [...this.selectedSubBrandList,...this.customSubBrandsList];
      deletedSubBrands = oldSubBrands.filter(osb => !updatedSubBrands.includes(osb));
      existingSubBrands = oldSubBrands.filter(osb => updatedSubBrands.includes(osb));
      newSubBrands = updatedSubBrands.filter(usb => !existingSubBrands.includes(usb));
      newSubBrands = [...new Set(newSubBrands)]
    }

    let brandEditObj: IBrandEdit = {
      brandInfo: {
        brandId: oldData.brandId,
        brandLevel: formValues.brandLevel,
        displayName: formValues.brand,
        marketId: formValues.marketId,
        name: formValues.brand,
        webImage: formValues.brandLogo,
        thumbNail: formValues.brandLogo,
        portfolio: {
          newPortfolioId: formValues.portfolioId,
          oldPortfolioId: oldData.portfolioId
        },
        ...(this.isBrandPROGRAMlevel ? {
          plan: {
            newPlanId: formValues.planId,
            oldPlanId: oldData.planId,
          }
        } : {
            subBrands: {
              deleted: [...deletedSubBrands],
              existing: [...existingSubBrands],
              new: [...newSubBrands],
            }
          }
        )
      }

    }


    return brandEditObj;
  } 

  showTooltip(){
    this.showOverViewTooltip = true;
  }
  hideTooltip(){
    this.showOverViewTooltip = false;
  }

  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }


}
