import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "environments/environment";
import { interval, Subscription } from "rxjs";
import { pairwise } from "rxjs/operators";
import { CookieService } from "../../../../node_modules/ngx-cookie-service";
import { AddYearDialogComponent } from "../../common/components/dialogs/add-year-dialog/add-year-dialog.component";
import { SharedDataService } from "../../common/services/shared-data.service";
import { ALERT_STATUS, GLOBAL_ELEMENTS } from "../../constants/app-constants";
import { GlobalElementsService } from "../services/global-elements.service";
import { UserService } from "./../../common/services/_user.service";
import { AGGR_VIEW } from "./../../constants/app-constants";
import { IMarketCurrency } from "../../common/interfaces/market";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { getGpId } from "app/common/utils/common-util";
import { StoreService } from "../../old/_services/_store.service";
import { googleAnalyticsCode } from "app/util";
import * as FullStory from "@fullstory/browser";
import { AuthService } from "app/common/services/auth.service";

const USER_INFO = JSON.parse(sessionStorage.getItem("user"));
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userName: string;
  value;
  userInfo;
  displayConfigMenu: boolean = false;
  userInitials;
  avatarSrc: string;
  workingYears = [];
  selectedYear: number;
  selectedAnualCalYear: number;
  selectedMarket;
  multipleMarkets = [];
  hideNavActions: boolean = false;
  alertsCount: number = null;
  subscription: Subscription;
  currentPage: string;
  showMarketDropDown: boolean = false;
  isTcpView: boolean = false;
  isUSDcurrency: boolean = true;
  isPlayBackViewPages: boolean = true;
  isAnualMediaCalender: boolean = false;
  disablePlanningYearChange: boolean = false;
  enablePlanningYearChange: boolean = false;
  planningYear: number;
  xchangeRate: number = 1;
  showXchangeUpdateAction: boolean = false;
  localCurrencyDetails: IMarketCurrency;
  showCurrencyExchange: boolean = false;
  showAggrView: boolean = true;

  ngOnInit() {
    this.getWorkingYears();

    this.sharedDataService.getPlanningYear.subscribe(
      (yr: number) => (this.planningYear = yr)
    );
    this.sharedDataService.getWorkingYear.subscribe(
      (yr: number) => (this.selectedYear = yr)
    );

    this.selectedAnualCalYear = this.selectedYear;
    this.sharedDataService.getUserInfo.subscribe((userInfo) => {
      const user = userInfo || USER_INFO;

      if (user) {
        this.userInfo = user;
        console.log("header getUserInfo", user);
        const { roleId, firstName, lastName, gpid, markets, defaultMarket } =
          user;

        this.displayConfigMenu = roleId <= 2;
        this.userName = firstName;
        this.userInitials = firstName.charAt(0) + lastName.charAt(0);
        this.getAvatarSrc(gpid);
        const marketInStore = this.store.get("market");
        this.selectedMarket =
          (marketInStore && Number(marketInStore)) ||
          (defaultMarket && Number(defaultMarket));
        this.store.set("market", this.selectedMarket);
        this.sharedDataService.setMarket(this.selectedMarket);
        this.getCurrencyExchange();
        this.multipleMarkets = markets.sort((a, b) =>
          a.displayName >= b.displayName ? 1 : -1
        );
        FullStory.identify(user.id);
      }
    });
    this.sharedDataService.getMarket.subscribe(
      (mktId) => (this.selectedMarket = mktId)
    );

    this.sharedDataService.getCurrencyExchange.subscribe(
      (res) => (this.localCurrencyDetails = res)
    );

    this.getCurrencyExchange();

    this.sharedDataService.sideNavSelection
      .pipe(pairwise())
      .subscribe((res: any) => {
        const prevEle = res[0];
        if (prevEle && prevEle.id == GLOBAL_ELEMENTS.USER_PROFILE.id) {
          const userInfo = JSON.parse(sessionStorage.getItem("user"));
          this.getAvatarSrc(userInfo.gpid);
        }
        if (prevEle && prevEle.id == GLOBAL_ELEMENTS.USER_MGMT.id) {
          const userInfo = JSON.parse(sessionStorage.getItem("user"));
          let currentSelectedMarket = this.store.get("market");
          if (!currentSelectedMarket) {
            this.selectedMarket = userInfo.markets[0].id;
            this.store.set("market", this.selectedMarket);
          }

          this.getCurrencyExchange();
          this.multipleMarkets = userInfo.markets;
          this.toggleMarketDropdown();
        }
      });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = event.url;
        const PLANNING_VIEW_PAGES = [
          "portfolioAllocation",
          "upfrontPlanning",
          "program",
          "scenarioPlanning",
          "commsTask",
          "annualMediaCalendar",
          "campaignSummary",
        ];

        const isPlanningViewPage = PLANNING_VIEW_PAGES.some(
          (page) => this.currentPage && this.currentPage.includes(page)
        );

        this.isPlayBackViewPages = !isPlanningViewPage;

        this.isAnualMediaCalender = this.currentPage.includes(
          "annualMediaCalendar"
        );
        this.disablePlanningYearChange =
          this.currentPage.includes("commsTask") ||
          this.currentPage.includes("program");
        this.enablePlanningYearChange =
          this.currentPage.includes("upfrontPlanning") ||
          this.currentPage.includes("scenarioPlanning") ||
          this.currentPage.includes("portfolioAllocation");
        //error
        this.toggleMarketDropdown();
        this.toggleCurrencyView();
        this.toggleAggrView();
      }
    });

    this.setWorkingYear();

    this.setAnualCalWorkingYear();
    this.getNotificationCount();
    //emit getNotificationCount() every 60 second
    const source = interval(60 * 1000);
    this.subscription = source.subscribe(() => {
      this.getNotificationCount();
    });

    this.hideNavActions = this.shouldHideNavActions();
  }

  getAvatarSrc(gpid) {
    this.avatarSrc = null;
    const avatarImgName = `${gpid}.jpg`;
    this.globalElementsService
      .getUserAvatar(avatarImgName)
      .subscribe(async (res: any) => {
        const UserAvatarString = await this.sharedDataService.blobToString(res);
        if (UserAvatarString.includes("nouserimagefound")) {
          console.log("no user image Found");
          this.avatarSrc = null;
        } else if (res) {
          const reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = () => {
            let imgSrc = reader.result as string;
            this.avatarSrc = imgSrc
              ? (this.sanitizer.bypassSecurityTrustUrl(imgSrc) as string)
              : null;
          };
        }
      });
  }

  constructor(
    private sharedDataService: SharedDataService,
    public router: Router,
    public dialog: MatDialog,
    public userService: UserService,
    private sanitizer: DomSanitizer,
    private globalElementsService: GlobalElementsService,
    private cookieService: CookieService,
    private store: StoreService,
    protected googleTagManagerService: GoogleTagManagerService,
    private authService: AuthService
  ) {}

  setSideNavElement(element) {
    console.log(element, GLOBAL_ELEMENTS[element]);
    this.sharedDataService.setSideNavSelection(GLOBAL_ELEMENTS[element]);
    this.setGoogleTagOnSideNavSelection(element);
  }

  setGoogleTagOnSideNavSelection(element: any) {
    switch (element) {
      case GLOBAL_ELEMENTS.USER_PROFILE:
        let tagObject = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "Change Profile Click",
          eventLable: `"Global Elements Strip Change profile click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject, this.googleTagManagerService);
        break;
      case GLOBAL_ELEMENTS.HELP:
        let tagObject1 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "Help Menu Clicked",
          eventLable: `Global Elements Strip help click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject1, this.googleTagManagerService);
        break;
      case GLOBAL_ELEMENTS.ALERTS:
        let tagObject2 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "Notifications Button Clicked",
          eventLable: `Global Elements Strip Notifications click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject2, this.googleTagManagerService);
        break;
      case GLOBAL_ELEMENTS.DOCUMENTS:
        let tagObject3 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "Document Center Click",
          eventLable: `Global Elements Strip Document Center Click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject3, this.googleTagManagerService);
        break;
      case GLOBAL_ELEMENTS.USER_MGMT:
        let tagObject4 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "User Management Clicked",
          eventLable: `Global Elements Strip User Management Button Clicked`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject4, this.googleTagManagerService);
        break;
      case GLOBAL_ELEMENTS.BRAND_MGMT:
        let tagObject5 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "Brand Management Clicked",
          eventLable: `Global Elements Strip Brand Management clicked`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject5, this.googleTagManagerService);
        break;
      case GLOBAL_ELEMENTS.OPTIMIZER_Q:
        let tagObject6 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Header_Elements`,
          eventAction: "Optimizer Queue Clicked",
          eventLable: `Global Elements Strip Optimizer Queue Clicked`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject6, this.googleTagManagerService);
        break;
      default:
        return null;
    }
  }
  setAnualCalWorkingYear() {
    this.sharedDataService.setAnualCalenderWorkingYear(
      this.selectedAnualCalYear
    );
  }
  setPlanningYear() {
    this.store.set("planningYear", this.planningYear);
    this.sharedDataService.setPlanningYear(this.planningYear);
  }

  setWorkingYear() {
    this.store.set("workingYear", this.selectedYear);
    this.sharedDataService.setWorkingYear(this.selectedYear);
    this.selectedAnualCalYear = this.selectedYear;
    this.setAnualCalWorkingYear();
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Header_Elements`,
      eventAction: "Change Year Clicked",
      eventLable: `Global Elements Strip Change Year Dropdown Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  updateXchangeRate() {
    const marketCurrency: IMarketCurrency = {
      conversionFactor: this.xchangeRate,
      currencyCode: this.localCurrencyDetails.currencyCode,
      marketId: this.localCurrencyDetails.marketId,
    };
    this.globalElementsService
      .setCurrencyExchangeRate(marketCurrency)
      .subscribe((res) => {
        this.getCurrencyExchange();
        this.showXchangeUpdateAction = false;
        this.refreshPage();
      });
  }

  addNextWorkingYear() {
    const maxYear = this.workingYears.reduce(
      (acc, cv) => (cv.value > acc ? cv.value : acc),
      0
    );
    const nextYear = maxYear + 1;
    let dialogRef;

    dialogRef = this.dialog.open(AddYearDialogComponent, {
      data: nextYear,
      minWidth: "500px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      this.sharedDataService.callgetWorkingYearsFromHeader();
      this.getWorkingYears();
    });
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Header_Elements`,
      eventAction: "Add New Year Clicked",
      eventLable: `Global Elements Strip Add New Year clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  aggrViewChange(viewType: string) {
    this.isTcpView = viewType === AGGR_VIEW.TCP;
    this.sharedDataService.setAggrView(viewType);
  }

  currencyViewChange(viewType: string) {
    const prevSelection = this.isUSDcurrency;
    this.isUSDcurrency = viewType === "USD";
    this.sharedDataService.setCurrencyView(
      this.isUSDcurrency ? "USD" : this.localCurrencyDetails.currencyCode
    );
    this.sharedDataService.setCurrencySymbolSource(
      this.isUSDcurrency ? "$" : this.localCurrencyDetails.currencySign
    );
    this.sharedDataService.setCurrencyExchangeRate(
      this.isUSDcurrency ? 1 : this.localCurrencyDetails.conversionFactor
    );
    if (prevSelection !== this.isUSDcurrency) {
      this.refreshPage();
    }
  }

  refreshPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([this.router.url]);
  }

  shouldHideNavActions(): boolean {
    return this.currentPage === "/error";
  }

  toggleMarketDropdown() {
    const multipleMarkets = this.multipleMarkets.length > 1;
    const MULTIPLE_MARKET_VIEW = ["/appRouting", "/market"];
    const isMarketDisplayPage = MULTIPLE_MARKET_VIEW.some(
      (page) => this.currentPage && this.currentPage.includes(page)
    );
    this.showMarketDropDown = multipleMarkets && isMarketDisplayPage;
    this.sharedDataService.setMarket(this.selectedMarket);
  }

  toggleAggrView() {
    this.showAggrView = this.currentPage !== "/appRouting";
  }

  toggleCurrencyView() {
    this.showCurrencyExchange =
      this.localCurrencyDetails &&
      this.localCurrencyDetails.currencyCode !== "USD" &&
      this.currentPage !== "/appRouting" &&
      false;
  }

  getCurrencyExchange() {
    this.globalElementsService
      .getCurrencyExchange(this.selectedMarket)
      .subscribe((res: IMarketCurrency) => {
        if (res) {
          this.localCurrencyDetails = res;
          this.toggleCurrencyView();
          this.xchangeRate = Number(res.conversionFactor);
          this.sharedDataService.setCurrencyView(res.currencyCode);
          this.sharedDataService.setCurrencyExchangeRate(this.xchangeRate);
          this.store.set("currencySign", res.currencySign);
          this.currencyViewChange(res.currencyCode);
        }
      });
  }

  changeMarket() {
    this.store.set("market", this.selectedMarket);
    const filteredMarket = this.multipleMarkets.filter(el => el.id == this.selectedMarket);
    const marketName = filteredMarket ? filteredMarket[0]['displayName']:'';
    this.store.set("selectedPortfolio", "");
    this.store.set("selectedPlan", "");
    this.getCurrencyExchange();
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Header_Elements`,
      eventAction: "Change Market Clicked",
      eventLable: `Global Elements Strip Change Market Dropdown clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
    this.sharedDataService.setMarket(this.selectedMarket);
    if (this.currentPage.match("/market")) {
      this.navigateToMarket(this.selectedMarket);
    }

    /* Adding full story code to capture the page information*/
    
    FullStory.setVars('page', {
       'market': marketName,
    });
  }

  logout() {
    sessionStorage.clear();
    // var idToken = this.authService.tokenManager.get("idToken");
    this.authService.tokenManager.clear();
    if (environment.host != "http://localhost:8080") {
      this.cookieService.delete("userEmail");
      this.cookieService.delete("gpid");
      window.location.replace("https://sso.mypepsico.com/login/logout.jsp");
    } else {
      this.router.navigate(["/login"]);
    }
  }

  navigateToHome() {
    const { defaultLanding, routingPage } = this.userInfo;
    if (routingPage == "Y") {
      this.router.navigate([`/appRouting`]);
    } else {
      const dftLndList = defaultLanding.split(",");
      const defLanLength = dftLndList.length;

      switch (defLanLength) {
        case 1:
          this.navigateToMarket(dftLndList[defLanLength - 1]);
          break;
        case 2:
          this.navigateToPortfolio(dftLndList[defLanLength - 1]);
          break;
        case 3:
          this.navigateToPlan(dftLndList[defLanLength - 1]);
          break;

        default:
          this.router.navigate([`/appRouting`]);
          console.error("Default Landing not found");
          break;
      }
    }
  }

  navigateToMarket(marketID) {
    this.router.navigate([`/market/${marketID}`]);
  }

  navigateToPortfolio(portfolioId) {
    if (portfolioId) {
      this.router.navigate([`/portfolio/${portfolioId}`]);
    }
  }

  navigateToPlan(planId) {
    if (planId) {
      this.router.navigate([`/plan/${planId}`]);
    }
  }

  getNotificationCount() {
    this.globalElementsService.getNotifications().subscribe((res: any) => {
      const statusNewCount = res.filter(
        (notf) => notf.alertStatus == ALERT_STATUS.NEW
      );
      this.alertsCount = statusNewCount.length || null;
    });
  }

  getWorkingYears() {
    this.globalElementsService
      .getCanvasYears()
      .subscribe((res: Array<number>) => {
        this.workingYears = [];
        res.map((yr) => {
          this.workingYears.push({
            displayName: "FY " + yr,
            value: yr,
          });
        });
      });
  }

  /*........... Clean up methods........ */

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
