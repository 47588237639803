import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators,FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { DOCS_LEVEL,SHAREPOINT_DOC_PATH } from "app/constants/app-constants";
import * as _ from "lodash";
import { GlobalElementsService } from "../../../../global-elements/services/global-elements.service";
import { CustomConfirmationDialogComponent } from "../custom-confirmation-dialog/custom-confirmation-dialog.component";
import { getFileBuffer } from 'app/common/utils/common-util';
import axios from 'axios';
import { environment } from 'environments/environment';
import { AbstractControl, ValidatorFn,ValidationErrors } from '@angular/forms';

@Component({
  selector: "upload-campaign-studies-dialog",
  templateUrl: "./uploadcampaignstudies-dialog.component.html",
  styleUrls: ["./uploadcampaignstudies-dialog.component.scss"],
})
export class UploadCampaignStudiesDialogComponent implements OnInit {
  campaignstudiesUploadForm: FormGroup;
  yearsList = [];
  brandsList = [];
  marketsList = [];
  portfoliosList = [];
  brandObjList: Array<string> = [];
  commsObjList: Array<string> = [];
  selectedBrandObjs: Array<string> = [];
  selectedCommsObjs: Array<string> = [];
  selectedYear: number = null;
  selectedBrand: string = "";
  campaignName: string = "";
  fileName: string = "";
  fileNameWithOutExtension: string = "";
  selectedDocumentCampaignStudies = '';
  extension = '';
  fileList:any;
  showMessages:boolean = false;
  messageString = "Saving data please wait. This might take a couple minutes due to file size.";//Saving data please wait...
  showDoneButton:boolean = false;
  showCancelUploadButton:boolean = true;
  validateChooseFileBtn:boolean = true;
  validateFileTitle:boolean = false;
  isLoading:boolean = false;
  validateFileSize:boolean = false;
  fileVersionName: string;
  originalfileName: string;
  existingFiles =[];
  originalfileNameWithoutExt: any;
  originalVersionFileName: string;
  originalVersionId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UploadCampaignStudiesDialogComponent>,
    public globalElementsService: GlobalElementsService
  ) {}

  initializeCreateForm(){
    // let regex = '^(?! )[A-Za-z0-9;\-_!\~@\#\$%&\(\)\+\=\{\}\[\]\^\. ]*(?<! )(?=^"\*:<>\?/\|]*)$';
    // /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
    let regex = '^(?! )[A-Za-z0-9; _\-]*(?<! )(?=^"\*:<>\?/\|]*)$';
    this.campaignstudiesUploadForm = this.formBuilder.group({
      csFileName: ["", Validators.required],
      csDescription: [""],
      csBrand: ["", Validators.required],
      csYear: [
        { value: null, disabled: this.data.name === DOCS_LEVEL.INPUT_FILES}, Validators.required
      ],
      // csCampaignName: ["", [Validators.required, Validators.pattern('[^"*:<>?/\|]*')]],
      csCampaignName: ["", [Validators.required, Validators.pattern(regex)]],
      brandObjective: [[]],
      commsObjective: [[]],
      csAdditionalAchievements: [""],
    })
  }
  initializeEditForm(){

    this.selectedBrandObjs = this.data.brandsobjective? this.data.brandsobjective.split(',') : [];
    this.selectedCommsObjs = this.data.commsobjective? this.data.commsobjective.split(',') : []; 
    this.getCommsObj();

    let regex = '^(?! )[A-Za-z0-9 ]*(?<! )(?=^"*:<>?/\|]*)$';

    let fileName = this.data.fileVersiondata[0].fileVersionName +'.'+ this.data.fileVersiondata[0].filetype;
    this.originalVersionFileName = this.data.fileVersiondata[0].fileVersionName +'.'+ this.data.fileVersiondata[0].filetype;
    this.originalVersionId = this.data.fileVersiondata[0].id;

    this.campaignstudiesUploadForm = this.formBuilder.group({
      csFileName: [ fileName, Validators.required],
      // csFileName: [{ value:this.data.filename}],
      csDescription: [this.data.description],
      csBrand: [this.data.brandname, Validators.required],
      csYear: [
        this.data.year, Validators.required
      ],
      // csCampaignName: ["", [Validators.required, Validators.pattern('[^"*:<>?/\|]*')]],
      csCampaignName: [this.data.campaignname, [Validators.required, Validators.pattern(regex)]],
      brandObjective: [this.selectedBrandObjs],
      commsObjective: [this.selectedCommsObjs],
      csAdditionalAchievements: [this.data.achievements],
    })
    this.fileName = fileName;
  }

  getDocsFromSharepointFilesAPI() {
    // this.isLoading = true;
    let path = 'Input%20Files/Campaign%20Studies';
    this.globalElementsService
      .getDocumentsWithFilesAPI(path)
      .subscribe((docsDetails: Array<any>) => {
        const docsDetailsArr = docsDetails["results"];
        this.existingFiles = docsDetailsArr.reduce((acc, cv) => {
          const results = cv;
          let docObj: any = {};
          docObj.title = results.Name || "N/A";
          docObj.author = "";
          docObj.path =
            "https://pepsico.sharepoint.com" + results.ServerRelativeUrl;
          
          docObj.fileExt = results.Name ? results.Name.split(".")[1] : "N/A";
          docObj.size = "";
          docObj.fileName = results.Name || "N/A";
          acc.push(docObj);
          return acc;
        }, []);

        // console.log(this.existingFiles)
      });
    }

  checkFormValidation(){
    if(this.data.isEditMode){
      if(this.campaignstudiesUploadForm.invalid|| this.validateFileTitle)
        return true;
      else
        return false;  

    }else{
      if(this.campaignstudiesUploadForm.invalid || this.validateChooseFileBtn || this.validateFileTitle || this.validateFileSize)
        return true;
      else 
        return false;
    }
  }

  
  checkFileNameValidation(){
    // const fileName = this.campaignstudiesUploadForm.get("csFileName").value;
    // this.globalElementsService.checkFileExists(fileName).subscribe((response) => {
    //   let count = response['count'];
    //   if(count > 0){
    //     this.validateFileTitle = true;
    //   } else {
    //     this.validateFileTitle = false;
    //   }
    // })
    let searchName= this.campaignstudiesUploadForm.get("csFileName").value;

    let fileMatched = this.existingFiles.find(item=>
      item.fileName == searchName
    );
    if(fileMatched){
      this.validateFileTitle = true;
    } else {
      this.validateFileTitle = false;
    }
  }

  formErrorHandeling(control: string, error: string) {
    return this.campaignstudiesUploadForm.controls[control].hasError(error);
  }

  ngOnInit() {
    this.getWorkingYears();
    this.getBrandsList();
    this.getBrandObjectives();
    this.getDocsFromSharepointFilesAPI();
    // this.editFileName();

    if(this.data.isEditMode){
      this.initializeEditForm();
    }else{
      this.initializeCreateForm();
    }    
    // this.campaignstudiesUploadForm.get("csFileName").disable();
  }
  getWorkingYears() {
    this.globalElementsService
      .getCanvasYears()
      .subscribe((res: Array<number>) => {
        this.yearsList = [];
        res.map((yr) => {
          this.yearsList.push({
            value: yr,
          });
        });
      });
  }
  getBrandsList() {
    let marketObj = {
      marketId : "3"
    }
    this.globalElementsService.getBrandsListByMarket(marketObj).subscribe((res: any) => {
      this.brandsList = [];
      this.brandsList = res.brands.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
    });
  }

  /*........... functionality Methods........ */
  getBrandObjectives() {
    this.globalElementsService.getBrandObjectives().subscribe((res: any) => {
      this.brandObjList = res.brandObjectives;
    });
  }
  brandObjectiveChange(brndObjctive: Array<string>) {
    this.selectedBrandObjs = brndObjctive;
    this.campaignstudiesUploadForm.patchValue({
      commsObjective: [],
    });
    this.selectedCommsObjs = [];
    this.getCommsObj();
  }
  getCommsObj() {
    let brndObj = {
      brandObjective: this.selectedBrandObjs,
    };
    this.globalElementsService
      .getCommsObjective(brndObj)
      .subscribe((res: Array<string>) => {
        this.commsObjList = res;
      });
  }
  commsObjectiveChange(commsObj: Array<string>) {
    this.selectedCommsObjs = commsObj;
  }
  getCampaignName(e) {
    this.campaignstudiesUploadForm
      .get("csCampaignName")
      .patchValue(e.target.value);
    this.campaignName = e.target.value;
    // this.getFileName();
  }
  getFileName() {
    // this.fileName = "";
    this.selectedYear = this.campaignstudiesUploadForm.get("csYear").value;
    this.selectedBrand = this.campaignstudiesUploadForm.get("csBrand").value;
    // this.campaignName =
    //   this.campaignstudiesUploadForm.get("csCampaignName").value;
    // if (
    //   this.selectedYear != null &&
    //   this.selectedBrand != "" &&
    //   this.campaignName != ""
    // ) {
    //   this.fileName =
    //     this.selectedBrand + "-" + this.selectedYear + "-" + this.campaignName;
    // }
    // this.campaignstudiesUploadForm.get("csFileName").patchValue(this.fileName);
    // this.checkFileNameValidation();
    // this.campaignstudiesUploadForm.get("csFileName").updateValueAndValidity();
    
    // console.log("fileName", this.fileName);
  }

  cancelConfirmDialog() {
    this.dialog
      .open(CustomConfirmationDialogComponent, {
        data: {
          message:
            "Are you sure you want to cancel and close Upload Documents?",
          customSaveButtonText: "YES",
          customCancelButtonText: "NO",
          isTitleVisible: false
        },
      })
      .afterClosed()
      .subscribe((_result: Boolean) => {
        if (_result) {
          console.log("closed", _result);
          this.dialogRef.close();
        }
      });
  }



  removeUnicode(data) {
		let toRemoveWords = ['\u000B'];
		let data1;
		toRemoveWords.forEach(el => {
			data1 = data.replace(el, '')
		});
		// let data1 = data.replace('\u000B',"")
		return data1;
	}

  onFileSelected(event){
    this.fileList = FileList = event.target.files;
    console.log(this.fileList[0].size);
    const fileSize = this.fileList[0].size;
    this.originalfileName = this.fileList[0].name; 
    this.originalfileNameWithoutExt = this.fileList[0].name.split('.')[0]; 
    this.campaignstudiesUploadForm.get("csFileName").patchValue(this.originalfileName);
    this.campaignstudiesUploadForm.get("csFileName").updateValueAndValidity();
    this.checkFileNameValidation();
    if(fileSize <= 150000000){
      this.validateFileSize = false;
    } else {
      this.validateFileSize = true;
    }
    this.extension = this.fileList[0].name.split('.').pop();
    this.selectedDocumentCampaignStudies = this.fileList[0].name;
    if(this.selectedDocumentCampaignStudies == ""){
      this.validateChooseFileBtn = true;
    } else {
      this.validateChooseFileBtn = false;
    }
  }

  submitForm(){
    if(this.data.isEditMode){
      this.showMessages = true;
      this.showCancelUploadButton = false;
      this.constructEditCampaignStudiesObj();
    }else{ 
      this.constructCampaignStudiesObj();
    }
  }

  //create code
  constructCampaignStudiesObj(){

    let fileName =  this.campaignstudiesUploadForm.get("csFileName").value;
    let fileNameWithoutExt =  this.campaignstudiesUploadForm.get("csFileName").value.split('.')[0];
       
    this.showMessages = true;
    this.showCancelUploadButton = false;
    this.isLoading = true;
    let data;
    let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CAMPAIGN_STUDIES}`;
    getFileBuffer(this.fileList[0]).then((fileBufferArray: ArrayBuffer) => {
      data = fileBufferArray;
      console.log(data);
      let docObj = {
        body: {
          data,
          // fileName: this.fileName,
          fileName: fileName,
          uploadLocation: path
        }
      };
      this.globalElementsService.uploadDocuments(docObj).subscribe((res) => {
        try{
              if(res['sqAuthheader']){
                this.fileName = `${fileName}`;
                this.fileNameWithOutExtension = `${fileNameWithoutExt}`;
                  try{
                      const SHAREPOINT_PATH_URL = environment.SHAREPOINT_PATH_URL;
                      const pathLoc = environment.pathLoc;
                      const location = docObj.body.uploadLocation;
                      let fileURL = `${SHAREPOINT_PATH_URL}/${pathLoc}${location}/${this.fileName}`;
                      // let fileURL = `${SHAREPOINT_PATH_URL}/${pathLoc}${location}/${this.originalfileName}`;
                        axios({
                          method: 'POST',
                          // url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}${location}')/files/add(overwrite=true,url='${this.fileName}')`,
                          url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}${location}')/files/add(overwrite=true,url='${fileName}')`,
                          headers: res['sqAuthheader'],
                          maxContentLength: Infinity,
                          maxBodyLength: Infinity,
                          data: docObj.body.data
                        }).then((response) => {
                        if(response.status == 200){
                          console.log("Uploaded Successfully!");
                            const campaignStudiesObj = {
                              // filename:`${this.campaignstudiesUploadForm.get("csFileName").value}`,
                              filename:`${fileNameWithoutExt}`,
                              filetype:this.extension,
                              fileURL:fileURL,
                              brandname:this.selectedBrand,
                              year:this.selectedYear, 
                              campaignname:this.campaignName,
                              description:this.campaignstudiesUploadForm.get("csDescription").value,
                              brandsobjective:this.selectedBrandObjs,
                              commsobjective:this.selectedCommsObjs,
                              achievements:this.campaignstudiesUploadForm.get("csAdditionalAchievements").value,
                            }
                            this.globalElementsService.saveCampaignStudiesInfo(campaignStudiesObj).subscribe((response) => {
                              if(response['status'] == 200){
                                this.messageString = "Data Saved Successfully!";
                                this.isLoading = false;
                                this.showDoneButton = true;
                              }
                            })
                          
                          // if(pathLoc == "Shared Documents/"){
                          //   axios({
                          //     method: 'POST',
                          //     url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}TEST/${location}')/files/add(overwrite=true,url='${this.fileName}')`,
                          //     headers: res['sqAuthheader'],
                          //     maxContentLength: Infinity,
                          //     maxBodyLength: Infinity,
                          //     data: docObj.body.data
                          //   }).then((response) => {
                          //     if(response.status == 200){
                          //       console.log("Uploaded to Lower environments Successfully!");
                          //     }
                          //   }).catch((error) => {
                          //     if(error.response.status == 404){
                          //       this.messageString="Upload failed as folder does not exists";
                          //       this.showDoneButton = true;
                          //       this.isLoading = false;
                          //       console.log('Error: ', error);
                          //     }
                          //     else {
                          //       this.showDoneButton = true;
                          //       this.isLoading = false;
                          //       this.messageString = "Failed to upload file";
                          //       console.log("Failed to upload file");
                          //     }
                          //   })
                          // }
                        }
                      }).catch((error) => {
                        if(error.response.status == 404){
                          this.messageString="Upload failed as folder does not exists";
                          this.showDoneButton = true;
                          console.log('Error: ', error);
                          this.isLoading = false;
                        } else {
                          console.log("Failed to upload file");
                          this.messageString = "Failed to upload file";
                          this.showDoneButton = true;
                          console.log('Error: ', error);
                          this.isLoading = false;
                        }    
                      })
                  } catch(error){
                    console.log(error);
                    this.isLoading = false;
                  }
                  
              }
          } catch(error){
            console.log(error);
            this.isLoading = false;
          }
      })
    })
    
  }

    //Edit code
  constructEditCampaignStudiesObj(){
    this.selectedYear = this.campaignstudiesUploadForm.get("csYear").value;
    this.selectedBrand = this.campaignstudiesUploadForm.get("csBrand").value;
    this.campaignName = this.campaignstudiesUploadForm.get("csCampaignName").value;

    //No file upload and file name edited
    let fileName =  this.campaignstudiesUploadForm.get("csFileName").value;
    // let fileNameWithoutExt =  this.campaignstudiesUploadForm.get("csFileName").value.split('.')[0]; 
    if(this.selectedDocumentCampaignStudies=="" && this.originalVersionFileName != fileName){
      this.showMessages = true;
      this.showCancelUploadButton = false;
      this.editFileName();
    }

    //No file upload
    if(this.selectedDocumentCampaignStudies=="" && this.originalVersionFileName == fileName){
      this.showMessages = true;
      this.showCancelUploadButton = false;
      this.updateCampaignStudiesInfo();
    }else{    
      //file upload
      this.showMessages = true;
      this.showCancelUploadButton = false;
      this.isLoading = true;
      let data;


      // this.globalElementsService.checkFileCounts(this.fileName).subscribe((response) => {
      //   let count = response['count'];
      //   this.fileVersionName= `V${count+1}_${this.fileName}`
      let fileName =  this.campaignstudiesUploadForm.get("csFileName").value;
      let fileNameWithoutExt =  this.campaignstudiesUploadForm.get("csFileName").value.split('.')[0]; 
      
      
      let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CAMPAIGN_STUDIES}`;
      getFileBuffer(this.fileList[0]).then((fileBufferArray: ArrayBuffer) => {
        data = fileBufferArray;
        console.log(data);
        let docObj = {
          body: {
            data,
            fileName: fileName,
            uploadLocation: path
          }
        };
        this.globalElementsService.uploadDocuments(docObj).subscribe((res) => {
          try{
                if(res['sqAuthheader']){
                  this.fileName = `${fileNameWithoutExt}.${this.extension}`;
                  this.fileNameWithOutExtension = `${fileNameWithoutExt}`;
                    try{
                        const SHAREPOINT_PATH_URL = environment.SHAREPOINT_PATH_URL;
                        const pathLoc = environment.pathLoc;
                        const location = docObj.body.uploadLocation;
                        let fileURL = `${SHAREPOINT_PATH_URL}/${pathLoc}${location}/${fileName}`;
                          axios({
                            method: 'POST',
                            url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}${location}')/files/add(overwrite=true,url='${fileName}')`,
                            headers: res['sqAuthheader'],
                            maxContentLength: Infinity,
                            maxBodyLength: Infinity,
                            data: docObj.body.data
                          }).then((response) => {
                          if(response.status == 200){
                            console.log("Uploaded Successfully!");
                              
                            const campaignStudiesObj = {
                              filename:`${fileNameWithoutExt}`,
                              filetype:this.extension,
                              fileURL:fileURL,
                              brandname:this.selectedBrand,
                              year:this.selectedYear, 
                              campaignname:this.campaignName,
                              id:this.data.id,
                              description:this.campaignstudiesUploadForm.get("csDescription").value,
                              brandsobjective:this.selectedBrandObjs,
                              commsobjective:this.selectedCommsObjs,
                              achievements:this.campaignstudiesUploadForm.get("csAdditionalAchievements").value,
                              fileVersionName: fileNameWithoutExt? fileNameWithoutExt : '', 
                              fileUploaded: true,
                              fileNameEdited: false
                            }
                      
                            this.globalElementsService.updateCampaignStudiesInfo(campaignStudiesObj).subscribe((response) => {
                              if(response['status'] == 200){
                                this.messageString = "Data Saved Successfully!";
                                this.showDoneButton = true;
                                this.isLoading = false;
                              }
                            })
                            
                            // if(pathLoc == "Shared Documents/"){
                            //   axios({
                            //     method: 'POST',
                            //     url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}TEST/${location}')/files/add(overwrite=true,url='${this.fileName}')`,
                            //     headers: res['sqAuthheader'],
                            //     maxContentLength: Infinity,
                            //     maxBodyLength: Infinity,
                            //     data: docObj.body.data
                            //   }).then((response) => {
                            //     if(response.status == 200){
                            //       console.log("Uploaded to Lower environments Successfully!");
                            //     }
                            //   }).catch((error) => {
                            //     if(error.response.status == 404){
                            //       this.messageString="Upload failed as folder does not exists";
                            //       this.showDoneButton = true;
                            //       this.isLoading = false;
                            //       console.log('Error: ', error);
                            //     }
                            //     else {
                            //       this.showDoneButton = true;
                            //       this.isLoading = false;
                            //       this.messageString = "Failed to upload file";
                            //       console.log("Failed to upload file");
                            //     }
                            //   })
                            // }
                          }
                        }).catch((error) => {
                          if(error.response.status == 404){
                            this.messageString="Upload failed as folder does not exists";
                            this.showDoneButton = true;
                            console.log('Error: ', error);
                            this.isLoading = false;
                          } else {
                            console.log("Failed to upload file");
                            this.messageString = "Failed to upload file";
                            this.showDoneButton = true;
                            console.log('Error: ', error);
                            this.isLoading = false;
                          }    
                        })
                    } catch(error){
                      console.log(error);
                      this.isLoading = false;
                    }
                    
                }
            } catch(error){
              console.log(error);
              this.isLoading = false;
            }
        })
        })
      // })
      }
    }

    updateCampaignStudiesInfo(){
      let fileName =  this.campaignstudiesUploadForm.get("csFileName").value;
      let fileNameWithoutExt =  this.campaignstudiesUploadForm.get("csFileName").value.split('.')[0];
      const campaignStudiesObj = {
        // filename:`${this.campaignstudiesUploadForm.get("csFileName").value}`,
        // filetype:this.extension,
        // fileURL:fileURL,
        brandname:this.selectedBrand,
        year:this.selectedYear, 
        campaignname:this.campaignName,
        id:this.data.id,
        fileUploaded: false,
        description:this.campaignstudiesUploadForm.get("csDescription").value,
        brandsobjective:this.selectedBrandObjs,
        commsobjective:this.selectedCommsObjs,
        achievements:this.campaignstudiesUploadForm.get("csAdditionalAchievements").value,
        fileVersionName: fileNameWithoutExt ? fileNameWithoutExt: '',
        fileNameEdited: false
      }

      this.globalElementsService.updateCampaignStudiesInfo(campaignStudiesObj).subscribe((response) => {
        if(response['status'] == 200){
          this.messageString = "Data Saved Successfully!";
          this.showDoneButton = true;
        }
      })
    }

    //Update file name in SharePoint
    editFileName(){
      const SHAREPOINT_PATH_URL = environment.SHAREPOINT_PATH_URL;
      const pathLoc = environment.pathLoc;
      let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CAMPAIGN_STUDIES}`;

      let oldfileName = this.originalVersionFileName;

      let fileName =  this.campaignstudiesUploadForm.get("csFileName").value;
      let fileNameWithoutExt =  this.campaignstudiesUploadForm.get("csFileName").value.split('.')[0];
      let extension = fileName.split('.').pop();
      let obj = {
        locationURL:  `/${pathLoc}${path}/`,
        fromFileName: oldfileName,
        toFileName: fileName,        
      }
      this.globalElementsService.editFileName(obj).subscribe((response) => {
        if(response['status'] == 200){
          let fileURL = `${SHAREPOINT_PATH_URL}/${pathLoc}${path}/${fileName}`;
          const campaignStudiesObj = {
            filename:`${fileNameWithoutExt}`,
            filetype: extension,
            fileURL:fileURL,
            brandname:this.selectedBrand,
            year:this.selectedYear, 
            campaignname:this.campaignName,
            id:this.data.id,
            description:this.campaignstudiesUploadForm.get("csDescription").value,
            brandsobjective:this.selectedBrandObjs,
            commsobjective:this.selectedCommsObjs,
            achievements:this.campaignstudiesUploadForm.get("csAdditionalAchievements").value,
            fileVersionName: fileNameWithoutExt? fileNameWithoutExt : '', 
            fileUploaded: false,
            fileNameEdited: true,
            originalVersionId: this.originalVersionId
          }
    
          this.globalElementsService.updateCampaignStudiesInfo(campaignStudiesObj).subscribe((response) => {
            if(response['status'] == 200){
              this.messageString = "Data Saved Successfully!";
              this.showDoneButton = true;
              this.isLoading = false;
            }
          })
        }
      },
      (err)=>{
        console.log(err);
      })
    }
    handleDoneButton(){
      this.showCancelUploadButton = true;
      this.showDoneButton = false;
      this.showMessages = false;
      let folderPath = 'Campaign Studies';
      this.dialogRef.close({ status: true,  DocumentType: folderPath });
    }
}
// https://pepsico.sharepoint.com/:t:/r/sites/NACanvas/Shared%20Documents/TEST/Input%20Files/Campaign%20Studies/Bikram%20test%20edit.txt?csf=1&web=1&e=Ql6Qbh