import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { AvatarUploadDialogComponent } from "app/common/components/dialogs/avatar-upload-dialog/avatar-upload-dialog.component";
import { UserProfileDialogComponent } from "app/common/components/dialogs/user-profile-dialog/user-profile-dialog.component";
import { IAvatarSave, IUserSave } from "app/common/interfaces/userManagement";
import { SharedDataService } from "app/common/services/shared-data.service";
import { UserService } from "app/common/services/_user.service";
import { StoreService } from "app/old/_services/_store.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { Subscription } from "../../../../../node_modules/rxjs";
import { take } from "../../../../../node_modules/rxjs/operators";
import { GlobalElementsService } from "../../services/global-elements.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { getGpId } from "app/common/utils/common-util";
import { environment } from "environments/environment";
import { googleAnalyticsCode } from "app/util";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @ViewChild("imageUploadDialog", { static: true })
  imageUploadDialog: TemplateRef<any>;
  @ViewChild("file", { static: true }) inputFile: ElementRef;
  @Output("dirty") formDirty = new EventEmitter<boolean>();

  userInfo;
  marketInfo = [];
  defaultPageDropDown: any = [];
  userForm: FormGroup;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  isLoading: boolean = false;
  avatarImgPath: string = "";
  avatharImgSts: boolean;
  avathar: string;
  fileName: string = "";
  saveBtnSubscription: Subscription;

  constructor(
    private globalElementsService: GlobalElementsService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private sharedData: SharedDataService,
    private store: StoreService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem("user"));
  }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !== 6);

    this.globalElementsService.setSaveEnableState(false);
    this.sharedData.getUserInfo.subscribe((res) => {
      this.userInfo = res || this.userInfo;
    });
    this.avatarProfile(this.userInfo.avatarImg);
    this.intiateForm(this.userInfo);
    this.getMarkets();
    this.getpsdMetricss();
    this.getLandingPageOptions(this.userInfo.defaultMarket);

    this.userForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.userForm.valid);
      this.formDirty.emit(this.userForm.dirty);
    });

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent
      .pipe(take(1))
      .subscribe(() => this.saveUserDialog());
    this.userInfo.roleId === 6 ? this.userForm.disable() : null;
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Profile_Page`,
      eventAction: "PageView",
      eventLable: `Global ElementsProfile Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  intiateForm(data) {
    this.userForm = this.fb.group({
      market: ["", Validators.required],
      landingpageInfo: [""],
      routingPage: [""],
    });

    if (data) {
      this.userForm.patchValue({
        market: data.defaultMarket,
        landingpageInfo: data.defaultLanding,
        routingPage: data.routingPage,
      });
    }
  }

  /*........... functionality Methods........ */

  userFromErrorHandeling(control: string, error: string) {
    return this.userForm.controls[control].hasError(error);
  }

  openImgUploadDialog() {
    this.dialog.open(this.imageUploadDialog, {
      height: "500px",
      width: "500px",
      disableClose: true,
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.openImgUploadDialog();
    this.isLoading = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.inputFile.nativeElement.value = "";
  }

  imageLoaded() {
    this.isLoading = false;
  }

  cropperReady() {}

  loadImageFailed() {
    this.isLoading = false;
    this.inputFile.nativeElement.value = "";
    this.dialog.closeAll();
  }

  uploadProfile() {
    const avatarObj = this.constructAvatarObj();
    let dialogRef;
    dialogRef = this.dialog.open(AvatarUploadDialogComponent, {
      data: avatarObj,
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.status) {
        this.avatharImgSts = true;
        this.avatarImgPath = this.croppedImage;
      }
      this.dialog.closeAll();
    });
  }

  //upload avatar modal popup close
  closeUploadDialog() {
    this.croppedImage = "";
    this.inputFile.nativeElement.value = "";
    this.dialog.closeAll();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "Close", {
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 3000,
      panelClass: ["notif-success"],
    });
  }

  saveUserDialog() {
    const userObj = this.constructUserObj();
    let dialogRef;
    dialogRef = this.dialog.open(UserProfileDialogComponent, {
      data: userObj,
      minWidth: "500px",
    });

    dialogRef.beforeClose().subscribe(() => {
      this.userService.get(this.userInfo.gpid).then((user) => {
        if (user) {
          this.sharedData.setUserInfo(user);
          this.store.set("user", JSON.stringify(user));
        }
      });
    });
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Profile_Page`,
      eventAction: "Profile Save Click",
      eventLable: `Global Elements profilePage save click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  };

  /*........... Service/API calls........ */

  getMarkets() {
    this.globalElementsService.getMarketData().subscribe(
      (data: any) => {
        this.marketInfo = data.markets.sort((a, b) =>
          a.displayName >= b.displayName ? 1 : -1
        );
      },
      (error) => {
        this.intiateForm(this.userInfo);
        this.openSnackBar(error);
      }
    );
  }

  //market select box change
  getLandingPageOptions(marketId: any) {
    this.globalElementsService
      .getDefaultPageDropDownData({ marketId })
      .subscribe(
        (res: any) => {
          let rawData = res.dropDowns;
          rawData.forEach((el) => (el.display = el.display.join(" >> ")));
          this.defaultPageDropDown = rawData;
        },
        (err) => this.openSnackBar(err)
      );
  }

  //getAvatar Image
  avatarProfile(avatarImgName: string) {
    this.globalElementsService
      .getUserAvatar(avatarImgName)
      .subscribe(async (res: any) => {
        const UserAvatarString = await this.sharedData.blobToString(res);
        const { firstName, lastName } = this.userInfo;
        this.avatharImgSts = false;
        this.avatarImgPath = null;
        this.avathar = firstName.charAt(0) + lastName.charAt(0);
        this.inputFile.nativeElement.value = "";
        if (UserAvatarString.includes("nouserimagefound")) {
          console.log("no user image Found");
        } else if (res) {
          const reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = () => {
            let imgSrc = reader.result as string;
            if (imgSrc) {
              this.avatharImgSts = true;
              this.avatarImgPath = this.sanitizer.bypassSecurityTrustUrl(
                imgSrc
              ) as string;
            }
          };
        }
      });
  }
  getpsdMetricss() {
    this.globalElementsService.getpsdMetrics().subscribe(async (res: any) => {
      console.log("resp::::", res);
    });
  }

  constructUserObj(): IUserSave {
    const userData = this.userForm.value;
    let userObj: IUserSave = {
      defaultMarketId: userData.market,
      defaultLandingPage: userData.landingpageInfo,
      routingPage: userData.routingPage,
      fileName: this.avatharImgSts ? this.userInfo.gpid + ".jpg" : "",
    };
    return userObj;
  }

  constructAvatarObj(): IAvatarSave {
    let avatarObj: IAvatarSave = {
      imageBody: this.croppedImage.replace("data:image/png;base64,", ""),
    };
    return avatarObj;
  }

  /*........... Clean up methods........ */

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Profile_Page`,
      eventAction: "Profile Page Exit",
      eventLable: `Global Elements ProfilePage Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
}
