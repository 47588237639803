import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ProgramDialogComponent } from 'app/common/components/dialogs/program-dialog/program-dialog.component';
import { IApproverList, IBreadcrumb, ITeamMembers, ITeamMembersReq, IDocument } from 'app/common/interfaces/common';
import { IKpisAndAudience, IOppBriefSave } from 'app/common/interfaces/program';
import { getCompletedAndFinalizedUsers, getGpId, getDocsFromSharepoint, isValidUserAccess } from 'app/common/utils/common-util';
import { PAGE_MODES,FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, STATUS_LIST, STATUS_LIST_TYPES, SHAREPOINT_DOC_PATH, FILE_NAMES } from 'app/constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { TeamMembersDialogComponent } from '../../common/components/dialogs/team-members-dialog/team-members-dialog.component';
import { ProgramService2 } from '../services/program.service';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../../constants/app-constants';
import { FileUploadDialogComponent } from 'app/common/components/dialogs/file-upload-dialog/file-upload-dialog.component';
import {getDocsFromSharepointFilesAPI } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {MultiselectAutocompleteComponent} from 'app/common/components/multiselect-autocomplete/multiselect-autocomplete.component';
import * as moment from 'moment';
import { SendNotificationsDialogComponent } from './../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component';
import {googleAnalyticsCode} from 'app/util';
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';
import ab2b64 from 'ab2b64' ;
import { DownloadFileDialogComponent } from 'app/common/components/dialogs/download-file-dialog/download-file-dialog.component';


@Component({
  selector: 'app-opportunity-brief',
  templateUrl: './opportunity-brief.component.html',
  styleUrls: ['./opportunity-brief.component.scss']
})
export class OpportunityBriefComponent implements OnInit {

  @Input() elementDetails;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');

  @Output('dirty') formDirty = new EventEmitter<boolean>()

  USER_INFO = JSON.parse(sessionStorage.getItem('user'));
  
  oppBriefForm: FormGroup;
  
  saveBtnSubscription: Subscription;
  opBriefApproverList: Array<IApproverList>;
  selectedOpBriefApprovers: Array<IApproverList>;
  successKPIsList: Array<string>;
  selectedSuccessKPIs:Array<string>;
  oppBriefStatusList: Array<string> = STATUS_LIST;
  oppBriefSatus: string = STATUS_LIST_TYPES.DRAFT;
  oppBriefEditableStatus:boolean = true;
  oldStatus: string;
  pathDetails: IBreadcrumb;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  audienceDocObj: IDocument;
  learningPlanDocObj: IDocument;
  mode = PAGE_MODES.HOME;
  constructor(
    private globalElementsService: GlobalElementsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private programService: ProgramService2,
    protected googleTagManagerService: GoogleTagManagerService

  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.returnAccess('!==', 6));
    this.globalElementsService.setSaveEnableState(false);
    this.pathDetails = {
      lastNodeName: `${this.elementDetails.oppBriefData.mjtbdName} >> Opportunity Brief`,
      pageId:this.elementDetails.oppBriefData.mjtbdId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.MJTBD,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.MJTBD
    }
    this.initiateMjtbdForm(this.elementDetails.oppBriefData.oppBriefData);
    this.getOppBriefDocsFromSharepoint();
    this.oppBriefForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.oppBriefForm.valid && this.oppBriefEditableStatus);
      this.formDirty.emit(this.oppBriefForm.dirty);
    })

    this.getApproversList();
    this.getTeamMembersList();
    this.getKPIs();
    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.updateOppBrief());

    this.updateStatusList(this.elementDetails.oppBriefData.oppBriefData);
    this.returnAccess('===', 6) ? this.oppBriefForm.disable() : null;
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Opportunity_Brief`,
      eventAction: "PageView",
      eventLable: `Edit Opportunity Brief Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  

  initiateMjtbdForm(oppBriefData) {

    this.oppBriefForm = this.fb.group({
      advantage: [oppBriefData ? oppBriefData.advantage : ''],
      assignmentName: [oppBriefData ? oppBriefData.assignmentName : '', Validators.required],
      opBriefApprover: [''],
      callToAction: [oppBriefData ? oppBriefData.callToAction : ''],
      commsObjective: [oppBriefData ? oppBriefData.commsObjective : ''],
      completedByUserId: [oppBriefData ? oppBriefData.completedByUserId : ''],
      considerations: [oppBriefData ? oppBriefData.considerations : ''],
      deliverables: [oppBriefData ? oppBriefData.deliverables : ''],
      endDt: [oppBriefData ? oppBriefData.endDt : ''],
      finalizedByUserID: [oppBriefData ? oppBriefData.finalizedByUserID : ''],
      hashTag: [oppBriefData ? oppBriefData.hashTag : '', Validators.maxLength(100)],
      designTarget: [oppBriefData ? oppBriefData.designTarget : '' , Validators.maxLength(100)],
      insight: [oppBriefData ? oppBriefData.insight : ''],
      problem: [oppBriefData ? oppBriefData.problem : ''],
      startDt: [oppBriefData ? oppBriefData.startDt : ''],
      woa: [oppBriefData ? oppBriefData.woa : ''],
      strategy: [oppBriefData ? oppBriefData.strategy : ''],
      successKPIs: [''],
      workingMediaBudget: [oppBriefData?oppBriefData.workingMediaBudget:''],
    });

    this.selectedTeamMembers = oppBriefData.teamMembers ? oppBriefData.teamMembers.split(',').map(el => Number(el)) : [];
    this.oppBriefSatus = oppBriefData && oppBriefData.obFormStatus || STATUS_LIST_TYPES.DRAFT;
    this.oldStatus = oppBriefData ? oppBriefData.obFormStatus : '';
    
  }


  /*........... functionality Methods........ */


  
  addTeamMembers(){
    
    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
      teamMembers : this.teamMembersList,
      selectedMembers : this.selectedTeamMembers
      },
      height: '400px',
      width: '500px',
      disableClose: true
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
      this.generateTeamMemeberAvatars(); 
    });
  }

  generateTeamMemeberAvatars(){
    if(this.teamMembersList){
      const selectedUsers = this.teamMembersList.filter(tm => this.selectedTeamMembers ? this.selectedTeamMembers.includes(tm.userId) : '');
      this.selectedTeamMembersAvatar = selectedUsers.map(user => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        }
        return {
          initials,
          avatar
        }
      })
    }
  }


  oppBriefFormErrorHandeling(control: string, error: string) {
    return this.oppBriefForm.controls[control].hasError(error);
  }

  successKPIsChange(kpiName) {
    this.selectedSuccessKPIs = kpiName;
  }

  updateStatusList(oppBriefData) {

    this.oppBriefForm.get("opBriefApprover").setValidators([Validators.required]);
    let approversIds: Array<any> = [];
    if (oppBriefData && oppBriefData.approversList) {
      approversIds = oppBriefData.approversList.split(',');
    }

    let isMatched = false;

    // if (this.USER_INFO.roleId <= 3) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }

    approversIds.forEach(id => {
      if (this.USER_INFO.id == id) {
        isMatched = true;
      }
    });

    if (this.oppBriefSatus === STATUS_LIST_TYPES.DRAFT || this.oppBriefSatus == null) {
      this.oppBriefForm.get("opBriefApprover").setValidators([]);
      this.oppBriefStatusList = MINIFIED_STATUS_LIST;
      this.oppBriefEditableStatus = true;
    } else if (isMatched && this.oppBriefSatus === STATUS_LIST_TYPES.COMPLETE) {
      this.oppBriefEditableStatus = true;
      this.oppBriefStatusList = STATUS_LIST;
    } else if (isMatched && this.oppBriefSatus === STATUS_LIST_TYPES.FINAL) {
      this.oppBriefEditableStatus = true;
      this.oppBriefStatusList = FINAL_STATUS_LIST;
    } else {
      this.oppBriefEditableStatus = false;
      this.oppBriefStatusList = STATUS_LIST;
    }
    this.oppBriefForm.get("opBriefApprover").updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.oppBriefForm.valid);
  }

  opBriefStatusChange() {
    if (this.oppBriefSatus === STATUS_LIST_TYPES.DRAFT) {
      this.oppBriefForm.get("opBriefApprover").setValidators([]);
    } else {
      this.oppBriefForm.get("opBriefApprover").setValidators([Validators.required]);      
    }
    this.oppBriefForm.get("opBriefApprover").updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.oppBriefForm.valid);
  
  }

  async getOppBriefDocsFromSharepoint() {
    const {year, hierarchy, campaignName} =  this.elementDetails.oppBriefData;
    const plan = hierarchy.split('/').pop();
    const audiencePath = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.AUDIENCE}`;
    const audienceDocData = await getDocsFromSharepointFilesAPI(audiencePath, this.globalElementsService);
    const campLearningPath = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.LEARNING_PLAN}`;
    const learningDocData = await getDocsFromSharepointFilesAPI(campLearningPath, this.globalElementsService);
    // this.audienceDocObj = audienceDocData.find(doc => doc.title == `${campaignName} ${FILE_NAMES.OPP_BRIEF_AUDIENCE}`);
    // this.learningPlanDocObj = learningDocData.find(doc => doc.title == `${campaignName} ${FILE_NAMES.OPP_BRIEF_LEARNING}`)
    
    this.audienceDocObj = audienceDocData.find( d => {
      let extension = d.title.split('.').pop()
      let maintitle = `${year}${plan}${campaignName}${FILE_NAMES.OPP_BRIEF_AUDIENCE}.${extension}`;
      maintitle = maintitle.replace(/ /g,'');
      maintitle = maintitle.replace("TCPAudienceConsiderations","Audiences&OtherConsiderations");
      if(d.title == maintitle){
        return true;
      }
    });

    this.learningPlanDocObj = learningDocData.find( d => {
      let extension = d.title.split('.').pop()
      let maintitle = `${campaignName} ${FILE_NAMES.OPP_BRIEF_LEARNING}.${extension}`
      if(d.title == maintitle){
        return true;
      }
    });
  }
  deleteFile(doc: IDocument) {
    const docObj = {
      isDelete: true,
      fileName: doc.fileName,
      docPath: doc.path.split('/Shared Documents/')[1]
    };
    let dialogRef;
    dialogRef = this.dialog.open(FileUploadDialogComponent, {
      data: { fileType: '', docObj },
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.status) {
        this.getOppBriefDocsFromSharepoint();
      }
      this.dialog.closeAll();
    });
  }

  downloadFile(path:string){
    let fileName = path.split('/').pop();
    path = path ? path.split('/Shared Documents/')[1] : '';
    let docPath = path.split(`/${fileName}`)[0];
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    const downloadObj = {
      fileName:fileName,
      path:path,
      docPath:docPath,
      extension:extension
    } 
    let dialogRef;
        dialogRef = this.dialog.open(DownloadFileDialogComponent, {
          data: downloadObj,
          minWidth: '500px',
          disableClose: true
        });
    // let fileName = path.split('/').pop();
    // const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length); 
    // this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
    //   // const byteCharacters = window.atob(response['thumbnailb64data']);
    //   this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
      // const downloadLink = document.createElement("a");
      // downloadLink.download = fileName.replace(/ /g,'');
      // downloadLink.href = source;
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // const byteArray = new Uint8Array(byteNumbers);
      // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
      // saveAs(blob, fileName.replace(/ /g,''));
  // })
}
downloadFileFromBase64(fileName, b64encodedString: string, contentType ) {
  if (b64encodedString) {
    var blob = this.base64ToBlob(b64encodedString, contentType);
    saveAs(blob, fileName);
  }
}

public base64ToBlob(b64Data, contentType='', sliceSize=512) {

  let byteArrays = ab2b64.b642ab(b64Data)
  return new Blob([byteArrays], {type: contentType});
}

  /*........... Service/API calls........ */
  getApproversList() {
    const { mjtbdId, year } = this.elementDetails.oppBriefData.oppBriefData;
    let obj = {
      referenceId: mjtbdId,
      referenceType: FORM_REFERENCE_TYPES.MJTBD_FORM,
      year: year
    }
    this.globalElementsService.getApproversList(obj).subscribe((res: Array<IApproverList>) => {
      this.opBriefApproverList = res;
      const oppBriefData=this.elementDetails.oppBriefData.oppBriefData;
      const approvers = oppBriefData.approversList ? oppBriefData.approversList.split(',') : [];
      this.selectedOpBriefApprovers=[];
      approvers.forEach((id:number) =>{
        const approversList=this.opBriefApproverList.find((el:IApproverList) => el.userId == id);
        if(approversList){
          this.selectedOpBriefApprovers.push(approversList);
        } else {
          this.selectedOpBriefApprovers = [];
        }
      });
      this.oppBriefForm.get("opBriefApprover").patchValue(this.selectedOpBriefApprovers);
    })
  }


  getTeamMembersList(){
    const { mjtbdId, year } = this.elementDetails.oppBriefData.oppBriefData;
    let teamMemberReq : ITeamMembersReq = {
      referenceId: mjtbdId,
      referenceType: FORM_REFERENCE_TYPES.MJTBD_FORM,
      year: year
    }

    this.globalElementsService.getTeamMembersList(teamMemberReq).subscribe((res:Array<ITeamMembers>)=>{
      this.teamMembersList = res ? res.sort((a, b)=> a.name >= b.name ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.teamMembersList){
        this.teamMembersList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.userId);
          if (!isDuplicate) {
            uniqueIds.push(el.userId);
        
            return true;
          }
          return false;
        })
      }
      this.generateTeamMemeberAvatars(); 
    })
    
  }
  sendMessageToCollaborators(){
    let data = this.constructOppBriefObj();
    let snDialogRef;
      snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
        data: {
          data :data,
          type:FORM_REFERENCE_TYPES.OP_BRIEF,
          },
        height: '150px',
        width: '500px',
        disableClose:true
      });

    snDialogRef.afterClosed().subscribe((res: any) => {
    });
    
  }

  opBriefApproverChange(apprvData) {
    this.selectedOpBriefApprovers = apprvData;
  }

sendData(){
  return this.opBriefApproverList;
}
sendSelectedData(){
  return this.selectedOpBriefApprovers; 
 }

selectChange = (event: any) => {
  this.selectedOpBriefApprovers = event.data;
  this.oppBriefForm.get("opBriefApprover").patchValue(this.selectedOpBriefApprovers);
};

  getKPIs(){
    const { mjtbdId } = this.elementDetails.oppBriefData.oppBriefData;
    let obj = {
      mjtbdId: mjtbdId
    }
    this.programService.getKPIsandAudience(obj).subscribe((res: IKpisAndAudience) => {
      this.successKPIsList = res.kpis;
      const oppBriefData=this.elementDetails.oppBriefData.oppBriefData;
      this.selectedSuccessKPIs = oppBriefData.successKPIs ? oppBriefData.successKPIs.split(',') : [];
      this.selectedSuccessKPIs = this.selectedSuccessKPIs.filter(el => res.kpis.includes(el));
      this.oppBriefForm.get("successKPIs").patchValue(this.selectedSuccessKPIs ? this.selectedSuccessKPIs : null);
    })
  }

  updateOppBrief() {
    const oppBrief = this.constructOppBriefObj();
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: { mode: 'update', type: 'updateOppBrief', apiData: oppBrief, planId: this.elementDetails.oppBriefData.planId },
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
      
      if(oppBrief.oppourtinyBriefInfo.startDt && moment(oppBrief.oppourtinyBriefInfo.startDt).isAfter(moment(), "day")){
        let dialogRefCreativeX;
        dialogRefCreativeX = this.dialog.open(ProgramDialogComponent, {
          data: { mode: 'openCreativeXPopup', type: 'openCreativeXPopup', planId: this.elementDetails.oppBriefData.planId },
          minWidth: '500px'
        });
        dialogRefCreativeX.afterClosed().subscribe((res: any) => {
        });
      }
    });
  
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Opportunity_Brief`,
      eventAction: "Save Opportunity Brief",
      eventLable: `Opportunity Brief Save Button Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  constructOppBriefObj(): IOppBriefSave {
    const { mjtbdId, year, obFormId} = this.elementDetails.oppBriefData.oppBriefData;
    const formValues = this.oppBriefForm.value;
    const oppBriefResData=this.elementDetails.oppBriefData.oppBriefData;
    const {completedByUserId, finalizedByUserID}=getCompletedAndFinalizedUsers(this.oldStatus, this.oppBriefSatus, oppBriefResData);
    let opBriefApprover = formValues.opBriefApprover && formValues.opBriefApprover.map(obj => obj.userId);
    let oppBrief: IOppBriefSave = {
      mjtbdId,
      year,
      alertURL: `/program/${mjtbdId}`,
      oppourtinyBriefInfo: {
        obFormId,
        mjtbdId,
        year,
        obFormStatus: this.oppBriefSatus || null,        
        assignmentName: formValues.assignmentName,
        workingMediaBudget: formValues.workingMediaBudget.replace(/,/g, ''),
        startDt: formValues.startDt,
        endDt: formValues.endDt,
        woa: formValues.woa,
        commsObjective: formValues.commsObjective,
        problem: formValues.problem,
        insight: formValues.insight,
        advantage: formValues.advantage,
        strategy: formValues.strategy,
        callToAction: formValues.callToAction,
        hashTag: formValues.hashTag,
        designTarget: formValues.designTarget,
        deliverables: formValues.deliverables,
        considerations: formValues.considerations,
        successKPIs: formValues.successKPIs ? formValues.successKPIs.toString() : '',
        approversList: opBriefApprover ? opBriefApprover.toString() : '',
        teamMembers: this.selectedTeamMembers.toString(),
        completedByUserId: completedByUserId,
        finalizedByUserID: finalizedByUserID,
        currentUserID: this.userInfo.id
      }
    }
    return oppBrief;
  }
  
  /*........... Clean up methods........ */

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Opportunity_Brief`,
      eventAction: "PageExit",
      eventLable: `Opportunity Brief Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
  CommaFormatted(event) {
    if(event.which >= 37 && event.which <= 40) return;
   
    if (event.target.value) {
      event.target.value = event.target.value.replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
   
   numberCheck (args) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }
  }
  openKPIFrameworkPPTPage(){
    // let sidePanelEle = { ...GLOBAL_ELEMENTS.KPI_FRAMEWORK };
    // this.sharedDataService.setSideNavSelection(sidePanelEle); 
    this.changePageMode(PAGE_MODES.EDIT);
  }
  changePageMode(pageMode) {
    this.mode = pageMode
  }
  isHomePageMode() {
    return !(this.mode === PAGE_MODES.HOME);
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId,_oprator, null, this.elementDetails.oppBriefData.planId);
  }
}
