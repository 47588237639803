import { Component, OnInit, Output, ViewChild, EventEmitter, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { SelectionModel } from '../../../../../../node_modules/@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '../../../../../../node_modules/@angular/material';
import { UserDeleteDialogComponent } from '../../../../common/components/dialogs/user-delete-dialog/user-delete-dialog.component';
import { GlobalElementsService } from '../../../services/global-elements.service';
import { UserManagementServiceService } from '../user-management-service.service';


@Component({
  selector: 'app-users-datatable',
  templateUrl: './users-datatable.component.html',
  styleUrls: ['./users-datatable.component.scss']
})
export class UsersDatatableComponent implements OnInit {

  @Output() userSelectionEvent = new EventEmitter();
  @Output() editUserEvent = new EventEmitter();
  @Output() deleteUserEvnet = new EventEmitter();
  @Input() pagination;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  users;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  isLoading: boolean = true;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['select', 'displayName', "email", "gpid", "personaId", "roleId"];
  selection = new SelectionModel<any>(true, []);
  errorLoading = false;


  constructor(
    private globalElementsService: GlobalElementsService,
    public dialog: MatDialog,
    private usrMgmtService: UserManagementServiceService,
    private sharedData: SharedDataService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {


    this.getUsersLists();

    this.sharedData.getUserInfo.subscribe(userInfo => {
      if (userInfo) {
        this.userInfo = userInfo;
      }
    });

    this.selection.changed.subscribe(() => this.userSelectionEvent.emit(this.selection.selected));


  }

  /*........... functionality Methods........ */

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {

    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /*........... Service/API calls........ */
  
  getUsersLists() {    
    this.isLoading = true;
    this.selection.clear();
    if (this.userInfo) {
      this.errorLoading = false;
      this.globalElementsService.getAllUsers(this.userInfo.gpid).subscribe((res: { users: any }) => {
        this.users = res.users;
        this.dataSource = new MatTableDataSource<any>(res.users);
        this.dataSource.paginator = this.pagination;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      })
    }
    else {
      this.errorLoading = true;
    }
  }

  doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  enableEditMode = () => {
    this.editUserEvent.emit();
  }
  handleDelete = () => {
    this.deleteUserEvnet.emit();
  }
}
