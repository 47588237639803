import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PAGE_MODES } from '../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
@Component({
  selector: 'app-tooltip-list',
  templateUrl: './tooltip-list.component.html',
  styleUrls: ['./tooltip-list.component.scss']
})
export class TooltipList implements OnInit {
  
  @Output('dirty')  formDirty = new EventEmitter<boolean>()

  selectedTooltip = [];
  mode = PAGE_MODES.HOME;
  userInfo: any;
  marketList: any = [];
  constructor(protected googleTagManagerService: GoogleTagManagerService,
    private globalElementsService : GlobalElementsService
  ) { this.userInfo = JSON.parse(sessionStorage.getItem("user"));}

  ngOnInit() {
    this.getMarkets();
  }
  getMarkets() {
    this.globalElementsService.getAllMarket(this.userInfo.gpid).subscribe(res => {
      if(res['markets'].length > 0){
        this.marketList = res['markets'].sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
      } else {
        this.marketList = [];
      }
    })

  }
  
  getSelectedTooltip(childEmmitedEvent) {
    this.selectedTooltip = childEmmitedEvent;
    if(this.selectedTooltip){
      this.selectedTooltip['selectedMarkets'] = this.selectedTooltip['selectedMarkets'].filter((el => el.id == this.selectedTooltip['marketId']));
    }
    this.enableEditMode();
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME || this.mode == PAGE_MODES.DELETE;
  }

  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
  }


  enableEditMode() {
    this.changePageMode(PAGE_MODES.EDIT);  
   
  }


  enableHomePageMode() {
    this.changePageMode(PAGE_MODES.HOME);
  }

  enableDeleteMode() {
    this.changePageMode(PAGE_MODES.DELETE);
  }

  changePageMode(pageMode) {
    this.mode = pageMode
  }

  isFormDirty(isDirty: boolean) {
    this.formDirty.emit(isDirty);
  }


}
