import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';
import { MatPaginator, MatSort, MatTableDataSource } from '../../../../../../node_modules/@angular/material';
import { PAGE_MODES, USER_ACCESS_LEVEL } from './../../../../constants/app-constants';
@Component({
  selector: 'app-user-access-setup-dialog',
  templateUrl: './user-access-setup-dialog.component.html',
  styleUrls: ['./user-access-setup-dialog.component.scss']
})

export class userAccessSetupDialogComponent implements OnInit {
 //message: string = "Saving User Information... Please Wait";
 //exitUserProfileForm : boolean = false;
portfoliosByMarketList: any [];
plansBymarketList: any [];
allRoles: any [];
portfolioSelected:number;
planSelected: number;
roleSelected: number;
userAccess: string;
//displayedColumns: string[] = ['pfName','role','delete']; 
//dataSource = new MatTableDataSource<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
    private dialogRef: MatDialogRef<userAccessSetupDialogComponent>,

    ) {}
  
  ngOnInit() {
    //this.saveUserProfile();
   // this.allRoles = this.data.allRoles;
   // this.portfoliosByMarketList = this.data.portfolios;
   this.portfoliosByMarketList = this.data.portfolios;
   this.allRoles = this.data.allRoles;
   this.plansBymarketList = this.data.plans;
    this.userAccess = this.data.userAccess;
    this.roleSelected = this.data.datasource.roleId ? this.data.datasource.roleId : null;
    if(this.userAccess === USER_ACCESS_LEVEL.BRAND) {
      this.planSelected =  this.data.datasource.id ? this.data.datasource.id: "" ;
    } else {
      this.portfolioSelected =  this.data.datasource.pfId ? this.data.datasource.pfId: "" ; 
    }
    //this.dataSource = this.data.dataSource;

  }

  
  saveUserForm() {
    this.dialogRef.close({ cancelBtnClickedSts: false, resultData: {index: this.data.index, pfId: this.portfolioSelected, planId : this.planSelected, roleId: this.roleSelected, userAccess:this.userAccess} });
  }
  // Quit the edit form on clicking OK
  cancelBtnClicked() {
    this.dialogRef.close({ cancelBtnClickedSts: true });
    //this.exitUserProfileForm ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }

}
