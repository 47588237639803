import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalElementsService } from '../services/global-elements.service';
import { MatTableDataSource } from '../../../../node_modules/@angular/material';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-optimzer-queue',
  templateUrl: './optimzer-queue.component.html',
  styleUrls: ['./optimzer-queue.component.scss']
})
export class OptimzerQueueComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['queueId','scenarioId', 'scenarioType', 'queuePosition', 'optimizationStatus',];

  constructor(
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this.getOptimizerQueueData();
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Optimizer_Queue`,
      eventAction:  "Page View",
      eventLable: `Global Elements Strip PageView`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  getOptimizerQueueData() {
    this.isLoading = true;
    this.globalElementsService.getOptimerQueue().subscribe((res:any) => {
      this.dataSource = new MatTableDataSource<any>(res);
      this.isLoading = false;

    })

  }
  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Optimizer_Queue`,
      eventAction: "Optimizer Queue Page Exit",
      eventLable: `Global Elements Optimizer Queue Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
   }

}
