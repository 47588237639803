import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { BrandManagementDialogsComponent } from '../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { IMarketSave } from './../../../../common/interfaces/brandManagement';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { take } from '../../../../../../node_modules/rxjs/operators';
import { Subscription } from '../../../../../../node_modules/rxjs';
import currencyToSymbolMap from 'currency-symbol-map/map'
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-enhance-market',
  templateUrl: './enhance-market.component.html',
  styleUrls: ['./enhance-market.component.scss']
})
export class EnhanceMarketComponent implements OnInit, OnDestroy {

  @Input() pageMode;
  @Output() changePageModeEmitter = new EventEmitter
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  @Input() editData;
  @Output() marketSelectionEvent = new EventEmitter();


  marketForm: FormGroup;
  displayName: string = 'Create New Market';
  mdmMarkets = [];
  isEditMode = false;
  saveBtnSubscription: Subscription;
  currencyOptions: Array<object>;
  netwokFactorIsVisibleChecked: boolean =false;
  networkFactorIsEditableChecked: boolean =false;
  marketId: number;
  selectedMarket: any;
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }




  /*........... Initialization Methods........ */

  ngOnInit() {
   //console.log('currencyToSymbolMap', currencyToSymbolMap);
    this.currencyOptions = Object.keys(currencyToSymbolMap).map(el => {
      return {
        label: `${el} ----- ${currencyToSymbolMap[el]}`,
        value: el
      }
    })

    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !==6);
    this.globalElementsService.setSaveEnableState(false);
    this.getMDMmarkets();
    this.iniitializeMarketForm();

    this.marketForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.marketForm.valid);
    })

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());
    this.userInfo.roleId === 6 ? this.marketForm.disable() : null;
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "PageView",
      eventLable: `Global Elements Create Market Button Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);

    this.isEditMode = this.pageMode == PAGE_MODES.EDIT;
    if (this.isEditMode) {
      this.displayName = 'Edit Market'
    }
  }



  iniitializeMarketForm(): void {
    this.marketForm = this.fb.group({
      market: ['', Validators.required],
      currency: ['', Validators.required],
      exchangeRate: [null, Validators.required],
    })
    this.netwokFactorIsVisibleChecked = false;
    this.networkFactorIsEditableChecked = false;
  }

  updateMarketForm() {

    const marketData = this.editData[0];
    // this.selectedMarket = this.mdmMarkets.find(el => el.mdmMktName == marketData.displayName);

    this.marketForm.patchValue({
      currency: marketData.marketCurrency.currencyCode,
      exchangeRate: marketData.marketCurrency.conversionFactor,
      market: this.selectedMarket
    });
    this.netwokFactorIsVisibleChecked = marketData.isVisible;
    this.networkFactorIsEditableChecked = marketData.isEditable;
    this.marketId = marketData.id

  }

  /*........... funcaitonality Methods........ */


  marketFormErrorHandeling(control: string, error: string): boolean {
    return this.marketForm.controls[control].hasError(error);
  }

  backToHome() {
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "Market Page Back Button Clicked",
      eventLable: `Global Elements New Market Page back click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  onSubmit(): void {

    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        module: 'market',
        type: this.isEditMode ? 'update' : 'save',
        apiData: this.isEditMode ? this.constructMarketEditObj() : this.constructPostObj()
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {
      this.backToHome();
    });
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "Market Page Save Button Clicked",
      eventLable: `Global Elements New Market Page save click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  /*........... Service/API calls........ */
  getMDMmarkets(): void {
    this.globalElementsService.getMarketsFromMDM().subscribe((res: any) => {
      console.log(res)
      this.mdmMarkets = res.sort((a, b)=> a.mdmMktName >= b.mdmMktName ? 1 : -1);
      if (this.isEditMode) {
        const marketData = this.editData[0];
        this.selectedMarket = this.mdmMarkets.find(el => el.mdmMktName == marketData.displayName);
        console.log(this.selectedMarket)
        this.updateMarketForm();
      }
    })
  }

  checkboxChangeValue(_controlName, _value) {
    this[_controlName] = _value;
  }
  constructPostObj(): IMarketSave {

    const { exchangeRate, currency, market } = this.marketForm.value;
    let newMarket: IMarketSave = {
      marketDisplayName: market.mdmMktName,
      marketName: market.mdmMktCode,
      currencyCode: currency,
      conversionFactor: exchangeRate,
      currencySign: currencyToSymbolMap[currency],
      netwokFactorIsVisibleChecked: this.netwokFactorIsVisibleChecked,
      networkFactorIsEditableChecked: this.networkFactorIsEditableChecked
    };

    return newMarket;
  }

  constructMarketEditObj(): IMarketSave {
    const { exchangeRate, currency, market } = this.marketForm.value;
    let newMarket: IMarketSave = {
      marketId: this.marketId,
      marketDisplayName: market.mdmMktName,
      marketName: market.mdmMktCode,
      currencyCode: currency,
      conversionFactor: exchangeRate,
      currencySign: currencyToSymbolMap[currency],
      netwokFactorIsVisibleChecked: this.netwokFactorIsVisibleChecked,
      networkFactorIsEditableChecked: this.networkFactorIsEditableChecked
    };

    return newMarket;
  }

  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "Market Page Exit",
      eventLable: `Global Elements New Market Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

}
