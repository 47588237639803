import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { User } from '../../types';

const BASE_API_URL = `${ environment.api }`;
@Injectable({
  providedIn: 'root'
})

export class MarketService {
 
  
  constructor(private http:HttpClient) { 

  }
  
  public getMarketData(obj:any){
    return this.http.post(`${BASE_API_URL}/market/`, obj);
  };
  getWidgetChartData(chartObj:any){
    return this.http.post(`${BASE_API_URL}/market/getWidgetChartData`, chartObj);
  }
  getChartData(chartObj:any){
    return this.http.post(`${BASE_API_URL}/market/getChartData`, chartObj);
  }
  public getPlansForMarket(obj:any){
    return this.http.post(`${BASE_API_URL}/plan/getPlansforMarket`, obj);
  };  
  
  public getPortfolioGroupsByMarket(groupObj:any){
    return this.http.post(`${BASE_API_URL}/pfgroup/getPortfolioGroups`, groupObj);
  }

  getFlowPackView(chartObj){
    return this.http.post(`${BASE_API_URL}/market/getFlowPackView`, chartObj);    
  }

  getMediaMix(chartObj){
    return this.http.post(`${BASE_API_URL}/market/getMediaMix`, chartObj);    
  }

  public updateTargetROI(obj: any) {
    return this.http.post(`${BASE_API_URL}/market/updateTargetROI`, obj);
  };
  
  public resetTargetROI(obj: any) {
    return this.http.post(`${BASE_API_URL}/market/resetTargetROI`, obj);
  };
  

}

