import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DOCS_LEVEL } from 'app/constants/app-constants';
import { BrandManagementService } from 'app/global-elements/services/brand-management.service';
import * as _ from 'lodash';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';
import { StoreService } from '../../../../old/_services/_store.service';
import { getFileBuffer, getDocsFromSharepointFilesAPI, isValidUserAccess } from 'app/common/utils/common-util';
import {ProgramService2} from '../../../../program/services/program.service';
import ab2b64 from 'ab2b64';
import { saveAs } from 'file-saver';
import { environment } from 'environments/environment';
import axios from 'axios';

@Component({
  selector: 'app-documents-upload-dialog',
  templateUrl: './documents-upload-dialog.component.html',
  styleUrls: ['./documents-upload-dialog.component.scss']
})
export class DocumentsUploadDialogComponent implements OnInit {

  
  selectedDocument = '';
  progress = 0;
  interval;
  location = '';
  show = 'hidden';
  mode = 'indeterminate';
  disableUpload = true;
  uploadComplete = 'false';
  isAdmin = false;
  documentUpload: FormGroup;
  documentCenterUpload = [];
  adminUsers = [];
  uploadStatus: string;
  yearsList=[];
  plansList=[];
  marketsList=[];
  portfoliosList=[];
  audienceList :any = [];
  inputList=['AOP Templates','cDNA','Change Compass','Personalization at Scale'];
  showInputFields : boolean = false;
  showFormFields: boolean = false;
  showSearchTab: boolean = false;
  showYear : boolean = false;
  showAudience : boolean = false;
  showPlan : boolean = false;
  sharePointPath: string;
  isUploadErr:boolean =false;
  errMsg:string = '';
  userInfo;
  searchBtnEnable:boolean = false;
  cancelBtnEnable:boolean = false;
  doneBtnEnable:boolean = false;
  showRadio:boolean=false;
  showPortfolio:boolean=true;
  showBrand:boolean=true;
  optionEnable:string='download';
  DownloadBase64Data: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private store: StoreService,

    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DocumentsUploadDialogComponent>,
    public globalElementsService: GlobalElementsService,
    private brandManagementService: BrandManagementService,
    private prgservice: ProgramService2

  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem("user"));
      this.documentUpload = this.formBuilder.group({
        uploadType: [''],
        fcInputFields:[null],
        fcYear :[{value: null, disabled: this.data.name ===  DOCS_LEVEL.INPUT_FILES}],
        fcaudience :[{value: null,disabled: this.data.name ===  DOCS_LEVEL.INPUT_FILES}],
        fcMarket :[{value: null, disabled: this.data.name !==  DOCS_LEVEL.BRAND_DOCUMENTS}],
        fcPortfolio : [{value: null, disabled: true}],
        fcPlan :[{value: null, disabled: true}],
        radio :[{value: '1', disabled: this.data.name !==  DOCS_LEVEL.SOCIAL_ENGAGEMENT}]
      });



    this.documentCenterUpload = [
      { value: 'global', viewValue: 'Global Reference' },
      { value: 'CANASTrai', viewValue: 'CANVAS Training' },
      { value: 'TCPtemp', viewValue: 'TCP Template' },
      { value: 'brandDoc', viewValue: 'Brand Documents' }
    ];
  }

  ngOnInit() {
    // this.getAllAudience();
    this.show = 'hidden';
    this.sharePointPath = this.data.sharePointPath;
    this.cancelBtnEnable = true;
    switch (this.data.name) {
      case DOCS_LEVEL.TCP_TEMPLATES:
        this.showSearchTab = true;
        this.showInputFields = false;
        this.showYear = true;
        this.showFormFields = true;
        this.getWorkingYears();
        this.showAudience = false;
        // this.showPlan = false;
        break;
      case DOCS_LEVEL.INPUT_FILES:
        this.showSearchTab = true;
        this.showInputFields = true;
        this.showYear = true;
        this.showAudience = true;
        // this.showPlan = true;
        this.showFormFields = true;
        this.getWorkingYears();
        break;
      case DOCS_LEVEL.BRAND_DOCUMENTS:
        this.showFormFields = true;
        this.showSearchTab = true;
        this.fetchMarkets();
        break;
      case DOCS_LEVEL.SOCIAL_ENGAGEMENT:
          this.showFormFields = true;
          this.showSearchTab = false;
          this.showYear = true;
          this.showRadio = true;
          this.showPortfolio=false;
          this.showBrand=false;
          this.getWorkingYears();
          this.fetchMarkets();
          break;
      default:
        this.showInputFields = false;
        this.showInputFields = false;
        this.showYear = false;
        this.showAudience = false;
        // this.showPlan = false;
        this.disableUpload = false;

    }

  }

  getAllAudience(event){
    (this.documentUpload.get("fcYear").value === null) ? this.documentUpload.get("fcaudience").disable() : this.documentUpload.get("fcaudience").enable();
    this.prgservice.getAllAudience(event.value.id).subscribe(response => {
      console.log(response);
      this.audienceList = response;
     
    })
  }

  shownSearchTab(event?){
    switch(event.index) {
      case 0:
        if(this.doneBtnEnable) {
          this.cancelBtnEnable = false;
        }
        this.searchBtnEnable = false;
        break;
      case 1:
          this.searchBtnEnable = true;
          this.cancelBtnEnable = true;
        break;

    }
   
  }

  shownUploadTab(){
    this.searchBtnEnable = false;
  }

  getWorkingYears() {
    (this.showInputFields && this.documentUpload.get("fcInputFields").value === null) ? this.documentUpload.get("fcYear").disable() : this.documentUpload.get("fcYear").enable();
    this.globalElementsService.getCanvasYears().subscribe((res: Array<number>) => {
      this.yearsList = [];
      res.map(yr => {
        this.yearsList.push({
          value: yr
        })
      })
    });
  }

  fetchMarkets() {
    this.documentUpload.get("fcMarket").enable();
    this.documentUpload.get("fcPortfolio").patchValue(null);
    this.documentUpload.get("fcPlan").patchValue(null);
    this.documentUpload.get("fcPlan").disable();
    this.documentUpload.get("fcPortfolio").disable();
    this.brandManagementService.getAllMarkets().subscribe((res: any) => {
      this.marketsList = res.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
    });
    this.enableUploadButton();
  }

  getPortfoliosByMkt(mktId) {
    this.documentUpload.get("fcPlan").patchValue(null);
    this.documentUpload.get("fcPlan").disable();
    this.globalElementsService.getPortfoliosByMkt(mktId).subscribe((res: any) => {
      this.portfoliosList = res.portfolios;
      _.isEmpty(this.portfoliosList) ? this.documentUpload.get("fcPortfolio").disable() : this.documentUpload.get("fcPortfolio").enable();
    });
    this.enableUploadButton();
  }

  getPlanByPortfolio(portfolioId: number) {
    this.brandManagementService.getPlanByPortfolio(portfolioId).subscribe((res: any) => {
      this.plansList = res.plans;
      _.isEmpty(this.plansList) ? this.documentUpload.get("fcPlan").disable() : this.documentUpload.get("fcPlan").enable();
    })
  }

  enableUploadButton() {
    if (this.showFormFields) {
      this.documentUpload.get("fcPlan").value != null ? this.disableUpload = false : this.disableUpload = true;
    } else if (this.showInputFields) {
      this.documentUpload.get("fcYear").value != null ? this.disableUpload = false : this.disableUpload = true;
    }
  }

  formErrorHandeling(control: string, error: string) {
    return this.documentUpload.controls[control].hasError(error);
  }

  setLocation(x) {
    this.location = x;
    this.disableUpload = false;
  }



  getFileBuffer(file) {
    return new Promise((resolve, reject) => {
      var myReader: FileReader = new FileReader();
      myReader.readAsArrayBuffer(file);
      myReader.onload = function (e) {
        resolve(myReader.result);
      }
      myReader.onerror = function (e) {
        //deferred.reject(e.target.error);
      }
    });
  }

  markUploadComplete() {

    this.mode = 'determinate';
    this.uploadComplete = 'true';
    this.progress = 100;

  }

  fetchSharePointPath() {
    let market = !!this.documentUpload.get("fcMarket").value ? this.documentUpload.get("fcMarket").value.displayName : null;
    let portfolio = !!this.documentUpload.get("fcPortfolio").value ? this.documentUpload.get("fcPortfolio").value.displayName : null;
    let year = !!this.documentUpload.get("fcYear").value ? this.documentUpload.get("fcYear").value : null;
    let inputfileds = !!this.documentUpload.get("fcInputFields").value ? this.documentUpload.get("fcInputFields").value : null;
    let plan = !!this.documentUpload.get("fcPlan").value ? this.documentUpload.get("fcPlan").value.displayName : null;
    switch (this.data.name) {
      case DOCS_LEVEL.TCP_TEMPLATES:
        return this.sharePointPath + "/" + year + "/" + market + "/" + portfolio + "/" + plan;
      case DOCS_LEVEL.INPUT_FILES:
        
        return this.sharePointPath + "/" + inputfileds + "/" + year + "/" + market;
      case DOCS_LEVEL.BRAND_DOCUMENTS:
        return this.sharePointPath + "/" + market + "/" + portfolio + "/" + plan;
      default:
        return this.sharePointPath;
    }
  }
  fileUploadCompleted() {
    let folderPath = this.searchDocuments();
    this.dialogRef.close({ status: true,  DocumentType: folderPath });
  }

  searchDocuments(){
    let docCat = this.sharePointPath;
  
    switch (this.data.name) {
      case DOCS_LEVEL.TCP_TEMPLATES:
        let tcpmarket = this.documentUpload.get('fcMarket').value.displayName;

        let tcpportfolio = this.documentUpload.get('fcPortfolio').value.displayName;
        let tcpyear = this.documentUpload.get('fcYear').value;

        let tcpplan = this.documentUpload.get('fcPlan').value.displayName;
        let TCPDocPath = `${docCat}/${tcpyear}/${tcpmarket}/${tcpportfolio}/${tcpplan}`;
        return TCPDocPath;
      
      case DOCS_LEVEL.BRAND_DOCUMENTS:
        let b_market = this.documentUpload.get('fcMarket').value.displayName;

        let b_portfolio = this.documentUpload.get('fcPortfolio').value.displayName;
        let b_plan = this.documentUpload.get('fcPlan').value.displayName;

        let BrandDocPath = `${docCat}/${b_market}/${b_portfolio}/${b_plan}`;
     
        return BrandDocPath;

        case DOCS_LEVEL.INPUT_FILES:
          let inputfileds = this.documentUpload.get('fcInputFields').value;
          let inputyear = this.documentUpload.get('fcYear').value;
          let inputmarket = this.documentUpload.get('fcMarket').value.displayName;

          let InputFilesPath = `${docCat}/${inputfileds}/${inputyear}/${inputmarket}`;
       
          return InputFilesPath;
        
      default:
        return docCat;
    }
    
  }
  downloadTemplate (marketIdCtrName, yearCtrName) {
    const marketObj =  this.documentUpload.get(marketIdCtrName).value;
    const year =  this.documentUpload.get(yearCtrName).value;
    if(marketObj && marketObj.id && year) {
      // this.globalElementsService.downloadSEtemplate({marketId: marketObj.id, year});
      this.globalElementsService.downloadSEtemplate({marketId: marketObj.id, year}).subscribe((res) => {
        this.DownloadBase64Data =  res["data"];
        let fileName = 'socialsentimentDataMID-' + marketObj.id + 'Year-' + year + '.xlsx';
        this.downloadFileFromBase64(fileName, res["data"],'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      });
    } else {
      if(!marketObj.id) {
        this.documentUpload.controls[marketIdCtrName].hasError('required');
     } 
     if (!year) {
       this.documentUpload.controls[yearCtrName].hasError('required');
     }

    }
  }
  downloadFileFromBase64(fileName, b64encodedString: string, contentType ) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  public base64ToBlob(b64Data, contentType='', sliceSize=512) {
    let byteArrays = ab2b64.b642ab(this.DownloadBase64Data)
    return new Blob([byteArrays], {type: contentType});
  }

  onFileSelectedSocialEngagement(event) {
    let fileList: FileList = event.target.files;
    const year = this.documentUpload.get("fcYear").value;
    const marketObj =  this.documentUpload.get("fcMarket").value;
    const marketId = marketObj.id;
    this.show='visible';
    this.cancelBtnEnable = false;
    this.doneBtnEnable = false;
    getFileBuffer(fileList[0]).then(async (fileBufferArray: ArrayBuffer) => {
      let fileName =  fileList[0].name;
      //let path=`${this.tcpDocData.hierarchy}/${this.year}/`;
      let Base64String = ab2b64.ab2b64(fileBufferArray);
      let type = fileList[0].type;
      let docObj = {
        Base64String,
        type,
        marketId,
        year 
      }
      this.isUploadErr =false;
      this.globalElementsService.uploadSEtemplate(docObj).subscribe((res) =>{
        this.uploadStatus="Uploaded Successfully"
        this.show = 'hidden';
        this.doneBtnEnable = true;
        event.target.value = null;
      }, (error) => {
        this.uploadStatus="Upload Failed"
        this.show = 'hidden';
        this.doneBtnEnable = true;
        this.isUploadErr = true;
        event.target.value = null;
      })
    })
  }
   onFileSelected(event) {
    let fileList: FileList = event.target.files;
    let location = this.location;

    let extension = fileList[0].name.split('.').pop();
    
    if(this.data.id == 5){
      let planValue = this.documentUpload.get('fcPlan').value.displayName;
      let AudienceValue = this.documentUpload.get('fcaudience').value.totalActivationAudience;
      this.selectedDocument = `${planValue}_${AudienceValue}.${extension}`;  
    }
    else{
      this.selectedDocument = fileList[0].name;
    }
    this.show='visible';
    this.cancelBtnEnable = false;
    this.doneBtnEnable = false;
    getFileBuffer(fileList[0]).then( (fileBufferArray: ArrayBuffer) => {
    let data = fileBufferArray;
      let docObj={
        body:{
          data,
          fileName: this.selectedDocument,
          uploadLocation:this.fetchSharePointPath()
        }
      };
      
      this.isUploadErr = false;
      this.globalElementsService.uploadDocuments(docObj).subscribe((res: any) => {
        const SHAREPOINT_PATH_URL = environment.SHAREPOINT_PATH_URL;
        const pathLoc = environment.pathLoc;
        const location = docObj.body.uploadLocation;
        const fileName = docObj.body.fileName;
        if(res.sqAuthheader){
          axios({
            method: 'POST',
            url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}${location}')/files/add(overwrite=true,url='${fileName}')`,
            headers: res.sqAuthheader,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            data: docObj.body.data
          }).then(async response => {
            try{
              if(response.status == 200){
                //logic to add in lower env if prod start
                if(pathLoc == "Shared Documents/"){
                  axios({
                    method: 'POST',
                    url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}TEST/${location}')/files/add(overwrite=true,url='${fileName}')`,
                    headers: res.sqAuthheader,
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    data: docObj.body.data
                  }).then(async response => {
                    try{
                      if(response.status == 200){        
                        // this.uploadStatus="Uploaded Successfully in lower Environment";
                        // this.show = 'hidden';
                        // this.doneBtnEnable = true;
                      }
                    } catch(error){
                        this.uploadStatus="Uploaded Failed";
                        this.isUploadErr = true;
                        this.show = 'hidden';
                        this.doneBtnEnable = false;
                        this.cancelBtnEnable = true;            
                    }
                  }).catch(error => {
                    if(error.response.status == 404){
                      this.uploadStatus="Upload failed as this folder does not exist";
                      this.show = 'hidden';
                      this.doneBtnEnable = false;
                      this.cancelBtnEnable = true;
                      console.log('Error: ', error);
                    }                  
                  })
                }
                //logic to add in lower env if prod end

                this.uploadStatus="Uploaded Successfully";
                this.show = 'hidden';
                this.doneBtnEnable = true;
              }
            } catch(error){
                this.uploadStatus="Uploaded Failed";
                this.isUploadErr = true;
                this.show = 'hidden';
                this.doneBtnEnable = false;
                this.cancelBtnEnable = true;            
            }
          }).catch(error => {
            if(error.response.status == 404){
              this.uploadStatus="Upload failed as this folder does not exist";
              this.show = 'hidden';
              this.doneBtnEnable = false;
              this.cancelBtnEnable = true;
              console.log('Error: ', error);
            }
           
          })
      }    
  })
 })
}
  changeOption(val) {
  if(val == '2') {
    this.optionEnable='upload';
  } else {
    this.optionEnable='download';
  }
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator);
  }
}
