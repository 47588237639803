import { ExportMenu } from '@amcharts/amcharts4/core';
import * as moment from 'moment-timezone';
import {REVENUE_TYPES, StatusConstants} from './app-constants';
import { ChannelData, Scenario, RevenueType} from './types';
import { environment } from 'environments/environment';

export function parseCurrency(number:number) {
  return parseInt(number.toString().replace(/,/g, ''), 10);
}

export function formatDate(date: string, format: string = 'MM/DD/YYYY') {
  date = moment(date).format(format);
  return date;
}

export const getRevenueType = (sector: string) => {
  let revenue = REVENUE_TYPES.REVENUE_ROI;

  switch (sector) {
    case 'PBNA':
    case 'SodaStream':
    case 'Smiley':
      revenue = REVENUE_TYPES.GROSS_PROFIT_MARGIN_ROI;
      break;
    case 'Shopper':
    case 'FLNA':
    case 'SAFBU':
    case 'QFNA':
    case 'JuicePlus':
      revenue = REVENUE_TYPES.NET_REVENUE_ROI;
      break;
  }

  return revenue;
}
export const getManualEffCalValueByRevenueType = (REVENUE_TYPE: RevenueType) => {
  let manualEffCalValue = 'prAvgPricePerVolume'
  
  switch (REVENUE_TYPE) {
    case REVENUE_TYPES.NET_REVENUE_ROI: 
      manualEffCalValue = 'prNetRevenue';
      break;
    case REVENUE_TYPES.GROSS_PROFIT_MARGIN_ROI: 
      manualEffCalValue = 'prGrossProfitMargin'
    break;
  }
  return manualEffCalValue;
}
export const BlankScenarioModel = (): Scenario => {
  return {
    channelData: [],
    id: null,
    scenariableId: null,
    scenariableType: null,
    workfrontId: null,
    creatorId: null,
    totalSpend: 0,
    name: null,
    roi: 0,
    roiNet: 0,
    roiGPM: 0,
    cid: 0,
    reach: 0,
    effectiveness: 0,
    legacy: false,
    optimized: null,
    approval: null,
  };
};

export const getInitialsLetters = (data: string): string => {
  try {
    return data.split(' ').map((name: string) => name.substring(0, 1)).join('');
  } catch (error) {
    console.error({ data, error });
  }
}

export const mapStatus = (status: string): string => {
  switch (status) {
    case StatusConstants.Planning:
      return 'Draft';
    case StatusConstants.Current:
      return 'Draft';
    case StatusConstants.ApprovedA:
    case StatusConstants.Requested:
      return 'Submitted';
    case StatusConstants.Approved:
      return 'Approved';
    case StatusConstants.Optimizing:
      return 'Optimizing';
    case StatusConstants.Optimized:
      return 'Optimized';
    default:
      return status;
  }
};

export const convertBaselineChannels = (chnl: { channel: string; spend: number }) => {
  const name = chnl.channel.toLowerCase();
  let newName = name;
  switch (name) {
    case 'streaming':
    case 'audio':
      newName = 'streaming audio';
      break;
    case 'digital other':
      newName = 'other';
      break;
    case 'terrestrial':
      newName = 'terrestrial audio';
      break;
    case 'local terrestrial':
      newName = 'local terrestrial radio';
      break;
    default:
      break;
  }

  return {
    channel: newName,
    spend: chnl.spend
  };
}

export const googleAnalyticsCode = (tagObject:any,googleService) => {
  let userInfo = JSON.parse(sessionStorage.getItem("user"));
  let pepHRFunctionName = sessionStorage.getItem('pepHRFunctionName');
  let pepsubdivision = sessionStorage.getItem('pepsubdivision');
  let pepHRSectorName = sessionStorage.getItem('pepHRSectorName');
  if(userInfo){
    tagObject.emailAddress = userInfo.email;
    tagObject.pepHRFunctionName = pepHRFunctionName ? pepHRFunctionName : 'NA';
    tagObject.pepsubdivision = pepsubdivision ? pepsubdivision : 'NA';
    tagObject.pepHRSectorName = pepHRSectorName ? pepHRSectorName : 'NA';
    googleService.pushTag(tagObject);
  }
}

export const updateScenarioReach = (scenario: Scenario, data: any): Scenario => {
  //
  const totalReach = data.filter(item => item.ReachForecast).reduce((curr, item) => {
    if (curr === 0) {
      return (1 - Number(item.ReachForecast.toFixed(4) / 100));
    }
    return curr * (1 - Number(item.ReachForecast.toFixed(4) / 100));
  }, 0);
  const normalizedReach = (1 - totalReach).toFixed(4);

  return {
    ...scenario,
    reach: +normalizedReach,
    reachChannelData: data.filter(item => item.ReachForecast).map(item => ({
      name: item.channel,
      spend: item.spend,
      reach: item.ReachForecast.toFixed(4)//(1 - Number(item.ReachForecast.toFixed(4) / 100))
    }))
  }
}

export const updateScenario = (scenario: Scenario, channelData: ChannelData[], data: any): Scenario => {
  const newScenario: Scenario = {
    ...scenario,
    name: data.hasOwnProperty('name') ? data.name : scenario.name,
    totalSpend: data.hasOwnProperty('currentSpend') ? data.currentSpend : scenario.totalSpend,
    // roi: data[0].TotalROIForecast,
    // roiNet: data[0].NetRevenue_TotalROIForecast,
    // roiGPM: data[0].GrossProfit_TotalROIForecast,
    // cid: 1 / data[0].TotalROIForecast,
    effectiveness: Array.isArray(data) && data.length ? data[0].TotalEffectiveness : 0,
    channelData: channelData.map((channel: ChannelData) => {
      const res = data.find(d => d.channel === channel.name);

      if (res) {
        return {
          ...channel,
          woa: {
            ...channel.woa,
            current: (channel.startDate && channel.endDate) ? calculateWeeksOnAir(channel.startDate, channel.endDate) : channel.woa.current,
          },
          roi: parseFloat((res.ROIForecast * channel.fac).toFixed(4)),
          roiNet: parseFloat((res.NetRevenue_ROIForecast * channel.fac).toFixed(4)),
          roiGPM: parseFloat((res.GrossProfit_ROIForecast * channel.fac).toFixed(4)),
          cid: parseFloat((1 / (res.ROIForecast * channel.fac)).toFixed(4)),
          eff: res.effectiveness,
          reach: channel.reach,
          rev: (res.ROIForecast * channel.fac) * channel.spend
        };
      }

      // no curve data for channel
      return {
        ...channel,
        woa: {
          ...channel.woa,
          current: (channel.startDate && channel.endDate) ? calculateWeeksOnAir(channel.startDate, channel.endDate) : channel.woa.current,
        },
        roi: parseFloat((channel.kpi * channel.fac).toFixed(4)),
        roiNet: parseFloat((channel.kpi * channel.fac).toFixed(4)),
        roiGPM: parseFloat((channel.kpi * channel.fac).toFixed(4)),
        cid: parseFloat((1 / (channel.kpi * channel.fac)).toFixed(4)),
        eff: 0,
        reach: channel.reach,
        rev: (channel.kpi * channel.fac) * channel.spend
      }
    }),
    approval: scenario.id ? scenario.approval : 'CUR',
  }

  //
  const rollUpData = newScenario.channelData.reduce((carry: any, channel: ChannelData) => {
    return {
      roi: carry.roi + (channel.spend * channel.roi),
      roiNet: carry.roiNet + (channel.spend * channel.roiNet),
      roiGPM: carry.roiGPM + (channel.spend * channel.roiGPM),
      spend: carry.spend + channel.spend,
      reach: carry.reach === 0 ? (1 - scenario.reach) : carry.reach * (1 - scenario.reach)
    };
  }, {
    roi: 0,
    roiNet: 0,
    roiGPM: 0,
    spend: 0,
    reach: 0
  });

  const combinedROI = Number(rollUpData.roi / rollUpData.spend);
  const combinedROINet = Number(rollUpData.roiNet / rollUpData.spend);
  const combinedROIGPM = Number(rollUpData.roiGPM / rollUpData.spend);
  const combinedReach = rollUpData.reach ? Number(1 - rollUpData.reach) : 0;

  newScenario.reach = isNaN(combinedReach) ? 0 : combinedReach;
  newScenario.roi = isNaN(combinedROI) ? 0 : combinedROI;
  newScenario.roiNet = isNaN(combinedROINet) ? 0 : combinedROINet;
  newScenario.roiGPM = isNaN(combinedROIGPM) ? 0 : combinedROIGPM;
  newScenario.cid = isNaN(combinedROI) ? 0 : (1 / combinedROI);

  return newScenario;
}

export const buildWFNote = (projectID, noteText, noteObjCode, objID, userNotifiedID = null) => {
  const note = { projectID, noteText, noteObjCode, objID, tags: [] };
  if (userNotifiedID) {
    note.tags.push({ objObjCode: 'USER', objID: userNotifiedID });
  }
  return [note];
};

export const SVGToImage = (svgData: any, width: number, height: number, format = 'png', callback: any) => {
  // SVG data URL from SVG string
  // var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
  // create canvas in memory(not in DOM)
  const canvas = document.createElement('canvas');
  // get canvas context for drawing on canvas
  const context = canvas.getContext('2d');
  // set canvas size
  canvas.width = width;
  canvas.height = height;
  // create image in memory(not in DOM)
  const image = new Image();
  // later when image loads run this
  image.onload =  () => { // async (happens later)
    // clear canvas
    context.clearRect(0, 0, width, height);
    // draw image with SVG data to canvas
    context.drawImage(image, 0, 0, width, height);
    // snapshot canvas as png
    const pngData = canvas.toDataURL('image/' + format);
    // pass png data URL to callback
    callback(pngData);
  }; // end async
  // start loading SVG data into in memory image
  image.src = svgData;
};

/**
 * @param startDate Date
 * @param endDate Date
 * @return Integer - The number of weeks (ceiling) between two dates, up to a maximum of one year (52 weeks).
 */
export const calculateWeeksOnAir = (startDate, endDate) => {

    const startDateMoment = moment(startDate, 'YYYY-MM-DD');
    const endDateMoment = moment(endDate, 'YYYY-MM-DD');

    return Math.min(52, (Math.abs(endDateMoment.diff(startDateMoment, 'weeks')) + 1));

};

/**
 * @param region String
 * @param level Integer
 * @return Boolean - If the given brand is the trademark or not.
 */
export const isTrademark = (region, level) => (
    (region === 'NA' || region === 'Global') ? (level === 2) : (level === 3)
);
