import { BrandManagementService } from './../../../services/brand-management.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { BrandManagementDialogsComponent } from '../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { IMarketSave, IMarketGroupSave } from './../../../../common/interfaces/brandManagement';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { take } from '../../../../../../node_modules/rxjs/operators';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-enhance-market-group',
  templateUrl: './enhance-market-group.component.html',
  styleUrls: ['./enhance-market-group.component.scss']
})
export class EnhanceMarketGroupComponent implements OnInit, OnDestroy {


  @Input() pageMode;
  @Input() selectedMarketGroupList;
  @Output() changePageModeEmitter = new EventEmitter
  userInfo = JSON.parse(sessionStorage.getItem('user'));

  @Output() marketGroupSelectionEvent = new EventEmitter
  @Output('dirty') formDirty = new EventEmitter<boolean>()

  marketGroupForm: FormGroup;
  displayName: string = 'Create New Market Group';
  marketsList: Array<any> = [];
  selectMarkets: Array<number> = [];
  saveBtnSubscription: Subscription;
  isEditMode: boolean = false;
  mgName: string = '';

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }




  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !== 6);
    this.globalElementsService.setSaveEnableState(false);
    this.getAllMarkets();
    this.iniitializeMarketForm();

    this.marketGroupForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.marketGroupForm.valid);
      this.formDirty.emit(this.marketGroupForm.dirty);
    })

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());

    if (this.pageMode == PAGE_MODES.EDIT) {
      this.isEditMode = true;
      this.displayName = 'Edit Market Group';
      //this.selectMarkets = this.selectedMarketGroupList;
      this.mgName = this.selectedMarketGroupList[0].name;

      //call service to get markets associated to a market group and show like checked
      this.getAllMarketsByMarketGroup(this.mgName);


    }

  }



  iniitializeMarketForm(): void {
    this.marketGroupForm = this.fb.group({
      marketGroupName: ['', Validators.required],
      markets: [, Validators.required],
    })

  }



  /*........... functionality Methods........ */

  formErrorHandeling(control: string, error: string): boolean {
    return this.marketGroupForm.controls[control].hasError(error);
  }

  backToHome() {
    this.marketGroupSelectionEvent.emit([]);
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Group_Management`,
      eventAction: "Market Group Management Back Button Cliked",
      eventLable: `Global Elements back button clicked from market group management`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  addMarketToForm(mktId: number, isChecked: boolean) {
    if (isChecked) {
      this.selectMarkets.push(mktId)
    } else {
      this.selectMarkets = this.selectMarkets.filter(id => id !== mktId);
    }
    this.marketGroupForm.patchValue({
      markets: this.selectMarkets,
    });
  }

  patchFormWithMarkets() {
    this.marketGroupForm.patchValue({
      markets: this.selectMarkets,
      marketGroupName: this.isEditMode ? this.mgName : ''
  
    });
  }


  isMarketSelected(mktId: number): boolean {
    return this.selectMarkets.includes(mktId);
  }

  onSubmit(): void {

    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        type: this.isEditMode ? 'update' : 'save',
        module: 'marketGroup',
        apiData: this.constructPostObj(),
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {
      this.backToHome();
    });


  }


  /*........... Service/API calls........ */
  getAllMarkets(): void {
    this.brandManagementService.getAllMarkets().subscribe((res: any) => {
      this.marketsList = res.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
    })
  }

  getAllMarketsByMarketGroup(marketGroupName: string): void{
    this.brandManagementService.getMarketsByMarketGroup(marketGroupName).subscribe((res: any) => {
       this.selectMarkets = res.marketIds;
       this.patchFormWithMarkets();
       this.userInfo.roleId === 6 ? this.marketGroupForm.disable() : null;

    })

  }



  constructPostObj(): IMarketGroupSave {

    let newMarketGroup: IMarketGroupSave = {
      marketGroupName: this.marketGroupForm.value.marketGroupName,
      marketIds: this.marketGroupForm.value.markets
    };
    if (this.isEditMode) {
      newMarketGroup.marketGroupId = this.selectedMarketGroupList[0].id;
    }
    return newMarketGroup;
  }

  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }

}
