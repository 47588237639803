import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';
import { MatPaginator, MatSort, MatTableDataSource } from '../../../../../../node_modules/@angular/material';
import { PAGE_MODES, USER_ACCESS_LEVEL } from './../../../../constants/app-constants';
import {MultiselectAutocompleteUserSetupComponent} from 'app/common/components/multiselect-autocomplete-user-setup/multiselect-autocomplete-user-setup.component';
@Component({
  selector: 'app-user-access-setup-multiple-dialog',
  templateUrl: './user-access-setup-dialog-multiple.component.html',
  styleUrls: ['./user-access-setup-dialog-multiple.component.scss']
})

export class userAccessSetupDialogMultipleComponent implements OnInit {
 //message: string = "Saving User Information... Please Wait";
 //exitUserProfileForm : boolean = false;
portfoliosByMarketList: any [];
plansBymarketList: any [];
allRoles: any [];
placeholder: string;
selctedData: any [];
valueFeild: string = "";
textFeild:string = "";
roleSelected: number;
userAccess: string;
//displayedColumns: string[] = ['pfName','role','delete']; 
//dataSource = new MatTableDataSource<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
    private dialogRef: MatDialogRef<userAccessSetupDialogMultipleComponent>,

    ) {}
  
  ngOnInit() {
    //this.saveUserProfile();
   // this.allRoles = this.data.allRoles;
   // this.portfoliosByMarketList = this.data.portfolios;
   this.selctedData = [];
   this.portfoliosByMarketList = this.data.portfolios;
   this.allRoles = this.data.allRoles;
   this.plansBymarketList = this.data.plans;
    this.userAccess = this.data.userAccess;
    //this.roleSelected = this.data.datasource.roleId ? this.data.datasource.roleId : null;
    if(this.userAccess === USER_ACCESS_LEVEL.BRAND) {
      this.placeholder =  "Select Plan";
      this.valueFeild = 'id';
      this.textFeild = 'displayName';
    } else {
      this.placeholder =  "Select Portfolio";
      this.valueFeild = 'pfId';
      this.textFeild = 'pfName';
    }
    //this.dataSource = this.data.dataSource;

  }

  
  saveUserForm() {
    this.dialogRef.close({ cancelBtnClickedSts: false, resultData: {index: this.data.index, selectedData: this.selctedData, roleId: this.roleSelected, userAccess:this.userAccess} });
  }
  // Quit the edit form on clicking OK
  cancelBtnClicked() {
    this.dialogRef.close({ cancelBtnClickedSts: true });
    //this.exitUserProfileForm ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }
  sendData(){
    if(this.userAccess === USER_ACCESS_LEVEL.BRAND) {
      return this.plansBymarketList;
    } else {
      return this.portfoliosByMarketList;
    }
  }
  sendSelectedData(){
   return this.selctedData; 
  }
  selectChange = (event: any) => {
    this.selctedData = event.data;
    //this.mjtbdForm.get("mjtbdApprover").patchValue(this.selectedMjtbdApprovers);
  };
}
