import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-confirmation-dialog',
  templateUrl: './custom-confirmation-dialog.component.html',
  styleUrls: ['./custom-confirmation-dialog.component.scss']
})
export class CustomConfirmationDialogComponent implements OnInit {
  message: any;
  customSaveButtonText: any;
  customCancelButtonText: any;
  isTitleVisible: true;
  customTitleText: any;

  constructor(
    public dialogo: MatDialogRef<CustomConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    cancel(): void {
      this.dialogo.close(false);
    }
    confirm(): void {
      this.dialogo.close(true);
    }

  ngOnInit() {
    this.message= this.data.message;
    this.customSaveButtonText = this.data.customSaveButtonText? this.data.customSaveButtonText: 'Yes';
    this.customCancelButtonText = this.data.customCancelButtonText? this.data.customCancelButtonText : 'No';
    this.customTitleText = this.data.customTitleText? this.data.customTitleText : 'Confirmation';
    this.isTitleVisible = this.data.isTitleVisible;
  }

}
