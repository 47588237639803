import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { UpdateTargetDialogComponent } from 'app/common/components/dialogs/update-target-dialog/update-target-dialog.component';
import { IBrandBaseTarget, IBrandTarget } from 'app/common/interfaces/plan';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Subscription } from '../../../../node_modules/rxjs';
import { take } from '../../../../node_modules/rxjs/operators';
import { IBreadcrumb } from './../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId, isValidUserAccess } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
import { NumberValidators } from 'app/common/validator/NumberValidators';



@Component({
  selector: 'app-performance-target',
  templateUrl: './performance-target.component.html',
  styleUrls: ['./performance-target.component.scss']
})
export class PerformanceTargetComponent implements OnInit, OnDestroy {
  
  @Input() elementDetails;  
  @Output('dirty')  formDirty = new EventEmitter<boolean>()
  
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  targetForm: FormGroup;
  targetData: IBrandBaseTarget;
  saveBtnSubscription: Subscription;
  pathDetails : IBreadcrumb;
  
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.targetData=this.elementDetails.targetData;
    this.globalElementsService.setShowSaveBtn(isValidUserAccess(6, '!==', null, this.targetData ? this.targetData.planId: null));
    this.globalElementsService.setSaveEnableState(false);
    this.pathDetails = {
      
      lastNodeName: 'Annual Brand Targets',
      pageId: this.targetData?this.targetData.planId:this.elementDetails?this.elementDetails.planId:undefined,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.ANNUAL_BRAND_TARGETS
    }
   // console.log('elementDetails', this.elementDetails);
    this.intiateForm(this.targetData);
    this.targetForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.targetForm.valid);
      this.formDirty.emit(this.targetForm.dirty);
    })


    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.updateBrandTarget());
    isValidUserAccess(6, '===', null, this.targetData?this.targetData.planId:null) ? this.targetForm.disable() : null;
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Marketing_Targets`,
      eventAction: "PageView",
      eventLable: `Brand Marketing Targets Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
    this.validateZero();
    this.validate100();
  }
  
  intiateForm(data) {
    this.targetForm = this.fb.group({
      annualHHpentrationTarget: [data ? Number(data.annualHHpentrationTarget).toFixed(2) : '',[Validators.max(100), Validators.min(0), NumberValidators.isNumberCheck()]],
      annualAwarenessIntensityTarget: [data ? Number(data.annualAwarenessIntensityTarget).toFixed(2) : '',[Validators.max(100), Validators.min(0), NumberValidators.isNumberCheck()]],
      annualPrefIntensityTarget: [data ? Number(data.annualPrefIntensityTarget).toFixed(2) : '',[Validators.max(100), Validators.min(0), NumberValidators.isNumberCheck()]],
      annualMarketShareTarget: [data ? Number(data.annualMarketShareTarget).toFixed(2) : '',[Validators.max(100), Validators.min(0), NumberValidators.isNumberCheck()]]
    });
  }

  /*........... functionality Methods........ */

  updateBrandTarget() {
    let dialogRef;
    dialogRef = this.dialog.open(UpdateTargetDialogComponent, {
      data: this.constructBrandTargetObj(),
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
      let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
        eventCategory: `Brand_Marketing_Targets`,
        eventAction: "Save Brnad Marketing Targets",
        eventLable: `Brand Marketing Targets Save button Click`,
        customeEvent: `custom_${environment.environmentName}_Event`,
        userId: getGpId(),
        user_id: getGpId()
      }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
    });
  }

  constructBrandTargetObj(): IBrandTarget {
    const formData = this.targetForm.value;
    let targetObj: IBrandTarget = {
      bmTargetId: this.targetData?this.targetData.bmTargetId:this.elementDetails?this.elementDetails.bmTargetId:undefined,
      planId: this.targetData?this.targetData.planId:this.elementDetails?this.elementDetails.planId:undefined,
      year: this.targetData?this.targetData.year:this.elementDetails?this.elementDetails.year:undefined,
      brandTargetInfo: formData
    }
    return targetObj;
  }
  
  /*........... Clean up methods........ */
 
  ngOnDestroy(){
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Marketing_Targets`,
      eventAction: "PageExit",
      eventLable: `Edit Brand Marketing Targets Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  validateZero(){
    if(this.targetForm.controls['annualHHpentrationTarget'].value==0){
      this.globalElementsService.setSaveEnableState(false);
      this.targetForm.controls['annualHHpentrationTarget'].setValue('');
    } 
    if(this.targetForm.controls['annualPrefIntensityTarget'].value==0) {
      this.targetForm.controls['annualPrefIntensityTarget'].setValue('');
      this.globalElementsService.setSaveEnableState(false);
    }
    if(this.targetForm.controls['annualAwarenessIntensityTarget'].value==0) {
      this.targetForm.controls['annualAwarenessIntensityTarget'].setValue('');
      this.globalElementsService.setSaveEnableState(false);
    }
    if(this.targetForm.controls['annualMarketShareTarget'].value==0) {
      this.targetForm.controls['annualMarketShareTarget'].setValue('');
      this.globalElementsService.setSaveEnableState(false);
    }
  }
  validate100(){
    if(this.targetForm.controls['annualHHpentrationTarget'].value==100.00){
      this.targetForm.controls['annualHHpentrationTarget'].setValue('100');
    } 
    if(this.targetForm.controls['annualPrefIntensityTarget'].value==100.00) {
      this.targetForm.controls['annualPrefIntensityTarget'].setValue('100');
    }
    if(this.targetForm.controls['annualAwarenessIntensityTarget'].value==100.00) {
      this.targetForm.controls['annualAwarenessIntensityTarget'].setValue('100');
    }
    if(this.targetForm.controls['annualMarketShareTarget'].value==100.00) {
      this.targetForm.controls['annualMarketShareTarget'].setValue('100');
    }
  }
}
