import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.scss']
})
export class BrandManagementComponent {
  @Output('dirty')
  formDirty = new EventEmitter<boolean>();

  isCreateMode = false;
  isEditMode = false;
  isHomeMode = true;
  editData: any;
  isMarketGroup: boolean;
  isPortfolio: boolean;
  isPortfolioGroup: boolean;

  CATEGORY_ID = {
    MARKET: 1,
    MARKET_GROUP: 2,
    PORTFOLIO: 3,
    PORTFOLIO_GROUP: 4,
    BRAND: 5,
  }

  categoryList = [{
    id: this.CATEGORY_ID.MARKET,
    name: 'Market',
    isOpened: true,
    class: 'opened'
  },
  {
    id: this.CATEGORY_ID.MARKET_GROUP,
    name: 'Market Group',
    isOpened: false,
    class: ''
  },
  {
    id: this.CATEGORY_ID.PORTFOLIO,
    name: 'Portfolio',
    isOpened: false,
    class: ''
  },
  {
    id: this.CATEGORY_ID.PORTFOLIO_GROUP,
    name: 'Portfolio Group',
    isOpened: false,
    class: ''
  },
  {
    id: this.CATEGORY_ID.BRAND,
    name: 'Brand',
    isOpened: false,
    class: ''
  }];

  selectedCategory = this.categoryList.find(el => el.id == this.CATEGORY_ID.MARKET);


  changeCategory(catId) {
    this.selectedCategory = this.categoryList.find(el => el.id == catId);
  }

  isFormDirty(isDirty:boolean) {

    this.formDirty.emit(isDirty);

  }
}
