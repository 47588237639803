import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { IOverview, IPortfolios } from 'app/common/interfaces/common';
import { IMarketBody, IMarketResponse, IPortfolioGroupResponse, IPortfolioGroups } from 'app/common/interfaces/market';
import { IOverTableInfo, IOverviewTable, ISectionHeader } from 'app/common/interfaces/overviewSection';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { constructOverviewTableData } from 'app/common/utils/overview-util';
import { AGGR_VIEW } from 'app/constants/app-constants';
import { SECTION_HEADER_INFO, TABLE_HEADERS } from 'app/constants/overview-constants';
import { MarketService } from "./services/market.service";
import { StoreService } from '../old/_services/_store.service';
import { environment } from 'environments/environment';
import { returnAccessflag, getGpId } from 'app/common/utils/common-util';
import { ACCESS_LEVEL } from 'app/constants/app-constants'
const userInfo = JSON.parse(sessionStorage.getItem('user'));
import {googleAnalyticsCode} from 'app/util';



@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})

export class MarketComponent implements OnInit, OnDestroy {

  paramsId: number;
  isLoading: boolean = false;
  overviewTableData: IOverTableInfo;
  sectionHeader: ISectionHeader;
  isMarketErr: boolean = false;
  overviewTableObj:IOverviewTable={
    businessUnit:'',
    totalPlanned:'',
    plannedToDate:'',
    actualToDate:'',
    spenDiff:'',
    plannedROI:'',
    actualROI:'',
    roiDiff:'',
    upfrontPlan:'',
    isGroup:false,
    isList:false,
    id:'',
    path:'',
    brandGrowth:'',
    audienceSeg:'',
    status:'',
    accessible:false
};
  marketName: string;
  portfolioList: Array<IPortfolios>;
  workingYear: number;
  type: string = 'market';
  planItViewovwData: Array<IOverview>;
  tcpViewOvwData: Array<IOverview>;
  overviewData: Array<IOverview>;
  finalList: Array<IOverviewTable> = [];
  portfolioGroupData: Array<IPortfolioGroups> = [];
  portfolios: Array<IPortfolios> = [];
  aggrView:string;
  userInfo;
  aggrViewSubscription: Subscription;
  workingYearSubscription: Subscription;
  constructor(
    private marketService: MarketService,
    private route: ActivatedRoute,
    public router: Router,
    private sharedDataService: SharedDataService,
    protected googleTagManagerService: GoogleTagManagerService,
    private store: StoreService,
  ) { this.userInfo = JSON.parse(sessionStorage.getItem("user"));}

  /*........... Initialization Methods........ */

  ngOnInit() {

    this.aggrViewSubscription =  this.sharedDataService.getAggrView.subscribe((viewType: string) => {
      this.aggrView = viewType;
      if (this.aggrView == AGGR_VIEW.PLANIT) {
        this.getMediaMix();
        this.overviewData = this.planItViewovwData;
      } else {
        this.overviewData = this.tcpViewOvwData;
      }


    });

    this.route.params.subscribe(params => {
      
      const marketId = params['id'];
      if(!returnAccessflag(ACCESS_LEVEL.MARKET, marketId).result) {
         this.router.navigate(['/accessDenied']);
         return null;
      } else {
        this.paramsId = marketId && Number(marketId);
       
        this.getmarketData();
        this.getOverviewTableData();
      }
    });
   this.workingYearSubscription = this.sharedDataService.getWorkingYear.subscribe((res: any) => {
      if (res) {
        this.workingYear = res;
        this.getmarketData();
        this.getOverviewTableData();
      }
    })
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event` , 
      eventCategory: `Market_Page`, 
      eventAction: "PageView", 
      eventLable: `Market View`, 
      customeEvent: `custom_${environment.environmentName}_Event`, 
      userId: '80074',
      user_id: '80074'
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
    if(this.paramsId) {
      this.store.set('market', this.paramsId);
      this.sharedDataService.setMarket(this.paramsId);
    }
  
  }

  /*........... Service/API calls........ */

  getmarketData() {

    if (!this.workingYear || !this.paramsId) {
      return null
    }
    this.isMarketErr = false;
    this.marketName = '';
    this.overviewData=null;
    const marketObj:IMarketBody = {
      marketId: this.paramsId || userInfo.defaultMarket,
      year: this.workingYear
    }
    this.marketService.getMarketData(marketObj).subscribe((res: IMarketResponse) => {
      if (res) {
        this.isMarketErr = false;
        this.marketName = res.market.displayName;
        this.portfolioList = res.portfolios;
        this.tcpViewOvwData = res.marketOverview;
        this.planItViewovwData = res.planItOverview;
        this.overviewData = this.aggrView == AGGR_VIEW.PLANIT ? this.planItViewovwData : this.tcpViewOvwData;
        this.sectionHeader =SECTION_HEADER_INFO.MARKET;     
        if (this.aggrView == AGGR_VIEW.PLANIT) {
          this.getMediaMix();
        }
        let tagObject =  {
          event: `custom_${environment.environmentName}_pageView`,
          pageUrl:`market/${this.marketName}`,
          pageTitle: `Market Page`,
          userId: getGpId(),
          user_id: getGpId()
        }
        googleAnalyticsCode(tagObject,this.googleTagManagerService);
      }
    }, () => this.isMarketErr = true);
  }

  getOverviewTableData() {

    if (!this.workingYear || !this.paramsId) {
      return null
    }
    this.finalList = [];
    this.overviewTableData = null;
    const marketObj:IMarketBody = {
      marketId: this.paramsId || userInfo.defaultMarket,
      year: this.workingYear
    }
    this.marketService.getPortfolioGroupsByMarket(marketObj).subscribe((res: IPortfolioGroupResponse) => {
      if (res) {
        this.portfolioGroupData = res.portfolioGroups;
        this.portfolios = res.portfolios;
        this.overviewTableData = this.constructOverviewTableData();
      }
    })
  }

  getMediaMix(){

    if(!this.paramsId || !this.workingYear){
      return null
    }

    const postObj :IMarketBody = {
      marketId:this.paramsId,
      year: this.workingYear
    }
    this.marketService.getMediaMix(postObj).subscribe((res:any)=>{
      const mediaMixObj: IOverview = {
        refernceCode: 'FLOWPACK_MEDIA',
        annualWorkingMediaBudget: null,
        flowPackData: res && res.channelSpend || null,
        ytdROI: null,
        year: this.workingYear
      }
      this.overviewData = [...this.overviewData, mediaMixObj]
    
    })
  }


  constructOverviewTableData(): IOverTableInfo {
    this.portfolioGroupData.forEach((grp: IPortfolioGroups) => {
      const row = constructOverviewTableData(null, this.overviewTableObj);
      row.businessUnit = grp.name;
      row.isGroup = true;
      row.accessible = true;
      row.id = grp.id;
      row.path = `/portfolioGroup/${row.id}`;
      this.finalList.push(row);
      let portFolioList = []
      grp.portfolios.forEach((portf: IPortfolios) => {
        const row = constructOverviewTableData({ displayName: portf.displayName, status: portf.status, overview: portf.pfOverview }, this.overviewTableObj);
        row.id = portf.id;
        row.accessible = portf.accessible;
        row.path = `/portfolio/${row.id}`;
        portFolioList.push(row);
        this.finalList.push(row);
      })
      let groupIndex = this.finalList.length - portFolioList.length - 1;
      this.finalList[groupIndex].totalPlanned = portFolioList.reduce((acc, cv) => {
        acc = acc + Number(cv.totalPlanned);
        return acc;
      }, 0);
      this.finalList[groupIndex].plannedToDate = portFolioList.reduce((acc, cv) => {
        acc = acc + Number(cv.plannedToDate);
        return acc;
      }, 0);
      this.finalList[groupIndex].actualToDate = portFolioList.reduce((acc, cv) => {
        acc = acc + Number(cv.actualToDate);
        return acc;
      }, 0);
      this.finalList[groupIndex].actualROI = portFolioList.reduce((acc, cv) => {
        acc = acc + Number(cv.actualROI);
        return acc;
      }, 0);
      this.finalList[groupIndex].plannedROI = portFolioList.reduce((acc, cv) => {
        acc = acc + Number(cv.plannedROI);
        return acc;
      }, 0);
      this.finalList[groupIndex].roiDiff = Number(this.finalList[groupIndex].plannedROI) != 0 ? (Number(this.finalList[groupIndex].actualROI) - Number(this.finalList[groupIndex].plannedROI)) / this.finalList[groupIndex].plannedROI : 0;
      this.finalList[groupIndex].spenDiff = Number(this.finalList[groupIndex].plannedToDate) != 0 ? (Number(this.finalList[groupIndex].actualToDate) - Number(this.finalList[groupIndex].plannedToDate)) / this.finalList[groupIndex].plannedToDate : 0;

    });

    this.portfolios.forEach((pf: IPortfolios) => {
      const row = constructOverviewTableData({ displayName: pf.displayName, status: pf.status, overview: pf.pfOverview }, this.overviewTableObj);
      row.isList = true;
      row.id = pf.id;
      row.accessible = pf.accessible;
      row.path = `/portfolio/${row.id}`;
      this.finalList.push(row);
    })
    let tableObj: IOverTableInfo = {
      tableMainHeaderRow: TABLE_HEADERS.MARKET.MAIN,
      tableSubHeaderRow: TABLE_HEADERS.MARKET.SUB,
      tableData: this.finalList
    }
    return tableObj;
  }
  
 

  ngOnDestroy(){    
    this.aggrViewSubscription.unsubscribe();
    this.workingYearSubscription.unsubscribe();
  }

}
