import { TooltipCategory } from "./../../../global-elements/tooltip-configuration/tooltip-category-list/tooltip-category.component";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { IHierarchy } from "app/common/interfaces/common";
import { BreadcrumbService } from "app/common/services/breadcrumb.service";
import { SharedDataService } from "app/common/services/shared-data.service";
import {
  BREADCRUMBS_REFERENCE_TYPES,
  GLOBAL_ELEMENTS,
} from "app/constants/app-constants";
import { REFERNCE_CODE } from "app/constants/overview-constants";
import { MarketService } from "app/market/services/market.service";
import { PlanService } from "app/plan/services/plan.service";
import { PortfolioGroupService } from "app/portfolio-group/services/portfolio-group.service";
import { PortfolioService } from "app/portfolio/services/portfolio.service";
import currencyToSymbolMap from "currency-symbol-map/map";
import * as moment from "moment";
import { Subscription } from "../../../../../node_modules/rxjs";
import {
  CHART_MONTHS,
  CHART_PERIODS,
  CHART_QUARTER,
  CHART_SERIES,
  TRENDS_CHART_LEGENDS,
  TREND_CHART_LEGENDS,
  WIDGET_CHART_LEGENDS,
} from "../../../constants/chart-constants";
import {
  IChartData,
  IChartResponse,
  ITrendsChart,
  ITrendSVisibility,
  IWidgetChart,
  IWidgetVisibility,
} from "../../interfaces/chartInterface";
import { AGGR_VIEW } from "./../../../constants/app-constants";
import { IWidgetPlanInfo } from "app/common/interfaces/plan";
import { isValidUserAccess } from "app/common/utils/common-util";
import { GlobalElementsService } from "../../../global-elements/services/global-elements.service";
import { take } from "../../../../../node_modules/rxjs/operators";
import * as _ from "lodash";
const lineColors = ["#FFC582", "#7030A0", "#8394B8"];

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent implements OnInit, OnDestroy {
  @Input() type: string;
  showtooltipTitle: boolean = false;
  tooltipTitle: string;
  tooltipTitleWidget: string;
  showtooltipTitleWidget: boolean = false;
  userInfo = JSON.parse(sessionStorage.getItem("user"));
  chartData;
  selectedMarket = sessionStorage.getItem("market");
  activeTrendsPeriod: any = CHART_PERIODS.monthly;
  activeWidgetsPeriod: any = CHART_PERIODS.monthly;
  isLoading: boolean = true;
  marketName: string;
  displayedColumns: string[] = ["name", "planned", "actual", "fy"];
  dataSource = new MatTableDataSource<any>();
  activeWidget: string;
  activeWidgetId: Array<number> = [];
  widgets: Array<string> = [];
  activeWidgetRow;
  workingYear: number;
  prevYear: number;
  prevYearMinified: string;
  cancelBtnSubscription: Subscription;
  trendChart;
  widgetChart;
  planId;
  aggrView: string;
  planWidgetData: IWidgetPlanInfo;
  trendsVisibilityObj: ITrendSVisibility = {
    pspn_bar: true,
    aspn_bar: true,
    prevy_bar: true,
    aroi_line: true,
    pdro_line: true,
    prevy_line: true,
  };
  widgetVisibilityObj: IWidgetVisibility = {
    aroi_line: true,
    pdro_line: true,
    prevy_line: true,
  };
  widgetChartObj: IWidgetChart = {
    period: "",
    aroi_line: "",
    pdro_line: "",
    prevy_line: "",
  };
  trendsChartObj: ITrendsChart = {
    period: "",
    pspn_bar: "",
    aspn_bar: "",
    prevy_bar: "",
    aroi_line: "",
    pdro_line: "",
    prevy_line: "",
  };
  paramsId: number;
  currencyCode: String = "USD";
  aggrViewSubscription: Subscription;
  workingYearSubscription: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private marketService: MarketService,
    private portfolioService: PortfolioService,
    private portfolioGroupService: PortfolioGroupService,
    private planService: PlanService,
    private sharedDataService: SharedDataService,
    private globalElementservice: GlobalElementsService
  ) {
    this.chartData = {};
  }

  ngOnInit() {
    this.isLoading = true;

    this.aggrViewSubscription = this.sharedDataService.getAggrView.subscribe(
      (viewType: string) => {
        this.aggrView = viewType;
        if (this.chartData) {
          this.getChartData(this.type);
          if (this.aggrView == AGGR_VIEW.PLANIT) {
            this.getChartData(`${this.type}PSPN`);
          }
        }
      }
    );

    this.route.params.subscribe((params) => {
      this.paramsId = params["id"];
      this.getChartData(this.type);
      if (this.aggrView == AGGR_VIEW.PLANIT) {
        this.getChartData(`${this.type}PSPN`);
      }
    });

    this.workingYearSubscription =
      this.sharedDataService.getWorkingYear.subscribe((res: any) => {
        if (res != this.workingYear) {
          this.chartData = {};
          this.workingYear = res;
          this.getChartData(this.type);
          if (this.aggrView == AGGR_VIEW.PLANIT) {
            this.getChartData(`${this.type}PSPN`);
          }
        }
      });

    this.sharedDataService.getCurrencyCode.subscribe(
      (res) => (this.currencyCode = res)
    );
    this.globalElementservice
      .getTooltipData("WorkingMediaBudgetROITrends", this.selectedMarket)
      .subscribe((res_n: any) => {
        if (res_n.data && res_n.data[0]) {
          this.tooltipTitle = res_n.data[0]["tooltipText"];
        }
      });
    this.globalElementservice
      .getTooltipData("OtherKeyMediaOutcomes", this.selectedMarket)
      .subscribe((res_n: any) => {
        if (res_n.data && res_n.data[0]) {
          this.tooltipTitleWidget = res_n.data[0]["tooltipText"];
        }
      });
  }
  showTooltip() {
    this.showtooltipTitle = true;
  }
  hideTooltip() {
    this.showtooltipTitle = false;
  }
  showTooltipWidget() {
    this.showtooltipTitleWidget = true;
  }
  hideTooltipWidget() {
    this.showtooltipTitleWidget = false;
  }

  returnAccess(_type) {
    return _type === "plan" && isValidUserAccess(6, "!==", null, this.paramsId);
  }
  async getChartData(type: string) {
    this.isLoading = true;
    if (!this.workingYear || !this.paramsId) {
      return null;
    }

    if (type == "program") {
      const hierarchyObj: IHierarchy = {
        referenceId: this.paramsId,
        referenceType: BREADCRUMBS_REFERENCE_TYPES.MJTBD,
        year: this.workingYear,
      };
      //  this.breadcrumbService.getHierarchy(hierarchyObj).subscribe(res =>  this.planId= res['PLAN'].id)

      await this.breadcrumbService
        .getHierarchy(hierarchyObj)
        .toPromise()
        .then((res) => (this.planId = res["PLAN"].id));
    }
    this.intializeService(type).subscribe(
      (res: IChartResponse) => {
        if (res) {
          this.isLoading = false;
          if (res.refCode) {
            if (!this.chartData["planItView"]) {
              this.chartData["planItView"] = [];
            }
            this.chartData["planItView"].push(
              res["planItView"].find(
                (chartData: IChartData) => chartData.refernceCode == res.refCode
              )
            );
            this.chartData["planItView"].push(
              res["planItView"].find(
                (chartData: IChartData) =>
                  chartData.refernceCode ==
                  (res.refCode === TRENDS_CHART_LEGENDS.ASPN
                    ? TRENDS_CHART_LEGENDS.AROI
                    : TRENDS_CHART_LEGENDS.PDRO)
              )
            );
            if (res.refCode === TRENDS_CHART_LEGENDS.ASPN) {
              this.chartData["prevYearTrendData"] = res["prevYearTrendData"]
                ? res["prevYearTrendData"]
                : [];
            }
          } else {
            this.chartData = res;
          }

          this.chartData.year = this.workingYear;
          this.prevYear = this.workingYear - 1;
          this.prevYearMinified = this.prevYear.toString().slice(2);
          this.genMediaRoiChart(CHART_PERIODS.monthly);
        }
      },
      (error) => {
        this.isLoading = false;
        console.error("Error: ", error);
      }
    );
    this.widgetDataServiceCall(type).subscribe(
      (widgetData: any) => {
        this.refreshWidgetChartData(widgetData);
      },
      (error) => {
        console.error("Error: ", error);
      }
    );
  }

  widgetDataServiceCall(type: string) {
    switch (type) {
      case "market":
        const mktChartObj = {
          marketId: this.paramsId || this.userInfo.defaultMarket,
          year: this.workingYear,
        };
        return this.marketService.getWidgetChartData(mktChartObj);

      case "portfolio":
        const pfChartObj = {
          portfolioId: this.paramsId,
          year: this.workingYear,
        };
        return this.portfolioService.getWidgetChartData(pfChartObj);
      case "plan":
      case "program":
        const planChartObj = {
          planId: this.paramsId,
          year: this.workingYear,
        };
        return this.planService.getWidgetChartData(planChartObj);
      default:
        return null;
    }
  }
  getCurrencySymbol() {
    const currenSymbol = currencyToSymbolMap[this.currencyCode];
    return currenSymbol;
  }

  intializeService(type: string) {
    switch (type) {
      case "market":
        const mktChartObj = {
          marketId: this.paramsId || this.userInfo.defaultMarket,
          year: this.workingYear,
          refrenceCode: TRENDS_CHART_LEGENDS.ASPN,
        };
        return this.aggrView == AGGR_VIEW.PLANIT
          ? this.marketService.getFlowPackView(mktChartObj)
          : this.marketService.getChartData(mktChartObj);
      case "marketPSPN":
        const mktChartObjPSPN = {
          marketId: this.paramsId || this.userInfo.defaultMarket,
          year: this.workingYear,
          refrenceCode: TRENDS_CHART_LEGENDS.PSPN,
        };
        return this.aggrView == AGGR_VIEW.PLANIT
          ? this.marketService.getFlowPackView(mktChartObjPSPN)
          : this.marketService.getChartData(mktChartObj);

      case "portfolio":
        const pfChartObj = {
          portfolioId: this.paramsId,
          year: this.workingYear,
          refrenceCode: TRENDS_CHART_LEGENDS.ASPN,
        };
        return this.aggrView == AGGR_VIEW.PLANIT
          ? this.portfolioService.getFlowPackView(pfChartObj)
          : this.portfolioService.getChartData(pfChartObj);
      case "portfolioPSPN":
        const pfChartObjPSPN = {
          portfolioId: this.paramsId,
          year: this.workingYear,
          refrenceCode: TRENDS_CHART_LEGENDS.PSPN,
        };
        return this.aggrView == AGGR_VIEW.PLANIT
          ? this.portfolioService.getFlowPackView(pfChartObjPSPN)
          : this.portfolioService.getChartData(pfChartObj);
      case "portfolioGroup":
        const pfGChartObj = {
          portfolioGroupId: this.paramsId,
          year: this.workingYear,
        };
        return this.portfolioGroupService.getChartData(pfGChartObj);

      case "plan":
      case "program":
        const planChartObj = {
          planId: this.paramsId,
          year: this.workingYear,
          refrenceCode: TRENDS_CHART_LEGENDS.ASPN,
        };
        return this.aggrView == AGGR_VIEW.PLANIT
          ? this.planService.getFlowPackView(planChartObj)
          : this.planService.getChartData(planChartObj);
      case "planPSPN":
      case "programPSPN":
        const planChartObjPSPN = {
          planId: this.paramsId,
          year: this.workingYear,
          refrenceCode: TRENDS_CHART_LEGENDS.PSPN,
        };
        return this.aggrView == AGGR_VIEW.PLANIT
          ? this.planService.getFlowPackView(planChartObjPSPN)
          : this.planService.getChartData(planChartObj);

      // case 'program':
      //   return this.planService.getChartData({
      //     planId: this.planId,
      //     year: this.workingYear
      //   })

      default:
        break;
    }
  }
  //generate this.chart
  genMediaRoiChart(chartType: string) {
    this.resetTrendsVisibilityObj();
    let chartDataMonthly = [];
    let chartDataQuaterly = [];
    const barColors = ["#4188ff", "#0C2D83", "#A6CFFF"];
    let trendData =
      this.aggrView == AGGR_VIEW.PLANIT
        ? this.chartData.planItView
        : this.chartData.trendData;
    const prevYearTrendData = this.chartData.prevYearTrendData || [];
    trendData = trendData || [];
    CHART_MONTHS.forEach((mnth) => {
      let temp = { ...this.trendsChartObj };
      temp.period = `${mnth.name}`;
      const currMnthPSPN = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.PSPN
      );
      const currMnthASPN = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.ASPN
      );
      const currMnthPDRO = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.PDRO
      );
      const currMnthAROI = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.AROI
      );
      const currMnthFyASPN = prevYearTrendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.ASPN
      );
      const currMnthFyAROI = prevYearTrendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.AROI
      );
      temp.pspn_bar =
        currMnthPSPN && currMnthPSPN[mnth.accesor]
          ? currMnthPSPN[mnth.accesor]
          : null;
      temp.pspn_bar =
        currMnthPSPN && currMnthPSPN[mnth.accesor]
          ? currMnthPSPN[mnth.accesor]
          : null;
      temp.aspn_bar =
        currMnthASPN && currMnthASPN[mnth.accesor]
          ? currMnthASPN[mnth.accesor]
          : null;
      temp.pdro_line =
        currMnthPDRO && currMnthPDRO[mnth.accesor]
          ? currMnthPDRO[mnth.accesor]
          : null;
      temp.aroi_line =
        currMnthAROI && currMnthAROI[mnth.accesor]
          ? currMnthAROI[mnth.accesor]
          : null;
      temp.prevy_bar =
        currMnthFyASPN && currMnthFyASPN[mnth.accesor]
          ? currMnthFyASPN[mnth.accesor]
          : null;
      temp.prevy_line =
        currMnthFyAROI && currMnthFyAROI[mnth.accesor]
          ? currMnthFyAROI[mnth.accesor]
          : null;
      chartDataMonthly.push(temp);
    });

    // use forEach
    CHART_QUARTER.forEach((qtr) => {
      let temp = { ...this.trendsChartObj };
      temp.period = `${qtr.name}`;
      const currQtrPSPN = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.PSPN
      );
      const currQtrASPN = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.ASPN
      );
      const currQtrPDRO = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.PDRO
      );
      const currQtrAROI = trendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.AROI
      );
      const currQtrFyASPN = prevYearTrendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.ASPN
      );
      const currQtrFyAROI = prevYearTrendData.find(
        (chartData: IChartData) =>
          chartData.refernceCode == TRENDS_CHART_LEGENDS.AROI
      );
      temp.pspn_bar =
        currQtrPSPN && currQtrPSPN[qtr.accesor]
          ? currQtrPSPN[qtr.accesor]
          : null;
      temp.aspn_bar =
        currQtrASPN && currQtrASPN[qtr.accesor]
          ? currQtrASPN[qtr.accesor]
          : null;
      temp.pdro_line =
        currQtrPDRO && currQtrPDRO[qtr.accesor]
          ? currQtrPDRO[qtr.accesor]
          : null;
      temp.aroi_line =
        currQtrAROI && currQtrAROI[qtr.accesor]
          ? currQtrAROI[qtr.accesor]
          : null;
      temp.prevy_bar =
        currQtrFyASPN && currQtrFyASPN[qtr.accesor]
          ? currQtrFyASPN[qtr.accesor]
          : null;
      temp.prevy_line =
        currQtrFyAROI && currQtrFyAROI[qtr.accesor]
          ? currQtrFyAROI[qtr.accesor]
          : null;
      chartDataQuaterly.push(temp);
    });

    this.trendChart = am4core.create("trendChart", am4charts.XYChart);
    this.trendChart.background.fill = am4core.color("#FFFFFF");
    this.trendChart.padding(10, 0, 10, 0);
    /* Create axes */
    let categoryAxis = this.trendChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "period";
    categoryAxis.renderer.minGridDistance = 30;

    //remove x axis lines in midddle of graph
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color("#3D5D9B");
    categoryAxis.background.fill = am4core.color("#E7EAF3");
    /* Create value axis */
    let columnAxis = this.trendChart.yAxes.push(new am4charts.ValueAxis());
    columnAxis.renderer.labels.template.fontSize = 10;
    columnAxis.renderer.width = 55;
    columnAxis.renderer.height = 400;
    columnAxis.renderer.labels.template.fill = am4core.color("#3D5D9B");
    columnAxis.numberFormatter = new am4core.NumberFormatter();
    columnAxis.numberFormatter.numberFormat = `${this.getCurrencySymbol()}#a`;
    columnAxis.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "M" },
    ];
    columnAxis.background.fill = am4core.color("#ffc5824d");

    // give proper name: ex: budget or rois instead of Value Axis 2
    let lineAxis = this.trendChart.yAxes.push(new am4charts.ValueAxis());
    lineAxis.renderer.opposite = true;
    lineAxis.syncWithAxis = columnAxis;
    lineAxis.renderer.labels.template.fontSize = 10;
    lineAxis.tooltip.disabled = true;
    lineAxis.renderer.width = 50;
    lineAxis.renderer.labels.template.fill = am4core.color("#3D5D9B");
    lineAxis.numberFormatter = new am4core.NumberFormatter();
    lineAxis.numberFormatter.numberFormat = "#.00";
    lineAxis.background.fill = am4core.color("#ffc5824d");

    let createLine = (name, field, color) => {
      let lineSeries = this.trendChart.series.push(new am4charts.LineSeries());
      lineSeries.name = name;
      lineSeries.stroke = am4core.color(color);
      lineSeries.dataFields.valueY = field;
      lineSeries.dataFields.categoryX = "period";
      lineSeries.yAxis = lineAxis;
      lineSeries.strokeWidth = 6;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltipText = `{valueY.value}`;
      lineSeries.propertyFields.fillOpacity = "fillOpacity";
      lineSeries.propertyFields.stroke = "stroke";
      lineSeries.propertyFields.strokeWidth = "12";
      //lineSeries.columns.template.strokeWidth = 0;
      lineSeries.tooltip.label.textAlign = "middle";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.tooltipText = `${
        name === TREND_CHART_LEGENDS.ACTUAL_ROI
          ? "DIRECTIONAL ROI"
          : "ORIG PLAN ROI"
      } : [bold]{valueY.formatNumber('#.00')}[/]`;
      bullet.fill = am4core.color(color);
      let square = bullet.createChild(am4core.Circle);
      square.width = 10;
      square.height = 10;
      square.stroke = am4core.color(color);
      square.horizontalCenter = "middle";
      square.verticalCenter = "middle";
      //lineSeries.propertyFields.stroke = "color";
    };
    // Create series
    let createSeries = (name, field, color) => {
      /* Create series */
      let columnSeries = this.trendChart.series.push(
        new am4charts.ColumnSeries()
      );
      columnSeries.name = name;
      columnSeries.fill = color;
      columnSeries.dataFields.valueY = field;
      columnSeries.dataFields.categoryX = "period";
      //remove spaces between bars
      columnSeries.columns.template.width = am4core.percent(100);
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;
      const toolTip = `[#fff font-size: 10px]{name} in {categoryX.formatNumber('#.00')}:\n[/][#fff font-size: 20px]{valueY.formatNumber( '${this.getCurrencySymbol()}#.0a' )}[/] [#fff]{additional}[/]`;
      columnSeries.columns.template.tooltipText = toolTip;
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "12";
      columnSeries.columns.template.strokeWidth = 0;
      columnSeries.tooltip.label.textAlign = "middle";
    };

    let fy_actul = TREND_CHART_LEGENDS.FY_ACTUAL.replace(
      "#MinifiedPrevYear#",
      this.prevYearMinified
    );

    createSeries(fy_actul, CHART_SERIES.prevy_bar, barColors[2]);
    createSeries(
      TREND_CHART_LEGENDS.PLANNED_BUDGET,
      CHART_SERIES.pspn_bar,
      barColors[0]
    );
    createSeries(
      TREND_CHART_LEGENDS.ACTUAL_BUDGET,
      CHART_SERIES.aspn_bar,
      barColors[1]
    );

    createLine(
      TREND_CHART_LEGENDS.PDR_OBJECTIVE,
      CHART_SERIES.pdro_line,
      lineColors[0]
    );
    createLine(
      TREND_CHART_LEGENDS.ACTUAL_ROI,
      CHART_SERIES.aroi_line,
      lineColors[1]
    );
    createLine(fy_actul, CHART_SERIES.prevy_line, lineColors[2]);

    if (chartType == CHART_PERIODS.monthly) {
      this.trendChart.data = chartDataMonthly;
      this.activeTrendsPeriod = CHART_PERIODS.monthly;
    } else {
      this.trendChart.data = chartDataQuaterly;
      this.activeTrendsPeriod = CHART_PERIODS.quaterly;
    }
  }
  // Methis to generate chart data
  // 1. Get Montly Cadence Data
  // 2. Get Quarter cadence data
  getWidgetChart(chartType: any) {
    this.resetWidgetVisibilityObj();
    let chartDataMonthly = [];
    let chartDataQuaterly = [];
    const widgetData = this.chartData.widgetData;
    const prevYearWidgetData = this.chartData.prevYearWidgetData;
    const widgetConfig =
      this.chartData && this.chartData.widgetValueConfig
        ? this.chartData.widgetValueConfig
        : [];
    // 1. Get Montly Cadence Data
    CHART_MONTHS.forEach((mnth) => {
      const temp = { ...this.widgetChartObj };
      temp.period = `${mnth.name}`;
      const prevYearWdgtData = prevYearWidgetData[this.activeWidget]
        ? prevYearWidgetData[this.activeWidget]
        : [];
      const currMnthPWGT = widgetData[this.activeWidget]
        ? widgetData[this.activeWidget].find(
            (chartData: IChartData) =>
              chartData.refernceCode == WIDGET_CHART_LEGENDS.PWGT
          )
        : null;
      const currMnthAWGT = widgetData[this.activeWidget]
        ? widgetData[this.activeWidget].find(
            (chartData: IChartData) =>
              chartData.refernceCode == WIDGET_CHART_LEGENDS.AWGT
          )
        : null;
      temp.pdro_line =
        currMnthPWGT && Number(currMnthPWGT[mnth.accesor])
          ? Number(
              currMnthPWGT[mnth.accesor].toFixed(5).slice(0, -1) /
                (this.returnpercentage(this.activeWidget) === "" ? 1 : 100)
            ).toString()
          : null;
      temp.aroi_line =
        currMnthAWGT && currMnthAWGT[mnth.accesor]
          ? Number(currMnthAWGT[mnth.accesor]).toFixed(4).toString()
          : null;
      console.log(
        "currMnthAWGT[mnth.accesor]",
        Number(currMnthAWGT[mnth.accesor]).toFixed(4).toString()
      );
      // ? Number(currMnthAWGT[mnth.accesor])
      //     .toFixed(5)
      //     .slice(0, -1)
      //     .toString()
      // : null;

      temp.prevy_line =
        prevYearWdgtData &&
        prevYearWdgtData[0] &&
        prevYearWdgtData[0][mnth.accesor]
          ? Number(prevYearWdgtData[0][mnth.accesor])
              .toFixed(5)
              .slice(0, -1)
              .toString()
          : null;
      chartDataMonthly.push(temp);
    });
    // 2. Get Quarter cadence data
    CHART_QUARTER.forEach((qtr) => {
      const temp = { ...this.widgetChartObj };
      temp.period = `${qtr.name}`;
      const prevYearWdgtData = prevYearWidgetData[this.activeWidget]
        ? prevYearWidgetData[this.activeWidget]
        : [];
      const currQtrPWGT = widgetData[this.activeWidget]
        ? widgetData[this.activeWidget].find(
            (chartData: IChartData) =>
              chartData.refernceCode == WIDGET_CHART_LEGENDS.PWGT
          )
        : null;
      const currQtrAWGT = widgetData[this.activeWidget]
        ? widgetData[this.activeWidget].find(
            (chartData: IChartData) =>
              chartData.refernceCode == WIDGET_CHART_LEGENDS.AWGT
          )
        : null;
      temp.pdro_line =
        currQtrPWGT && Number(currQtrPWGT[qtr.accesor])
          ? Number(
              currQtrPWGT[qtr.accesor].toFixed(5).slice(0, -1) /
                (this.returnpercentage(this.activeWidget) === "" ? 1 : 100)
            ).toString()
          : null;
      temp.aroi_line =
        currQtrAWGT && currQtrAWGT[qtr.accesor]
          ? Number(currQtrAWGT[qtr.accesor]).toFixed(4).toString()
          : null;
      //Number(currQtrAWGT[qtr.accesor]).toFixed(5).slice(0, -1).toString()
      temp.prevy_line =
        prevYearWdgtData &&
        prevYearWdgtData[0] &&
        prevYearWdgtData[0][qtr.accesor]
          ? Number(prevYearWdgtData[0][qtr.accesor])
              .toFixed(5)
              .slice(0, -1)
              .toString()
          : null;
      chartDataQuaterly.push(temp);
    });

    // Create Widget Chart data and appending to DOM
    this.widgetChart = am4core.create("widgetChart", am4charts.XYChart);
    this.widgetChart.padding(10, 0, 10, 0);
    /* Create axes */
    let categoryAxis = this.widgetChart.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "period";
    categoryAxis.renderer.minGridDistance = 30;
    //remove x axis lines in midddle of graph
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fontSize = 10;

    /* Create value axis */
    let valueAxis = this.widgetChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.width = 50;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = this.returnNumberFormat(
      widgetConfig,
      this.activeWidget
    )
      ? "#.00"
      : "#.00%";

    let createLine = (field, color) => {
      let lineSeries = this.widgetChart.series.push(new am4charts.LineSeries());
      lineSeries.stroke = am4core.color(color);
      lineSeries.dataFields.valueY = field;
      lineSeries.dataFields.categoryX = "period";
      lineSeries.yAxis = valueAxis;

      //lineSeries.stroke = am4core.color("#fdd400");
      lineSeries.strokeWidth = 2;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.propertyFields.stroke = "color";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.tooltipText = `[bold]{valueY.formatNumber('#.00${this.returnpercentage(
        this.activeWidget
      )}')}[/]`;
      bullet.fill = am4core.color(color);
      let square = bullet.createChild(am4core.Circle);
      square.width = 5;
      square.height = 5;
      square.stroke = am4core.color(color);
      square.horizontalCenter = "middle";
      square.verticalCenter = "middle";
    };
    createLine(CHART_SERIES.pdro_line, lineColors[0]);
    createLine(CHART_SERIES.aroi_line, lineColors[1]);
    createLine(CHART_SERIES.prevy_line, lineColors[2]);
    if (chartType == CHART_PERIODS.monthly) {
      this.widgetChart.data = chartDataMonthly;
      this.activeWidgetsPeriod = CHART_PERIODS.monthly;
    } else {
      this.widgetChart.data = chartDataQuaterly;
      this.activeWidgetsPeriod = CHART_PERIODS.quaterly;
    }
  }
  returnNumberFormat(widgetConfig, widgetName) {
    let _result = false;
    let filterData = widgetConfig.find((el) => {
      return el.widgetDisplayName === widgetName;
    });
    if (filterData && filterData.widgetValueType) {
      _result = filterData.widgetValueType === "Number" ? true : false;
    }
    return _result;
  }
  returnpercentage(widgetName) {
    const widgetConfig =
      this.chartData && this.chartData.widgetValueConfig
        ? this.chartData.widgetValueConfig
        : [];
    let _result = false;
    let filterData = widgetConfig.find((el) => {
      return el.widgetDisplayName === widgetName;
    });
    if (filterData && filterData.widgetValueType) {
      _result = filterData.widgetValueType === "Number" ? true : false;
    }
    return _result ? "" : "%";
  }

  genwidgetTable(period: any) {
    this.getWidgetChart(period);
    let widgetTableObj = {
      widgetName: "",
      planned: "",
      actual: "",
      fy: "",
      footNote: "",
      id: "",
    };
    let widgetMonthlyTable = [];
    let widgetQuaterlyTable = [];
    const data = this.chartData;
    const date = new Date();
    let currentMonth = date.getMonth() + 1;
    if (currentMonth == 12) {
      currentMonth = 11;
    }
    // if (this.widgets.length == 0) {
    //   this.dataSource = new MatTableDataSource<any>([]);
    //   return;
    // }

    const widgetData = this.chartData.widgetData;
    const prevYearWidgetData = this.chartData.prevYearWidgetData;
    const widgetConfig =
      this.chartData && this.chartData.widgetValueConfig
        ? this.chartData.widgetValueConfig
        : [];
    const priviousAndCurrentDifference = _.difference(
      _.keys(prevYearWidgetData),
      _.keys(widgetData)
    );
    priviousAndCurrentDifference.forEach((wdgt) => {
      // const currMnthPWGT = widgetData[wdgt] && widgetData[wdgt].find((chartData: IChartData) => chartData.refernceCode == WIDGET_CHART_LEGENDS.PWGT);
      const currMnthPWGT =
        prevYearWidgetData[wdgt] &&
        prevYearWidgetData[wdgt].find(
          (chartData: IChartData) =>
            chartData.refernceCode == WIDGET_CHART_LEGENDS.PWGT
        );
      let widgetName = prevYearWidgetData[wdgt][0].widgetName;
      const month = CHART_MONTHS[currentMonth].accesor;
      let temp = { ...widgetTableObj };
      temp.widgetName = widgetName;
      temp.actual = "0";
      temp.planned = currMnthPWGT
        ? this.getWidgetsFormattedValue(currMnthPWGT[month], 1)
        : "0";
      temp.fy =
        prevYearWidgetData[widgetName] &&
        prevYearWidgetData[widgetName][0] &&
        prevYearWidgetData[widgetName][0].annual
          ? this.getWidgetsFormattedValue(
              prevYearWidgetData[widgetName][0].annual,
              this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
            )
          : "0";
      // temp.footNote = currMnthPWGT ? currMnthPWGT['widgetFootNote'] : '';
      // temp.id = currMnthAWGT ? currMnthAWGT.widgetId : 0;
      widgetMonthlyTable.push(temp);
      widgetQuaterlyTable.push(temp);
    });

    this.widgets.forEach((wdgt) => {
      let temp = { ...widgetTableObj };
      temp.widgetName = wdgt;
      const prevYearWdgtData = prevYearWidgetData[wdgt] || [];
      const currMnthPWGT =
        widgetData[wdgt] &&
        widgetData[wdgt].find(
          (chartData: IChartData) =>
            chartData.refernceCode == WIDGET_CHART_LEGENDS.PWGT
        );
      const currMnthAWGT =
        widgetData[wdgt] &&
        widgetData[wdgt].find(
          (chartData: IChartData) =>
            chartData.refernceCode == WIDGET_CHART_LEGENDS.AWGT
        );
      const month = CHART_MONTHS[currentMonth].accesor;
      temp.planned = currMnthPWGT
        ? this.getWidgetsFormattedValue(currMnthPWGT[month], 1)
        : "0";
      temp.actual = "0";
      let today = moment();
      const currentMonthVal = CHART_MONTHS[today.month()].accesor;
      if (this.workingYear < today.year()) {
        temp.actual = currMnthAWGT["annual"]
          ? this.getWidgetsFormattedValue(
              currMnthAWGT["annual"],
              this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
            )
          : "0";
      } else {
        if (currMnthAWGT[currentMonthVal] != null) {
          temp.actual = currMnthAWGT
            ? this.getWidgetsFormattedValue(
                currMnthAWGT[currentMonthVal],
                this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
              )
            : "0";
        } else {
          for (let i = 0; i < today.month(); i++) {
            const month = CHART_MONTHS[i].accesor;
            if (currMnthAWGT[month] != null) {
              temp.actual = currMnthAWGT
                ? this.getWidgetsFormattedValue(
                    currMnthAWGT[month],
                    this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
                  )
                : "0";
            }
          }
        }
      }
      /*while (temp.actual == '0') {
        const month = CHART_MONTHS[today.month()].accesor;
        temp.actual = currMnthAWGT ? this.getWidgetsFormattedValue(currMnthAWGT[month]!=null) : '0';
        today.subtract(1, 'month');
        //Breaks infinity loop if all values are '0'
        if (moment().month() == today.month()) {
          break;
        }
      }*/
      temp.fy =
        prevYearWdgtData[0] && prevYearWdgtData[0].annual
          ? this.getWidgetsFormattedValue(
              prevYearWdgtData[0].annual,
              this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
            )
          : "0";
      temp.footNote = currMnthPWGT ? currMnthPWGT["widgetFootNote"] : "";
      temp.id = currMnthAWGT ? currMnthAWGT.widgetId : 0;
      widgetMonthlyTable.push(temp);
    });

    this.widgets.forEach((wdgt) => {
      let temp = { ...widgetTableObj };
      temp.widgetName = wdgt;
      const prevYearWdgtData = prevYearWidgetData[wdgt] || [];
      const currQtrPWGT =
        data.widgetData[wdgt] &&
        data.widgetData[wdgt].find(
          (chartData: IChartData) =>
            chartData.refernceCode == WIDGET_CHART_LEGENDS.PWGT
        );
      const currQtrAWGT =
        data.widgetData[wdgt] &&
        data.widgetData[wdgt].find(
          (chartData: IChartData) =>
            chartData.refernceCode == WIDGET_CHART_LEGENDS.AWGT
        );
      const quater = Math.ceil(currentMonth / 3);

      temp.planned = currQtrPWGT
        ? this.getWidgetsFormattedValue(
            currQtrPWGT[CHART_QUARTER[quater - 1].accesor],
            1
          )
        : "0";
      temp.fy =
        prevYearWdgtData[0] && prevYearWdgtData[0].annual
          ? this.getWidgetsFormattedValue(
              prevYearWdgtData[0].annual,
              this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
            )
          : "0";
      temp.footNote = currQtrPWGT ? currQtrPWGT["widgetFootNote"] : "";
      temp.actual = "0";
      let today = moment();
      let currQuarter = moment().quarter() > 1 ? moment().quarter() - 1 : 1;
      if (this.workingYear < today.year()) {
        temp.actual = currQtrAWGT["annual"]
          ? this.getWidgetsFormattedValue(
              currQtrAWGT["annual"],
              this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
            )
          : "0";
      } else {
        while (temp.actual == "0") {
          temp.actual = currQtrAWGT
            ? this.getWidgetsFormattedValue(
                currQtrAWGT[CHART_QUARTER[currQuarter].accesor],
                this.returnNumberFormat(widgetConfig, wdgt) ? 1 : 100
              )
            : "0"; //temp.actual = currQtrAWGT ? this.getWidgetsFormattedValue(currQtrAWGT[month]) : '0';
          //Break loop if current quarter is 0
          if (currQuarter == 0) {
            break;
          }
          currQuarter--;
        }
      }
      temp.id = currQtrAWGT ? currQtrAWGT.widgetId : 0;
      widgetQuaterlyTable.push(temp);
    });
    //selected widget table row
    this.activeWidgetRow = widgetMonthlyTable.find(
      (el) => el.widgetName == this.activeWidget
    );
    if (period == CHART_PERIODS.monthly) {
      this.dataSource = new MatTableDataSource<any>(widgetMonthlyTable);
    } else {
      this.dataSource = new MatTableDataSource<any>(widgetQuaterlyTable);
    }
  }

  widgetTableClick(row: any) {
    this.activeWidget = row.widgetName;
    //this.activeWidgetId = row.id;
    this.getWidgetChart(CHART_PERIODS.monthly);
    this.genwidgetTable(CHART_PERIODS.monthly);
  }
  // trend contains multiple series of chart, the visibility of each chart is handling.....
  toggleTrendsSeries(idx: any, key: any) {
    this.trendsVisibilityObj[key] = !this.trendsVisibilityObj[key];
    let series = this.trendChart.series.getIndex(idx);
    if (series.isHiding || series.isHidden) {
      series.show();
    } else {
      series.hide();
    }
  }

  // WIdget contains multiple series of chart, the visibility of each chart is handling.....
  toggleWidgetSeries(idx: any, key: any) {
    this.widgetVisibilityObj[key] = !this.widgetVisibilityObj[key];
    let series = this.widgetChart.series.getIndex(idx);
    if (series.isHiding || series.isHidden) {
      series.show();
    } else {
      series.hide();
    }
  }

  resetWidgetVisibilityObj() {
    this.widgetVisibilityObj = {
      aroi_line: true,
      pdro_line: true,
      prevy_line: true,
    };
  }
  resetTrendsVisibilityObj() {
    this.trendsVisibilityObj = {
      pspn_bar: true,
      aspn_bar: true,
      prevy_bar: true,
      aroi_line: true,
      pdro_line: true,
      prevy_line: true,
    };
  }

  getWidgetsFormattedValue(widgetDataVal, defaultmultipler = 100) {
    let formattedVal;

    widgetDataVal = (widgetDataVal * defaultmultipler).toFixed(2);
    formattedVal = widgetDataVal;

    if (!widgetDataVal) {
      return 0;
    } else {
      return formattedVal;
    }
  }
  editPlansWidgetTargets() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.KEY_MEDIA_OUTCOME_PLANS };
    this.planWidgetData = {
      planId: Number(this.paramsId),
      widgetId: this.activeWidgetId,
      year: this.workingYear,
      widgetName: this.widgets,
    };
    sidePanelEle.planWidgetData = this.planWidgetData;
    this.sharedDataService.setSideNavSelection(sidePanelEle);
    this.cancelBtnSubscription =
      this.globalElementservice.getSidePanelCancelEvent
        .pipe(take(1))
        .subscribe(() => {
          this.widgetDataServiceCall(this.type).subscribe(
            (widgetData: any) => {
              this.refreshWidgetChartData(widgetData);
            },
            (error) => {
              console.error("Error: ", error);
            }
          );
          this.cancelBtnSubscription.unsubscribe();
        });
  }
  refreshWidgetChartData(widgetData) {
    if (widgetData) {
      for (let _key in widgetData) {
        this.chartData[_key] = widgetData[_key];
      }
      this.widgets = [];
      for (let widget in this.chartData.widgetData) {
        this.widgets.push(widget);
      }
      this.activeWidget = this.widgets[0] || null;
      //this.activeWidgetId = this.activeWidget &&  this.chartData.widgetData[this.activeWidget][0] ? this.chartData.widgetData[this.activeWidget][0].widgetId : 0;
      //this.activeWidgetId = Object.values(this.chartData.widgetData) && Object.values(this.chartData.widgetData.filter()).map(el => el['widgetId'] )
      this.getWidgetChart(CHART_PERIODS.monthly);
      this.genwidgetTable(CHART_PERIODS.monthly);
    }
  }
  ngOnDestroy() {
    this.aggrViewSubscription.unsubscribe();
    this.workingYearSubscription.unsubscribe();
  }
}
