import { CoreService } from 'app/core/core.service';
import { SharedDataService } from './../../common/services/shared-data.service';
import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit{
loader!: { isLoading: boolean };
  sideNavElement;
  isFormTouched: any;

  constructor(
    private sharedData: SharedDataService,
    private coreService: CoreService
  ) { }




  ngOnInit(){
    this.loader = this.coreService.loader;
    this.sharedData.sideNavSelection.subscribe(res => this.sideNavElement =res );
  }

  isFormDirty(isDirty:boolean) {
    this.isFormTouched= isDirty;
  }
}
