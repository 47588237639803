import { SharedDataService } from './../services/shared-data.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '../../../../node_modules/@angular/common';

@Pipe({
    name: 'canvasCurrency'
})
export class CanvasCurrencyPipe extends CurrencyPipe implements PipeTransform {

    xChangeRate: number;
    currencyCode: string;
    CurrencySymbol: string;
    constructor(
        private sharedDataService: SharedDataService
    ) {
        super('en-US');
        this.sharedDataService.getCurrencyCode.subscribe(res => this.currencyCode = res);
        this.sharedDataService.getExchangeRate.subscribe(res => this.xChangeRate = res);
        this.sharedDataService.getCurrencySymbol.subscribe(res => this.CurrencySymbol = res);
    }

    transform(value: any, isROI?): any {
        if (!value) {
            value = 0;
        }
        let updatedValue = value;
        let format = '1.2-2';
        if (this.currencyCode !== 'USD') {
            updatedValue = value * this.xChangeRate;
        }

        if (!isROI) {
            format = '1.0-0';
        }
        return super.transform(updatedValue, this.CurrencySymbol, 'symbol', format);

    }

}
