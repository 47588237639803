import { GLOBAL_ELEMENTS } from './../../constants/app-constants';
import { SharedDataService } from './../../common/services/shared-data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { userInfo } from 'os';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit, OnDestroy {

  userInfo;

  constructor(
    private sharedDataService: SharedDataService,
    protected googleTagManagerService: GoogleTagManagerService
  ) {  this.userInfo = JSON.parse(sessionStorage.getItem("user")); }

  ngOnInit() {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Help_Page`,
      eventAction: "PageView",
      eventLable: `Global Elements Strip Help Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  navigateToDocCenter() {
    const globalDocs = { ...GLOBAL_ELEMENTS.DOCUMENTS };
    this.sharedDataService.setSideNavSelection(globalDocs);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Help_Page`,
      eventAction: "Document Center Clicked",
      eventLable: `Global Elements MSP Doc Center Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  sendEmail(){
    window.location.href = "mailto:'GlobalMSP@pepsico.com'"
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Help_Page`,
      eventAction: "Help Email Click",
      eventLable: `Global Elements Help Email Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
      
        ngOnDestroy(): void {
          let tagObject =  {
            event: `custom_${environment.environmentName}_Event`,
            eventCategory: `Help_Page`,
            eventAction: "PageExit",
            eventLable: `Global Elements Help Page Exit`,
            customeEvent: `custom_${environment.environmentName}_Event`,
            userId: getGpId(),
            user_id: getGpId()
          }
          googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
}
