import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-tooltip-configuration',
  templateUrl: './tooltip-configuration.component.html',
  styleUrls: ['./tooltip-configuration.component.scss']
})
export class TooltipConfigurationComponent {
  @Output('dirty')
  formDirty = new EventEmitter<boolean>();

  isCreateMode = false;
  isEditMode = false;
  isHomeMode = true;
  editData: any;
  isCategoryList: boolean;
  isTooltipConfiguration: boolean;


  CATEGORY_ID = {
    CATEGORY_LIST: 1,
    TOOLTIP_CONFIGURATION: 2
  }

  categoryList = [
  //   {
  //   id: this.CATEGORY_ID.CATEGORY_LIST,
  //   name: 'Tooltip Category List',
  //   isOpened: true,
  //   class: 'opened'
  // },
  {
    id: this.CATEGORY_ID.TOOLTIP_CONFIGURATION,
    name: 'Tooltip Management',
    isOpened: true,
    class: 'opened'
  }];

  selectedCategory = this.categoryList.find(el => el.id == this.CATEGORY_ID.CATEGORY_LIST);


  changeCategory(catId) {
    this.selectedCategory = this.categoryList.find(el => el.id == catId);
  }

  isFormDirty(isDirty:boolean) {

    this.formDirty.emit(isDirty);

  }
}
