import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { ScenarioPlanningDialogComponent } from '../../../common/components/dialogs/scenario-planning-dialog/scenario-planning-dialog.component';
import { ANALYTICS_TYPE, MEDIA_CHANNEL_REFERENCES, STATUS_LIST, STATUS_LIST_TYPES,FINANCIAL_VALUES } from './../../../constants/app-constants';
import { PlanService } from './../../services/plan.service';
import { StoreService } from './../../../old/_services/_store.service';
import { IChannelList, IScenarioAnalytics, ILinearFactor } from 'app/common/interfaces/plan';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OptimizerMessageDialogComponent } from 'app/common/components/dialogs/optimizer-message-dialog/optimizer-message-dialog.component';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ProgramService } from 'app/common/api/service/program.service';
@Component({
  selector: 'app-create-scenario',
  templateUrl: './create-scenario.component.html',
  styleUrls: ['./create-scenario.component.scss']
})
export class CreateScenarioComponent implements OnInit {

  @Input() elementDetails;

  channelForm: FormGroup;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');
  selectedMarket = sessionStorage.getItem('market');
  scenarioName: string;
  isFormInitialized: boolean = false;
  scenarioId: number = null;
  scenarioAnalytics: IScenarioAnalytics;
  channelsList: Array<IChannelList> = [];
  isEditMode: boolean = false;
  planEntityId: null;
  planningYear: number;
  curveList;
  selectedCurveVersion: string = '';
  selectedCurveOld: string = '';
  isCurveListEmpty: boolean = true;
  isAddDisabled: boolean = true;
  selectedChannelDetails;
  selectedMediaChannel = [];
  enableOptimize: boolean = false;
  scenarioStatusList: Array<string> = STATUS_LIST;
  scenarioStatus: string = STATUS_LIST_TYPES.DRAFT;
  netWorkFactorIsVisible: boolean = false;
  netWorkFactorIsEditable: boolean = false;
  networkReference: number = 0;
  primeReference: number = 0;
  secReference: number = 0;
  networkFactor: number = 0;
  primeFactor: number = 0;
  secFactor: number = 0;
  cpp:any;
  inflationCPP:any = 0;
  percentage:number = 0;
  channelId:number;
  isCPPNotExists:boolean = false;
  inflationPercentage:number = 0;
  channelBudget = [];
  budgetError: boolean = false;
  tooltipTitleScenarioPlanner:string;
  showtooltipTitleScenarioPlanner:boolean = false;
  WOA = [];
  financialValue:string = '';
  roiText: any;
  scenarioforminvalid:boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private planService: PlanService,
    private programService: ProgramService,
    private sharedDataService: SharedDataService,
    private globalElementsService: GlobalElementsService,
    private store: StoreService

  ) {

  }


  /*........... Initialization Methods........ */

  ngOnInit() {
    this.sharedDataService.getPlanningYear.subscribe((res: any) => {
      if (res != this.planningYear) {
        this.planningYear = res;
      }
    });
    this.globalElementsService.getTooltipData('ScenarioPlanner', this.selectedMarket).subscribe((res_n: any) => {
      if(res_n.data && res_n.data[0]) {
        this.tooltipTitleScenarioPlanner = res_n.data[0]["tooltipText"];
      }
    });


    if (!this.isFormInitialized) {
      this.channelForm = this.fb.group({
        channels: this.fb.array([]),
      });
      this.isFormInitialized = true;
    }


    this.channelForm.valueChanges.subscribe(() => {
      const channelsData = this.channelForm.value.channels;
      const channelsWithoutLock = channelsData.filter(chnl => (chnl.channelId && !chnl.channelLock));
      const modalChannelCount = channelsData.filter(chnl => (chnl.channelId && !chnl.isCustomChannel));
      this.enableOptimize = channelsWithoutLock.length >= 2 && modalChannelCount.length >=2;
    })

    this.getFinancialValueFromPortfolioForSubBrand();

  }
  getFinancialValueFromPortfolioForSubBrand(){
      const postObj = {
        planEntityId: this.planEntityId,
        year: this.planningYear
      }
      this.planService.getFinancialValueFromPortfolioForSubBrand(postObj).subscribe(response => {
        const fValue = response['portfolioData']['financialValue'];
        this.financialValue = FINANCIAL_VALUES[fValue];
        switch(this.financialValue){
          case 'Gross Profit':{
            this.roiText = 'Gross Profit ROI'
            break;
          }
          case 'Net Revenue':{
            this.roiText = 'Net Revenue ROI'
            break;
          }
          case 'Gross Revenue':{
            this.roiText = 'Gross Revenue ROI'
            break;
          }
        }
      })
    }

  showTooltip(){
    this.showtooltipTitleScenarioPlanner = true;
  }
  hideTooltip(){
    this.showtooltipTitleScenarioPlanner = false;
  }

  setInflationCPP(channelIdx:number, getCppIfNotExist){
    if(getCppIfNotExist) {
      let val = this.channels().controls[channelIdx].get('isCustomChannel').value ? this.channels().controls[channelIdx].get('isCustomChannel').value : false;
      if(val == true){
        this.cpp = this.channels().controls[channelIdx].get('cpp').value ? this.channels().controls[channelIdx].get('cpp').value.toString().replace(/,/g, '') : 0;
        this.inflationPercentage = Number(this.channels().controls[channelIdx].get('inflationPercentage').value);
        let tempCPP = Number(this.cpp) * this.inflationPercentage / 100;
        this.inflationCPP = Math.round(tempCPP + Number(this.cpp));
        this.channels().controls[channelIdx].get('cppinflationAdjustment').patchValue(Number(this.inflationCPP).toLocaleString());
      } else {
        this.cpp = this.channels().controls[channelIdx].get('cpp').value ? this.channels().controls[channelIdx].get('cpp').value.toString().replace(/,/g, '') : 0;
        this.channels().controls[channelIdx].get('cppinflationAdjustment').patchValue(Number(this.cpp).toLocaleString());
      }
    }
  }
  setInflationCPPValue(channelIdx:number){
    this.cpp = this.channels().controls[channelIdx].get('cpp').value ? this.channels().controls[channelIdx].get('cpp').value.toString().replace(/,/g, '') : 0;
    this.inflationPercentage = Number(this.channels().controls[channelIdx].get('inflationPercentage').value);
    let tempCPP = Number(this.cpp) * this.inflationPercentage / 100;
    this.inflationCPP = Math.round(tempCPP + Number(this.cpp));
    this.channels().controls[channelIdx].get('cppinflationAdjustment').patchValue(Number(this.inflationCPP).toLocaleString());
    this.getChannelBudgetLimit(this.channels().controls[channelIdx], channelIdx);
  }

  ngOnChanges({ elementDetails }: SimpleChanges) {

    this.planEntityId = this.elementDetails ? this.elementDetails.planEntityId : null;
    const mediaChannelObj = {
      marketId: this.store.get("market"),
      referenceType: MEDIA_CHANNEL_REFERENCES.SUB_BRAND,
      referenceId: this.planEntityId
    }

    this.programService.getMediaChannelCurves(mediaChannelObj).subscribe(res => {
      this.curveList = res;
      this.isCurveListEmpty = this.curveList.length == 0;
      if(!this.isEditMode){
        this.selectedCurveVersion = this.curveList[0];
        this.getChanelByCurve(this.curveList[0]);
      }
    })

    if (this.elementDetails && this.elementDetails.payload) {
      if (!this.isFormInitialized) {
        this.channelForm = this.fb.group({
          channels: this.fb.array([]),
        });
        this.isFormInitialized = true;
      }
      this.isEditMode = true;
      this.selectedChannelDetails = this.elementDetails.payload;
      this.scenarioStatus = this.selectedChannelDetails.scenario.scenarioStatus;
      let modalChannelData = this.selectedChannelDetails.channels;
      this.selectedCurveVersion = modalChannelData && modalChannelData.length > 0 ? modalChannelData[0].curve : null;
      this.getChanelDataOnEdit(this.selectedCurveVersion);
      if(this.selectedCurveOld=='') {
        this.selectedCurveOld = this.selectedCurveVersion;
      }

    } else {
      this.scenarioName = `Upfront Planning_${this.elementDetails.brandName}_${moment().format('DD_MM_YYYY')}`;
    }

    this.getFinancialValueFromPortfolioForSubBrand();

  }

  initializeEditMode(scenarioChannelDetails: any) {
    const scenarioDetails = scenarioChannelDetails.scenario;
    this.scenarioId = scenarioDetails.scenarioId;
    this.scenarioName = scenarioDetails.scenarioName;
    const analyticsData = {
      scenarioId: scenarioDetails.scenarioId,
      plannedWMBudget: scenarioDetails.aWMBudget,
      plannedROI: scenarioDetails.plannedROI,
      plannedReach: scenarioDetails.plannedReach,
      estimatedRevenue: scenarioDetails.plannedRevenue,
      weeksOnAir: scenarioDetails.weeksOnAir,
      estimatedEffectiveness: scenarioDetails.plannedEffectiveness
    }
    this.scenarioAnalytics = analyticsData;
    const channelsDetailsList = scenarioChannelDetails.channels;
    channelsDetailsList.forEach((el, idx) => {
      if(el.channelType == "DATA"){

      this.selectedMediaChannel[idx] = this.channelsList.find(chnl => chnl.mediaChannel == el.channelName);
      }
      this.WOA[idx] = el.weeksOnAir;
      el.cpp = el.cpp ? el.cpp.toLocaleString() : 0;
      let baseCPP = el.baseCPP ? Number(el.baseCPP).toLocaleString() : 0;
      let newChannelForm = this.newChannel();
      let percentageFactor = el.percentageFactor ? Number(el.percentageFactor) : null;
      percentageFactor = percentageFactor ? Math.round(percentageFactor) : 0;
      newChannelForm.get('channelName').setValidators(el.channelType == "CUSTOM" ? [] : [Validators.required]);
      newChannelForm.get('channelName').setValidators(el.channelType == "DATA" ?   [Validators.required]:[]);
      const custChannelName = el.channelType == "CUSTOM" ? el.channelName : '';
      newChannelForm.patchValue({
        channelName:this.selectedMediaChannel[idx]?this.selectedMediaChannel[idx]:'',
        customChannelName: custChannelName,
        cWMBudget: el.cWMBudget,
        minBudget: el.minBudget,
        maxBudget: el.maxBudget,
        cppinflationAdjustment: this.selectedCurveOld == this.selectedCurveVersion?el.cpp:this.selectedMediaChannel[idx]?this.selectedMediaChannel[idx].cpp:0,
        inflationPercentage: this.selectedCurveOld == this.selectedCurveVersion?percentageFactor:0,
        startDt: el.startDt,
        endDt: el.endDt,
        cpp: this.selectedCurveOld == this.selectedCurveVersion?baseCPP:this.selectedMediaChannel[idx]?this.selectedMediaChannel[idx].cpp:0,
        channelId: el.channelId,
        mediaChannelCurve: this.selectedCurveVersion,
        channelLock: el.channelLock == 'Y',
        networkInput:Math.round(Number(el.networkPercent * 100)) || null,
        primeInput: Math.round(Number(el.dayPartPercent * 100)) || null,
        secInput: Math.round(Number(el.durationPercent * 100)) || null,
        customRoi: el.ROI ?  parseFloat(el.ROI).toFixed(2): 0.00,
        adjsutmentFactor: el.adjsutmentFactor,
        isCustomChannel: el.channelType === "CUSTOM" ? true : false,
        baseCPP: this.selectedCurveOld == this.selectedCurveVersion ? baseCPP : this.selectedMediaChannel[idx]?this.selectedMediaChannel[idx].cpp:0
      });
      this.channels().push(newChannelForm);
      this.isLinearTvChannel(idx);
      if(el.channelType != "CUSTOM" && this.selectedMediaChannel[idx].channelName ){
        this.getChannelBudgetLimit(el, idx);
      }


    });


    this.getAnalyticsData();

  }





  // ................. Dynamic Form Array methods ......................

  channels(): FormArray {
    return this.channelForm.get("channels") as FormArray
  }


  validatecWBudget(control: AbstractControl) {

    if (control.value && parseFloat(control.parent.value.maxBudget) > 0) {
      let budgetInput =control.value ? parseFloat(control.value.toString().replace(/,/g, '')) : 0
      if(budgetInput < parseFloat(control.parent.value.minBudget) || budgetInput > parseFloat(control.parent.value.maxBudget)){
        return {cWbudgetError:true};
      }
      return null;
    }
  }
  newChannel(): FormGroup {
    return this.fb.group({
      channelName: ['', Validators.required],
      customChannelName: null,
      channelId: null,
      touchPointId: 1,
      channelSpend: 123.45,
      cWMBudget: ['',  [Validators.required, this.validatecWBudget]],
      maxBudget: 0,
      minBudget: 0,
      startDt: ['', Validators.required],
      endDt: ['', Validators.required],
      cpp: ['', Validators.required],
      cppinflationAdjustment:['', Validators.required],
      inflationPercentage:0,
      channelLock: [false],
      mediaChannelCurve: '',
      networkInput: [0, Validators.max(100)],
      primeInput: [0, Validators.max(100)],
      secInput: [0, Validators.max(100)],
      networkInputImpact: null,
      primeInputImpact: null,
      secInputImpact: null,
      adjsutmentFactor: 1,
      customRoi: null,
      isCustomChannel: false,
      percentageFactor:0,
      baseCPP: 0
    },{validator: this.checkStartDate})
  }

  /*........... functionality Methods........ */

  optimizeScenario() {
    const dialogRef = this.dialog.open(OptimizerMessageDialogComponent, {
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      const optObj = this.populateOptimizeScenarioObj();
      this.submitOptimze(optObj);
    });

  }

  addChannel() {
    this.channels().push(this.newChannel());
    this.selectedMediaChannel.push('');

  }

  checkStartDate: ValidatorFn = (control: FormControl) => {
      let startDate = new Date(control.get('startDt').value);
      let endDate = new Date(control.get('endDt').value);
      if(startDate && endDate){
        if (startDate > endDate) {
          const err = { dateLessThan: true };
          control.get('startDt').setErrors(err);
          control.get('endDt').setErrors(err);
          return err;
        } else if(startDate >= endDate){
          const err = { dateEqual: true };
          control.get('startDt').setErrors(err);
          control.get('endDt').setErrors(err);
          return err;
        }
        else {
          const dateLessErrorstartDate = control.get('startDt').hasError('dateLessThan');
          const dateLessErrorendDate = control.get('endDt').hasError('dateLessThan');
          const dateStartEqual = control.get('startDt').hasError('dateEqual');
          const dateEndEqual = control.get('endDt').hasError('dateEqual');
          if(dateLessErrorstartDate){
            delete control.get('startDt').errors['dateLessThan'];
            control.get('startDt').updateValueAndValidity();
          }
          if(dateStartEqual){
            delete control.get('startDt').errors['dateEqual'];
            control.get('startDt').updateValueAndValidity();
          }
          if(dateLessErrorendDate){
            delete control.get('endDt').errors['dateLessThan'];
            control.get('endDt').updateValueAndValidity();
          }
          if(dateEndEqual){
            delete control.get('endDt').errors['dateEqual'];
            control.get('endDt').updateValueAndValidity();
          }
        }
        // return null;
     };
   }

  customChannelChkChange(checkBoxEle, fctrl: FormControl) {
    const isChecked = checkBoxEle.checked;
    fctrl.get('channelName').setValidators(isChecked ? [] : [Validators.required]);
    fctrl.get('channelName').updateValueAndValidity();
    fctrl.get('customChannelName').setValidators(isChecked ? [Validators.required] : []);
    fctrl.get('customChannelName').updateValueAndValidity();
    fctrl.get('customRoi').setValidators(isChecked ? [Validators.required] : []);
    fctrl.get('customRoi').updateValueAndValidity();
    fctrl.get('cpp').setValidators(isChecked ? [] : [Validators.required]);
    fctrl.get('cpp').updateValueAndValidity();
    fctrl.get('cppinflationAdjustment').setValidators(isChecked ? [] : [Validators.required]);
    fctrl.get('cppinflationAdjustment').updateValueAndValidity();
    fctrl.get('cWMBudget').setValidators(isChecked ? [] :  [Validators.required, this.validatecWBudget]);
    fctrl.get('cWMBudget').updateValueAndValidity();
  }



  removeChannel(chnlIdx: number) {
    const channelDetails = this.channels().at(chnlIdx).value;

    const { channelId } = channelDetails;

    if (channelId) {
      this.deleteChannel(channelId, chnlIdx);

    } else {
      this.channels().removeAt(chnlIdx);
      this.selectedMediaChannel.splice(chnlIdx,1);
      this.WOA.splice(chnlIdx,1);
    }

  }

  isLinearTvChannel(channelIdx:number){
    let channelName = this.channels().controls[channelIdx].get('channelName').value.channelName;

    if(channelName){
      channelName = channelName.toLowerCase();
      if(!this.channels().controls[channelIdx].get('networkInput').value) {
        this.channels().controls[channelIdx].get('networkInput').patchValue(this.networkReference ? Math.round(this.networkReference * 100) : 0);
      }
      if(!this.channels().controls[channelIdx].get('primeInput').value) {
        this.channels().controls[channelIdx].get('primeInput').patchValue(this.primeReference ?  Math.round(this.primeReference * 100) : 0);
      }
      if(!this.channels().controls[channelIdx].get('secInput').value) {
        this.channels().controls[channelIdx].get('secInput').patchValue(this.secReference ? Math.round(this.secReference * 100) : 0);
      }
      return channelName==='linear tv';
    }
  }


  updateChannelnCPP(channelDetails, index) {

    this.channels().controls[index].get('channelName').patchValue(channelDetails);

    if(!channelDetails.cpp){
      this.isCPPNotExists = true;
      this.channels().controls[index].patchValue({
        cppinflationAdjustment:0,
        cpp: 0,
        inflationPercentage:0,
        channelName: channelDetails,
        mediaChannelCurve: this.selectedCurveVersion
      })

    } else {
      this.isCPPNotExists = false;
      this.channels().controls[index].patchValue({
        cppinflationAdjustment:channelDetails.cpp.toLocaleString(),
        cpp: channelDetails.cpp.toLocaleString(),
        channelName: channelDetails,
        mediaChannelCurve: this.selectedCurveVersion
      })
    }
    this.isLinearTvChannel(index);
    this.getChannelBudgetLimit(channelDetails, index);
  }

  updateChannelLock(checkBoxEle, index, channelId) {
    const isChecked = checkBoxEle.currentTarget.checked;

    const channelLockObj = {
      channelId,
      channelLock: isChecked ? 'Y' : 'N'
    }
    this.planService.lockChannel(channelLockObj).subscribe(() => {
      this.channels().controls[index].patchValue({
        channelLock: isChecked
      });

    })
  }

  updateSavedChannelId(channelId, channelIdx) {
    this.channels().controls[channelIdx].patchValue({
      channelId: channelId
    });
  }
   updateSavedChannelIds(channelIds) {
    this.channels().controls.forEach((control, index) => {
      if(channelIds[index]){
      control.patchValue({
        channelId: channelIds[index]
           });
          }
    });
  }


  calculateNetworkInputImpact(event: any, channelIndex: number) {
    let networkInputImpact = null;
    if (this.channels().controls[channelIndex].get('networkInput').valid && !_.isEmpty(event.target.value)) {
      const impact = event.target.value;
      const impactInDecimal = impact / 100;
      networkInputImpact = 1 + ((impactInDecimal - this.networkReference) * this.networkFactor)
    }
    this.channels().controls[channelIndex].patchValue({
      networkInputImpact,
      networkInput:event.target.value ? event.target.value : 0
    });
  }

  getNetworkImpact(channelIndex: number) {
    return this.channels().controls[channelIndex].get('networkInputImpact').value
  }

  calculatePrimeInputImpact(event: any, channelIndex: number) {
    let primeInputImpact = null;
    if (this.channels().controls[channelIndex].get('primeInput').valid && !_.isEmpty(event.target.value)) {
      const impact = event.target.value;
      const impactInDecimal = impact / 100;
      primeInputImpact = 1 + ((impactInDecimal - this.primeReference) * this.primeFactor);
    }
    this.channels().controls[channelIndex].patchValue({
      primeInputImpact,
      primeInput: event.target.value ? event.target.value : 0
    });
  }

  getPrimeImpact(channelIndex: number) {
    return this.channels().controls[channelIndex].get('primeInputImpact').value
  }



  calculateSecInputImpact(event: any, channelIndex: number) {
    let secInputImpact = null;
    if (this.channels().controls[channelIndex].get('secInput').valid && !_.isEmpty(event.target.value)) {
      const impact = event.target.value;
      const impactInDecimal = impact / 100;
      secInputImpact = 1 + ((impactInDecimal - this.secReference) * this.secFactor);
    }
    this.channels().controls[channelIndex].patchValue({
      secInputImpact,
      secInput: event.target.value ? event.target.value : 0
    });
  }

  getSecInputImpact(channelIndex: number) {
    return this.channels().controls[channelIndex].get('secInputImpact').value
  }


  saveMetaData(){
    const scenarioObj = {
      scenarioId: this.scenarioId,
      name: this.scenarioName
    }

    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'save',
        module: 'scenarioMeta',
        apiData: scenarioObj,
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(res => {

    });


    // this.planService.updateScenarioName(scenarioObj).subscribe(()=>{})

  }

  removeDuplicateChannels(channelsList){
    const flatChannelsList = channelsList.reduce((chnl,currchnl)=>{
      return chnl.concat(currchnl);
    },[]);
    const uniqueChannels = flatChannelsList.filter((el,index,self)=>{
      const foundIndex =self.findIndex(obj => obj.channelName === el.channelName);
    return index === foundIndex;
  });
   return uniqueChannels;
  }
  /*........... Service/API calls........ */

  getChanelDataOnEdit(curveVersion) {
    this.isAddDisabled = true;
    this.selectedCurveVersion = curveVersion;

    const mediaChannelObj = {
      marketId: this.store.get("market"),
      referenceType: MEDIA_CHANNEL_REFERENCES.SUB_BRAND,
      referenceId: this.planEntityId,
      curveVersion
    }

    this.networkReference = 0;
    this.networkFactor = 0;
    this.secReference = 0;
    this.secFactor = 0;
    this.primeReference = 0;
    this.primeFactor = 0;
    this.programService.getChannelsPerCurve(mediaChannelObj)
    .subscribe((res) => {
    this.channelsList = res.body.sort((a, b) => a.channelName.localeCompare(b.channelName));
    this.channelsList = this.removeDuplicateChannels(this.channelsList);
      this.channelsList.forEach(el=>el.cpp= Math.round(el.cpp));
      this.isAddDisabled = false;
      if (this.isEditMode) {
        this.initializeEditMode(this.selectedChannelDetails);
      }
      this.getLinearFactors();
      this.getNetworkFactorSettins();
    })
  }

  calculateWeeksOnAir(channelIdx){
    let startDt = this.channelForm.get('channels')['controls'][channelIdx].get('startDt').value ? Date.parse(this.channelForm.get('channels')['controls'][channelIdx].get('startDt').value) : this.channelForm.get('channels')['controls'][channelIdx].get('startDt').value;
    let endDt = this.channelForm.get('channels')['controls'][channelIdx].get('endDt').value ? Date.parse(this.channelForm.get('channels')['controls'][channelIdx].get('endDt').value) : this.channelForm.get('channels')['controls'][channelIdx].get('endDt').value;
    if(startDt!=null && endDt!=null){
      this.WOA[channelIdx] = this.weeksBetween(startDt,endDt);
    }

    const channelDetails = this.channels().at(channelIdx).value;
    if(channelDetails.startDt && channelDetails.endDt &&channelDetails.channelName){
      this.getChannelBudgetLimit(channelDetails, channelIdx);
    }
  }

  weeksBetween(d1, d2) {
    return Math.ceil((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}
  getChanelByCurve(curveVersion) {
    this.isAddDisabled = true;
    this.selectedCurveVersion = curveVersion;

    const mediaChannelObj = {
      marketId: this.store.get("market"),
      referenceType: MEDIA_CHANNEL_REFERENCES.SUB_BRAND,
      referenceId: this.planEntityId,
      curveVersion
    }

    this.networkReference = 0;
    this.networkFactor = 0;
    this.secReference = 0;
    this.secFactor = 0;
    this.primeReference = 0;
    this.primeFactor = 0;
      this.programService.getChannelsPerCurve(mediaChannelObj).subscribe((res) => {
      this.channelsList = res.body.sort((a, b) => a.channelName.localeCompare(b.channelName));
      this.channelsList = this.removeDuplicateChannels(this.channelsList);
      this.channelsList.forEach(el=>el.cpp= Math.round(el.cpp));
      this.isAddDisabled = false;
      this.getLinearFactors();
      this.getNetworkFactorSettins();
      if(this.channels().length>0) this.channels().clear();
      if (this.isEditMode) {
      this.initializeEditMode(this.selectedChannelDetails); //this.getChanelDataOnEdit(this.selectedCurveVersion);
     }
    })
  }

  //Set the End date for maximum of 52 weeks
  getMaxDate(chnlIdx:number){
    const channelDetails = this.channels().at(chnlIdx).value;
    const currentDate = moment(channelDetails.startDt);
    const endDate = currentDate.add(52,"weeks").toDate();
    return endDate;
  }
  getNetworkFactorSettins() {
    try{
      this.planService.getNetworkFactorSettings({marketId: this.store.get("market")}).subscribe((res: any) => {

      this.netWorkFactorIsEditable = res && res.isEditable ? res.isEditable == 1 : false;
      this.netWorkFactorIsVisible = res && res.isVisible ? res.isVisible == 1  : false;
    })
  } catch (e){console.log(e);}
}
  returnVisibleTrueOrFalse(channelIdx){
    //console.log("this.channels().controls[channelIdx].get('channelName')", this.channels().controls[channelIdx].get('channelName'));
    let channelName = this.channels().controls[channelIdx].get('channelName').value.channelName;
    return this.netWorkFactorIsVisible && channelName && channelName.toLowerCase() === 'linear tv';
  }
  getLinearFactors() {
    try{
      const linearFactorObj = this.populateLinearFactorObj();
      this.planService.getLinearFactorsBySubBrand(linearFactorObj).subscribe((res: ILinearFactor) => {

        this.networkReference = res.networkReference;
        this.networkFactor = res.network;
        this.secReference = res["30SecondReference"];
        this.secFactor = res["30Second"];
        this.primeReference = res.primeTimeReference;
        this.primeFactor = res.primeTime;
        //const channelData = this.channels() && this.channels().length > 0 ? this.channels().controls.filter(el => el.isLinear) : [];

      })
    } catch (e){console.log(e);}


  }


  deleteChannel(channelId, chnlIdx) {
    const isLastChannel = this.channels().controls.length == 1;

    //If last, send boolean to dialog.
    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'delete',
        module: 'upfront',
        element: 'channel',
        apiData: channelId,
        isLast: isLastChannel
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(res => {
      //Remove the channel from formArray, if in edit mode
      if (res !== 'no') {
        this.channels().removeAt(chnlIdx);
        this.selectedMediaChannel.splice(chnlIdx,1);
        this.WOA.splice(chnlIdx,1);
        this.getAnalyticsData();
        if (isLastChannel) {
          this.planService.deleteScenario(this.scenarioId).subscribe(() => {
            this.globalElementsService.setSidePanelCancelEvent();
          });
        }
      }
    });
  }


  getAnalyticsData() {
    const postObj = {
      marketId: Number(this.store.get("market")),
      scenarioId: this.scenarioId,
      type: ANALYTICS_TYPE.UPFRONT
    }
    //Fetch the analytics data based on new saved channel
    this.planService.getScenarioAnalytics(postObj).subscribe((res: IScenarioAnalytics) => {
      this.scenarioAnalytics = res;
    })
  }

  onSubmit(channelIdx) {
    const channelFormInfo = this.channelForm.value.channels[channelIdx];
    channelFormInfo.mediaChannelCurve = this.selectedCurveVersion;
    channelFormInfo.cWMBudget = channelFormInfo.cWMBudget ? channelFormInfo.cWMBudget.toString().replace(/,/g, '') : '';
    channelFormInfo.cpp = channelFormInfo.cpp ? channelFormInfo.cpp.toString().replace(/,/g, '') : '';
    const postObj = this.populatePostObject(channelFormInfo);

    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'save',
        apiData: postObj
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.scenarioId) {
        this.scenarioId = result.scenarioId;
        this.updateSavedChannelId(result.channelId, channelIdx);

        this.getAnalyticsData();
        this.budgetError = false;
      }
    });

  }
  showErrors(formGroup: FormGroup| FormArray): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as FormControl | FormGroup | FormArray;
      if(control instanceof FormControl) {
        control.updateValueAndValidity();
        control.markAsTouched();
      }else if(control instanceof FormGroup || control instanceof FormArray) {
        //control is nested group or array, calling showErrors recursively
        this.showErrors(control);
      }
  })
  }

  saveChannelList() {
    const channelFormInfo = this.channelForm.value
    if(this.channelForm.valid){
      this.scenarioforminvalid = false;
    const postObj = this.populateChannelListFormPostObject(channelFormInfo);
    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'saveList',
        module: 'saveChannelList',
        apiData: postObj
      },
      minWidth: '500px'
    })


    dialogRef.afterClosed().subscribe(result => {
      if (result && result.scenarioId) {
       this.scenarioId = result.scenarioId;
       this.updateSavedChannelIds(result.channelId);
       this.getAnalyticsData();
        this.budgetError = false;
      }
    });

  }else{
    this.scenarioforminvalid = true;
    this.showErrors(this.channelForm);

  }

  }

  submitOptimze(optimizeObj) {
    this.planService.optimizeScenario(optimizeObj).subscribe(res => {
      this.globalElementsService.setSidePanelCancelEvent();
    })
  }
  populateChannelListFormPostObject(channelFormVal) {
    let postObj = [];
    channelFormVal.channels.forEach((channelVal, idx) => {
    channelVal.cWMBudget = channelVal.cWMBudget ? channelVal.cWMBudget.toString().replace(/,/g, '') : '';
    channelVal.cpp = channelVal.cpp ? channelVal.cpp.toString().replace(/,/g, '') : '';
    channelVal.mediaChannelCurve = this.selectedCurveVersion;

    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    const userId = this.userInfo.id;
    const marketId = this.store.get("market");
    const planEntityId = this.planEntityId ? Number(this.planEntityId) : this.planEntityId;
    const scenarioInfo = {
      scenarioId: this.scenarioId,
      scenarioName: this.scenarioName,
      scenarioType: ANALYTICS_TYPE.UPFRONT,
      scenarioStatus: this.scenarioStatus || null,
      scenarioReference: "xyz",
      year: this.planningYear,
      runType: "SAMPLE"
    }

    channelVal.cppinflationAdjustment = channelVal.cppinflationAdjustment ? channelVal.cppinflationAdjustment.toString().replace(/,/g, ''): 0;
    channelVal.cpp = channelVal.cpp ? channelVal.cpp.toString().replace(/,/g, ''): 0;
    const { inflationPercentage, cppinflationAdjustment,networkInput, primeInput, secInput, channelName, customChannelName, adjsutmentFactor, mediaChannelCurve, channelId, cWMBudget, startDt, endDt, cpp, channelLock, isCustomChannel, customRoi, baseCPP } = channelVal;
    //(channelName && isCustomChannel)? channelName.toLowerCase() === 'linear tv'
        const channelInfo = {
      channelName: isCustomChannel ? customChannelName : channelName.channelName,
      curve: mediaChannelCurve,
      channelId: channelId,
      cWMBudget: cWMBudget,
      startDt: startDt,
      endDt: endDt,
      cpp: cppinflationAdjustment,
      baseCPP:cpp,
      percentageFactor: Number(inflationPercentage),
      channelLock: channelLock ? 'Y' : 'N',
      networkPercent: networkInput ? networkInput / 100 : null,
      dayPartPercent: primeInput ? primeInput / 100 : null,
      durationPercent: secInput ? secInput / 100 : null,
      isLinear: (channelName && !isCustomChannel) ?channelName.channelName.toLowerCase() === 'linear tv': null,
      isCustomChannel: isCustomChannel ? 'Y' : 'N',
      customRoi: customRoi,
      adjsutmentFactor: adjsutmentFactor
    }


    postObj.push({
      marketId,
      planEntityId,
      scenarioInfo,
      channelInfo,
      userId
    })

  });
    return postObj;
  }

  populatePostObject(channelFormInfo) {
    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    const userId = this.userInfo.id;
    const marketId = this.store.get("market");
    const planEntityId = this.planEntityId ? Number(this.planEntityId) : this.planEntityId;

    const scenarioInfo = {
      scenarioId: this.scenarioId,
      scenarioName: this.scenarioName,
      scenarioType: ANALYTICS_TYPE.UPFRONT,
      scenarioStatus: this.scenarioStatus || null,
      scenarioReference: "xyz",
      year: this.planningYear,
      runType: "SAMPLE"
    }
    channelFormInfo.cppinflationAdjustment = channelFormInfo.cppinflationAdjustment ? channelFormInfo.cppinflationAdjustment.toString().replace(/,/g, ''): 0;
    channelFormInfo.cpp = channelFormInfo.cpp ? channelFormInfo.cpp.toString().replace(/,/g, ''): 0;
    const { inflationPercentage, cppinflationAdjustment,networkInput, primeInput, secInput, channelName, customChannelName, adjsutmentFactor, mediaChannelCurve, channelId, cWMBudget, startDt, endDt, cpp, channelLock, isCustomChannel, customRoi, baseCPP } = channelFormInfo;

    const channelInfo = {
      channelName: isCustomChannel ? customChannelName : channelName.channelName,
      curve: mediaChannelCurve,
      channelId: channelId,
      cWMBudget: cWMBudget,
      startDt: startDt,
      endDt: endDt,
      cpp: cppinflationAdjustment,
      baseCPP:cpp,
      percentageFactor: Number(inflationPercentage),
      channelLock: channelLock ? 'Y' : 'N',
      networkPercent: networkInput ? networkInput / 100 : null,
      dayPartPercent: primeInput ? primeInput / 100 : null,
      durationPercent: secInput ? secInput / 100 : null,
      isLinear: channelName?channelName.channelName.toLowerCase() === 'linear tv':null,
      isCustomChannel: isCustomChannel ? 'Y' : 'N',
      customRoi: customRoi,
      adjsutmentFactor: adjsutmentFactor
    }


    const postObj = {
      marketId,
      planEntityId,
      scenarioInfo,
      channelInfo,
      userId
    }


    return postObj;


  }


  populateLinearFactorObj() {

    const linearFactorObj = {
      marketId: this.store.get("market"),
      referenceType: MEDIA_CHANNEL_REFERENCES.SUB_BRAND,
      referenceId: this.planEntityId,
      curveVersion: this.selectedCurveVersion
    }

    return linearFactorObj;
  }


  populateOptimizeScenarioObj() {
    const postObj = {
      scenarioId: this.scenarioId,
      scenarioName: this.scenarioName,
      scenarioType: ANALYTICS_TYPE.UPFRONT,
      optimizedByUserId: this.userInfo.id
    }
    return postObj;
  }

  CommaFormatted(event,channelIdx) {
    if(event.which >= 37 && event.which <= 40) return;
    //old code
    // if (event.target.value) {
    //   event.target.value = event.target.value.replace(/\D/g, "")
    //    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    // event.target.value = this.commafy(event.target.value)
    event.target.value = event.target.value.replace(/[^0-9.]/g, "")
    let commafiedNumber = this.commafy((event.target.value).replace(/,/g, ''))
    this.channels().controls[channelIdx].patchValue({cWMBudget: commafiedNumber })
  }

  commafy( num ) {
    var str = num.toString().split('.');
    if(str.length>2){
      str = str.slice(0, 2);
    }
    if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length > 2) {
      str[1] = str[1].slice(0, 2);;
        // str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }
   numberCheck (args) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }
  }
  updateValue(channelIdx){
    const value = this.channels().controls[channelIdx].get('customRoi').value;
    if(value){
      this.channels().controls[channelIdx].patchValue({customRoi: parseFloat(value).toFixed(2)})
    }
  }

  getChannelBudgetLimit(channelDetails, index){
    const channelFormInfo = this.channelForm.value.channels[index];
    channelFormInfo.cWMBudget = channelFormInfo.cWMBudget ? channelFormInfo.cWMBudget.toString().replace(/,/g, '') : '';
    channelFormInfo.cpp = channelFormInfo.cpp ? channelFormInfo.cpp.toString().replace(/,/g, '') : '';
    const postObj = this.populatePostObject(channelFormInfo)
    this.planService.getChannelBudgetLimit(postObj).subscribe((res: any) => {
       this.channelForm.get('channels')['controls'][index].patchValue({minBudget: parseFloat(res.data.Minimum_Budget)});
       this.channelForm.get('channels')['controls'][index].patchValue({maxBudget: parseFloat(res.data.Maximum_Budget)});
       //this.channelForm.channels[index].controls.patchValue({maxBudget: parseFloat(res.data.Maximum_Budget).toFixed(2)});
      this.channelBudget[index]=res.data;
    }, err => {
      if (err.status == 476) {

      }
    });
  }
  validateBudget(channelIdx){

    if(!this.channelForm.get('channels')['controls'][channelIdx].get('isCustomChannel').value){
    if( this.channelBudget[channelIdx].Minimum_Budget && this.channelBudget[channelIdx].Maximum_Budget  ){
      let budgetInput = this.channelForm.get('channels')['controls'][channelIdx].get('cWMBudget').value ? parseFloat(this.channelForm.get('channels')['controls'][channelIdx].get('cWMBudget').value.toString().replace(/,/g, '')) : 0
      if(budgetInput < parseFloat(this.channelBudget[channelIdx].Minimum_Budget) ||
      budgetInput > parseFloat(this.channelBudget[channelIdx].Maximum_Budget)){
        this.budgetError = true;
      }else{
        this.budgetError = false;
      }
    } else {
      this.budgetError = false;
    }
  }
}

}
