import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { environment } from 'environments/environment';

import axios from 'axios';
@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  message: string = "Upload in Progress";
  uploadSts=false;
  isErr: boolean = false;
  isDelMode:boolean = false;
  errMsg: string = ''
  isDelConfirmed: boolean = false;
  showOkBtn: boolean = false;
  thumbnailb64data: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private globalElementsService: GlobalElementsService
  ) { }

  ngOnInit() {
    const { isDelete } = this.data.docObj;
    if (isDelete) {
      this.message = "Are you sure you want to delete?";
      this.isDelMode = true;
      this.isDelConfirmed = false;
    } else {
      this.uploadAvatar();
    }
  }


  async uploadAvatar() {
         
    this.globalElementsService.uploadDocuments(this.data.docObj).subscribe(async (res:any) => {
      const SHAREPOINT_PATH_URL = environment.SHAREPOINT_PATH_URL;
      const pathLoc = environment.pathLoc;
      const location = this.data.docObj.body.uploadLocation;
      const fileName = this.data.docObj.body.fileName;
      if(res.sqAuthheader){
        axios({
          method: 'POST',
          url: `${SHAREPOINT_PATH_URL}/_api/web/getfolderbyserverrelativeurl('${pathLoc}${location}')/files/add(overwrite=true,url='${fileName}')`,
          headers: res.sqAuthheader,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          data: this.data.docObj.body.data
      }).then(async response => {
          if(response.status == 200){
            this.thumbnailb64data = await this.globalElementsService.getThumbnailBase64DataSharePoint_new({ "filePathWName" : `${this.data.docObj.body.uploadLocation}/${this.data.docObj.body.fileName}`}).toPromise();
            try{
              if(this.thumbnailb64data){
                this.message = "Uploaded Successfully";
                this.uploadSts=true;
                this.showOkBtn = true;
              } else {
  
              }
            } catch(error){
              this.message = "File Uploaded Successfully. Error Showing in Thumbnail";
              this.uploadSts=true;
              this.showOkBtn = true;
            }
            
          }
      }).catch(error => {
          if(error.response.status == 404){
            this.message = "Upload failed as this folder does not exist";
            this.uploadSts=true;
            this.showOkBtn = true;
          } else if(error.response.status == 403){
            this.message = "File size too big to handle the request";
            this.uploadSts=true;
            this.showOkBtn = true;
          }
      })
    //    try {
    //     this.thumbnailb64data = await this.globalElementsService.getThumbnailBase64DataSharePoint_new({ "filePathWName" : `${this.data.docObj.body.uploadLocation}/${this.data.docObj.body.fileName}`}).toPromise();
    //     if(this.thumbnailb64data) {
    //       this.message = "Uploaded Successfully";
    //       this.uploadSts=true;
    //       this.showOkBtn = true;
    //     } else {
          
    //     }
    //   }catch (error) {
    //     this.message = "Uploaded Successfully";
    //     this.uploadSts=true;
    //     this.showOkBtn = true;
    //   }
    //   }       
    // }, (error) => {
    //   console.log(error);
    //   this.message = "Error uploading file";
    //   this.uploadSts=false;
    //   this.showOkBtn = true;
       }
    })
  }

  deleteSharePointFile(){
    this.globalElementsService.deleteSharePointFile(this.data.docObj).subscribe((res:any) => {
      if(res){
        this.message = "Deleted Successfully";
        this.uploadSts=true;
        this.isDelConfirmed = true;
      }      
    }, (err) => {
      if (err.status == 476) {
        this.isErr = true;
        this.errMsg = err.error.message;
      }
      this.isDelConfirmed = true;
      this.message = "Error deleting file";
      this.uploadSts=false;
    })
  }

  close(){
    this.dialogRef.close({ status: this.uploadSts,  fileType:this.data.fileType, thumbnailImage: this.thumbnailb64data ? this.thumbnailb64data : '' });
  }

}
