import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { CommonDialogToShowNotificationComponent } from '../../../../common/components/dialogs/common-dailog-to-show-notification/common-dailog-to-show-notification';
import { PAGE_MODES } from '../../../../constants/app-constants';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-enhance-tooltip-categpory',
  templateUrl: './add-tooltip-category.component.html',
  styleUrls: ['./add-tooltip-category.component.scss']
})
export class EnhanceTooltipCategoryComponent implements OnInit, OnDestroy {

  @Input() pageMode;
  @Input() editData;
  @Output() tooltipCatSelctionEvent = new EventEmitter();
  @Output() changePageModeEmitter = new EventEmitter
  userInfo = JSON.parse(sessionStorage.getItem('user'));


  tooltipCatForm: FormGroup;
  //tooltipCatCode: string;
  //tooltipCategoryName: string;
  isEditMode:boolean = false;
  mdmMarkets = [];
  saveBtnSubscription: Subscription;
  currencyOptions: Array<object>;
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }




  /*........... Initialization Methods........ */

  ngOnInit() {
   //console.log('currencyToSymbolMap', currencyToSymbolMap);
    this.isEditMode = this.pageMode === PAGE_MODES.EDIT;
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !==6);
    this.globalElementsService.setSaveEnableState(false);
    this.iniitializetooltipCatForm();
    this.tooltipCatForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.tooltipCatForm.valid);
    })

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());
    this.userInfo.roleId === 6 ? this.tooltipCatForm.disable() : null;
  }



  iniitializetooltipCatForm(): void {
    this.tooltipCatForm = this.fb.group({
      tooltipCatCode: ['', [Validators.required, Validators.minLength(4)]],
      tooltipCategoryName: ['', [Validators.required, Validators.minLength(4)]],
    })
    if(this.isEditMode && this.editData && this.editData[0]) {
      this.tooltipCatForm.patchValue({
        tooltipCatCode: this.editData[0].tooltipCatCode,
        tooltipCategoryName: this.editData[0].tooltipCatName
      })
    }
  }



  /*........... funcaitonality Methods........ */


  tooltipCatFormErrorHandeling(control: string, error: string): boolean {
    return this.tooltipCatForm.controls[control].hasError(error);
  }

  backToHome() {
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z_-]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  onSubmit(): void {

    const dialogRef = this.dialog.open(CommonDialogToShowNotificationComponent, {
      data: {
        type: 'save',
        modulenm: 'tooltipCat',
        apiData: this.constructPostObj(),
        deafultMsg: 'Saving Tooltip Category.'
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {
      this.backToHome();
    });
  }



  constructPostObj(): any {

    const {  tooltipCatCode, tooltipCategoryName} = this.tooltipCatForm.value;
    let postObj  = {
      tooltipCatCode: tooltipCatCode,
      tooltipCatName: tooltipCategoryName
    };

    return postObj;
  }

  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
  }

}
