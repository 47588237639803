import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpApiAppConfig } from '../api/config/interface/ihttp-api-app-config';
import { HttpApiHelperService } from '../api/helper/http-api-helper.service';
import { IHttpApiRequest } from '../api/interface/ihttp-api-request';
import { HttpApiConfigService } from '../api/config/http-api-config.service';
import { IHttpCacheData } from './interface/ihttp-cache-data';
import { CacheConstant } from './cache-constant';

@Injectable()
export class HttpCacheService {
  constructor(
    private httpApiConfigService: HttpApiConfigService,
    private httpApiHelperService: HttpApiHelperService
  ) {}
  private cache = new Map<string, IHttpCacheData>();
  private maxAge!: number;

  get(req: HttpRequest<unknown>): HttpResponse<unknown> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);
    const appConfig: IHttpApiAppConfig =
      this.httpApiConfigService.getConfiguration();
    this.maxAge =
      appConfig && appConfig.cacheMaxAge
        ? Number(appConfig.cacheMaxAge)
        : CacheConstant.CACHE_MAX_AGE;
    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - this.maxAge;

    return !isExpired ? cached.response : undefined;
  }

  put(req: HttpRequest<unknown>, response: HttpResponse<unknown>): void {
    const url = req.url;
    const entry: IHttpCacheData = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - this.maxAge;
    this.cache.forEach((expiredEntry) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  /**
   * @name clearCacheByRequest
   * @description method to clear Cache ByRequest
   * @param {IHttpApiRequest} request
   * @returns void
   */
  clearCacheByRequest(request: IHttpApiRequest): void {
    const url = this.httpApiHelperService.getUrl(request);
    this.clearCacheByUrl(url);
  }
  /**
   * @name clearCacheByUrl
   * @description method to clear Cache ByUrl
   * @param {string} url
   * @returns void
   */
  clearCacheByUrl(url: string): void {
    const tobeClearedCacheKeys: string[] = [];
    this.cache.forEach((value: IHttpCacheData) => {
      if (value.url.split('?')[0] === url.split('?')[0]) {
        tobeClearedCacheKeys.push(value.url);
      }
    });
    this.cache.forEach((expiredEntry) => {
      if (tobeClearedCacheKeys.includes(expiredEntry.url)) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  /**
   * @name clearAllCache
   * @description method to  clear AllCache
   * @returns void
   */
  clearAllCache(): void {
    this.cache.forEach((entry) => {
      this.cache.delete(entry.url);
    });
  }
}
