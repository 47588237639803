import { Component, OnInit, Inject } from '@angular/core';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanService } from 'app/plan/services/plan.service';

@Component({
  selector: 'app-common-dailog-to-show-notification',
  templateUrl: './common-dailog-to-show-notification.html',
  styleUrls: ['./common-dailog-to-show-notification.scss']
})
export class CommonDialogToShowNotificationComponent implements OnInit {
  message;
  exitForm: boolean = false;
  isErr: boolean = false;
  isDelMode: boolean = false;
  errMsg: string = ''
  isDelConfirmed: boolean = false;
  apiResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    //private planService: PlanService,
    private globalElementsService: GlobalElementsService,
    private dialogRef: MatDialogRef<CommonDialogToShowNotificationComponent>
  ) { }

  ngOnInit() {
    
    const { type, deafultMsg, modulenm } = this.data;
    this.message = deafultMsg;
    if (modulenm == 'tooltipCat') {
      switch (type) {
        case 'save':
        case 'edit':
          this.saveTooltipCat();
          break;
        case 'delete':
          this.tooltipCatDelete();
          break;
      }
    } else if(modulenm == 'tooltip') {
      switch (type) {
        case 'save':
        case 'edit':
          this.saveTooltip();
          break;
        case 'delete':
          this.tooltipDelete();
          break;
      }
    }

  }

  saveTooltipCat() {
    //const { tooltipCatData } = this.data.apiData;
    this.globalElementsService.addUpdateTooltipCat(this.data.apiData).subscribe(res => {
      this.message = "Successfully saved Tooltip Category.";
      // this.exitForm = true;
    }, err => {
      this.message = "Error in saving Tooltip Category."
    })
  }
  saveTooltip() {
    if(this.data.apiData) {
      const createTooltip = this.globalElementsService.addUpdateTooltip(this.data.apiData).subscribe(res => {
        this.message = "Successfully saved Tooltip.";
        // this.exitForm = true;
      }, err => {
        this.message = "Error in saving Tooltip."
      })
    }
  }

  deleteTooltipCategory() {
    this.message = "Deleting tooltip category. Please wait..";
    this.globalElementsService.deleteTooltipCat(this.data.apiData).subscribe(() => {
      this.message = "Successfully deleted tooltip category.";
      this.isDelConfirmed = true;
      this.exitForm = true;
    }, err => {
      if (err.status == 476) {
        this.isErr = true;
        this.errMsg = err.error.error;
        this.message = "";
        this.isDelConfirmed = true;
      } else {
        this.isDelConfirmed = true;
        this.message = "Error in deleting Tooltip Category."
      }
     
    })
  }
  tooltipDelete() {
    this.isDelMode = true;
    this.message = "Are you sure you want to delete tooltip?";
  }
deleteTooltip() {
    this.message = "Deleting tooltip. Please wait..";
    this.globalElementsService.deleteTooltip(this.data.apiData).subscribe(() => {
      this.message = "Successfully deleted tooltip.";
      this.isDelConfirmed = true;
      this.exitForm = true;
    }, err => {
      this.isDelConfirmed = true;
      this.message = "Error in deleting Tooltip."
    })
  }
  tooltipCatDelete() {
    this.isDelMode = true;
    this.message = "Are you sure you want to delete tooltip category?";
  }

  // Quit the edit form on clicking OK
  handleOk() {
    this.exitForm ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }

}
