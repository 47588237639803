import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { IUpfrontScenarios, upfrontPlanScenario } from 'app/common/interfaces/plan';
import { ActivatedRoute, Router } from '@angular/router';
import { IApproverList, IBreadcrumb, ITeamMembers } from 'app/common/interfaces/common';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { getCompletedAndFinalizedUsers, isValidUserAccess } from 'app/common/utils/common-util';
import { FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, STATUS_LIST, STATUS_LIST_TYPES } from 'app/constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { TeamMembersDialogComponent } from '../../common/components/dialogs/team-members-dialog/team-members-dialog.component';
import { ITeamMembersReq } from './../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES, FINANCIAL_VALUES } from './../../constants/app-constants';
import { PlanService } from './../services/plan.service';
import {MultiselectAutocompleteComponent} from 'app/common/components/multiselect-autocomplete/multiselect-autocomplete.component';
import { StoreService } from '../../old/_services/_store.service';
import { SendNotificationsDialogComponent } from './../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component';


@Component({
  selector: 'app-upfront-planning',
  templateUrl: './upfront-planning.component.html',
  styleUrls: ['./upfront-planning.component.scss']
})
export class UpfrontPlanningComponent implements OnInit {
  selectedMarket = sessionStorage.getItem('market');
  @ViewChild('approveListDialog', { static: true }) approveListDialog: TemplateRef<any>;
  ufPlanApprover: FormControl = new FormControl("", Validators.required);
  paramsId;
  upfrontPlanData;
  subBrandsUpfrontData;
  scenariosData;
  totalAllocation: number = 0;
  totalPlannedROI: number = 0;
  totalPlannedEffectiveness: number = 0;
  selectedScenarios = {};
  workingYear
  isOvwBudgetEditMode = false;
  isOvwMetricsEditMode = false;
  owBudgetVal;
  owROIVal;
  owEffectivenessVal;
  ufPlanStatusList: Array<string> = STATUS_LIST;
  ufPlanStatus: string = STATUS_LIST_TYPES.DRAFT;
  USER_INFO = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');
  ufPlanEditableStatus: boolean = true;
  ufPlanApproverList: Array<IApproverList>;
  selectedUfPlanApprovers: Array<IApproverList> = [];
  isStatusConfirm: boolean = false;
  oldStatus: string;
  upfrontPlanScenarioList: Array<upfrontPlanScenario> = [];
  upfrontAllScenariosList: Array<IUpfrontScenarios> = [];
  statusUpdateRes: string = "Updating Upfront Planning status... Please Wait"; 
  planName: string = "";
  pathDetails: IBreadcrumb;
  plannedEffectiveness: number = 0;
  plannedROI: number =0;
  plannedSpend: number = 0;
  plannedEffInOverview; 
  numeratorEff: number = 0;
  denominator: number = 0;
  finalPlannedEff: number = 0;
  finalPlannedROI: number = 0;
  numeratorROI: number = 0;
  denominatorROI: number = 0;
  diffOfROI: number = 0;
  diffOfEff: number = 0;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  financialValue:string = '';
  showTooltipUpfrontPlanning:boolean = false;
  tooltipUpfrontPLanning:string;

  constructor(
    private planService: PlanService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedData: SharedDataService,
    private globalElementsService: GlobalElementsService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: StoreService,
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.getTooltipData('MasterBrand', this.selectedMarket).subscribe((res_n: any) => {
      if(res_n.data && res_n.data[0]) {
        this.tooltipUpfrontPLanning = res_n.data[0]["tooltipText"];
      }
    });
    this.route.params.subscribe(params => {
      const scenarioId = params['id'];
      this.paramsId = scenarioId && Number(scenarioId);


      const year = params['year'];
      if(year && parseInt(year)) {
        this.store.set('planningYear', parseInt(year));
        this.sharedData.setPlanningYear(parseInt(year));
        this.workingYear = parseInt(year);
        this.store.set('workingYear', parseInt(year));
        this.sharedData.setWorkingYear(parseInt(year));
      }

    });



    this.sharedData.getPlanningYear.subscribe((res: any) => {
      if (res) {
        this.workingYear = res;
        this.store.set('workingYear', parseInt(this.workingYear));
        this.sharedData.setWorkingYear(parseInt(this.workingYear));
        this.totalAllocation = 0;
        this.totalPlannedROI = 0;
        this.totalPlannedEffectiveness = 0;
        this.selectedScenarios = [];
        this.pathDetails = {
          lastNodeName: `${this.workingYear} Upfront Scenario`,
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.UPFRONT_SCENARIO
        }

        this.getUpfrontPlan();
        this.getSubBrandsData();
        this.getFinancialValueFromPortfolio();

      }
    })

  }

  /*........... functionality Methods........ */

  navigateToScenarioPlanning(brandId: number) {

    this.router.navigate([`/scenarioPlanning/${brandId}`])

  }
  updateUfPlanStatus() {
    if (this.ufPlanStatus == STATUS_LIST_TYPES.COMPLETE) {
      this.isStatusConfirm = false;
    } else {
      this.isStatusConfirm = true;
      this.updateUfPlanData()
    }

    this.dialog.open(this.approveListDialog, {
      width: '500px',
      disableClose: true
    });
  }
  
  ufApproverValid(){
    return this.selectedUfPlanApprovers.length > 0 ? true: false;
  }

  updateStatusList() {
    let approversIds: Array<any> = [];
    if (this.upfrontPlanData && this.upfrontPlanData.approversList) {
      approversIds = this.upfrontPlanData.approversList.split(',');
    }

    let isMatched = false;

    // if (this.USER_INFO.roleId <= 3 ) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }

    approversIds.forEach(id => {
      if (this.USER_INFO.id == id) {
        isMatched = true;
      }
    });

    if ((this.ufPlanStatus === STATUS_LIST_TYPES.DRAFT || this.ufPlanStatus == null) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.ufPlanStatusList = MINIFIED_STATUS_LIST;
      this.ufPlanEditableStatus = true;
    } else if ((isMatched && this.ufPlanStatus === STATUS_LIST_TYPES.COMPLETE ) && (this.returnAccess('!=', 5) || this.returnAccess('!=', 6))) {
      this.ufPlanEditableStatus = true;
      this.ufPlanStatusList = STATUS_LIST;
    } else if ((isMatched && this.ufPlanStatus === STATUS_LIST_TYPES.FINAL)&& (this.returnAccess('!=', 5)  || this.returnAccess('!=', 6))) {
      this.ufPlanEditableStatus = true;
      this.ufPlanStatusList = FINAL_STATUS_LIST;
    } else if(this.returnAccess('==', 5) || this.returnAccess('==', 6)){
      this.ufPlanEditableStatus = false;
      this.ufPlanStatusList = STATUS_LIST;
    }
  }

  updateUfPlanData() {
    const upfrontData = this.constructUpfrontPostObj('budget');
    this.planService.updateUpfrontOverview(upfrontData).subscribe(() => {
      this.isStatusConfirm = true;
      this.getUpfrontPlan();
    }, (error) => {
      this.isStatusConfirm = true;
      this.statusUpdateRes = error.message;
    });
  }

  closeDialog() {
    this.getUpfrontPlan();
    this.dialog.closeAll();
    this.isStatusConfirm = false;
  }

  setStatusUpdateRes(oldStatus: string, currentStatus: string) {
    if (oldStatus && currentStatus) this.statusUpdateRes = "";

    if (oldStatus == STATUS_LIST_TYPES.DRAFT && currentStatus == STATUS_LIST_TYPES.COMPLETE) {
      this.statusUpdateRes = `Upfront allocation for ${this.planName} ${this.workingYear} has been submitted for review.`;
    } else if (oldStatus == STATUS_LIST_TYPES.COMPLETE && currentStatus == STATUS_LIST_TYPES.FINAL) {
      this.statusUpdateRes = `Upfront allocation for ${this.planName} ${this.workingYear} has been approved.`;
    } else if (oldStatus == STATUS_LIST_TYPES.COMPLETE && currentStatus == STATUS_LIST_TYPES.DRAFT) {
      this.statusUpdateRes = `Upfront allocation for ${this.planName} ${this.workingYear} has been reverted back to draft.`;
    } else if (oldStatus == STATUS_LIST_TYPES.FINAL && currentStatus == STATUS_LIST_TYPES.REPLAN) {
      this.statusUpdateRes = `Upfront allocation for ${this.planName} ${this.workingYear} has been reopened for updates.`;
    }
  }

  isMasterBrand(subBrand) {
    const brandName = subBrand.planEntity.displayName;
    return brandName.toLowerCase().includes('masterbrand');
  }

  calculateSpendBudget(brand, scenario,index) {
    let totalBudgetAllocation = 0;
    let numeratorROI = 0;
    
      brand.selectedItems.every(el => {
        if(el == 'NA' && brand.previousSelection.find(el => el != 'NA')){
          brand.selectedItems = [];
          brand.selectedItems.push('NA');
          return false
        } 
        else if(el == 'NA' && brand.previousSelection.find(el => el == 'NA')) {
          brand.selectedItems = brand.selectedItems.filter(el => el != 'NA');
        }
        totalBudgetAllocation += Number(el.aWMBudget ? el.aWMBudget : 0);
        numeratorROI += ((el.aWMBudget ? el.aWMBudget : 0) * (el.plannedROI ? el.plannedROI : 0));
        return true; 
      })
      brand.previousSelection = brand.selectedItems;
    
    this.subBrandsUpfrontData[index].selectedSubBrandBudget = totalBudgetAllocation;
    let plannedROI = 0;
    plannedROI = numeratorROI > 0 && totalBudgetAllocation > 0 ? numeratorROI / totalBudgetAllocation : 0;
    this.subBrandsUpfrontData[index].selectedSubBrandROI = plannedROI;

  if (brand.selectedItems == 'NA') {
    delete this.selectedScenarios[brand.planEntity.id];

  } else {
    this.selectedScenarios[brand.planEntity.id] = brand.selectedItems;
    console.log(this.selectedScenarios);

    this.upfrontPlanScenarioList.forEach(ufPlanItem => {
      if (ufPlanItem.upfrontPlanId == brand.ufPlanId) {
        ufPlanItem.scenarioId = scenario.scenarioId;
      }
    })

  }
  this.calculateTotalAllocation();
}



  calculateTotalAllocation() {
    this.totalAllocation = 0;
    this.totalPlannedEffectiveness = 0;
    this.totalPlannedROI = 0;
    this.numeratorROI = 0;
    this.numeratorEff = 0;
    Object.values(this.selectedScenarios).forEach((ss: any) => {
      ss.forEach(objectVal => {
        this.totalAllocation += Number(objectVal.aWMBudget);
      })
    });

    Object.values(this.selectedScenarios).forEach((ss: any) => {
      ss.forEach(objectVal => {
          this.numeratorROI += (objectVal.aWMBudget  * objectVal.plannedROI)
          this.numeratorEff += (objectVal.aWMBudget  * objectVal.plannedEffectiveness)
       })
      });
    this.totalPlannedROI = this.numeratorROI / this.totalAllocation
    this.totalPlannedEffectiveness = this.numeratorEff / this.totalAllocation

    //calculating difference of ROI and Effectiveness 

    this.diffOfROI = (this.totalPlannedROI - this.owROIVal) / this.owROIVal ;
    this.diffOfEff = (this.totalPlannedEffectiveness - this.owEffectivenessVal) / this.owEffectivenessVal;

    if (!isFinite(this.diffOfROI)) {
      this.diffOfROI = null;
    }
    if (!isFinite(this.diffOfEff)) {

      this.diffOfEff = null;
    }
  }

  ufPlanApproverChange(apprvData: Array<IApproverList>) {
    this.selectedUfPlanApprovers = apprvData;
  }

  /*........... Service/API calls........ */

  getUpfrontPlan() {

    const postObj = {
      planId: this.paramsId,
      year: this.workingYear
    }
    this.planService.getPortfolioInfo(postObj).subscribe((res: any) => {
      this.setStatusUpdateRes(this.oldStatus, this.ufPlanStatus);
      if(!res.upfrontData[0]) {
        res.upfrontData[0] = {}
      }
      this.upfrontPlanData = res.upfrontData[0];
      this.selectedTeamMembers = this.upfrontPlanData && this.upfrontPlanData.teamMembers ? this.upfrontPlanData.teamMembers.split(',').map(el => Number(el)) : [];

      this.ufPlanStatus = res.upfrontData[0].ufAllocationStatus || STATUS_LIST_TYPES.DRAFT;
      this.oldStatus = res.upfrontData[0].ufAllocationStatus || null;
      this.owBudgetVal = this.upfrontPlanData.aWMBudget ? parseFloat(this.upfrontPlanData.aWMBudget).toLocaleString() : null;
      this.owROIVal = this.upfrontPlanData.aTROI ? parseFloat(this.upfrontPlanData.aTROI).toLocaleString(): null;
      this.owEffectivenessVal = this.upfrontPlanData.aTEffectiveness ? parseFloat(this.upfrontPlanData.aTEffectiveness).toLocaleString() : null;
      this.planName = res.planEnrty && res.planEnrty.displayName || '';
      this.getApproversList();
      this.getTeamMembersList();
      this.getAllScenarios();
    }, (error) => {
      this.statusUpdateRes = error.message;
    })
  }
  
 getFinancialValueFromPortfolio(){
    const postObj = {
      planId: this.paramsId,
      year: this.workingYear
    }
    this.planService.getFinancialValuePortfolio(postObj).subscribe(response => {
      const fValue = response['portfolioData']['financialValue'];
      this.financialValue = FINANCIAL_VALUES[fValue];
    })
  }

  addTeamMembers() {

    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
        teamMembers: this.teamMembersList,
        selectedMembers: this.selectedTeamMembers
      },
      height: '400px',
      width: '500px',
      disableClose:true
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
      this.updateTeamMembers()
      this.generateTeamMemeberAvatars();

    });
  }

  generateTeamMemeberAvatars() {
    if(this.teamMembersList){
      const selectedUsers = this.teamMembersList.filter(tm => this.selectedTeamMembers.includes(tm.userId));
      this.selectedTeamMembersAvatar = selectedUsers.map(user => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        }
  
        return {
          initials,
          avatar
        }
      })
    }
  }

  sendMessageToCollaborators(){
    let data = this.constructUpfrontPostObj('budget');
    let snDialogRef;
      snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
        data: {
          data :data,
          type:FORM_REFERENCE_TYPES.UPFRONT_PLANNER,
          },
        height: '150px',
        width: '500px',
        disableClose:true
      });

    snDialogRef.afterClosed().subscribe((res: any) => {
    });
    
  }


  getTeamMembersList() {
    let teamMemberReq: ITeamMembersReq = {
      referenceId: this.paramsId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: this.workingYear
    }

    this.globalElementsService.getTeamMembersList(teamMemberReq).subscribe((res: Array<ITeamMembers>) => {
      this.teamMembersList = res ? res.sort((a, b)=> a.name >= b.name ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.teamMembersList){
        this.teamMembersList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.userId);
          if (!isDuplicate) {
            uniqueIds.push(el.userId);
        
            return true;
          }
          return false;
        })
      }
      this.generateTeamMemeberAvatars(); 
    })

  }

  getApproversList() {
    let obj = {
      referenceId: this.paramsId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: this.workingYear
    }
    this.globalElementsService.getApproversList(obj).subscribe((res: Array<IApproverList>) => {
      this.ufPlanApproverList = res;
      this.updateStatusList();
      const approvers = this.upfrontPlanData.approversList ? this.upfrontPlanData.approversList.split(',') : [];
      this.selectedUfPlanApprovers = [];
      approvers.forEach(id => {
        const ufPlanApprover = this.ufPlanApproverList.find((el: any) => el.userId == id);
        if (ufPlanApprover) {
          this.selectedUfPlanApprovers.push(ufPlanApprover);
        } else {
          this.selectedUfPlanApprovers = [];
        }
      });
      this.ufPlanApprover.setValue(this.selectedUfPlanApprovers);
    })
  }

  updateOverviewBudget() {
    if (this.isOvwBudgetEditMode) {
      const upfrontData = this.constructUpfrontPostObj('budget');
      this.planService.updateUpfrontOverview(upfrontData).subscribe(() => this.getUpfrontPlan());
    }
    this.isOvwBudgetEditMode = !this.isOvwBudgetEditMode;
  }
  sendData(){
    return this.ufPlanApproverList;
  }
  
  selectChange = (event: any) => {
    this.selectedUfPlanApprovers = event.data;
  };

  updateOverviewMetrics() {

    if (this.isOvwMetricsEditMode) {
      const upfrontData = this.constructUpfrontPostObj('');
      this.planService.updateUpfrontOverview(upfrontData).subscribe(() => this.getUpfrontPlan());
    }
    this.isOvwMetricsEditMode = !this.isOvwMetricsEditMode;
  }


  updateTeamMembers() {
    const upfrontData = this.constructUpfrontPostObj('');
    this.planService.updateUpfrontOverview(upfrontData).subscribe(() => this.getUpfrontPlan());
  }

  getSubBrandsData() {

    const postObj = {
      planId: this.paramsId,
      year: this.workingYear
    }
    this.planService.getSubBrandsScenarioData(postObj).subscribe((res: any) => {
      this.subBrandsUpfrontData = res.upfrontallocationplandata || [];
      this.subBrandsUpfrontData.map((el,index) => {
        if(el.planEntity.name.includes('Masterbrand')){
          const itemToFound = el;
          const foundIdx  = index;
          this.subBrandsUpfrontData.splice(foundIdx,1);
          this.subBrandsUpfrontData.unshift(itemToFound);
        }
      })
      this.upfrontPlanScenarioList = [];
      this.subBrandsUpfrontData.forEach((item,index) => {
        let planObj = {
          upfrontPlanId: item.ufPlanId,
          scenarioId: null
        }
        this.upfrontPlanScenarioList.push(planObj);
        this.subBrandsUpfrontData[index].selectedItems = [];
        this.subBrandsUpfrontData[index].selectedSubBrandBudget = 0;
        this.subBrandsUpfrontData[index].selectedSubBrandROI = 0;
    
        if(this.subBrandsUpfrontData[index].planEntity.displayName.length > 50){
          var shortName = this.subBrandsUpfrontData[index].planEntity.displayName.slice(0,50).concat('...');
          this.subBrandsUpfrontData[index].planEntity.displayName = shortName;
        }
      })
      this.subBrandsUpfrontData = this.subBrandsUpfrontData.filter(data=> data && data.planEntity && data.planEntity.isDeleted != true )
    })
  }

//get All Scnearios based on a plan  for upfront scenarios. 

  getAllScenarios() {

    const obj = {
      planId: this.paramsId,
      year: this.workingYear
    }
    this.planService.getScenariosForPlan(obj).subscribe((res: IUpfrontScenarios) => {

      this.scenariosData = res || [];
      this.scenariosData.forEach(item => {
        let scenariosObj = {
          plannedSpend: item.plannedSpend,
          plannedROI: item.plannedROI,
          plannedEffectiveness: item.plannedEffectiveness,
          scenarioName: item.scenarioName
        }

        this.upfrontAllScenariosList.push(scenariosObj);

      })

      this.diffOfROI = (this.totalPlannedROI - this.owROIVal) / this.owROIVal;
      this.diffOfEff = (this.totalPlannedEffectiveness - this.owEffectivenessVal) / this.owEffectivenessVal;

      if (!isFinite(this.diffOfROI)) {
        this.diffOfROI = null;
      }
      if (!isFinite(this.diffOfEff)) {
  
        this.diffOfEff = null;
      }
  
    })

  
  }


  constructUpfrontPostObj(type: string) {
    const isBudgetUpdate = type == 'budget';
    const approversIds = this.selectedUfPlanApprovers && this.selectedUfPlanApprovers.map((obj: IApproverList) => obj.userId);
    const { completedByUserId, finalizedByUserID } = getCompletedAndFinalizedUsers(this.oldStatus, this.ufPlanStatus, this.upfrontPlanData);
    const upFrontData = {
      year: this.workingYear,
      ufAllocationId: this.upfrontPlanData.ufAllocationId,
      planId: this.upfrontPlanData.planId,
      ufAllocationStatus: this.ufPlanStatus,
      alertURL: `/upfrontPlanning/${this.upfrontPlanData.planId}/${this.workingYear}`,
      aWMBudget: isBudgetUpdate ? (this.owBudgetVal == null) ? null : parseFloat(this.owBudgetVal.toString().replace(/,/g, '')) : (this.upfrontPlanData.aWMBudget == null) ? null : parseFloat(this.upfrontPlanData.aWMBudget.toString().replace(/,/g, '')),
      aTROI: isBudgetUpdate ? this.upfrontPlanData.aTROI : (this.owROIVal == null) ? null : parseFloat(this.owROIVal.toString().replace(/,/g, '')),
      aTEffectiveness: isBudgetUpdate ? this.upfrontPlanData.aTEffectiveness : (this.owEffectivenessVal == null) ? null : parseFloat(this.owEffectivenessVal.toString().replace(/,/g, '')),
      // aWMBudget: isBudgetUpdate ? parseInt(this.owBudgetVal.toString().replace(/,/g, '')) : parseInt(this.upfrontPlanData.aWMBudget.toString().replace(/,/g, '')),
      
      teamMembers: this.selectedTeamMembers.toString(),
      completedByUserId: completedByUserId,
      finalizedByUserID: finalizedByUserID,
      approversList: approversIds ? approversIds.toString() : '',
      upfrontPlanScenarioList: this.upfrontPlanScenarioList,
      currentUserID: this.USER_INFO.id
    }
    return upFrontData;

  }
  CalculateROI(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owROIVal = a.join(".");
    }
  }
  CalculateEffectiveness(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owEffectivenessVal = a.join(".");
    }
  }
  CalculateBudget(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owBudgetVal = a.join(".");
    }
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator, null, this.paramsId);
  }
}
