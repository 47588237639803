import { MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from "@angular/core";
import { GlobalElementsService } from "../../../../global-elements/services/global-elements.service";

@Component({
  selector: "app-user-profile-dialog",
  templateUrl: "./user-profile-dialog.component.html",
  styleUrls: ["./user-profile-dialog.component.scss"],
})
export class UserProfileDialogComponent implements OnInit {
  message: string = "Saving User Information... Please Wait";
  exitUserProfileForm: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService
  ) {}

  ngOnInit() {
    this.saveUserProfile();
  }

  saveUserProfile() {
    this.globalElementsService.saveUserProfile(this.data).subscribe(
      (data: any) => {
        this.message = "Successfully saved user profile";
        this.exitUserProfileForm = true;
      },
      () => {
        this.message = "Error creating User.";
      }
    );
  }

  // Quit the edit form on clicking OK
  handleOk() {
    this.exitUserProfileForm
      ? this.globalElementsService.setSidePanelCancelEvent()
      : null;
  }
}
