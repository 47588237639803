import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ProgramService2 } from "../services/program.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { getGpId } from "app/common/utils/common-util";
import { DASHBOARD_MESSAGE } from "app/constants/app-constants";
import { environment } from "environments/environment";
import { googleAnalyticsCode } from "app/util";

@Component({
  selector: "app-datorama-dashboard",
  templateUrl: "./datorama-dashboard.component.html",
  styleUrls: ["./datorama-dashboard.component.scss"],
})
export class DatoramaDashboardComponent implements OnInit {
  @Input() elementDetails;

  rtoIFrame;
  planName: string;
  isLoading: boolean = false;
  message: string;

  constructor(
    private sanitizer: DomSanitizer,
    private programService: ProgramService2,
    protected googleTagManagerService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getDashboardUrl();
    if (this.elementDetails.datoramaData) {
      this.planName = this.elementDetails.datoramaData.planName;
    }
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Datorama_Dashboard`,
      eventAction: "PageView",
      eventLable: `RTO Dashboard Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  getDashboardUrl() {
    const urlObj = { ...this.elementDetails.datoramaData };
    this.programService.getDashboardUrl(urlObj).subscribe(
      (res) => {
        if (res["rtoIFrame"]) {
          this.rtoIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(
            res["rtoIFrame"]
          );
        }
        this.message = DASHBOARD_MESSAGE.DATORAMA_SUCCESS.replace(
          "##PLANNAME##",
          this.planName
        );
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.message = DASHBOARD_MESSAGE.ERROR;
      }
    );
  }

  ngOnDestroy(): void {
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Datorama_Dashboard`,
      eventAction: "PageExit",
      eventLable: `RTO Dashboard Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
}
