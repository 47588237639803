import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';

@Component({
  selector: 'app-show-audit-history-dialog',
  templateUrl: './show-audit-history-dialog.component.html',
  styleUrls: ['./show-audit-history-dialog.component.scss']
})
export class ShowAuditHistoryDialogComponent implements OnInit {
  displayedColumns: string[] = ['userId','action','createdAt','updatedAt'];
  dataSource = new MatTableDataSource();
  isLoading:boolean = true;
  isLoadingMessage:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,) { }

  ngOnInit() {
    let elementId = this.data;
    this.isLoadingMessage = "Loading data please wait...";
    this.globalElementsService.getAuditHistoryData(elementId).subscribe(res => {
      if(res['data'] && res['data'].length > 0){
        this.dataSource = res['data'] ? res['data'] : [];
        this.isLoading = false;
      } else {
        this.isLoadingMessage = "No audit details found";
      }
    })
  }

}
