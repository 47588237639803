import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatTable } from "@angular/material/table";
import { DomSanitizer } from "@angular/platform-browser";
import { CancelDialogComponent } from "app/common/components/dialogs/cancel-dialog/cancel-dialog.component";
import {
  IApproverList,
  IDocument,
  ITeamMembersReq,
} from "app/common/interfaces/common";
import { SharedDataService } from "app/common/services/shared-data.service";
import {
  getCompletedAndFinalizedUsers,
  getDocsFromSharepointFilesAPI,
  isValidUserAccess,
} from "app/common/utils/common-util";
import {
  FINAL_STATUS_LIST,
  FORM_REFERENCE_TYPES,
  MINIFIED_STATUS_LIST,
  STATUS_LIST,
  STATUS_LIST_TYPES,
} from "app/constants/app-constants";
import { GlobalElementsService } from "app/global-elements/services/global-elements.service";
import { PlanService } from "app/plan/services/plan.service";
import {
  MatDialog,
  MatTableDataSource,
} from "../../../../node_modules/@angular/material";
import { ActivatedRoute } from "../../../../node_modules/@angular/router";
import { ICommsTask, ICommsTaskChannel } from "../../common/interfaces/program";
import { ScenarioPlanningDialogComponent } from "./../../common/components/dialogs/scenario-planning-dialog/scenario-planning-dialog.component";
import { TeamMembersDialogComponent } from "./../../common/components/dialogs/team-members-dialog/team-members-dialog.component";
import { IBreadcrumb, ITeamMembers } from "./../../common/interfaces/common";
import { IPlanBase } from "./../../common/interfaces/plan";
import {
  BREADCRUMBS_LAST_NODE_TYPES,
  BREADCRUMBS_REFERENCE_TYPES,
  MEDIA_CHANNEL_REFERENCES,
} from "./../../constants/app-constants";
import { ProgramService2 } from "./../services/program.service";
import { SHAREPOINT_DOC_PATH } from "app/constants/app-constants";
import { FileNotFoundComponent } from "app/common/components/dialogs/file-not-found-dialog/file-not-found/file-not-found.component";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
import { CHANNEL_COLORS } from "app/constants/overview-constants";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MarketMultiselectComponent } from "../../../app/common/components/market-multiselect/market-multiselect.component";

import { manageCustomAudienceDialog } from "./../../common/components/dialogs/manage-custom-audience-dialog/manage-custom-audience-dialog.component";
import { SendNotificationsDialogComponent } from "./../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component";
const KPIS = [
  "Spend Pacing",
  "Cost per Viewable Reach (CPvM)",
  "Cost per 1,000 Impressions (CPM)",
  "Maximum imps/spend at 10+/month frequency",
];
import { saveAs } from "file-saver";
import { fileMimeTypes } from "app/constants/app-constants";
import ab2b64 from "ab2b64";

@Component({
  selector: "app-scenario-planning-ct",
  templateUrl: "./scenario-planning-ct.component.html",
  styleUrls: ["./scenario-planning-ct.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ScenarioPlanningCtComponent implements OnInit {
  @ViewChild("logoUploadDialog", { static: true })
  logoUploadDialog: TemplateRef<any>;
  @ViewChild("approveListDialog", { static: true })
  approveListDialog: TemplateRef<any>;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  selectedMarketTooltip = sessionStorage.getItem("market");
  commsApprover: FormControl = new FormControl("", Validators.required);
  page: number = 1;
  totalRecords: any;
  dataSource;
  anotherdataSource;
  scenarioId;
  planningYear: number;
  scenarioChannelData = [];
  displayedColumns: string[] = [
    "action",
    "touchPointName",
    "subBrands",
    "keyInterception",
    "audience",
    "cWMBudget",
    "weeksOnAir",
    "startDt",
    "endDt",
    "optimizationKPIs",
    "touchPointContent",
  ];
  displayedColumnsForCommsTask: string[] = [
    "info",
    "CommsTaskName",
    "Total CommsTaskBudget",
  ];
  selectedMarket: Array<string> = [];
  selectedMarketNames: Array<string> = [];
  selectedYears: Array<string> = [];
  paramsId: number;
  commsTaskName = "";
  plannedBudget: number = 0;
  plannedROI: number = 0;
  mjtbd;
  assets: any = [];
  masterassets;
  selectedLogoImg = {};
  audienceList = [];
  optyKPIsList = [];
  commsTaskOverview;
  isOvwBudgetEditMode = false;
  isOvwMetricsEditMode = false;
  owBudgetVal;
  owROIVal;
  owEffectivenessVal;
  plannedEffectiveness: number = 0;
  selectedScenarioIdx = null;
  expandedElement = null;
  commsStatusList: Array<string> = STATUS_LIST;
  channelColors: Array<string> = CHANNEL_COLORS;
  commsStatus: string = STATUS_LIST_TYPES.DRAFT;
  userInfo = JSON.parse(sessionStorage.getItem("user"));
  attach_money = sessionStorage.getItem("currencySign");
  commsEditableStatus: boolean = true;
  commsApproverList: Array<IApproverList>;
  selectedCommsApprovers: Array<IApproverList> = [];
  oldStatus: string;
  planId: number;
  statusUpdateRes: string = "Updating Comms Task status... Please Wait!!";
  mjtbdName: string = "";
  isStatusConfirm: boolean = false;
  pathDetails: IBreadcrumb;
  channelsForm: FormGroup;
  currentSelectedAudience: Array<string>;
  allSelectedAudienceList: Array<string> = [];
  outofScopeAudience: Array<string> = [];
  selectedOptyKpis;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  marketId: number;
  subBrandsList: Array<IPlanBase> = [];
  channelsList: Array<string> = [];
  campaignName = "";
  totalBudget: number;
  commsTasksNamesData: any;
  data: any;
  marketChangeSubscription: Subscription;
  fullName = "";
  marketName: string;
  planName: string;
  hierarchy: string;
  cDNAFiles;
  ideaFormDocList: Array<IDocument> = [];
  requiredDate: string;
  recommendedDate: string;
  isDateVisible: boolean = false;
  isDatePanelVisible: boolean = false;
  channelsListWithFlag: any[];
  selectedChannels: any;
  opportunityBriefStartDate: any;
  channelNameSub: Subscription;
  startDtSub: Subscription;
  markets: any = [];
  years: any = [];
  name: any;
  tempMarkets: any = [];
  search_txt: any;
  customAudienceList: any = [];
  MJTBDCustomAudienceList: any = [];
  totalassetCount: number;
  tooltipTitleCommsTask: string;
  showtooltipTitleCommsTask: boolean = false;
  constructor(
    private sharedData: SharedDataService,
    private prgmService: ProgramService2,
    private planService: PlanService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public logoDialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private programService: ProgramService2,
    private sanitizer: DomSanitizer
  ) {}

  /*........... Initialiszation Methods........ */

  ngOnInit() {
    this.optyKPIsList = [...KPIS];
    this.initiateChannelsForm();

    this.globalElementsService
      .getTooltipData("CampaignOverview", this.selectedMarketTooltip)
      .subscribe((res_n: any) => {
        if (res_n.data && res_n.data[0]) {
          this.tooltipTitleCommsTask = res_n.data[0]["tooltipText"];
        }
      });
    this.route.params.subscribe((params) => {
      this.paramsId = params["id"];
      if (this.planningYear) {
        this.getCommsTaskDetails();
      }
      this.getCampaignName(this.paramsId);
    });

    this.channelsForm.valueChanges.subscribe(
      ({ audience, optimizationKPIs }) => {
        this.currentSelectedAudience = audience;
        this.allSelectedAudienceList =
          _.uniq(
            _.union(this.outofScopeAudience, this.currentSelectedAudience)
          ) || [];
        this.selectedOptyKpis = optimizationKPIs;
      }
    );

    this.sharedData.sideNavSelection.subscribe((res: any) => {
      if (res && res.id == 0) {
        this.getCommsTaskDetails();
      }
    });

    this.marketChangeSubscription = this.sharedData.getMarket.subscribe(
      (mktId) => {
        this.marketId = Number(mktId);
        this.getChannels();
      }
    );

    this.sharedData.getPlanningYear.subscribe((res: number) => {
      console.log("spc page planningYear", res);
      if (this.planningYear != res) {
        this.planningYear = res;
        this.getCommsTaskDetails();
      }
    });

    this.channelNameSub = this.channelsForm
      .get("channelName")
      .valueChanges.subscribe((value) => {
        this.selectedChannels = this.channelsListWithFlag.find(
          (val) => val.channelName === value
        );
        this.setCreativeXVisibility();
      });
    this.startDtSub = this.channelsForm
      .get("startDt")
      .valueChanges.subscribe((value) => {
        this.setCreativeXDates();
        this.setCreativeXVisibility();
      });
    this.getHierarchy();
    this.getAllMarkets();
    this.getAllYears();
    this.getChannels();
    this.getSubBrands();
  }
  showTooltip() {
    this.showtooltipTitleCommsTask = true;
  }
  hideTooltip() {
    this.showtooltipTitleCommsTask = false;
  }
  openManageCustomAudiencePopup() {
    let manageAudienceCustomDailog;

    manageAudienceCustomDailog = this.dialog.open(manageCustomAudienceDialog, {
      data: {
        planId: this.planId,
        refrenceId:
          this.expandedElement && this.expandedElement.channelId
            ? this.expandedElement.channelId
            : null,
        type: "CommsTask",
      },
      height: "500px",
      minWidth: "500px",
    });
    manageAudienceCustomDailog.afterClosed().subscribe((res: any) => {
      this.getCustomAudienceList();
    });
  }
  getCustomAudienceList() {
    const postObj = {
      planId: this.planId,
      type: "CommsTask",
    };
    this.globalElementsService
      .customAudienceGetAll(postObj)
      .subscribe((res: Array<string>) => {
        if (this.customAudienceList.length > 0) {
          const _diffrentList =
            _.difference(
              this.customAudienceList.map((el) => el.customAudienceName),
              res["data"].map((el) => el.customAudienceName)
            ) || [];
          this.currentSelectedAudience = _.without(
            this.currentSelectedAudience,
            _diffrentList.join(",")
          );
        }
        this.customAudienceList = res["data"] ? res["data"] : [];
        if (
          this.currentSelectedAudience &&
          this.currentSelectedAudience.length > 0
        ) {
          this.outofScopeAudience =
            _.difference(
              this.currentSelectedAudience,
              _.union(
                this.audienceList,
                this.customAudienceList
                  ? this.customAudienceList.map((el) => el.customAudienceName)
                  : []
              )
            ) || [];
          this.currentSelectedAudience =
            _.difference(
              this.currentSelectedAudience,
              this.outofScopeAudience
            ) || [];
          this.allSelectedAudienceList =
            _.uniq(
              _.union(this.outofScopeAudience, this.currentSelectedAudience)
            ) || [];
        }
      });
  }
  getMJTBDCustomAudienceList() {
    const postObj = {
      planId: this.planId,
      type: "MJTBD",
    };
    this.globalElementsService
      .customAudienceGetAll(postObj)
      .subscribe((res: Array<string>) => {
        this.MJTBDCustomAudienceList =
          (res["data"] && res["data"].map((el) => el.customAudienceName)) || [];
      });
  }
  getAllMarkets() {
    this.programService.getAllMarkets().subscribe((response) => {
      if (response["markets"]) {
        this.markets = response["markets"];
        this.tempMarkets = response["markets"];
      } else {
        this.markets = [];
      }
    });
  }
  getAllYears() {
    this.programService.getAllYears().subscribe((response) => {
      if (response["years"]) {
        this.years = response["years"];
      } else {
        this.years = [];
      }
    });
  }
  patchCommsTask(_audience) {
    this.currentSelectedAudience = _audience ? _audience.split(",") : [];
    if (
      this.currentSelectedAudience &&
      this.currentSelectedAudience.length > 0
    ) {
      this.outofScopeAudience =
        _.difference(
          this.currentSelectedAudience,
          _.union(
            this.audienceList,
            this.customAudienceList
              ? this.customAudienceList.map((el) => el.customAudienceName)
              : []
          )
        ) || [];
      this.currentSelectedAudience =
        _.difference(this.currentSelectedAudience, this.outofScopeAudience) ||
        [];
      this.allSelectedAudienceList =
        _.uniq(
          _.union(this.outofScopeAudience, this.currentSelectedAudience)
        ) || [];
    }
    this.channelsForm
      .get("audience")
      .patchValue(
        this.currentSelectedAudience && this.currentSelectedAudience.length > 0
          ? this.currentSelectedAudience
          : []
      );
  }
  deleteOutOfScopeAudience(audience) {
    this.outofScopeAudience = _.without(this.outofScopeAudience, audience);
    this.allSelectedAudienceList =
      _.uniq(_.union(this.outofScopeAudience, this.currentSelectedAudience)) ||
      [];
  }
  getCampaignName(commsTaskId) {
    this.programService.getCampaignName(commsTaskId).subscribe((response) => {
      this.campaignName = response["campaignData"]["campaignName"];
      // this.getAllCommsTaskIds(response['campaignData']['campaignId']);

      this.data = {
        campaignId: response["campaignData"]["campaignId"],
        year: this.planningYear,
      };
      this.getTotalCommsTaskBudget(this.data);
      this.getTotalCommsTaskUnallocatedBudget(this.data);
    });
  }

  getTotalCommsTaskBudget(data) {
    this.programService.getTotalCommsTasksBudget(data).subscribe((response) => {
      this.commsTasksNamesData = response["results"];
      this.anotherdataSource = new MatTableDataSource<any>(
        this.commsTasksNamesData
      );
      var chart = am4core.create("chartdiv", am4charts.PieChart);
      chart.data = this.anotherdataSource.filteredData;

      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "pWMBudget";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.tooltipText = `{category} : ${this.attach_money}{value} ({value.percent.formatNumber('#.')}%)`;
      pieSeries.innerRadius = am4core.percent(30);
      chart.numberFormatter.numberFormat = "#,###";
      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;
      pieSeries.tooltip.disabled = false;

      let colorArry = this.channelColors.map((el) => am4core.color(el));
      pieSeries.colors.list = [...colorArry];
    });
  }

  getTotalCommsTaskUnallocatedBudget(data) {
    this.programService.getUnallocatedBudget(data).subscribe((response) => {
      let channels = response["scenarioData"]["channels"];
      var merged = [].concat.apply([], channels);
      let finalArray = merged.map((element) => element.cWMBudget);
      var numberArray = [];
      for (var i = 0; i < finalArray.length; i++) {
        numberArray.push(parseInt(finalArray[i]));
      }
      let temp = [];

      for (let i of numberArray) i && temp.push(i); // copy each non-empty value to the 'temp' array

      numberArray = temp;
      let total = 0;
      for (let i = 0; i < numberArray.length; i++) {
        total += numberArray[i];
      }
      // console.log(total);
      this.totalBudget = total;
    });
  }

  async getAllFilesFromSharepoint() {
    let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CDNA}/${this.planningYear}/${this.marketName}`;
    this.cDNAFiles = await getDocsFromSharepointFilesAPI(
      path,
      this.globalElementsService
    );
  }

  initiateChannelsForm() {
    this.channelsForm = this.fb.group({
      channelName: ["", Validators.required],
      touchPointName: [""],
      keyInterception: [""],
      audience: [],
      optimizationKPIs: [""],
      cWMBudget: [""],
      startDt: [""],
      endDt: [""],
      touchPointContent: "",
      subBrand: [""],
      isPreFlightTestCompleted: [false],
      channelId: [],
      campaign: [],
    });
    this.selectedLogoImg = null;
  }

  updateChannelsForm() {
    const {
      audience,
      cWMBudget,
      channelName,
      endDt,
      keyInterception,
      optimizationKPIs,
      startDt,
      touchPointContent,
      touchPointName,
      channelId,
      subBrandId,
      isPreFlightTestCompleted,
    } = this.expandedElement;
    this.patchCommsTask(audience);
    this.channelsForm.patchValue({
      touchPointName: touchPointName,
      keyInterception: keyInterception,
      optimizationKPIs: (optimizationKPIs && optimizationKPIs.split(",")) || [],
      cWMBudget: Number(cWMBudget).toLocaleString(),
      startDt: startDt,
      endDt: endDt,
      touchPointContent: touchPointContent,
      subBrand: subBrandId,
      channelName: channelName,
      isPreFlightTestCompleted: isPreFlightTestCompleted,
      channelId,
    });
    this.selectedLogoImg = touchPointContent;
    this.setCreativeXVisibility();
  }

  ApplyFilter(event, type) {
    this.page = 1;
    if (type == "MARKET") {
      this.selectedMarket = event.data.map((el) => {
        return el.mdmMktCode;
      });
      this.selectedMarketNames = event.data.map((el) => {
        return el.mdmMktName;
      });
    } else {
      this.selectedYears = event.value.map((el) => {
        return el;
      });
    }

    this.assets = [];
    if (this.selectedMarket.length > 0 && this.selectedYears.length == 0) {
      this.selectedMarket.forEach((x) => {
        this.assets = [
          ...this.assets,
          ...this.masterassets.filter((el) => {
            if (el.country && el.country != null) {
              if (!this.assets.includes(el)) {
                return el.country.indexOf(x) > -1;
              }
            }
          }),
        ];
        this.totalRecords = this.assets.length;
      });
      this.Search();
    } else if (
      this.selectedYears.length > 0 &&
      this.selectedMarket.length == 0
    ) {
      this.selectedYears.forEach((x) => {
        this.assets = [
          ...this.assets,
          ...this.masterassets.filter((el) => {
            if (el.year == x.toString()) {
              if (!this.assets.includes(el)) {
                return true;
              }
            }
            return false;
          }),
        ];
        this.totalRecords = this.assets.length;
      });
      this.Search();
    } else if (
      this.selectedMarket.length > 0 &&
      this.selectedYears.length > 0
    ) {
      this.selectedMarket.forEach((x) => {
        this.selectedYears.forEach((y) => {
          let filterResult = this.masterassets.filter((el) => {
            if (el.country && el.country != null) {
              if (el.country.indexOf(x) > -1 && el.year == y.toString()) {
                if (!this.assets.includes(el)) {
                  return true;
                }
              }
            }
            return false;
          });
          this.assets = [...this.assets, ...filterResult];
          this.totalRecords = this.assets.length;
        });
      });
      this.Search();
    } else {
      let planId = this.planId;
      const postObj = {
        planId: planId,
      };
      this.assets = this.masterassets;
      if (this.assets) {
        this.totalRecords = this.assets.length;
      }
      this.selectedMarket = [];
      this.selectedYears = [];
      this.name = "";
    }
  }

  Search() {
    this.page = 1;
    if (
      this.name == "" &&
      this.selectedMarket.length > 0 &&
      this.selectedYears.length == 0
    ) {
      this.selectedMarket.forEach((x) => {
        this.assets = [
          ...this.assets,
          ...this.masterassets.filter((el) => {
            if (el.country && el.country != null) {
              if (!this.assets.includes(el)) {
                return el.country.indexOf(x) > -1;
              }
            }
          }),
        ];
        this.totalRecords = this.assets.length;
      });
    } else if (
      this.name == "" &&
      this.selectedYears.length > 0 &&
      this.selectedMarket.length == 0
    ) {
      this.selectedYears.forEach((x) => {
        this.assets = [
          ...this.assets,
          ...this.masterassets.filter((el) => {
            if (el.year == x.toString()) {
              if (!this.assets.includes(el)) {
                return true;
              }
            }
          }),
        ];
        this.totalRecords = this.assets.length;
      });
    } else if (
      this.name == "" &&
      this.selectedMarket.length > 0 &&
      this.selectedYears.length > 0
    ) {
      this.selectedMarket.forEach((x) => {
        this.selectedYears.forEach((y) => {
          let filterResult = this.masterassets.filter((el) => {
            if (el.country && el.country != null) {
              if (el.country.indexOf(x) > -1 && el.year == y.toString()) {
                if (!this.assets.includes(el)) {
                  return true;
                }
              }
            }
            return false;
          });
          this.assets = [...this.assets, ...filterResult];
          this.totalRecords = this.assets.length;
        });
      });
    } else if (
      this.name == "" &&
      this.selectedMarket.length == 0 &&
      this.selectedYears.length == 0
    ) {
      this.assets = this.masterassets;
      if (this.assets) {
        this.totalRecords = this.assets.length;
      }
    } else if (this.name != "") {
      this.assets = this.assets.filter((el) => {
        if (el && el.fullName != null && el.description != null) {
          if (
            el.fullName
              .toLocaleLowerCase()
              .includes(this.name ? this.name.toLocaleLowerCase() : "") ||
            el.description
              .toLocaleLowerCase()
              .includes(this.name ? this.name.toLocaleLowerCase() : "")
          )
            return true;
        }
        return false;
      });
      this.totalRecords = this.assets.length;
    }
  }
  sendData() {
    return this.markets;
  }
  sendSelectedData() {
    return this.selectedMarket;
  }
  selectChange = (event: any) => {
    this.selectedMarket = event.data;
  };

  /*........... functionality Methods........ */

  shouldDisableNewtouchPoint() {
    return (
      this.scenarioChannelData.length !==
      (this.dataSource && this.dataSource.data.length)
    );
  }

  saveOrUpdateChannel() {
    const postObj = this.populateChannelPostObject();

    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: "save",
        module: "commsTask",
        apiData: postObj,
      },
      minWidth: "500px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getCommsTaskDetails();
      this.getTotalCommsTaskBudget(this.data);
      this.getTotalCommsTaskUnallocatedBudget(this.data);
    });
  }

  deleteChannel() {
    //If last, send boolean to dialog.
    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: "delete",
        module: "upfront",
        element: "channel",
        apiData: this.expandedElement.channelId,
        cWMBudget: this.expandedElement.cWMBudget,
        year: this.planningYear,
        commsTaskId: this.paramsId,
        scenarioId: this.scenarioId,
        isLast: false,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getCommsTaskDetails();
      this.getTotalCommsTaskBudget(this.data);
      this.getTotalCommsTaskUnallocatedBudget(this.data);
    });
  }

  expandColumnToEdit(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.updateChannelsForm();
  }

  updateOverviewBudget() {
    if (this.isOvwBudgetEditMode) {
      const ovwData = this.constructUpfrontPostObj("budget");
      this.prgmService
        .updateCommsTaskOverview(ovwData)
        .subscribe(() => this.getCommsTaskDetails());
    }
    this.isOvwBudgetEditMode = !this.isOvwBudgetEditMode;
  }

  updateScenarioStatus() {
    const ovwData = this.constructUpfrontPostObj("");
    this.prgmService.updateCommsTaskOverview(ovwData).subscribe(
      () => {
        this.isStatusConfirm = true;
        this.getCommsTaskDetails();
      },
      (error) => {
        this.isStatusConfirm = true;
        this.statusUpdateRes = error.message;
      }
    );
  }

  // updateOverviewMetrics() {

  //   if (this.isOvwMetricsEditMode) {
  //     const ovwData = this.constructUpfrontPostObj('');
  //     this.prgmService.updateCommsTaskOverview(ovwData).subscribe(() => this.getCommsTaskDetails());
  //   }
  //   this.isOvwMetricsEditMode = !this.isOvwMetricsEditMode;
  // }

  createNewScenario() {
    this.channelsForm.reset();
    this.selectedLogoImg = null;
    const BASE_CHANNEL_MODAL = {
      audience: null,
      cWMBudget: null,
      channelId: null,
      channelLock: null,
      channelName: null,
      channelSpend: null,
      cpp: null,
      curve: null,
      dayPartPercent: null,
      durationPercent: null,
      effectiveness: null,
      endDt: null,
      keyInterception: null,
      networkPercent: null,
      optimizationKPIs: null,
      reach: null,
      revenue: null,
      startDt: null,
      touchPointContent: null,
      touchPointName: null,
      weeksOnAir: null,
    };
    const tempChannel = { ...BASE_CHANNEL_MODAL };
    const channels = [...this.scenarioChannelData] || [];
    channels.unshift(tempChannel);

    this.expandedElement = tempChannel;
    this.dataSource = new MatTableDataSource<any>(channels);
  }

  // editScenario(scenarioDetails) {
  //   let sidePanelEle = { ...GLOBAL_ELEMENTS.NEW_SCENARIO };
  //   sidePanelEle['payload'] = scenarioDetails;
  //   this.openSidePanel(sidePanelEle);
  // }

  // openSidePanel(sidePanelEle) {
  //   const displayName = sidePanelEle.displayName.replace('##mjtbtName##', this.mjtbd.name);
  //   sidePanelEle.displayName = displayName;
  //   sidePanelEle['commsTaskId'] = this.paramsId;
  //   sidePanelEle['commsTaskName'] = this.commsTaskName;
  //   sidePanelEle['planId'] = this.paramsId;
  //   this.sharedData.setSideNavSelection(sidePanelEle);
  // }

  setStatusUpdateRes(oldStatus: string, currentStatus: string) {
    if (oldStatus && currentStatus) this.statusUpdateRes = "";

    if (
      oldStatus == STATUS_LIST_TYPES.DRAFT &&
      currentStatus == STATUS_LIST_TYPES.COMPLETE
    ) {
      this.statusUpdateRes = `Comms Task ${this.commsTaskName} for ${this.mjtbdName} ${this.planningYear}  has been submitted for your review.`;
    } else if (
      oldStatus == STATUS_LIST_TYPES.COMPLETE &&
      currentStatus == STATUS_LIST_TYPES.FINAL
    ) {
      this.statusUpdateRes = `Comms Task ${this.commsTaskName} for ${this.mjtbdName} ${this.planningYear} has been approved.`;
    } else if (
      oldStatus == STATUS_LIST_TYPES.COMPLETE &&
      currentStatus == STATUS_LIST_TYPES.DRAFT
    ) {
      this.statusUpdateRes = `Comms Task ${this.commsTaskName} for ${this.mjtbdName} ${this.planningYear} has been reverted back to draft.`;
    } else if (
      oldStatus == STATUS_LIST_TYPES.FINAL &&
      currentStatus == STATUS_LIST_TYPES.REPLAN
    ) {
      this.statusUpdateRes = `Comms Task ${this.commsTaskName} for ${this.mjtbdName} ${this.planningYear} has been reopened for updates.`;
    }
  }

  deleteScenario(scenarioDetails) {
    const scenarioId = scenarioDetails.scenario.scenarioId;
    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: "delete",
        element: "scenario",
        apiData: scenarioId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getCommsTaskDetails();
    });
  }

  selectedScenario(scenatioDetails, scenarioIdx) {
    this.selectedScenarioIdx = scenarioIdx;
    // this.plannedBudget = scenatioDetails.aWMBudget;
    this.plannedEffectiveness = scenatioDetails.plannedEffectiveness;
    this.plannedROI = scenatioDetails.plannedROI;
  }
  commsApproverChange(apprvData: Array<IApproverList>) {
    this.selectedCommsApprovers = apprvData;
  }

  updateCommsStatus() {
    if (this.commsStatus == STATUS_LIST_TYPES.COMPLETE) {
      this.isStatusConfirm = false;
    } else {
      this.isStatusConfirm = true;
      this.updateScenarioStatus();
    }

    this.dialog.open(this.approveListDialog, {
      width: "500px",
      disableClose: true,
    });
  }

  closeDialog() {
    this.getCommsTaskDetails();
    this.dialog.closeAll();
    this.isStatusConfirm = false;
  }

  updateStatusList() {
    let approversIds: Array<any> = [];
    if (this.commsTaskOverview && this.commsTaskOverview.approversList) {
      approversIds = this.commsTaskOverview.approversList.split(",");
    }

    let isMatched = false;

    approversIds.forEach((id) => {
      if (this.userInfo.id == id) {
        isMatched = true;
      }
    });

    if (
      this.commsStatus === STATUS_LIST_TYPES.DRAFT ||
      this.commsStatus == null
    ) {
      this.commsStatusList = MINIFIED_STATUS_LIST;
      this.commsEditableStatus = true;
    } else if (isMatched && this.commsStatus === STATUS_LIST_TYPES.COMPLETE) {
      this.commsEditableStatus = true;
      this.commsStatusList = STATUS_LIST;
    } else if (isMatched && this.commsStatus === STATUS_LIST_TYPES.FINAL) {
      this.commsEditableStatus = true;
      this.commsStatusList = FINAL_STATUS_LIST;
    } else {
      this.commsEditableStatus = false;
      this.commsStatusList = STATUS_LIST;
    }
  }

  /*........... Service/API calls........ */

  getApproversList() {
    let obj = {
      referenceId: this.planId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: this.planningYear,
    };
    this.globalElementsService
      .getApproversList(obj)
      .subscribe((res: Array<IApproverList>) => {
        this.commsApproverList = res;
        this.updateStatusList();
        const approvers = this.commsTaskOverview.approversList
          ? this.commsTaskOverview.approversList.split(",")
          : [];
        this.selectedCommsApprovers = [];
        approvers.forEach((id) => {
          const commsApprover = this.commsApproverList.find(
            (el: any) => el.userId == id
          );
          if (commsApprover) {
            this.selectedCommsApprovers.push(commsApprover);
          } else {
            this.selectedCommsApprovers = [];
          }
        });
        this.commsApprover.setValue(this.selectedCommsApprovers);
      });
  }

  addTeamMembers() {
    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
        teamMembers: this.teamMembersList,
        selectedMembers: this.selectedTeamMembers,
      },
      height: "400px",
      minWidth: "500px",
      disableClose: true,
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
      // this.updateTeamMembers()
      //this.updatePortfolioAllocation();
      this.updateScenarioStatus();
      this.generateTeamMemeberAvatars();
    });
  }

  generateTeamMemeberAvatars() {
    if (this.teamMembersList) {
      const selectedUsers = this.teamMembersList.filter((tm) =>
        this.selectedTeamMembers.includes(tm.userId)
      );
      this.selectedTeamMembersAvatar = selectedUsers.map((user) => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(
          0
        )}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, "");
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl(
            "data:image/jpg;base64, " + base64String
          );
        }

        return {
          initials,
          avatar,
        };
      });
    }
  }

  getTeamMembersList() {
    let teamMemberReq: ITeamMembersReq = {
      referenceId: this.planId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: this.planningYear,
    };

    this.globalElementsService
      .getTeamMembersList(teamMemberReq)
      .subscribe((res: Array<ITeamMembers>) => {
        this.teamMembersList = res
          ? res.sort((a, b) => (a.name >= b.name ? 1 : -1))
          : [];
        const uniqueIds = [];
        if (this.teamMembersList) {
          this.teamMembersList = res.filter((el) => {
            const isDuplicate = uniqueIds.includes(el.userId);
            if (!isDuplicate) {
              uniqueIds.push(el.userId);

              return true;
            }
            return false;
          });
        }
        this.generateTeamMemeberAvatars();
      });
  }

  stringToArray(csString) {
    return (csString && csString.split(",")) || [];
  }

  //get all assets
  getAllAssets() {
    if (this.assets && this.assets.length == 0) {
      let planId = this.planId;
      const postObj = {
        planId: planId,
      };
      this.programService.getCampaignDAMAssets(postObj).subscribe(
        (res: any) => {
          this.totalassetCount = parseInt(res.total);
          let loopCount = parseInt((this.totalassetCount / 1000).toString());

          let loopRemainder = this.totalassetCount % 1000;
          if (loopRemainder > 0) {
            loopCount = loopCount + 1;
          }
          let count = 0;
          for (let i = 1; i <= loopCount; i++) {
            count = count + 1;
            const Obj = {
              planId: this.planId,
              pageNo: i,
            };
            this.getData(Obj);
          }
          console.log(`Count is ${count}`);
        },
        (error) => {
          console.error("Error: ", error);
        }
      );
    }
  }
  async getData(Obj) {
    const data = await this.programService.gettotalNoOfAssets(Obj).toPromise();
    console.log(`Data Received for ${Obj.pageNo}`);
    console.log(data.data);
    if (data.data && data.data.length > 0) {
      if (!this.assets) {
        this.assets = [];
      }
      this.assets.push(...data.data);
      this.totalRecords = this.assets.length;
      this.masterassets = this.assets;
    } else {
      this.assets = [];
      this.masterassets = [];
    }
  }

  handleCancel() {
    if (this.channelsForm.dirty) {
      let dialogRef;

      dialogRef = this.dialog.open(CancelDialogComponent, {
        minWidth: "500px",
      });

      dialogRef.afterClosed().subscribe((res: any) => {
        if (res.status) {
          this.dialog.closeAll();
          this.globalElementsService.setSidePanelCancelEvent();
          this.getCommsTaskDetails();
        }
      });
    } else {
      this.globalElementsService.setSidePanelCancelEvent();
      this.closeDialog();
    }
  }

  getHierarchy() {
    let planObj = {
      planId: this.planId,
    };
    this.planService.getHierarchy(planObj).subscribe(
      (res: any) => {
        if (res) {
          this.marketName = res.market.displayName;
          this.planName = res.plan.displayName;
          this.hierarchy = `${res.market.displayName}/${res.portfolio.displayName}/${res.plan.displayName}`;
          this.getAllFilesFromSharepoint();
          this.getAllAssets();
        }
      },
      (error) => {}
    );
  }

  navigateToCreativePreFlight() {
    window.open(
      "https://app.creativex.com/preflight/pretests?login_org=pepsico",
      "_blank"
    );
  }

  getCommsTaskDetails() {
    if (!this.paramsId || !this.planningYear) {
      return null;
    }

    const postObj = {
      commsTaskId: this.paramsId,
      year: this.planningYear,
    };

    this.prgmService
      .getAudiencec(postObj)
      .subscribe((res: Array<string>) => (this.audienceList = res));
    this.prgmService.getCommsTaskData(postObj).subscribe((res: any) => {
      this.expandedElement = null;
      this.setStatusUpdateRes(this.oldStatus, this.commsStatus);
      //this.scenarioChannelData = [res.scenarioData[0]];
      this.commsTaskName = res.commsTaskName;
      this.pathDetails = {
        lastNodeName: this.commsTaskName,
        pageId: this.paramsId,
        pageRefType: BREADCRUMBS_REFERENCE_TYPES.COMMSTASK,
        lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.COMMSTASK,
      };
      this.commsTaskOverview = res.commsTask;
      this.owBudgetVal = this.commsTaskOverview.aWMBudget || null;
      this.owROIVal = this.commsTaskOverview.aTROI || null;
      this.mjtbdName = (res.mjtbd && res.mjtbd.name) || "";
      this.commsStatus =
        (this.commsTaskOverview &&
          this.commsTaskOverview.commsTaskAllocationStatus) ||
        STATUS_LIST_TYPES.DRAFT;
      this.oldStatus =
        (this.commsTaskOverview &&
          this.commsTaskOverview.commsTaskAllocationStatus) ||
        STATUS_LIST_TYPES.DRAFT;
      this.selectedTeamMembers = this.commsTaskOverview.teamMembers
        ? this.commsTaskOverview.teamMembers.split(",").map((el) => Number(el))
        : [];
      this.mjtbd = res.mjtbd;
      if (this.mjtbd.name.length > 50) {
        this.fullName = this.mjtbd.name;
        const shortName = res.mjtbd.name.slice(0, 50).concat("...");
        res.mjtbd.name = shortName;
        this.mjtbd = res.mjtbd;
      } else {
        this.mjtbd = res.mjtbd;
      }
      this.planId = res.planId;
      this.getHierarchy();
      this.scenarioId = (res.scenario && res.scenario.scenarioId) || null;
      this.scenarioChannelData = res.channels;
      this.dataSource = new MatTableDataSource<any>(this.scenarioChannelData);
      this.plannedBudget = this.scenarioChannelData.reduce((acc, cv) => {
        acc = acc + Number(cv.cWMBudget);
        return acc;
      }, 0);
      this.opportunityBriefStartDate = res.opportunityBriefFormData.StartDt
        ? res.opportunityBriefFormData.StartDt
        : null;
      this.getApproversList();
      this.getTeamMembersList();
      this.getCustomAudienceList();
      this.getMJTBDCustomAudienceList();
    });
  }

  downloadFile(val) {
    let marketName = this.marketName;
    let planName = this.planName;
    let audienceName = `${planName}_${val}`;
    this.cDNAFiles = this.cDNAFiles.sort((a, b) => {
      return b.modifiedDtUnix - a.modifiedDtUnix;
    });
    const fileData = this.cDNAFiles.find(
      (doc: IDocument) => doc.title.split(".")[0] == audienceName
    );

    if (fileData) {
      let fileName = fileData.fileName;
      const extension = fileName.substring(
        fileName.lastIndexOf(".") + 1,
        fileName.length
      );
      let path = fileData.path;
      this.globalElementsService
        .downloadFile({
          fileName: fileName,
          path: path.split("/Shared Documents/")[1],
        })
        .subscribe((response) => {
          this.downloadFileFromBase64(
            fileName,
            response["thumbnailb64data"],
            fileMimeTypes[extension]
          );
          // const byteCharacters = window.atob(response['thumbnailb64data']);
          // const downloadLink = document.createElement("a");
          // downloadLink.download = fileName.replace(/ /g,'');
          // downloadLink.href = source;
          // document.body.appendChild(downloadLink);
          // downloadLink.click();
          // document.body.removeChild(downloadLink);
          // const byteNumbers = new Array(byteCharacters.length);
          // for (let i = 0; i < byteCharacters.length; i++) {
          //   byteNumbers[i] = byteCharacters.charCodeAt(i);
          // }
          // const byteArray = new Uint8Array(byteNumbers);
          // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
          // saveAs(blob, fileName.replace(/ /g,''));
          // return true;
        });
    } else {
      this.dialog.open(FileNotFoundComponent, {
        data: {
          val: val,
        },
        minWidth: "500px",
      });
    }
  }
  downloadFileFromBase64(fileName, b64encodedString: string, contentType) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    let byteArrays = ab2b64.b642ab(b64Data);
    return new Blob([byteArrays], { type: contentType });
  }

  //  .......................Form Details................................

  showdwnldbtn(val) {
    return !(
      this.customAudienceList.map((el) => el.customAudienceName).indexOf(val) >
        -1 || this.MJTBDCustomAudienceList.indexOf(val) > -1
    );
  }
  openImgUploadDialog() {
    this.assets = this.masterassets;
    if (this.assets) {
      this.totalRecords = this.assets.length;
    }
    this.selectedMarket = [];
    this.selectedYears = [];
    this.selectedMarketNames = [];
    this.name = "";
    //this.currChannelIdx = channelIdx;
    this.logoDialog.open(this.logoUploadDialog, {
      height: "100%",
      width: "800px",
      disableClose: true,
    });
  }

  //close dialog
  closeLogoDialog() {
    this.logoDialog.closeAll();
  }

  selectLogo(sel: any) {
    this.assets.forEach((element) => {
      delete element.selected;
      if (element.webImage == sel.webImage) {
        element.selected = true;
        this.selectedLogoImg = sel.webImage;
      }
    });
    this.closeLogoDialog();
  }

  getChannels() {
    if (this.marketId) {
      this.prgmService
        .getCommsTaskChannels({ marketId: this.marketId })
        .subscribe((res: Array<any>) => {
          let channelsList = [];
          const channelInfo = res;
          if (channelInfo && channelInfo.length > 0) {
            channelsList = channelInfo.map((el) => el.channelName);
          }
          this.channelsList = channelsList;
          this.channelsListWithFlag = res;
        });
    }
  }

  updateCreativeXDates(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.setCreativeXDates();
    this.setCreativeXVisibility();
  }

  setCreativeXDates() {
    if (this.channelsForm.value.startDt) {
      if (
        this.channelsForm.value.startDt &&
        moment(this.channelsForm.value.startDt).isAfter(moment(), "day")
      ) {
        this.requiredDate = moment(this.channelsForm.value.startDt)
          .subtract(10, "days")
          .format("L");
        this.recommendedDate = moment(this.channelsForm.value.startDt)
          .subtract(1, "months")
          .format("L");
      }
    } else if (
      this.opportunityBriefStartDate &&
      moment(this.opportunityBriefStartDate).isAfter(moment(), "day")
    ) {
      this.requiredDate = moment(this.opportunityBriefStartDate)
        .subtract(10, "days")
        .format("L");
      this.recommendedDate = moment(this.opportunityBriefStartDate)
        .subtract(1, "months")
        .format("L");
    }
  }

  setCreativeXVisibility() {
    this.setCreativeXDates();
    if (
      this.selectedChannels &&
      this.selectedChannels.isCreativeX &&
      this.selectedChannels.isCreativeX == "Y"
    ) {
      if (this.channelsForm.value.startDt) {
        if (
          this.channelsForm.value.startDt &&
          moment(this.channelsForm.value.startDt).isAfter(moment(), "day")
        ) {
          this.isDatePanelVisible = true;
        } else {
          this.isDatePanelVisible = false;
        }
      } else {
        if (
          this.opportunityBriefStartDate &&
          moment(this.opportunityBriefStartDate).isAfter(moment(), "day")
        ) {
          this.isDatePanelVisible = true;
        } else {
          this.isDatePanelVisible = false;
        }
      }
    } else {
      this.isDatePanelVisible = false;
    }
  }

  getSubBrands() {
    if (!this.paramsId || !this.planningYear) {
      return null;
    }
    const postObj: ICommsTask = {
      commsTaskId: this.paramsId,
      year: this.planningYear,
    };
    this.prgmService
      .getSubPlans(postObj)
      .subscribe((res: Array<IPlanBase>) => (this.subBrandsList = res));
  }

  getSubBrandName(subBrandId) {
    const subBrand = this.subBrandsList.find((el) => el.id == subBrandId);
    return (subBrand && subBrand.displayName) || "";
  }
  sendMessageToCollaborators() {
    let data = this.constructUpfrontPostObj("budget");
    let snDialogRef;
    snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
      data: {
        data: data,
        type: FORM_REFERENCE_TYPES.COMMS_TASK,
      },
      height: "150px",
      width: "500px",
      disableClose: true,
    });

    snDialogRef.afterClosed().subscribe((res: any) => {});
  }
  constructUpfrontPostObj(type: string) {
    const isBudgetUpdate = type == "budget";
    const approversIds =
      this.selectedCommsApprovers &&
      this.selectedCommsApprovers.map((obj: IApproverList) => obj.userId);
    const { completedByUserId, finalizedByUserID } =
      getCompletedAndFinalizedUsers(
        this.oldStatus,
        this.commsStatus,
        this.commsTaskOverview
      );
    const upfrontData = {
      allocationId: this.commsTaskOverview.allocationId,
      year: this.planningYear,
      alertURL: `/commsTask/${this.paramsId}`,
      commsTaskId: this.commsTaskOverview.commsTaskId,
      aWMBudget: isBudgetUpdate
        ? this.owBudgetVal
        : this.commsTaskOverview.aWMBudget,
      aTROI: isBudgetUpdate ? this.commsTaskOverview.aTROI : this.owROIVal,
      referenceCode: this.commsStatus,
      aTEffectiveness: this.commsTaskOverview.aTEffectiveness,
      teamMembers: this.selectedTeamMembers.toString(),
      completedByUserId: completedByUserId,
      finalizedByUserID: finalizedByUserID,
      approversList: approversIds ? approversIds.toString() : "",
      currentUserID: this.userInfo.id,
      commsTaskName: this.commsTaskName,
      scenario: {
        scenarioId: this.scenarioId || null,
      },
    };
    return upfrontData;
  }

  populateChannelPostObject() {
    const commsTaskId = this.paramsId;

    const scenarioInfo = {
      scenarioId: this.scenarioId,
      year: this.planningYear,
    };

    const channelFormVal = this.channelsForm.value;
    const channelInfo = {
      channelId: this.expandedElement.channelId || null,
      channelName: channelFormVal.channelName,
      audience:
        this.allSelectedAudienceList && this.allSelectedAudienceList.length > 0
          ? this.allSelectedAudienceList.toString()
          : "",
      cWMBudget: channelFormVal.cWMBudget
        ? channelFormVal.cWMBudget.replace(/,/g, "")
        : 0,
      endDt: channelFormVal.endDt,
      keyInterception: channelFormVal.keyInterception,
      optimizationKPIs: channelFormVal.optimizationKPIs
        ? channelFormVal.optimizationKPIs.toString()
        : "",
      startDt: channelFormVal.startDt,
      touchPointContent: this.selectedLogoImg,
      touchPointName: channelFormVal.touchPointName,
      subBrandId: channelFormVal.subBrand,
      isPreFlightTestCompleted: channelFormVal.isPreFlightTestCompleted,
    };

    const postObj = {
      commsTaskId,
      scenarioInfo,
      channelInfo,
      marketId: this.marketId,
    };

    return postObj;
  }
  CommaFormatted(event) {
    if (event.which >= 37 && event.which <= 40) return;

    if (event.target.value) {
      event.target.value = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  numberCheck(args) {
    if (args.key === "e" || args.key === "+" || args.key === "-") {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.marketChangeSubscription.unsubscribe();
    this.channelNameSub.unsubscribe();
    this.startDtSub.unsubscribe();
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator, null, this.planId);
  }
}
