import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { IpfAllocPlanSave } from '../../common/interfaces/portfolioAllocation';

const BASE_API_URL = `${ environment.api }`;
const PORTFOLIO_API_URL = `${ environment.api }/portfolio`;
@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(private http: HttpClient) { }

  public getPortfolioData(obj:any){
    return this.http.post(PORTFOLIO_API_URL, obj);
  };

  getChartData(chartObj:any){
    return this.http.post(`${PORTFOLIO_API_URL}/getChartData`, chartObj);
  }
  getWidgetChartData(chartObj:any){
    return this.http.post(`${PORTFOLIO_API_URL}/getWidgetChartData`, chartObj);
  }

  getFlowPackView(chartObj:any){
    return this.http.post(`${PORTFOLIO_API_URL}/getFlowPackView`, chartObj);
  }

  getMediaMix(chartObj:any){
    return this.http.post(`${PORTFOLIO_API_URL}/getMediaMix`, chartObj);
  }

  getPortfolioAllocationPerYear(portfolioObj){    
    return this.http.post(`${BASE_API_URL}/pfGroupAlloc/getPortfolioAllocationPerYear`, portfolioObj);
  }
  
  getPlanData(planObj) {
    return this.http.post(`${BASE_API_URL}/pfGroupAlloc/getPlanData`, planObj);
  } 

  updatePortfolioAllocation(pfAllocationObj){
    return this.http.post(`${BASE_API_URL}/pfGroupAlloc/updatePortfolioAllocation`, pfAllocationObj);
  }

  updatePortfolioAllocationPlan(planObj: IpfAllocPlanSave){
    return this.http.post(`${BASE_API_URL}/pfGroupAlloc/updatePortfolioAllocationPlan`, planObj);
  }
  getFinancialValueForPortfolio(postObj){
    return this.http.post(`${BASE_API_URL}/pfGroupAlloc/getFinancialValueForPortfolio`, postObj);
  }
  getPlansByPortfolioId(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getPlansByPortfolioId`, postObj);
  }
  getKPIPercentage(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getKPIFrameWorkPercentage`, postObj);
  }
  getPortfolioDashboardUrl(portfolioId) {
    return this.http.post(`${BASE_API_URL}/portfolioRTMDash/getPortfolioRTODashURL`, { portfolioId });
  }
  getTCPArchitecturePercentage(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getTCPArchitecturePercentage`, postObj);
  }
  getmjtbdAndCampainList(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getMJTBDAndCampaignList`, postObj);
  }
  getOppBreifPercentage(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getOppBreifPercentage`, postObj);
  }
  getCampaignIdeaPercentage(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getCampaignIdeaPercentage`, postObj);
  }
  getConnectionPercentage(postObj){
    return this.http.post(`${BASE_API_URL}/pfTCPTracker/getConnectionPercentage`, postObj);
  }
  public updateTargetROI(obj: any) {
    return this.http.post(`${PORTFOLIO_API_URL}/updateTargetROI`, obj);
  };
  public resetTargetROI(obj: any) {
    return this.http.post(`${PORTFOLIO_API_URL}/resetTargetROI`, obj);
  };
}
