import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';

@Component({
  selector: 'app-campaign-idea-upload-dialog',
  templateUrl: './campaign-idea-upload-dialog.component.html',
  styleUrls: ['./campaign-idea-upload-dialog.component.scss']
})
export class CampaignIdeaUploadDialogComponent implements OnInit {
  message: string = "Uploading... Please Wait";
  uploadSts=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
    private dialogRef: MatDialogRef<CampaignIdeaUploadDialogComponent>
  ) { }

  ngOnInit() {
    this.uploadAvatar();
  }
  uploadAvatar() {
    this.globalElementsService.uploadCampaignIdeaImage(this.data).subscribe((res:any) => {
      this.message = res.message;
      this.uploadSts=true;
    }, () => {
      this.message = "Error uploading profile.";
      this.uploadSts=false;
    })
  }

  close(){
    this.dialogRef.close({ status: this.uploadSts })
  }

}
