import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanService } from '../../../../plan/services/plan.service';
import {getDocsFromSharepointFilesAPI } from 'app/common/utils/common-util';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { FILE_NAMES, FILE_TYPE, GLOBAL_ELEMENTS, SHAREPOINT_DOC_PATH } from 'app/constants/app-constants';
import { IDocument, IOverview } from 'app/common/interfaces/common';


@Component({
  selector: 'app-get-market-and-year-dialog',
  templateUrl: './get-market-and-year-dialog.component.html',
  styleUrls: ['./get-market-and-year-dialog.component.scss']
})
export class GetMarketAndYearDialogComponent implements OnInit {

  years:any = [];
  markets:[];
  defaultMarketId:number;
  path:string;
  positioningDoc: IDocument;
  brandIdeaDoc: IDocument;
  growthStrategyDoc: IDocument;
  selectedMarket:string;
  selectedYear:string;
  sourcePath:string;
  destinationPath:string;
  positioningDocPath: string;
  brandIdeaDocPath: string;
  growthStrategyDocPath: string;
  type:string;
  docData: any[];
  showMessage:boolean = false;
  displayMessage:boolean = false;
  message:string = 'Importing Document Please wait...';
  showButtons:boolean = true;
  thumbnailb64data:any;
  planName:string;
  portfolioName:string;
  showOkBtn:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GetMarketAndYearDialogComponent>,
    private planService: PlanService,
    private globalElementsService: GlobalElementsService,
  ) { }

  ngOnInit() {
    this.destinationPath = this.data.path;
    this.type = this.data.type.toLowerCase();
    if(this.data.userInfo.markets.length == 0) {
      this.defaultMarketId = this.data.userInfo.defaultMarket;
      const postObj = {
        marketId:Number(this.defaultMarketId)
      }
      this.planService.getDefaultMarketName(postObj).subscribe(res => {
        if(res['markets'].length > 0){
          this.markets = res['markets'];
        } else {
          this.markets = [];
        }
      })
    } else {
      this.markets = this.data.userInfo.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
    }
    this.getAllYears();
    // this.getAllFoundationalDocuments();
  }

  getAllYears(){
    this.planService.getAllYears().subscribe(response => {
      if(response['years'].length > 0){
        this.years = response['years'];
      } else {
        this.years = [];
      }
    })
  }
  getAllDocuments(){
    if(this.selectedMarket == null || this.selectedYear == null) {
      return false;
    } else {
    this.generateSourcePath();
    }
    
  }
  async generateSourcePath(){
    const postObj = {
      planId:this.data.planData.planId,
      marketId:this.selectedMarket['id']
    }
    this.planService.getPlanAndPortfolio(postObj).subscribe(res => {
      this.planName = res['planName'] ? res['planName'] : null;
      this.portfolioName = res['portfolio'] ? res['portfolio'] : null;
      this.sourcePath = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedYear}/${this.selectedMarket['displayName']}/${this.portfolioName}/${this.planName}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
      if(this.sourcePath){
      this.getAllFoundationalDocuments();
      }
    });
  }
  async getAllFoundationalDocuments(){
    this.docData = await getDocsFromSharepointFilesAPI(this.sourcePath, this.globalElementsService);

    this.docData = this.docData.sort((a,b) => {
      return b.modifiedDtUnix-a.modifiedDtUnix;
    });

    if(this.docData.length > 0) {
      switch(this.type) {
        case FILE_TYPE.POSITIONING:
          const positioning_fileName = `${this.selectedYear}_${this.data.planData.plan}_${FILE_NAMES.PURPOSEFUL_POSITIONING}`;
          this.positioningDoc = this.docData.find((doc: IDocument) => doc.fileName && doc.fileName.split(".")[0].toLocaleLowerCase() == `${positioning_fileName.toLocaleLowerCase()}`);
          
          break;

          case FILE_TYPE.BRAND_IDEA:
          const brandIdea_fileName = `${this.selectedYear}_${this.data.planData.plan}_${FILE_NAMES.BRAND_IDEA}`;
          this.brandIdeaDoc = this.docData.find((doc: IDocument) => doc.fileName && doc.fileName.split(".")[0].toLocaleLowerCase() == `${brandIdea_fileName.toLocaleLowerCase()}`);
          
          break;

          case FILE_TYPE.GROWTH_STRATEGY:
          const growthStrategy_fileName = `${this.selectedYear}_${this.data.planData.plan}_${FILE_NAMES.GROWTH_STRATEGY}`;
          this.growthStrategyDoc = this.docData.find((doc: IDocument) => doc.fileName && doc.fileName.split(".")[0].toLocaleLowerCase() == `${growthStrategy_fileName.toLocaleLowerCase()}`);
          
        }
      
    } else {
      this.docData = [];
      this.showMessage = true;
    }
    
  }
  importDocuments(){
    if(this.selectedMarket == null && this.selectedYear == null){
      return false;
    } else if(this.selectedMarket == null || this.selectedYear == null){
      return false;
    } else if(this.docData.length == 0){
      return false;
    } else if(this.positioningDoc == null && this.brandIdeaDoc == null && this.growthStrategyDoc == null){
      return false;
    }
    this.displayMessage = true;
    this.showButtons = false;
    switch(this.type){
      case FILE_TYPE.POSITIONING:
        if(this.positioningDoc.path!= null){
          let path = this.positioningDoc.path;
          let fileName = path.split('/').pop();
          this.globalElementsService.importDocuments({workingYear:this.data.planData.selectedWorkingYear,fileName: fileName, destinationPath:this.destinationPath,path: path.split('/Shared Documents/')[1]}).subscribe(async response => {
            if(response){
              try{
                this.thumbnailb64data = await this.globalElementsService.getThumbnailBase64DataSharePoint_new({ "filePathWName" : `${this.destinationPath}/${fileName}`}).toPromise();
                if(this.thumbnailb64data){
                    this.message = 'Imported Successfully!';
                    this.showButtons = false;
                    this.displayMessage = true;
                    this.showOkBtn = true;
                  }
              } catch(error){
                this.message = 'Imported Successfully.';
                this.showButtons = false;
                this.displayMessage = true;
                this.showOkBtn = true;
              }
            }
          })
        }
        break;
        case FILE_TYPE.BRAND_IDEA:
          if(this.brandIdeaDoc.path!= null){
            let path = this.brandIdeaDoc.path;
            let fileName = path.split('/').pop();
            this.globalElementsService.importDocuments({workingYear:this.data.planData.selectedWorkingYear,fileName: fileName, destinationPath:this.destinationPath,path: path.split('/Shared Documents/')[1]}).subscribe(async response => {
              if(response){
                try{
                  this.thumbnailb64data = await this.globalElementsService.getThumbnailBase64DataSharePoint_new({ "filePathWName" : `${this.destinationPath}/${fileName}`}).toPromise();
                  if(this.thumbnailb64data){
                    this.message = 'Imported Successfully!';
                    this.showButtons = false;
                    this.displayMessage = true;
                    this.showOkBtn = true;
                  }
                  
                } catch(error){
                  this.message = 'Imported Successfully';
                  this.showButtons = false;
                  this.displayMessage = true;
                  this.showOkBtn = true;
                }
              }
            })
          }
          break;
          case FILE_TYPE.GROWTH_STRATEGY:
            if(this.growthStrategyDoc.path!= null){
              let path = this.growthStrategyDoc.path;
              let fileName = path.split('/').pop();
              this.globalElementsService.importDocuments({workingYear:this.data.planData.selectedWorkingYear,fileName: fileName, destinationPath:this.destinationPath,path: path.split('/Shared Documents/')[1]}).subscribe(async response => {
                if(response){
                  try{
                  this.thumbnailb64data = await this.globalElementsService.getThumbnailBase64DataSharePoint_new({ "filePathWName" : `${this.destinationPath}/${fileName}`}).toPromise();
                    if(this.thumbnailb64data){
                      this.message = 'Imported Successfully!';
                      this.showButtons = false;
                      this.displayMessage = true;
                      this.showOkBtn = true;
                    }
                  } catch(error){
                    this.message = 'Imported Successfully';
                    this.showButtons = false;
                    this.displayMessage = true;
                    this.showOkBtn = true;
                  }
                }
              })
            }
    }
  }

 close(){
  this.dialogRef.close({ fileType:this.type, thumbnailImage: this.thumbnailb64data ? this.thumbnailb64data : '' });
 }
}