import { Component, OnInit, Inject } from '@angular/core';
import { PlanService } from 'app/plan/services/plan.service';
import { UpdateTargetDialogComponent } from '../update-target-dialog/update-target-dialog.component';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-tcp-architecture-dialog',
  templateUrl: './tcp-architecture-dialog.component.html',
  styleUrls: ['./tcp-architecture-dialog.component.scss']
})
export class TcpArchitectureDialogComponent implements OnInit {

  message: string = "Saving TCP Architecture data... Please Wait";
  status=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private dialogRef: MatDialogRef<UpdateTargetDialogComponent>,
    private globalElementsService: GlobalElementsService
  ) { }

  ngOnInit() {
    this.updateTcpArchitectureInfo();
  }


  updateTcpArchitectureInfo() {    
    this.planService.updateTCParchitecture(this.data).subscribe((res:any)=>{
      if(res){
        this.message = "Saved Successfully";
        this.status=true;
      }   
    }, () => {
      this.message = "Error in saving TCP Architecture data";
      this.status=false;
    })
    
  }

  close(){
    this.dialogRef.close({ status: this.status })
    this.status ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }OnInit() {
  }

}
