import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ICommsTask, ICommsTaskChannel } from 'app/common/interfaces/program';
import { Observable } from 'rxjs';
import { HttpApiService } from '../../../core/http-core/api/http-api.service';
import { IHttpApiRequest } from '../../../core/http-core/api/interface/ihttp-api-request';
const BASE_API_URL = `${environment.api}`;
const CAMPAIGN_API_URL = `${BASE_API_URL}/campaign`;
const MJTBD_API_URL = `${BASE_API_URL}/mjtbd`;


@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  constructor(private http: HttpClient,    private httpApiService: HttpApiService) {

  }
  public getMjtbdData(obj: any) {
    return this.http.post(`${MJTBD_API_URL}/`, obj);
  };

  getCommsTaskData(commsTaskObj) {
    return this.http.post(`${BASE_API_URL}/commsTask/getCommsTaskData`, commsTaskObj);
  }

  getAudiencec(commsTaskObj) {
    return this.http.post(`${BASE_API_URL}/commsTask/getAudience`, commsTaskObj);
  }

  saveTouchpoint(touchPointObj) {
    return this.http.post(`${BASE_API_URL}/commsTask/saveChannel`, touchPointObj);
  }

  getCommsTaskChannels(postObj) {
    return this.http.post(`${BASE_API_URL}/commsTask/getCommsTaskChannels`, postObj);
  }
  getChannelsWithoutCurve(commsTaskObj: ICommsTaskChannel) {
    return this.http.post(`${BASE_API_URL}/commsTask/getChannelsWithoutCurve`, commsTaskObj);
  }
  getSubPlans(commsTaskObj: ICommsTask) {
    return this.http.post(`${BASE_API_URL}/commsTask/getSubPlans`, commsTaskObj);
  }

  getMediaChannelCurves(postObj) {
    return this.http.post(`${BASE_API_URL}/commsTask/getCurves`, postObj);
  }

  // getChannelsPerCurve(postObj) {
  //   return this.http.post(`${BASE_API_URL}/commsTask/getChannelsPerCurve`, postObj);
  // }

   /**
   * @name getChannelsPerCurve
   * @description getChannelsPerCurve
   * @param {obj}
   * @returns {Observable<IPlanResponse>} Observable of type IPlanResponse
   */
  public getChannelsPerCurve(postObj: any):Observable<any> {
    console.log("getChannelsPerCurve",postObj);
    const request: IHttpApiRequest = {
      config: {
        resource: `/commsTask/getChannelsPerCurve`,
        apiUrl: BASE_API_URL,
        useMockData: false,
        mockDataPath: '',
        group: 'commsTask',
        noCache: true,
        // showApiLoader: false,
        // showApiErrorDialog: false,
        // showApiTimeLog: true,
        headers: {},
        latency: undefined
      },
      data: postObj,
      args: {},
      urlTemplate: ''
    };
    console.log("request",request);
    return this.httpApiService.post(request);

    //return this.http.post(`${BASE_API_URL}/plan`, obj);
  };
  getScenarioAnalytics(touchPointObj) {
    return this.http.post(`${BASE_API_URL}/analytics/getScenarioAnalytics`, touchPointObj);
  }

  getChartData(chartObj: any) {
    return this.http.post(`${MJTBD_API_URL}/getChartData`, chartObj);
  }

  deleteScenario(scenarioId) {
    return this.http.post(`${BASE_API_URL}/commsTask/deleteScenario`, { scenarioId });
  }

  deleteScenarioChannel(touchpointId) {
    return this.http.post(`${BASE_API_URL}/commsTask/deleteScenarioChannel`, { touchpointId });
  }

  duplicateScenario(scenarioDetails) {
    return this.http.post(`${BASE_API_URL}/commsTask/duplicateScenario`, scenarioDetails);
  }

  updateCommsTaskOverview(commsTaskOvw) {
    return this.http.post(`${BASE_API_URL}/commsTask/updateCommsTaskOverview`, commsTaskOvw, { responseType: 'text' });
  }

  getCampaignsAndIdeas(campaignObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/getCampaignsAndIdeas`, campaignObj);
  }

  getApproversList(mjtbdObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/getApproversList`, mjtbdObj);
  }

  getKPIsandAudience(mjtbdObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/getKPIsandAudience`, mjtbdObj);
  }

  saveCampaignIdea(campaignIdeaObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/saveCampaignIdea`, campaignIdeaObj);
  }

  saveCampaign(campaignObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/saveCampaign`, campaignObj);
  }
  getSuccessKpisFromOppBrief(campaignId){
    return this.http.post(`${CAMPAIGN_API_URL}/getSuccessKpisFromOppBrief`, {campaignId});
  }

  editCampaign(campaignObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/editCampaign`, campaignObj);
  }

  addCommsTask(commsTaskObj) {
    return this.http.post(`${MJTBD_API_URL}/addCommsTask/`, commsTaskObj);
  }

  editCommsTask(commsTaskObj) {
    return this.http.post(`${MJTBD_API_URL}/editCommsTask/`, commsTaskObj);
  }

  editCampaignIdea(campaignIdeaObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/editCampaignIdea`, campaignIdeaObj);
  }

  editOppourtinyBrief(oppBriefObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/editOppourtinyBrief`, oppBriefObj);
  }

  getCampaignDAMLogos() {
    return this.http.post<any>(`${CAMPAIGN_API_URL}/getAssets`, {});
  }

  getDashboardUrl(urlObj) {
    return this.http.post(`${BASE_API_URL}/plan/getIframes`, urlObj);
  }

  deleteOpportunityBrief(oppBriefObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/deleteOpportunityBrief`, oppBriefObj);
  }

  deleteCampaignIdea(campaignIdeaObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/deleteCampaignIdea`, campaignIdeaObj);
  }

  deleteCampaign(campaignObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/deleteCampaign`, campaignObj);
  }

  deleteCommsTask(commsTaskObj) {
    return this.http.post(`${BASE_API_URL}/commsTask/deleteCommsTask`, commsTaskObj);
  }

  validateDeleteCampaign(campaignObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/validateDeleteCampaign`, campaignObj);
  }

  getAllAudience(planid) {
    return this.http.post(`${BASE_API_URL}/commsTask/getAllAudience`, { planid });
  }

  checkCampaignIdeaLock(postObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/checkCampaignIdeaLock`, postObj);
  }

  updateCampaignIdeaLock(postObj) {
    return this.http.post(`${CAMPAIGN_API_URL}/updateCampaignIdeaLock`, postObj);
  }

  clearCampaignIdeaForm(formId){
    return this.http.post(`${CAMPAIGN_API_URL}/clearCampaignIdeaForm`,formId);
  }

  clearOpportunityBriefForm(formId){
    return this.http.post(`${CAMPAIGN_API_URL}/clearOpportunityBriefForm`,formId);
  }

  getCampaignName(commsTaskId){
    return this.http.post(`${CAMPAIGN_API_URL}/getCampaingName`,{commsTaskId:commsTaskId});
  }
  getUnallocatedBudget(data){
    return this.http.post(`${BASE_API_URL}/commsTask/getCommsTaskUnallocatedBudget`,data);
  }
  getTotalCommsTasksBudget(data){
    return this.http.post(`${BASE_API_URL}/commsTask/getTotalCommsTaskBudget`,data);
  }
  getCampaignDataForBreadCrump(obj){
    return this.http.post(`${BASE_API_URL}/campaign/getCampaignDataForBreadCrump`,obj);
  }
  getCampaignSummaryData(obj){
    return this.http.post(`${BASE_API_URL}/campaign/getCampaignSummary`,obj);
  }
  getfilteredCampaignAssets(data){
    return this.http.post(`${BASE_API_URL}/campaign/getFilteredCampaignsData`,data);
  }
  getAllMarkets(){
    return this.http.post(`${BASE_API_URL}/campaign/getAllMarkets`,{});
  }
  getCampaignDAMAssets(obj:any){
    return this.http.post<any>(`${CAMPAIGN_API_URL}/getDAMAssets`, obj);
  }
  getAllYears(){
    return this.http.post(`${BASE_API_URL}/campaign/getAllYears`,{});
  }
  downloadCampaignSummaryPPT(obj){
    return this.http.post(`${BASE_API_URL}/campaign/downloadCampaignSummaryPPT`,obj);
  }
  getCampaignsByMjtbdYearMarket(obj){
    return this.http.post(`${BASE_API_URL}/campaign/getCampaignsByMjtbdYearMarket`,obj);
  }
  importCampaign(obj){
    return this.http.post(`${BASE_API_URL}/campaign/importCampaign`,obj);
  }
  gettotalNoOfAssets(Obj){
    return this.http.post<any>(`${CAMPAIGN_API_URL}/gettotalNoOfAssets`, Obj);
  }


}
