import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../../../constants/app-constants';
import { IBreadcrumb, IBreadcrumbDisplayObj, IHierarchy } from './../../interfaces/common';
import { BreadcrumbService } from './../../services/breadcrumb.service';
import { returnAccessflag, isValidUserAccess } from 'app/common/utils/common-util';
import { ACCESS_LEVEL } from 'app/constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { IMarketCurrency } from 'app/common/interfaces/market';
import { environment } from 'environments/environment';
import { getDocsFromSharepointFilesAPI, getFileBuffer, getGpId } from 'app/common/utils/common-util';
import { googleAnalyticsCode } from 'app/util';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnChanges, OnInit, OnDestroy {

  @Input() pathDetails: IBreadcrumb;
  workingYear: number;
  breadcrumbs: Array<IBreadcrumbDisplayObj> = [];
  userInfo = JSON.parse(sessionStorage.getItem("user"));
  breadCrumbsLink: string;
  isSticky: boolean = false;
  constructor(
    private sharedData: SharedDataService,
    private breadcrumbService: BreadcrumbService,
    public globalElementsService: GlobalElementsService,
    private router: Router,
    protected googleTagManagerService: GoogleTagManagerService
  ) {

  }




  ngOnChanges() {
    if (this.pathDetails && this.pathDetails.pageId) {
      this.sharedData.getWorkingYear.subscribe((res: number) => this.workingYear = res);
      this.getHierarchicalpath();
    }

  }
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }
  ngOnInit() {
    // window.addEventListener('scroll', this.scroll, true);
    let element: HTMLElement = document.getElementsByClassName('mat-drawer-content')[0] as HTMLElement;
    element.addEventListener('scroll', this.scroll, true);

  }
  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    // To see what changed:
    const number = event.srcElement.scrollTop;
    if(number > 110) {
      this.isSticky = true
    } else {
      this.isSticky = false;
    }
  };

  getClass(pageType: string, isLastNode: boolean) {
    const portfolioId = this.breadcrumbs.find(el => el.navType === pageType) ? this.breadcrumbs.find(el => el.navType === pageType).id : null;
    const textColorClass = this.isSticky ? "spanScroll" : "spanNormal";
    if (this.userInfo.roleId == 5 && (pageType == 'MARKET')) {

      return `${textColorClass} noLinks`;
    }

    if (isValidUserAccess(5, '==', portfolioId) && (pageType == 'PORTFOLIO')) {
      return `${textColorClass} noLinks`;
    }

    if (isLastNode) {

      return `${textColorClass} noLinks lastNode`;
    }

    return textColorClass
  }



  navRouter(pageType: string, id: number, navType:string) {
    const portfolioId = this.breadcrumbs.find(el => el.navType === navType) ? this.breadcrumbs.find(el => el.navType === pageType).id : null;
    if (this.userInfo.roleId == 5 && (navType == 'MARKET')) {
      return null;
    }
    if (isValidUserAccess(5, '==', portfolioId) && (pageType == 'PORTFOLIO')) {
      return null;
    }
    this.globalElementsService.setSidePanelCancelEvent();

    switch (pageType) {
      case BREADCRUMBS_REFERENCE_TYPES.MARKET:
        this.navigateToMarket(id);
        break;

      case BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO:
        this.navigateToPortfolio(id);
        break;

      case BREADCRUMBS_REFERENCE_TYPES.PLAN:
        this.navigateToPlan(id);
        break;

      case BREADCRUMBS_REFERENCE_TYPES.MJTBD:
        this.navigateToMJTBD(id);
        break;

        case BREADCRUMBS_REFERENCE_TYPES.CAMPAIGN:
        this.navigateToMJTBD(id);
        break;

        case BREADCRUMBS_REFERENCE_TYPES.UPFRONT_SCENARIO:
        this.navigateToUpfrontScenario(id);

      default:
        break;
    }

  }



  navigateToMarket(marketId) {
    this.router.navigate([`/market/${marketId}`]);
  }

  navigateToPortfolio(portfolioId) {
    this.router.navigate([`/portfolio/${portfolioId}`]);
  }

  navigateToPlan(planId) {
    this.router.navigate([`/plan/${planId}`]);
  }

  navigateToUpfrontScenario(planId) {
     this.router.navigate([`/upfrontPlanning/${planId}`]);
  }

  navigateToMJTBD(mjtbdId) {
    this.router.navigate([`/program/${mjtbdId}`]);
  }
  navigateToAccessDenied() {
    this.router.navigate(['/accessDenied']);
  }

  createBreadcrumbPath(breadCrumbsData) {
    this.breadcrumbs = [];
    let marketObj, pfObj, planObj, mjtbdObj, lastNodeObj,campaignObj;

    if (!breadCrumbsData) {
      return null
    }

    marketObj = this.getMarketObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.MARKET]);
    this.breadcrumbs.push(marketObj);


    switch (this.pathDetails.lastNodeType) {


      case BREADCRUMBS_LAST_NODE_TYPES.PORTFOLIO_ALLOCATION:
      case BREADCRUMBS_LAST_NODE_TYPES.MEDIA_CHANNEL_CALENDAR_PF:
      case BREADCRUMBS_LAST_NODE_TYPES.PLAN:
      case BREADCRUMBS_LAST_NODE_TYPES.TCP_TRACKER:
        pfObj = this.getPortfolioObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO])
        this.breadcrumbs.push(pfObj);
        break;

      case BREADCRUMBS_LAST_NODE_TYPES.MEDIA_CHANNEL_CALENDAR_PLAN:
      case BREADCRUMBS_LAST_NODE_TYPES.TCP_DOCUMENT_TRACKER:
      case BREADCRUMBS_LAST_NODE_TYPES.ANNUAL_BRAND_TARGETS:
      case BREADCRUMBS_LAST_NODE_TYPES.FOUNDATIONAL_BRAND_DOCUMENTS:
      case BREADCRUMBS_LAST_NODE_TYPES.TCP_ARCHITECTURE:
      case BREADCRUMBS_LAST_NODE_TYPES.UPFRONT_SCENARIO:
      case BREADCRUMBS_LAST_NODE_TYPES.MJTBD:
        pfObj = this.getPortfolioObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO])
        this.breadcrumbs.push(pfObj);
        planObj = this.getPlanObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PLAN])
        this.breadcrumbs.push(planObj);
        break;
        case BREADCRUMBS_LAST_NODE_TYPES.CAMPAIGN:
        pfObj = this.getPortfolioObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO])
        this.breadcrumbs.push(pfObj);
        planObj = this.getPlanObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PLAN])
        this.breadcrumbs.push(planObj);
        break;

        case BREADCRUMBS_LAST_NODE_TYPES.SCENARIO:
        pfObj = this.getPortfolioObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO])
        this.breadcrumbs.push(pfObj);
        planObj = this.getPlanObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PLAN])
        this.breadcrumbs.push(planObj);
        const upfrontObj = this.getUpfrontObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PLAN]);
        this.breadcrumbs.push(upfrontObj);
        break;


      case BREADCRUMBS_LAST_NODE_TYPES.COMMSTASK:
        pfObj = this.getPortfolioObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO])
        this.breadcrumbs.push(pfObj);
        planObj = this.getPlanObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.PLAN])
        this.breadcrumbs.push(planObj);
        mjtbdObj = this.getMJTBDObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.MJTBD])
        if(mjtbdObj.name.length > 50){
          const shortName = mjtbdObj.name.slice(0,50).concat('...');
          mjtbdObj.name = shortName;
        }
        this.breadcrumbs.push(mjtbdObj);
        campaignObj = this.getCAMPAIGNObj(breadCrumbsData[BREADCRUMBS_REFERENCE_TYPES.CAMPAIGN])
        this.breadcrumbs.push(campaignObj);
        break;


      default:
        break;
    }

    if (this.breadcrumbs.length) {
      lastNodeObj = this.getLastNode();
      /* commenting this code to stop to move in prod
      if(this.breadcrumbs.length==4 &&
        this.breadcrumbs[3].name.toLowerCase().includes("upfront scenario")){
        lastNodeObj=this.getSubBrandCamelCase(lastNodeObj)
      }*/
      this.breadcrumbs.push(lastNodeObj);
    }

    let path = "";
    this.breadcrumbs.forEach(el => {
      if(el && el.name ){
        path += "/"+el.name;
      }
    });
    if(path && this.pathDetails.lastNodeType!= 'TCP_ARCHITECTURE'){
      let tagObject =  {
        event: `custom_${environment.environmentName}_pageView`,
        pageUrl: path,
        pageTitle: `${this.pathDetails.lastNodeType} Page`,
        userId: getGpId(),
        user_id: getGpId()
      }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
    }
 }

  getMarketObj(marketDetails): IBreadcrumbDisplayObj {
    const marketObj: IBreadcrumbDisplayObj = {
      id: marketDetails.id,
      name: marketDetails.name,
      navType: BREADCRUMBS_REFERENCE_TYPES.MARKET,
      isLastNode: false
    }
    return marketObj
  }


  getPortfolioObj(pfDetails): IBreadcrumbDisplayObj {
    const portfolioObj: IBreadcrumbDisplayObj = {
      id: pfDetails.id,
      name: pfDetails.name,
      navType: BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO,
      isLastNode: false
    }
    return portfolioObj
  }

  getPlanObj(planDetails): IBreadcrumbDisplayObj {
   let plan= planDetails.name;//.toLowerCase();
     const arr = plan.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
     const planName = arr.join(" ");
    const planObj: IBreadcrumbDisplayObj = {
      id: planDetails.id,
      name: planName,
      navType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
      isLastNode: false
    }
    if(planObj.name.includes('Rte')){
      let replaceData = planObj.name.replace("Rte","RTE");
      console.log(replaceData);
      planObj.name = replaceData;
    }
    return planObj
  }
  getSubBrandCamelCase(SubBrandDetails): IBreadcrumbDisplayObj {
    let SubBrand= SubBrandDetails.name;//.toLowerCase();
     const arr = SubBrand.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
    const subBrandName = arr.join(" ");
    const subBrandObj: IBreadcrumbDisplayObj = {
      id: SubBrandDetails.id,
      name: subBrandName,
      navType: BREADCRUMBS_REFERENCE_TYPES.PLAN_ENTITY,
      isLastNode: true
    }

    return subBrandObj
  }

  getMJTBDObj(prgmDetails): IBreadcrumbDisplayObj {
    const planObj: IBreadcrumbDisplayObj = {
      id: prgmDetails.id,
      name: prgmDetails.name,
      navType: BREADCRUMBS_REFERENCE_TYPES.MJTBD,
      isLastNode: false
    }
    return planObj
  }

  getCAMPAIGNObj(prgmDetails): IBreadcrumbDisplayObj {
    const planObj: IBreadcrumbDisplayObj = {
      id: prgmDetails.mjtbdId,
      name: prgmDetails.campaignName,
      navType: BREADCRUMBS_REFERENCE_TYPES.CAMPAIGN,
      isLastNode: false
    }
    return planObj
  }


  getUpfrontObj(planDetails): IBreadcrumbDisplayObj {
    const upfrontObj: IBreadcrumbDisplayObj = {
      id: planDetails.id,
      name: this.pathDetails.upfrontScenrioName,
      navType: BREADCRUMBS_REFERENCE_TYPES.UPFRONT_SCENARIO,
      isLastNode: false
    }

    return upfrontObj
  }

  getLastNode(): IBreadcrumbDisplayObj {
    const lastNode: IBreadcrumbDisplayObj = {
      name: this.pathDetails.lastNodeName,
      fullName: this.pathDetails.fullName,
      isLastNode: true
    }
    return lastNode;
  }



  getHierarchicalpath() {
    if (!this.workingYear) {
      return null
    }

    const postObj = this.constructHierarchyObj();
    this.breadcrumbService.getHierarchy(postObj).subscribe(res => {
      const returnAccessFlagResult = res[BREADCRUMBS_REFERENCE_TYPES.PLAN] && returnAccessflag(ACCESS_LEVEL.PLAN, res[BREADCRUMBS_REFERENCE_TYPES.PLAN].id);

      if(returnAccessFlagResult && res[BREADCRUMBS_REFERENCE_TYPES.PLAN]  && !returnAccessFlagResult.result) {
        this.navigateToAccessDenied();
      } else if(returnAccessFlagResult && returnAccessFlagResult.marketChange && returnAccessFlagResult.marketId) {
        this.globalElementsService.getCurrencyExchange(returnAccessFlagResult.marketId).subscribe((_res:IMarketCurrency) => {
          if(_res) {
              const xchangeRate = Number(_res['conversionFactor']);
              sessionStorage.setItem('currencySign', _res['currencySign']);
              this.sharedData.setMarket(returnAccessFlagResult.marketId);
              this.sharedData.setCurrencyView(_res['currencyCode']);
              this.sharedData.setCurrencySymbolSource(_res['currencySign'] ? _res['currencySign'] : '$');
              this.sharedData.setCurrencyExchangeRate(xchangeRate);
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.navigate([this.router.url]);
          }
      }, (err) => {

      });
    }
      this.createBreadcrumbPath(res);
    })
  }



  constructHierarchyObj(): IHierarchy {

    const hierarchyObj: IHierarchy = {
      referenceId: this.pathDetails.pageId,
      referenceType: this.pathDetails.pageRefType,
      year: this.workingYear

    }
    return hierarchyObj
  }
}
