import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { IHierarchy } from '../interfaces/common';

const BASE_API_URL = `${environment.api}/analytics`;

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

    constructor(
        private http: HttpClient
    ) { };

    public getHierarchy(lastNodeEle: IHierarchy) {
        return this.http.post(`${BASE_API_URL}/getHierarchy`, lastNodeEle)
    }



}