import { UsrMgmtActions } from './../../../common/interfaces/userManagement';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from '../../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementServiceService {

  usrMgmt: UsrMgmtActions = {
    mode: 'default',
    data: null
  };
  private usrMgmtSource = new BehaviorSubject<any>(this.usrMgmt);

  public usrMgmtActions = this.usrMgmtSource.asObservable();

  updateUserMgmtMode(actionType: UsrMgmtActions) {
  
    this.usrMgmtSource.next(actionType);
  }

}

