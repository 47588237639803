import { forEach } from "lodash";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { filter, map, switchMap } from "rxjs/operators";
import { environment } from "environments/environment";
import { IEmbedConfiguration, models } from "powerbi-client";
import { HttpApiService } from "../../../core/http-core/api/http-api.service";
import { IHttpApiRequest } from "../../../core/http-core/api/interface/ihttp-api-request";
import { BehaviorSubject, Observable, interval } from "rxjs";
import { eToken } from "app/common/interfaces/powerbitoken";
const BASE_API_URL = `${environment.api}`;

@Injectable({
  providedIn: "root",
})
export class PowerBIDashboardService {
  accessToken = { token: "", expiry: "" };
  // token: string = "";
  reportId: string;
  reportEmbedUrl: string;
  workspaceId: string;
  datasetId: string;
  config: IEmbedConfiguration;
  expiry: string;
  reportsInfo: {
    reportId: string;
    portfolioId: string;
    reportType: string;
    datasetId: string;
    workspaceId: string;
    reportEmbedUrl: string;
    embedToken: { token: ""; expiry: "" };
  }[];
  private embedToken = new BehaviorSubject<string | null>("");
  private accTokenSubject = new BehaviorSubject<string | null>("");
  private tokenSubject = new BehaviorSubject<eToken>({
    token: "",
    expiry: "",
  });
  private tokenObservable = this.tokenSubject.asObservable();
  castToken = this.accTokenSubject.asObservable();
  castembedToken = this.tokenSubject.asObservable();
  embedReportConfigList: any; // used in powerbiReportsDashboardComponent
  constructor(
    private httpClient: HttpClient //private refreshPowerBITokenService: RefreshPowerBITokenService
  ) {
    this.embedToken = new BehaviorSubject<string>("");
    // Initiate the token retrieval and refresh process
    // this.watchForToken();
  }
  /**
   * @name getAuthToken
   * @description method to get power Bi AccessToken using service principal credentials
   * @returns {string} - access token
   */
  getAuthToken() {
    this.getAccessToken().subscribe(
      (res: any) => {
        //console.log("azure AccessToken response", res.token);
        this.accessToken = res.token;
        this.accTokenSubject.next(this.accessToken.token);
        //getEmbedParamsForMultipleReports method is used to get the report details like embed url, embed token for multiple reports
        this.getEmbedParamsForMultipleReports(res.token).subscribe(
          (reportConfigList) => {
            if (reportConfigList) {
              console.log("this.reportConfigList", reportConfigList);
              this.embedReportConfigList = reportConfigList.reportsDetail;
              if (reportConfigList.embedToken) {
                this.setToken({
                  token: reportConfigList.embedToken.token,
                  expiry: reportConfigList.embedToken.expiration,
                });
                //this.embedReportTokenConfig(reportConfigList.embedToken.token);
              }
              console.log("App Comp multi embedUrls:: ", reportConfigList);
            }
          },
          (error) => console.error(error)
        );
      },
      (error) => {
        console.error("Error getting access token:", error);
      }
    );
  }
  //service start
  getAccessToken(): Observable<any> {
    return this.httpClient.get(`${BASE_API_URL}/powerbi/getAccessToken`);
  }
  getToken(): Observable<eToken> {
    return this.tokenObservable;
  }
  setToken(token: eToken) {
    this.tokenSubject.next(token);
  }
  // watchForToken() {
  //   this.getToken().subscribe((token: eToken) => {
  //     // this.embedReportTokenConfig(token.token);
  //   });
  // }

  /**
   * @name getPowerBIReportforPortfolio
   * @description get the portfolio id of the report to verifiy the report is available or not
   * @param {string} portfolioId - portfolio id
   * @returns {boolean} - reportFound or not found
   */
  getPowerBIReportforPortfolio(portfolioId): Observable<any> {
    const postObj = {
      portfolioId: portfolioId,
    };
    console.log("Embed Url :::", postObj);
    return this.httpClient.post(
      `${BASE_API_URL}/powerbi/getPowerBIReportforPortfolio`,
      postObj
    );
  }
  /**
   * @name getEmbedParamsForMultipleReports
   * @description get the report embed details like embed url, embed token for multiple reports
   * @param {string} portfolioId - portfolio id
   * @returns {boolean} - report details like embed url, embed token
   */
  getEmbedParamsForMultipleReports(accessToken: string): Observable<any> {
    const postObj = {
      token: accessToken,
    };
    console.log("Embed Url :::", postObj);
    return this.httpClient.post(
      `${BASE_API_URL}/powerbi/getEmbedParamsForMultipleReports`,
      postObj
    );
  }

  // embedReportTokenConfig(token: string) {
  //   if (this.config && this.config.accessToken) {
  //     this.config.accessToken = token;
  //   }
  // }

  /**
   * @name getPowerBIReportUrls
   * @description get the RTO power BI report url
   * @param {string} portfolioId - portfolio id
   * @returns {string} - report url
   */
  getPowerBIReportUrls(reqObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/powerbi/getPowerBIReportUrls`,
      reqObj
    );
  }
}
