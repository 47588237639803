import { BrandManagementDialogsComponent } from './../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, OnInit, Output, OnChanges, Input, OnDestroy } from '@angular/core';
import { IPortfolioGroup } from './../../../../common/interfaces/brandManagement';
import { BrandManagementService } from './../../../services/brand-management.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-homepage-portfolio-group',
  templateUrl: './homepage-portfolio-group.component.html',
  styleUrls: ['./homepage-portfolio-group.component.scss']
})
export class HomepagePortfolioGroupComponent implements OnInit, OnChanges, OnDestroy {

  @Output() portfolioGroupSelectionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Input() pageMode;

  portfolioGroups: Array<IPortfolioGroup> = [];
  selectedPortfolioGroupList = [];
  isLoading = true;
  errorLoading = false;

  constructor(
    public dialog: MatDialog,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this.getPortfolioGroups();
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Group_Management`,
      eventAction: "PageView",
      eventLable: "Global Elements Portfolio Group Management Page view",
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      this.handleDelete()
    }
  }

  isPortfolioGroupSelected(pfGrpId) {
    return this.selectedPortfolioGroupList.includes(pfGrpId)
  }


  selectPortfolioGroup(pfGrpId) {

    if (this.selectedPortfolioGroupList.includes(pfGrpId)) {
      this.selectedPortfolioGroupList = this.selectedPortfolioGroupList.filter(el => el != pfGrpId)
    } else {
      this.selectedPortfolioGroupList.push(pfGrpId);
    }

    const pfGroupsList = this.portfolioGroups.filter(mkt => this.selectedPortfolioGroupList.includes(mkt.id))
    this.portfolioGroupSelectionEvent.emit(pfGroupsList);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Group_Management`,
      eventAction: "Select Portfolio Group click",
      eventLable: `Global Elements select Portfolio Group click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  handleDelete(){

    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        type: 'delete',
        module: 'portfolioGroup',
        apiData: {
          portfolioGroups: this.selectedPortfolioGroupList
        }
      },

      minWidth: '500px'
    })


    dialogRef.beforeClose().subscribe(() => {
      this.selectedPortfolioGroupList = [];
      this.getPortfolioGroups();
      this.portfolioGroupSelectionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });
  }

  getPortfolioGroups(){
    this.isLoading = true;
    this.errorLoading = false;
    this.brandManagementService.getAllPortfolioGroups().subscribe((res: Array<IPortfolioGroup>) => {
      this.portfolioGroups = res;
      this.isLoading = false;
      this.errorLoading = false;
    }, () => this.errorLoading = true);
  }

  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Group_Management`,
      eventAction: "Portfolio Group Management Page Exit",
      eventLable: `Global Elements Portfolio Group Management Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
}
