import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ITeamMembers } from '../../../interfaces/common';
import * as  _  from 'lodash';

@Component({
  selector: 'app-team-members-dialog',
  templateUrl: './team-members-dialog.component.html',
  styleUrls: ['./team-members-dialog.component.scss']
})
export class TeamMembersDialogComponent implements OnInit {

  teamMembers: Array<ITeamMembers> = [];
  selectedMembers: Array<number> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.teamMembers = this.data.teamMembers ? this.data.teamMembers : [];
    this.selectedMembers = this.data.selectedMembers ? this.data.selectedMembers : [];
  }
  sendData(){
    return this.teamMembers;
  }
  sendSelectedData(){
   return this.selectedMembers; 
  }
  selectChange = (event: any) => {
    const teams = event.data.map(el => el.userId);
    this.selectedMembers = teams;
  };


}
