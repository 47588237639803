import * as Color from 'color';

const OOH = Color('#2196F3');
const DIGITAL = Color('#8BC34A');
const AUDIO = Color('#FFC107');
const PRINT = Color('#F44336');
const TV = Color('#00BCD4');

export const GroupColorConstants = {
  OOH: OOH.hex(),
  DIGITAL: DIGITAL.hex(),
  AUDIO: AUDIO.hex(),
  PRINT: PRINT.hex(),
  TV: TV.hex(),

  DISPLAY: DIGITAL.lighten(0.15).hex(),
  OLV: DIGITAL.lighten(0.25).hex(),
  VIDEO: DIGITAL.lighten(0.35).hex(),
  'CONTENT PARTNERSHIPS': DIGITAL.lighten(0.45).hex(),
  SEM: DIGITAL.lighten(0.55).hex(),
  ECOMM: DIGITAL.lighten(0.65).hex(),

  // DIGITAL
  'FACEBOOK/IG': DIGITAL.darken(0.15).hex(),
  TWITTER: DIGITAL.darken(0.25).hex(),
  PINTEREST: DIGITAL.darken(0.35).hex(),
  SNAPCHAT: DIGITAL.darken(0.45).hex(),

  // AUDIO
  'TERRESTRIAL AUDIO': AUDIO.darken(0.15).hex(),
  'LOCAL TERRESTRIAL RADIO': AUDIO.darken(0.30).hex(),
  'STREAMING AUDIO': AUDIO.lighten(0.15).hex(),

  // PRINT
  MAGAZINES: PRINT.darken(0.15).hex(),
  NEWSPAPER: PRINT.lighten(0.15).hex(),

  // TV
  'LINEAR TV': TV.darken(0.15).hex(),
  OTT: TV.darken(0.30).hex(),
  ADDRESSABLE: TV.lighten(0.15).hex(),
  FEP: TV.lighten(0.30).hex(),
};
