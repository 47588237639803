import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PAGE_MODES } from './../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-brand-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketBrandComponent implements OnInit {
  
  @Output('dirty')  formDirty = new EventEmitter<boolean>()

  selectedMarketList = [];
  mode = PAGE_MODES.HOME;
  userInfo: any;
  
  constructor(protected googleTagManagerService: GoogleTagManagerService
  ) { this.userInfo = JSON.parse(sessionStorage.getItem("user"));}

  ngOnInit() {
  }

  getSelectedMarket(childEmmitedEvent) {
    this.selectedMarketList = childEmmitedEvent;
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME || this.mode == PAGE_MODES.DELETE;
  }

  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "Add Market Button Clicked",
      eventLable: `Global Elements New Market Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);   
  }


  enableEditMode() {
    this.changePageMode(PAGE_MODES.EDIT);  
   
  }


  enableHomePageMode() {
    this.changePageMode(PAGE_MODES.HOME);
  }

  enableDeleteMode() {
    this.changePageMode(PAGE_MODES.DELETE);
  }

  changePageMode(pageMode) {
    this.mode = pageMode
  }

  isFormDirty(isDirty: boolean) {
    this.formDirty.emit(isDirty);
  }


}
