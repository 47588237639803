import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  loader = { isLoading: false };
  constructor(
  ) { }

}
