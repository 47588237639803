import { ICheckEditLock, IEditLock } from "./../common/interfaces/program";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { FileUploadDialogComponent } from "app/common/components/dialogs/file-upload-dialog/file-upload-dialog.component";
import { ProgramDialogComponent } from "app/common/components/dialogs/program-dialog/program-dialog.component";
import {
  IBreadcrumb,
  IDocument,
  IOverview,
} from "app/common/interfaces/common";
import { ISectionHeader } from "app/common/interfaces/overviewSection";
import { IPlanOverviewData } from "app/common/interfaces/plan";
import { SharedDataService } from "app/common/services/shared-data.service";
import {
  getDocsFromSharepointFilesAPI,
  getFileBuffer,
  getGpId,
  isValidUserAccess,
} from "app/common/utils/common-util";
import {
  FILE_NAMES,
  FILE_TYPE,
  GLOBAL_ELEMENTS,
  SHAREPOINT_DOC_PATH,
  TCP_DOC_ID,
} from "app/constants/app-constants";
import { SECTION_HEADER_INFO } from "app/constants/overview-constants";
import { PlanService } from "app/plan/services/plan.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import {
  ICampaignIdeaSave,
  ICampaignKPIandAudience,
  ICommsTaskSave,
  IOppBriefSave,
} from "../common/interfaces/program";
import { GlobalElementsService } from "../global-elements/services/global-elements.service";
import {
  AGGR_VIEW,
  BREADCRUMBS_LAST_NODE_TYPES,
  BREADCRUMBS_REFERENCE_TYPES,
} from "./../constants/app-constants";
import { ProgramService2 } from "./services/program.service";
import { environment } from "environments/environment";
import { StoreService } from "../old/_services/_store.service";
import { googleAnalyticsCode } from "app/util";
import { saveAs } from "file-saver";
import { fileMimeTypes } from "app/constants/app-constants";
import ab2b64 from "ab2b64";
import { DownloadFileDialogComponent } from "app/common/components/dialogs/download-file-dialog/download-file-dialog.component";

// const USER_INFO = JSON.parse(sessionStorage.getItem('user'));

@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.scss"],
})
export class ProgramComponent implements OnInit {
  @ViewChild("logoUploadDialog", { static: true })
  logoUploadDialog: TemplateRef<any>;
  @ViewChild("addCommsTaskDialog", { static: true })
  addCommsTaskDialog: TemplateRef<any>;
  @ViewChild("importCampaignDialog", { static: true })
  importCampaignDialog: TemplateRef<any>;
  userInfo = JSON.parse(sessionStorage.getItem("user"));

  // campaignForm: FormGroup;
  commsTaskForm: FormGroup;
  campaignForm: any = [];
  commsTasksData: any;
  mjtbdName: string;
  isLoading: boolean = false;
  planningYear: number;
  type: string = "program";
  planItViewovwData: Array<IOverview>;
  tcpViewOvwData: Array<IOverview>;
  overviewData: Array<IOverview>;
  sectionHeader: ISectionHeader;
  paramsId: number;
  isAddCampaign: boolean = false;
  isCampaign: boolean = false;
  campaignName = new FormControl("");
  campaignId = new FormControl("");
  brandProblemToSolve: string;
  campaignList = [];
  campaignAudienceList: Array<string> = [];
  selectedCampaignAudience: Array<string> = [];
  campaignTitle: string = "";
  campaignIdea: string = "";
  selectedLogoImg: string = "";
  assets;
  selectedCampaignId: number;
  selectedDocument: string;
  oppBriefAudienceDocList = [];
  oppBriefLearningDocList;
  hierarchy: string;
  planId: number;
  marketId: number;
  planName: string;
  aggrView: string;
  pathDetails: IBreadcrumb;
  cancelBtnSubscription: Subscription;
  ideaFormDocList: Array<IDocument> = [];
  fullName = "";
  base64textString: string;
  workingYear: any;
  userName: string;
  marketsList: [];
  selectedCampaign: any;
  selectedYear: any;
  selectedMarket: any;
  currentPlanName: any;
  FilteredCampaignList: any = [];
  newCampaignName = "";
  USER_INFO: any;
  isEditCampaign: boolean = false;
  commsTaskFormMode = "save";
  selectedCommsTaskID: any;
  formUpdated: boolean = false;
  commsFormSub: Subscription;

  constructor(
    private programService: ProgramService2,
    private globalElementsService: GlobalElementsService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder,
    public router: Router,
    private planService: PlanService,
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService,
    private store: StoreService
  ) {}

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.USER_INFO = JSON.parse(this.store.get("user"));

    this.sharedDataService.getAggrView.subscribe((viewType: string) => {
      this.aggrView = viewType;
      if (this.overviewData) {
        this.overviewData =
          this.aggrView == AGGR_VIEW.PLANIT
            ? this.planItViewovwData
            : this.tcpViewOvwData;
      }
    });

    this.getCampaignDAMLogos();
    this.route.params.subscribe((params) => {
      const mjtbdId = params["id"];
      this.paramsId = mjtbdId && Number(mjtbdId);
    });

    this.sharedDataService.getPlanningYear.subscribe((res: any) => {
      console.log("peogram page planningYear", res);
      if (res) {
        this.planningYear = res;
        this.getMjtbdData();
      }
    });

    this.sharedDataService.sideNavSelection.subscribe((res: any) => {
      if (res && res.id == GLOBAL_ELEMENTS.DEFAULT.id) {
        this.getCampaigns();
      }
    });
    this.getKPIsAndAudience();

    this.initiateCommsTaskForm();
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `MJTBD_Page`,
      eventAction: "PageView",
      eventLable: `MJTBD Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);

    // this.sharedDataService.getWorkingYear.subscribe(res => this.workingYear = res);

    this.globalElementsService.getCanvasYears().subscribe((res) => {
      this.workingYear = res;
    });
    this.marketsList = this.USER_INFO
      ? this.USER_INFO.markets.sort((a, b) =>
          a.displayName >= b.displayName ? 1 : -1
        )
      : [];
  }

  refreshDeletedFiles() {
    this.getOppBriefDocsFromSharepoint();
  }

  initiateCommsTaskForm() {
    this.commsTaskForm = this.fb.group({
      commsTaskName: ["", Validators.required],
      campaignId: ["", Validators.required],
    });
  }

  /*........... functionality Methods........ */

  commsTaskFormErrorHandeling(control: string, error: string) {
    return this.commsTaskForm.controls[control].hasError(error);
  }

  newcampaignList(el: any) {
    let oppForm = new FormGroup({});
    if (el && el.oppForm) {
      el.oppForm["hashTagStatus"] = false;
      el.oppForm["strategyStatus"] = false;
      el.oppForm["advantageStatus"] = false;

      el.oppForm["target"] = "";
      el.oppForm["whatWouldWeDo"] = "";
      el.oppForm["targetStatus"] = false;
      el.oppForm["whatWouldWeDoStatus"] = false;
      el.oppForm["ciTitle"] = false;

      for (let [key, value] of Object.entries(el.oppForm)) {
        oppForm.addControl(key, new FormControl(value));
      }
    }

    // const audienceDocObj = this.oppBriefAudienceDocList.find(doc => doc.title == `${el.campaignName} ${FILE_NAMES.OPP_BRIEF_AUDIENCE}`);
    // const learningPlanDocObj = this.oppBriefLearningDocList.find(doc => doc.title.split('.').pop() == `${el.campaignName} ${FILE_NAMES.OPP_BRIEF_LEARNING}`)
    const audienceDocObj = this.oppBriefAudienceDocList.find((d) => {
      let extension = d.title.split(".").pop();
      let maintitle = `${this.planningYear}${this.planName}${el.campaignName}${TCP_DOC_ID.AUDIENCE_CONSIDERATION}.${extension}`;
      maintitle = maintitle.replace(/ /g, "");
      if (d.title == maintitle) {
        return true;
      }
    });

    const learningPlanDocObj = this.oppBriefLearningDocList.find((d) => {
      let extension = d.title.split(".").pop();
      let maintitle = `${el.campaignName} ${FILE_NAMES.OPP_BRIEF_LEARNING}.${extension}`;
      if (d.title == maintitle) {
        return true;
      }
    });

    const campIdeaDocObj = this.ideaFormDocList.filter((doc) => {
      return doc.title.includes(el.campaignName);
    });

    if (
      el.campaignIdeas &&
      el.campaignIdeas.length > 0 &&
      el.campaignIdeas[0]
    ) {
      el.campaignIdeas[0].campaignImage = el.campaignIdeas[0].campIdeaImageURL
        ? el.campaignIdeas[0].campIdeaImageURL
        : "";
    } else {
    }

    return {
      campaignId: el.campaignId,
      campaignName: el.campaignName,
      oppForm: oppForm,
      audienceDoc: audienceDocObj,
      learningPlanDoc: learningPlanDocObj,
      campaignIdeas: el.campaignIdeas,
      brandProblemToSolve: this.brandProblemToSolve,
      campaignFormDoc: campIdeaDocObj,
    };
  }

  patchCampaignForm(campaignList) {
    this.campaignForm = [];
    campaignList.forEach((el: any) => {
      let newcampaignListForm = this.newcampaignList(el);
      this.campaignForm.push(newcampaignListForm);
    });
  }

  enableAddCampaign() {
    this.campaignName.setValue("");
    this.isAddCampaign = true;
    this.isEditCampaign = false;
  }

  downloadFile(path: string) {
    let fileName = path.split("/").pop();
    path = path ? path.split("/Shared Documents/")[1] : "";
    let docPath = path.split(`/${fileName}`)[0];
    const extension = fileName.substring(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    const downloadObj = {
      fileName: fileName,
      path: path,
      docPath: docPath,
      extension: extension,
    };
    let dialogRef;
    dialogRef = this.dialog.open(DownloadFileDialogComponent, {
      data: downloadObj,
      minWidth: "500px",
      disableClose: true,
    });
    // let fileName = path.split('/').pop();
    // const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    // this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
    //   this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
    // const byteCharacters = window.atob(response['thumbnailb64data']);
    // const downloadLink = document.createElement("a");
    // downloadLink.download = fileName.replace(/ /g,'');
    // downloadLink.href = source;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    // saveAs(blob, fileName.replace(/ /g,''));
    // })
  }
  downloadFileFromBase64(fileName, b64encodedString: string, contentType) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    let byteArrays = ab2b64.b642ab(b64Data);
    return new Blob([byteArrays], { type: contentType });
  }

  updateOppBrief(oppBrief) {
    const oppBriefObj = this.constructOppBriefObj(oppBrief);
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "update",
        type: "updateOppBrief",
        apiData: oppBriefObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getCampaigns();

      this.dialog.closeAll();
    });
  }

  clearOptyBrief(optyFormData) {
    const dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "delete",
        type: "clearOptyBrief",
        apiData: optyFormData.obFormId,
        planId: this.planId,
      },
      minWidth: "500px",
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      this.getCampaigns();
    });
  }

  editOppBrief(data, campaignName: string) {
    data.workingMediaBudget = Number(data.workingMediaBudget).toLocaleString();
    let sidePanelEle = { ...GLOBAL_ELEMENTS.OPP_BRIEF };
    sidePanelEle.oppBriefData = {
      mjtbdId: this.paramsId,
      mjtbdName: this.mjtbdName,
      oppBriefData: data,
      campaignName,
      year: this.planningYear,
      hierarchy: this.hierarchy,
      planId: this.planId,
    };
    this.cancelBtnSubscription =
      this.globalElementsService.getSidePanelCancelEvent
        .pipe(take(1))
        .subscribe(() => this.refreshDeletedFiles());
    this.sharedDataService.setSideNavSelection(sidePanelEle);
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `MJTBD_Page`,
      eventAction: "Edit Opportunity Brief",
      eventLable: `Edit Opportunity Brief Button Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  clearCampIdea(campIdea) {
    const formId = campIdea["ciFormId"];

    const dbLockChkObj: ICheckEditLock = {
      formId,
      userId: getGpId(),
    };

    //this.programService.checkCampaignIdeaLock(dbLockChkObj).subscribe(() => {

    const dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "delete",
        type: "campaignIdea",
        apiData: formId,
        planId: this.planId,
      },
      minWidth: "500px",
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      this.getCampaigns();
    });

    /* }, err => {

    if (err.status == 476) {
      const displayMsg = err.error.message
      this.dialog.open(ProgramDialogComponent, {
        data: { mode: 'editLock', displayMsg, planId: this.planId },
        minWidth: '500px'
      });
    }
  })*/
  }

  editCampIdea(campIdea, campaignName: string, campaignId?) {
    campIdea["campaignId"] = campaignId;
    campIdea["mjtbdId"] = this.paramsId;
    campIdea["year"] = this.planningYear;
    const formId = campIdea["ciFormId"];

    const dbLockChkObj: ICheckEditLock = {
      formId,
      userId: getGpId(),
    };

    // this.programService.checkCampaignIdeaLock(dbLockChkObj).subscribe(() => {

    let sidePanelEle = { ...GLOBAL_ELEMENTS.CAMPAIGN_IDEA };
    sidePanelEle.campIdeaData = {
      mjtbdId: this.paramsId,
      mjtbdName: this.mjtbdName,
      mode: "update",
      data: campIdea,
      campaignName,
      year: this.planningYear,
      hierarchy: this.hierarchy,
      planId: this.planId,
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
    /*const editLockObj : IEditLock = {
        beingEdited:'Y',
        editingBy: getGpId(),
        formId
      }
      this.programService.updateCampaignIdeaLock(editLockObj).subscribe(()=>{});*/

    //}, err => {

    /*if (err.status == 476) {
        const displayMsg = err.error.message
        this.dialog.open(ProgramDialogComponent, {
          data: { mode: 'editLock', displayMsg, planId: this.planId },
          minWidth: '500px'
        });*/
    //}
    //})

    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `MJTBD_Page`,
      eventAction: "Edit Campaign Idea Click",
      eventLable: `Edit Campaign Idea Button Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  addCampIdea(campIdea, campaignName: string, campaignId?, campaignIdeas?) {
    campIdea["campaignId"] = campaignId;
    campIdea["mjtbdId"] = this.paramsId;
    campIdea["year"] = this.planningYear;
    let sidePanelEle = { ...GLOBAL_ELEMENTS.CAMPAIGN_IDEA };
    sidePanelEle.campIdeaData = {
      mjtbdId: this.paramsId,
      mjtbdName: this.mjtbdName,
      mode: "save",
      data: campIdea,
      campaignIdeaList: campaignIdeas,
      campaignName,
      year: this.planningYear,
      hierarchy: this.hierarchy,
      planId: this.planId,
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
  }

  enableOppBriefInputs(type: string, fctrl: FormControl) {
    fctrl.get(type).setValue(true);
  }

  updateCampaignIdeaTitle(evtVal: string) {
    this.campaignTitle = evtVal;
  }

  updateCampaignIdea(ideaName: string) {
    this.campaignIdea = ideaName;
  }

  deleteFile(doc: IDocument) {
    const docObj = {
      isDelete: true,
      fileName: doc.fileName,
      docPath: doc.path.split("/Shared Documents/")[1],
    };
    let dialogRef;
    dialogRef = this.dialog.open(FileUploadDialogComponent, {
      data: { fileType: "", docObj },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.status) {
      }
      this.getOppBriefDocsFromSharepoint();
      this.dialog.closeAll();
    });
  }

  navigateToCreativeScorcard() {
    window.open("https://app.creativex.com/login?login_org=pepsico", "_blank");
  }

  navigateToAllocation(type: string) {
    let sidePanelEle;
    switch (type) {
      case "RTO":
        sidePanelEle = { ...GLOBAL_ELEMENTS.DATORAMA_DASHBOARD };
        sidePanelEle.datoramaData = {
          marketId: this.marketId,
          planId: this.planId,
          planName: this.planName,
        };
        this.sharedDataService.setSideNavSelection(sidePanelEle);
        let tagObject = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `MJTBD_Page`,
          eventAction: "RTO Dashboard Click",
          eventLable: `RTO Dashboard Button Clicked From MJTBD Page`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject, this.googleTagManagerService);
        break;

      case "CPD":
        sidePanelEle = { ...GLOBAL_ELEMENTS.CPERF_DASHBOARD };
        sidePanelEle.cPerfData = {
          marketId: this.marketId,
          planId: this.planId,
          planName: this.planName,
        };
        this.sharedDataService.setSideNavSelection(sidePanelEle);
        let tagObject1 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `MJTBD_Page`,
          eventAction: "Campaign Perofrmance Dashboard",
          eventLable: `Campaign Perofrmance Dashboard Clicked From MJTBDPage Page`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject1, this.googleTagManagerService);
        break;

      case "CMT":
        this.commsTaskFormMode = "save";
        this.formUpdated = false;
        this.commsTaskForm.patchValue({
          commsTaskName: null,
          campaignId: null,
        });

        this.dialog.open(this.addCommsTaskDialog, {
          height: "400px",
          width: "500px",
          disableClose: true,
        });
        //this.router.navigate(['/commsTask']);
        let tagObject2 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `MJTBD_Page`,
          eventAction: "Create Comms Task",
          eventLable: `Create Comms Task Button Clicked`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject2, this.googleTagManagerService);
        break;

      case "importCampaign":
        this.dialog.open(this.importCampaignDialog, {
          height: "450px",
          width: "500px",
          disableClose: true,
        });
        //this.router.navigate(['/commsTask']);
        let tagObject3 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `MJTBD_Page`,
          eventAction: "Create Comms Task",
          eventLable: `Create Comms Task Button Clicked`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject3, this.googleTagManagerService);
        break;

      default:
        break;
    }
  }
  openEditCommsTask(commsTask) {
    this.commsTaskFormMode = "edit";
    this.formUpdated = false;
    this.selectedCommsTaskID = commsTask.id;
    this.commsTaskForm.patchValue({
      commsTaskName: commsTask.name,
      campaignId: commsTask.campaignId,
    });
    this.commsFormSub = this.commsTaskForm.valueChanges.subscribe((res) => {
      this.formUpdated = true;
    });

    this.dialog.open(this.addCommsTaskDialog, {
      height: "400px",
      width: "500px",
      disableClose: true,
    });
    //this.router.navigate(['/commsTask']);
    let tagObject4 = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `MJTBD_Page`,
      eventAction: "Update Comms Task",
      eventLable: `Update Comms Task Button Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject4, this.googleTagManagerService);
  }

  saveCampaignName() {
    if (this.campaignName.valid) {
      this.saveCampaign();
    }
  }

  campaignAudienceChange(selectedAUdience) {
    this.selectedCampaignAudience = selectedAUdience;
  }

  saveCampaignDetails(campaignId: number) {
    const campaignIdeaObj: ICampaignIdeaSave =
      this.constructCampaignIdeaObj(campaignId);

    this.saveCampaignIdea(campaignIdeaObj);
  }

  openImgUploadDialog(campaignId: number) {
    this.selectedCampaignId = campaignId;
    this.dialog.open(this.logoUploadDialog, {
      height: "700px",
      width: "800px",
      disableClose: true,
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  selectLogo(sel: any) {
    this.assets.forEach((element) => {
      delete element.selected;
      if (element.webImage == sel.webImage) {
        element.selected = true;
        this.selectedLogoImg = sel.webImage;
        this.saveCampaignDetails(this.selectedCampaignId);
      }
    });
    this.closeDialog();
  }

  fileChangeEvent(event: any, fileType, campaignName: string): void {
    let fileList: FileList = event.target.files;
    this.selectedDocument = fileList[0].name;
    getFileBuffer(fileList[0]).then(
      (fileBufferArray: ArrayBuffer) => {
        let fileName;
        let path;
        let subPath = `${SHAREPOINT_DOC_PATH.TCP}/${this.planningYear}`;
        let lastDot = fileList[0].name.lastIndexOf(".");
        const ext = fileList[0].name.substring(lastDot + 1);
        switch (fileType) {
          case FILE_TYPE.AUDIENCE:
            path = `${subPath}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.AUDIENCE}`;
            fileName = `${this.planningYear}${this.planName}${campaignName}${TCP_DOC_ID.AUDIENCE_CONSIDERATION}.${ext}`;
            fileName = fileName.replace(/ /g, "");
            //fileName = `${campaignName} ${FILE_NAMES.OPP_BRIEF_AUDIENCE}.${ext}`
            break;
          case FILE_TYPE.LEARNING_PLAN:
            path = `${subPath}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.LEARNING_PLAN}`;
            fileName = `${campaignName} ${FILE_NAMES.OPP_BRIEF_LEARNING}.${ext}`;
            break;
          case FILE_TYPE.IDEA_FORM:
            path = `${subPath}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.CAMPAIGN_IDEA}/${SHAREPOINT_DOC_PATH.ATTACHMENTS}`;
            fileName = `${campaignName} ${this.selectedDocument}`;
            break;
          default:
            fileName = "";
            break;
        }
        let data = fileBufferArray;
        let docObj = {
          body: {
            data,
            fileName: fileName,
            uploadLocation: path,
          },
        };
        let dialogRef;
        dialogRef = this.dialog.open(FileUploadDialogComponent, {
          data: { fileType, docObj },
          minWidth: "500px",
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((res: any) => {
          if (res.status) {
            this.getCampaigns();
            this.getOppBriefDocsFromSharepoint();
          }
          this.dialog.closeAll();
        });
      },
      (error) => {
        console.log("Error: ", error);
      }
    );
  }

  async getOppBriefDocsFromSharepoint() {
    this.isLoading = true;
    this.campaignForm = [];
    const audiencePath = `${SHAREPOINT_DOC_PATH.TCP}/${this.planningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.AUDIENCE}`;
    this.oppBriefAudienceDocList = await getDocsFromSharepointFilesAPI(
      audiencePath,
      this.globalElementsService
    );
    const campLearningPath = `${SHAREPOINT_DOC_PATH.TCP}/${this.planningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.LEARNING_PLAN}`;
    this.oppBriefLearningDocList = await getDocsFromSharepointFilesAPI(
      campLearningPath,
      this.globalElementsService
    );
    const campIdeaPath = `${SHAREPOINT_DOC_PATH.TCP}/${this.planningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.CAMPAIGN_IDEA}/${SHAREPOINT_DOC_PATH.ATTACHMENTS}`;
    this.ideaFormDocList = await getDocsFromSharepointFilesAPI(
      campIdeaPath,
      this.globalElementsService
    );

    this.getCampaigns();
  }

  saveCommsTask() {
    const commsTaskObj = this.constructCommsTaskSaveObj();
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "save",
        type: "saveCommsTask",
        apiData: commsTaskObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getMjtbdData();

      this.dialog.closeAll();
    });
  }
  editCommsTask() {
    const commsTaskObj = this.constructCommsTaskEditObj();
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "update",
        type: "editCommsTask",
        apiData: commsTaskObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getMjtbdData();
      this.formUpdated = false;
      if (this.commsFormSub) {
        this.commsFormSub.unsubscribe();
      }
      this.dialog.closeAll();
    });
  }

  deleteCampIdea(ciFormId: number) {
    const campIdeaObj = {
      ciFormId,
      year: this.planningYear,
    };
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "delete",
        type: "deleteCampaignIdea",
        apiData: campIdeaObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getCampaigns();

      this.dialog.closeAll();
    });
  }

  deleteOppBrief(obFormId: number) {
    const campIdeaObj = {
      obFormId,
      year: this.planningYear,
    };
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "delete",
        type: "deleteOppBrief",
        apiData: campIdeaObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getCampaigns();

      this.dialog.closeAll();
    });
  }

  deleteCampaign(campaignId: number) {
    const campaignObj = {
      campaignId,
      year: this.planningYear,
    };
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "delete",
        type: "deleteCampaign",
        apiData: campaignObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getCampaigns();

      this.dialog.closeAll();
    });
  }

  deleteCommsTask(commstaskId: number) {
    const commsObj = {
      commstaskId,
      year: this.planningYear,
    };
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        mode: "delete",
        type: "deleteCommstask",
        apiData: commsObj,
        planId: this.planId,
      },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getMjtbdData();

      this.dialog.closeAll();
    });
  }

  /*........... Service/API calls........ */

  getMjtbdData() {
    this.overviewData = null;
    let obj = {
      mjtbdId: this.paramsId,
      year: this.planningYear,
    };
    this.programService.getMjtbdData(obj).subscribe((res: any) => {
      if (res) {
        this.commsTasksData = res.commsTasks;
        if (res.mjtbd.name.length > 30) {
          this.fullName = res.mjtbd.name;
          this.mjtbdName = res.mjtbd.name.slice(0, 30).concat("...");
        } else {
          this.mjtbdName = res.mjtbd.name;
        }
        this.pathDetails = {
          fullName: this.fullName,
          lastNodeName: this.mjtbdName,
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.MJTBD,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.MJTBD,
        };
        this.sectionHeader = SECTION_HEADER_INFO.PROGRAM;
        this.planId = res.planId;
        this.getHierarchy();
        this.getplanData();
      }
    });
  }

  getplanData() {
    this.overviewData = null;
    const obj = {
      planId: this.planId,
      year: this.planningYear,
    };
    this.planService.getPlanData(obj).subscribe((res: IPlanOverviewData) => {
      if (res) {
        this.tcpViewOvwData = res.planOverview;
        this.planItViewovwData = res.planItOverview;
        this.overviewData =
          this.aggrView == AGGR_VIEW.PLANIT
            ? this.planItViewovwData
            : this.tcpViewOvwData;
      }
    });
  }

  getHierarchy() {
    let planObj = {
      planId: this.planId,
    };
    this.planService.getHierarchy(planObj).subscribe(
      (res: any) => {
        if (res) {
          this.marketId = res.market.id;
          this.planName = res.plan.displayName;
          this.hierarchy = `${res.market.displayName}/${res.portfolio.displayName}/${res.plan.displayName}`;
          this.getOppBriefDocsFromSharepoint();
        }
      },
      (error) => {
        this.getOppBriefDocsFromSharepoint();
      }
    );
  }

  getKPIsAndAudience() {
    const mjtbdObj = {
      mjtbdId: this.paramsId,
    };

    this.programService
      .getKPIsandAudience(mjtbdObj)
      .subscribe((res: ICampaignKPIandAudience) => {
        this.campaignAudienceList = res.audience;
      });
  }

  getCampaigns() {
    let mjtbdObj = {
      mjtbdId: this.paramsId,
      year: this.planningYear,
    };
    this.programService.getCampaignsAndIdeas(mjtbdObj).subscribe(
      (res: any) => {
        this.campaignList = res.campaigns;

        this.brandProblemToSolve = res.brandProblemToSolve;
        this.patchCampaignForm(res.campaigns);
        // this.selectedLogoImg = this.cam
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  saveCampaign() {
    let campaignObj = {
      mjtbdId: this.paramsId,
      year: this.planningYear,
      campaignInfo: {
        campaignName: this.campaignName.value,
        oppBriefName: "Opportunity Brief",
      },
    };

    this.programService.saveCampaign(campaignObj).subscribe((res: any) => {
      if (res) {
        this.isAddCampaign = false;
        this.campaignName.setValue("");
        this.getCampaigns();
      }
    });
  }
  editCampaign(campaign) {
    this.isEditCampaign = true;
    this.isAddCampaign = false;
    this.campaignName.setValue(campaign.campaignName);
    this.campaignId.setValue(campaign.campaignId);
  }
  updateCampaign() {
    if (this.campaignName.valid) {
      let campaignObj = {
        mjtbdId: this.paramsId,
        year: this.planningYear,
        campaignInfo: {
          campaignName: this.campaignName.value,
          campaignId: this.campaignId.value,
          // oppBriefName: "Opportunity Brief"
        },
      };

      this.programService.editCampaign(campaignObj).subscribe((res: any) => {
        if (res) {
          this.isAddCampaign = false;
          this.isEditCampaign = false;
          this.campaignName.setValue("");
          this.campaignId.setValue("");
          this.getCampaigns();
        }
      });
    }
  }

  getCampaignDAMLogos() {
    this.programService.getCampaignDAMLogos().subscribe((res: any) => {
      this.assets = res;
    });
  }

  saveCampaignIdea(campaignIdeaObj: ICampaignIdeaSave) {
    this.programService.saveCampaignIdea(campaignIdeaObj).subscribe((res) => {
      this.campaignTitle = "";
      this.campaignIdea = "";
      this.selectedCampaignAudience = [];
      this.selectedCampaignId = null;
      this.selectedLogoImg = "";
      this.base64textString = null;
      this.getCampaigns();
    });
  }

  constructOppBriefObj(oppBriefData): IOppBriefSave {
    const mjtbdId = this.paramsId;
    const year = this.planningYear;
    const formValues = oppBriefData.value;
    let oppBrief: IOppBriefSave = {
      mjtbdId,
      year,
      alertURL: `/program/${mjtbdId}`,
      oppourtinyBriefInfo: {
        obFormId: formValues.obFormId,
        mjtbdId,
        year,
        obFormStatus: "DRAFT",
        assignmentName: formValues.assignmentName,
        workingMediaBudget: formValues.workingMediaBudget,
        startDt: formValues.startDt,
        endDt: formValues.endDt,
        woa: formValues.woa,
        commsObjective: formValues.commsObjective,
        problem: formValues.problem,
        insight: formValues.insight,
        advantage: formValues.advantage,
        strategy: formValues.strategy,
        callToAction: formValues.callToAction,
        hashTag: formValues.hashTag,
        designTarget: formValues.designTarget,
        deliverables: formValues.deliverables,
        considerations: formValues.considerations,
        successKPIs: formValues.successKPIs,
        approversList: formValues.opBriefApprover,
        teamMembers: null,
        completedByUserId: null,
        finalizedByUserID: null,
        currentUserID: this.userInfo.id,
      },
    };
    return oppBrief;
  }

  constructCampaignIdeaObj(campaignId: number): ICampaignIdeaSave {
    let campaignIdeaObj: ICampaignIdeaSave = {
      mjtbdId: this.paramsId,
      campaignId: campaignId,
      year: this.planningYear,
      alertURL: `/program/${this.paramsId}`,
      campaignIdeaInfo: {
        ciTitle: this.campaignTitle,
        campaignImage: "",
        whatWouldWeDo: this.campaignIdea,
        situation: "",
        target: this.selectedCampaignAudience.toString(),
        campaignGoal: "",
        insight: "",
        addionalInsight: "",
        results: "",
        connectionToBBI: "",
        approversList: "",
        teamMembers: null,
        completedByUserId: null,
        finalizedByUserID: null,
        currentUserID: this.userInfo.id,
      },
    };
    if (this.base64textString) {
      campaignIdeaObj.campaignIdeaInfo.campaignImageBase64 =
        this.base64textString;
    }
    return campaignIdeaObj;
  }

  constructCommsTaskSaveObj(): ICommsTaskSave {
    const { commsTaskName, campaignId } = this.commsTaskForm.value;
    const commsTaskObj: ICommsTaskSave = {
      mjtbdId: this.paramsId,
      year: this.planningYear,
      campaignId: campaignId,
      commsTaskName: commsTaskName,
    };

    return commsTaskObj;
  }

  constructCommsTaskEditObj() {
    const { commsTaskName, campaignId } = this.commsTaskForm.value;
    const commsTaskObj = {
      mjtbdId: this.paramsId,
      year: this.planningYear,
      campaignId: campaignId,
      commsTaskName: commsTaskName,
      commsTaskID: this.selectedCommsTaskID,
    };

    return commsTaskObj;
  }

  fileChangeEventForCampaignIdea(event, campaignId) {
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this.handleFile.bind(this, campaignId);

      reader.readAsBinaryString(file);
    }
  }

  handleFile(campaignId, event) {
    var binaryString = event.target.result;
    this.base64textString = btoa(binaryString);
    // console.log(btoa(binaryString));

    // const campaignIdeaObj: ICampaignIdeaSave = this.constructCampaignIdeaObj(campaignId);
    this.saveCampaignDetails(campaignId);

    this.selectedLogoImg = "data:image/jpg;base64," + this.base64textString;
    // this.campaignIdeaForm.get("cmpLogo").patchValue(this.selectedLogoImg);
    // let dialogRef;
    // dialogRef = this.dialog.open(CampaignIdeaUploadDialogComponent, {
    //   data: imageData,
    //   minWidth: '500px'
    // });
    // dialogRef.afterClosed().subscribe((res: any) => {
    //   if (res.status) {
    //   }
    //   this.dialog.closeAll();
    // });
  }

  ngOnDestroy(): void {
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `MJTBD_Page`,
      eventAction: "PageExit",
      eventLable: `MJTBD Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
    if (this.commsFormSub) {
      this.commsFormSub.unsubscribe();
    }
  }
  getCampaignsByMjtbdYearMarket() {
    let obj = {
      mjtbdId: this.paramsId,
      selectedYear: this.selectedYear,
      selectedMarket: this.selectedMarket,
      currentPlanName: this.planName,
    };

    this.programService.getCampaignsByMjtbdYearMarket(obj).subscribe((res) => {
      this.FilteredCampaignList = res["campaigns"];
    });
  }

  importCampaign() {
    let campaignObj = {
      selectedCampaign: this.selectedCampaign,
      mjtbdId: this.paramsId,
      year: this.planningYear,
      selectedYear: this.selectedYear,
      campaignInfo: {
        campaignName: this.newCampaignName,
        oppBriefName: "Opportunity Brief",
      },
    };

    this.programService.importCampaign(campaignObj).subscribe((res) => {
      this.dialog.closeAll();
      this.getCampaigns();
      this.resetImportDialogData();
    });
  }
  resetImportDialogData() {
    this.selectedYear = undefined;
    this.selectedMarket = undefined;
    this.currentPlanName = undefined;
    this.FilteredCampaignList = [];
    this.newCampaignName = "";
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator, null, this.planId);
  }
}
