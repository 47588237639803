import { DOCS_LEVEL } from "./../../constants/app-constants";
import { DocumentsUploadDialogComponent } from "./../../common/components/dialogs/documents-upload-dialog/documents-upload-dialog.component";

import { MatDialog } from "@angular/material/dialog";
import { GlobalElementsService } from "./../services/global-elements.service";
import { MatPaginator } from "@angular/material";
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as moment from "moment";
import { Router } from "@angular/router";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { CustomConfirmationDialogComponent } from "app/common/components/dialogs/custom-confirmation-dialog/custom-confirmation-dialog.component";


import {
  AfterViewInit,
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { isMoment } from "../../../../node_modules/moment";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { getGpId } from "app/common/utils/common-util";
import { environment } from "environments/environment";
import { googleAnalyticsCode } from "app/util";

import { saveAs } from "file-saver";
import { fileMimeTypes } from "../../../app/constants/app-constants";
import ab2b64 from "ab2b64";
import axios from "axios";
import { DownloadFileDialogComponent } from "app/common/components/dialogs/download-file-dialog/download-file-dialog.component";
import { UploadCampaignStudiesDialogComponent } from "app/common/components/dialogs/uploadcampaignstudies-dialog/uploadcampaignstudies-dialog.component";
import { CampaignStudyDetailsDialogComponent } from "app/common/components/dialogs/campaign-study-details-dialog/campaign-study-details-dialog.component";
import { SharedDataService } from "../../common/services/shared-data.service";

@Component({
  selector: "app-document-center",
  templateUrl: "./document-center.component.html",
  styleUrls: ["./document-center.component.scss"],
})
export class DocumentCenterComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() elementDetails;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) ssort: MatSort;
  @ViewChild(MatPaginator, { static: true }) spaginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) csort: MatSort;
  @ViewChild(MatPaginator, { static: false }) cpaginator: MatPaginator;
  userInfo;
  fullName:string;
  docsLevel;
  isLoading: boolean = true;
  alignButton: boolean = false;
  dataSource = new MatTableDataSource<any>();
  dataSourceSocialSentiment = new MatTableDataSource<any>();
  dataSourceCampaignStudies = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "title",
    "folder",
    "modifiedDtUnix",
    "market",
    "brand",
  ];
  displayedColumnsSE: string[] = [
    "country",
    "year",
    "uploadStatus",
    "uploadedBy",
    "uploadedAt",
  ];
  displayedColumnsCS: string[] = ['filename','campaignname', 'brandname', 'year', 'Actions'];
  docCategory = [
    {
      id: 1,
      name: DOCS_LEVEL.GLOBAL_REFERENCE,
      isOpened: true,
      isVisible: true,
      sharePointPath: "Global Reference",
      class: "opened",
    },
    {
      id: 2,
      name: DOCS_LEVEL.CANVAS_TRAINING,
      isOpened: false,
      isVisible: true,
      sharePointPath: "MSP Training",
      class: "",
    },
    {
      id: 3,
      name: DOCS_LEVEL.TCP_TEMPLATES,
      isOpened: false,
      isVisible: true,
      sharePointPath: "TCP Templates",
      class: "",
    },
    {
      id: 4,
      name: DOCS_LEVEL.BRAND_DOCUMENTS,
      isOpened: false,
      isVisible: true,
      sharePointPath: "Brand Documents",
      class: "",
    },
    {
      id: 5,
      name: DOCS_LEVEL.INPUT_FILES,
      isOpened: false,
      isVisible: false,
      sharePointPath: "Input Files",
      class: "",
    },
    {
      id: 6,
      name: DOCS_LEVEL.SOCIAL_ENGAGEMENT,
      isOpened: false,
      isVisible: false,
      sharePointPath: "Social Engagement & Listening",
      class: "",
    },
    {
      id: 7,
      name: DOCS_LEVEL.CAMPAIGN_STUDIES,
      isOpened: false,
      isVisible: false,
      sharePointPath: "Campaign Studies",
      class: "",
    },
  ];
  defaultPath = this.docCategory.find((el) => el.id == 1).sharePointPath;
  tableData = [];
  mainData = [];
  selectedDocCat = this.docCategory.find((el) => el.id == 1);
  showMarket: boolean = false;
  campaignStudiesData:any;
  selectedMode: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private _liveAnnouncer: LiveAnnouncer,    
    protected googleTagManagerService: GoogleTagManagerService,
    private sharedDataService: SharedDataService,
  ) {}

  ngOnInit() {
    
    this.getCampaignStudiesData();
    if (!this.showMarket) {
      this.displayedColumns = ["title", "folder", "modifiedDtUnix"];
    }
    this.userInfo = JSON.parse(sessionStorage.getItem("user"));
    const markets = this.userInfo.markets;
    const mid = sessionStorage.getItem("market");
    const isusMarket = markets.filter((el) => el.id == 3); 
    this.docCategory.forEach((cat) => {
     
      if(cat.id == 7 && mid == '3'){
        cat.isVisible = true;
      }
      // if(cat.id == 7 && isusMarket.length > 0){
      //   cat.isVisible = true;
      // }
      if (cat.id == 5 && this.userInfo.roleId <= 2) {
          cat.isVisible = true;
      }
      if (cat.id == 6 && this.userInfo.roleId <= 2) {
          cat.isVisible = true;
      }
    });
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Document_Center`,
      eventAction: "PageView",
      eventLable: `Global Elements Document Center Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  ngAfterViewInit() {
    this.dataSourceCampaignStudies.sort = this.csort;
  }
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  async getCampaignStudiesData(){
    let path = 'Input Files/Campaign Studies';
    // let documentsData = await this.getDocsFromSharepointFilesAPI_CampaignStudies(path,this.globalElementsService);
    // console.log(documentsData);

    
  }

  getDocsFromSharepointFilesAPI_CampaignStudies = async (path, serviceName) => {
    let docData = [];
    let data:  Array<any> = await serviceName.getDocumentsWithFilesAPI(path).toPromise();//.subscribe((docsDetails: Array<any>) => {
    if (data) {
      const docsDetailsArr = data["results"];
      docData = docsDetailsArr.reduce((acc, cv) => {
        const results  = cv;
        let docObj: any = {};
        docObj.title = results.Name || 'N/A';
        docObj.author = "";
        docObj.path = "https://pepsico.sharepoint.com"+ results.ServerRelativeUrl;
        docObj.folder = "";
        const date = results.TimeLastModified || "N/A";
        docObj.modifiedDt = moment(date).isValid() ? moment(date).format('ll') : 'N/A';
        // docObj.modifiedDtLL = moment(date).isValid() ? moment(date).format('MM/DD/YYYY hh:mm:ss') : 'N/A';
        docObj.modifiedDtUnix = moment(date).isValid() ? moment(date).unix() : 'N/A';
        docObj.fileExt = results.Name ? results.Name.split(".")[1] : "N/A";
        docObj.size = "";
        docObj.fileName = results.Name || 'N/A'; 
        acc.push(docObj);
        return acc;
      }, []);
    }
    return docData;
  }

  //Detect Changes to the component and get document at market or portfolio or brand level
  ngOnChanges({ elementDetails }: SimpleChanges) {
    this.sharedDataService.getdocumentMode.subscribe(
      (mode: string) => {
        this.selectedMode = mode;
        if(this.selectedMode=='Campaign'){
          this.getDocsOfType(7, '');
        }else{
          if (this.elementDetails && this.elementDetails.level !== this.docsLevel) {
            this.docsLevel = this.elementDetails.level;
            let subPath = this.elementDetails.subPath;
                  switch (this.elementDetails.level) {
              case DOCS_LEVEL.BRAND_DOCUMENTS:
                this.getDocsOfType(4, subPath);
                break;

              default:
                this.getDocsOfType(1, subPath);
                break;
            } 
          }
        }
      }      
    );
    
  }

  getDocsOfType(docTypeId, subPath) {
    let sharepointPath;

    //Set class and selection of each Document Category
    this.docCategory.forEach((el) => {
      if (el.id == docTypeId) {
        this.selectedDocCat = el;
        el.isOpened = true;
        el.class = "opened";
        sharepointPath = el.sharePointPath;

        this.setGoogleTagOnDocType(el.id);
        if (
          docTypeId == 3 ||
          docTypeId == 4 ||
          docTypeId == 5 ||
          docTypeId == 6
        ) {
          const docType = this.docCategory.find((e: any) => e.id == docTypeId);
          if (docType) {
            this.openUploadDialog(docType);
          }
        }
      } else {
        el.isOpened = false;
        el.class = "";
      }
    });
    if (subPath) {
      sharepointPath = sharepointPath + subPath;
    }
  //  this.getDocsFromSharepoint(sharepointPath);
  this.alignButton = false;
  if(docTypeId < 6) {
    this.getDocsFromSharepointFilesAPI(sharepointPath);
  } else if(docTypeId == 6){
    this.tableData = [];
    this.getSEuploadInformation();
  }
  else if(docTypeId == 7){
    this.tableData = [];
    this.alignButton = true;
    this.getCampaignStudiesInformation();
  }
  }
  returntrueFalse(docId) {
    return docId != 6;
  }
  getSEuploadInformation() {
    this.isLoading = true;
    this.globalElementsService.getSEuploadData().subscribe(
      (res) => {
        this.dataSourceSocialSentiment = new MatTableDataSource<any>(res);
        this.dataSourceSocialSentiment.paginator = this.spaginator;

        this.dataSourceSocialSentiment.sort = this.ssort;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  getCampaignStudiesInformation() {
    this.isLoading = true;
    this.globalElementsService.getCampaignStudiesData().subscribe((response) => {
      if(response['data']){
        const uniqueCampaignNames = [];
        response['data'] = response['data'].filter(el => {
          const isDuplicate = uniqueCampaignNames.includes(el.filename);
          if (!isDuplicate) {
            uniqueCampaignNames.push(el.filename);
        
            return true;
          }
          return false;
        })
        response['data'].map((el) => {
                el.fileURL = `${environment.SHAREPOINT_PATH_URL}/${environment.pathLoc}Input Files/Campaign Studies/${el.filename}.${el.filetype}`;
                if(el.campaignname.length > 25){
                  el['fullName'] = el.campaignname;
                  const shortCampaignNames = el.campaignname.slice(0,25).concat('...');
                  el['shortName'] = shortCampaignNames;
                } 
                else {
                  el['fullName'] = el.campaignname;
                  el['shortName'] = el.campaignname;
                }
            })
              this.dataSourceCampaignStudies = new MatTableDataSource<any>(response['data']);

              //new logic to deal mix case sorting
              this.dataSourceCampaignStudies.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                  return data[sortHeaderId].toLocaleLowerCase();
                }
              
                return data[sortHeaderId];
              };
              //logic end
              this.mainData = response['data'];
              this.dataSourceCampaignStudies.paginator = this.cpaginator;
              this.dataSourceCampaignStudies.sort = this.csort;
              this.isLoading = false;
      } else {
        this.mainData = [];
        this.isLoading = false;
      }
    })
      // (response) => {
      //   response['data'].map((el) => {
      //       el.fileURL = `${environment.SHAREPOINT_PATH_URL}/${environment.pathLoc}Input Files/Campaign Studies/${el.filename}.${el.filetype}`;
      //   })
      //     this.dataSourceCampaignStudies = new MatTableDataSource<any>(response['data']);
      //     this.tableData = response['data'];
      //     this.dataSourceCampaignStudies.paginator = this.cpaginator;
      //     this.dataSourceCampaignStudies.sort = this.csort;
      //     this.isLoading = false;
      // },
      // (err) => {
      //   this.tableData = [];
      //   this.isLoading = false;
      // }
    // this.globalElementsService.getSEuploadData().subscribe((res) => {
    //   this.dataSourceCampaignStudies = new MatTableDataSource<any>(res);
    //   this.dataSourceCampaignStudies.paginator = this.spaginator;

    //   this.dataSourceCampaignStudies.sort = this.ssort;
    //   this.isLoading = false;
    // },err => {
    //   this.isLoading = false;
    // });
  }
  
  deleteCampaignStudiesData(id){
    console.log(id);
    // this.isLoading = true;
    this.globalElementsService.deleteCampaignStudiesData(id).subscribe((res) => {
      if(res){
        setTimeout(() => {
          this.getCampaignStudiesInformation(); 
        },1000);          
      }
     
      // this.isLoading = false;      
    },err => {
      // this.isLoading = false;
    });
  }
  openDeletePopup(idx,fileVision) {
    let fileName = fileVision.fileVersionName+'.'+fileVision.filetype;
    this.dialog
      .open(CustomConfirmationDialogComponent, {
        data: {
          message:
            `Are you sure you want to delete ${fileName}`,
          customSaveButtonText: "Yes",
          customCancelButtonText: "No",
        },
      })
      .afterClosed()
      .subscribe((_result: Boolean) => {
        if (_result) {
          this.deleteCampaignStudiesData(idx);
        }
      });
  }
  
  doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  getDocsFromSharepointFilesAPI(docsPath) {
    this.isLoading = true;
    let path = docsPath || this.defaultPath;
    this.globalElementsService
      .getDocumentsWithFilesAPI(path)
      .subscribe((docsDetails: Array<any>) => {
        const docsDetailsArr = docsDetails["results"];
        this.tableData = docsDetailsArr.reduce((acc, cv) => {
          const results = cv;
          let docObj: any = {};
          docObj.title = results.Name || "N/A";
          docObj.author = "";
          docObj.path =
            "https://pepsico.sharepoint.com" + results.ServerRelativeUrl;
          const BrandDocument = docsPath.split("/")[0];
          if (BrandDocument == DOCS_LEVEL.BRAND_DOCUMENTS) {
            docObj.market = docsPath.split("/")[1]
              ? docsPath.split("/")[1]
              : "NA";
            this.showMarket = true;
            this.displayedColumns = [
              "title",
              "brand",
              "market",
              "folder",
              "modifiedDtUnix",
            ];
            if (docObj.market == "NA") {
              docObj.brand = "NA";
            } else {
              docObj.brand =
                docsPath.indexOf("/") > -1
                  ? docsPath.split("/").pop()
                  : docsPath;
            }
            docObj.folder = DOCS_LEVEL.BRAND_DOCUMENTS;
          } else {
            this.showMarket = false;
            this.displayedColumns = ["title", "folder", "modifiedDtUnix"];
            docObj.folder =
              docsPath.indexOf("/") > -1 ? docsPath.split("/").pop() : docsPath;
          }
          const date = results.TimeLastModified || "N/A";

          docObj.modifiedDt = moment(date).isValid()
            ? moment(date).format("ll")
            : "N/A";
          docObj.modifiedDtUnix = moment(date).isValid()
            ? moment(date).unix()
            : "N/A";
          docObj.fileExt = results.Name ? results.Name.split(".")[1] : "N/A";
          docObj.size = "";
          docObj.fileName = results.Name || "N/A";
          acc.push(docObj);
          return acc;
        }, []);

        this.dataSource = new MatTableDataSource<any>(this.tableData);
        this.dataSource.paginator = this.paginator;

        this.dataSource.sort = this.sort;

        //Load after the docs are downloaded and the calculations are complete
        this.isLoading = false;
      });
  }

  //   downloadFile(path:string){
  //     let fileName = path.split('/').pop();
  //     const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
  //     this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
  //       this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
  //       // const byteCharacters = window.atob(response['thumbnailb64data']);
  //       /*const downloadLink = document.createElement("a");
  //       downloadLink.setAttribute('download', fileName.replace(/ /g,''));
  //       downloadLink.setAttribute('href', `${source}`)
  //       document.body.appendChild(downloadLink);
  //       downloadLink.click();
  //       document.body.removeChild(downloadLink);*/
  //       //const byteCharacters = source;
  //       // const byteNumbers = new Array(byteCharacters.length);
  //       // for (let i = 0; i < byteCharacters.length; i++) {
  //       //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       // }
  //       // const byteArray = new Uint8Array(byteNumbers);
  //       // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
  //       // saveAs(blob, fileName.replace(/ /g,''));
  //   })
  // }

  downloadFile(path: string) {
    let fileName = path.split("/").pop();
    path = path ? path.split("/Shared Documents/")[1] : "";
    let docPath = path.split(`/${fileName}`)[0];
    const extension = fileName.substring(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    const downloadObj = {
      fileName: fileName,
      path: path,
      docPath: docPath,
      extension: extension,
    };
    let dialogRef;
    dialogRef = this.dialog.open(DownloadFileDialogComponent, {
      data: downloadObj,
      minWidth: "500px",
      disableClose: true,
    });
    // this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
    // const byteCharacters = window.atob(response['thumbnailb64data']);
    // const downloadLink = document.createElement("a");
    // downloadLink.download = fileName.replace(/ /g,'');
    // downloadLink.href = source;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    // saveAs(blob, fileName.replace(/ /g,''));
  }
  downloadFileFromBase64(fileName, b64encodedString: string, contentType) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    let byteArrays = ab2b64.b642ab(b64Data);
    return new Blob([byteArrays], { type: contentType });
  }

  getDocsFromSharepoint(docsPath) {
    this.isLoading = true;
    let path = docsPath || this.defaultPath;
    this.globalElementsService
      .getDocuments(path)
      .subscribe((docsDetails: Array<any>) => {
        this.tableData = docsDetails.reduce((acc, cv) => {
          const { results } = cv.Cells;
          let docObj: any = {};
          docObj.title = results.find((el) => el.Key == "Title").Value || "N/A";
          docObj.author =
            results.find((el) => el.Key == "Author").Value || "N/A";
          docObj.path =
            results.find((el) => el.Key == "OriginalPath").Value || "N/A";

          const parentPath = results
            .find((el) => el.Key == "ParentLink")
            .Value.split("/");
          docObj.folder = parentPath.pop();
          const date = results.find((el) => el.Key == "LastModifiedTime").Value;

          docObj.modifiedDt = moment(date).isValid()
            ? moment(date).format("ll")
            : "N/A";

          docObj.fileExt =
            results.find((el) => el.Key == "FileExtension").Value || "N/A";
          docObj.size = results.find((el) => el.Key == "Size").Value || "N/A";
          docObj.fileName =
            docObj.path.substring(
              docObj.path.lastIndexOf("/") + 1,
              docObj.path.lastIndexOf(".")
            ) || "N/A";
          acc.push(docObj);
          return acc;
        }, []);

        this.dataSource = new MatTableDataSource<any>(this.tableData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        //Load after the docs are downloaded and the calculations are complete
        this.isLoading = false;
      });
  }

  setGoogleTagOnDocType(docTypeId: any) {
    switch (docTypeId) {
      case 1:
        let tagObject = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Document_Center`,
          eventAction: "Global Reference Folder clicked",
          eventLable: `Global Elements Global Reference Folder click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject, this.googleTagManagerService);
        break;
      case 2:
        let tagObject1 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Document_Center`,
          eventAction: "MSP Training Folder click",
          eventLable: `Global Elements MSP Training Folder click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject1, this.googleTagManagerService);
        break;
      case 3:
        let tagObject2 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Document_Center`,
          eventAction: "TCP & AOP Documents Folder click",
          eventLable: `Global Elements TCP & AOP Documents Folder click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject2, this.googleTagManagerService);
        break;
      case 4:
        let tagObject3 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Document_Center`,
          eventAction: "Brand Documents Folder click",
          eventLable: `Global Elements Brand Documents Folder Clicked`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject3, this.googleTagManagerService);
        break;
      case 5:
        let tagObject4 = {
          event: `custom_${environment.environmentName}_Event`,
          eventCategory: `Document_Center`,
          eventAction: "Input Files Folder Click",
          eventLable: `Global Elements Input Files Folder click`,
          customeEvent: `custom_${environment.environmentName}_Event`,
          userId: getGpId(),
          user_id: getGpId(),
        };
        googleAnalyticsCode(tagObject4, this.googleTagManagerService);
        break;
      default:
        return null;
    }
  }

  openUploadDialog(data) {
    console.log("data", data);
    const dialogRef = this.dialog.open(DocumentsUploadDialogComponent, {
      minWidth: "500px",
      maxHeight: "700px",
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        if (this.selectedDocCat.id == 6) {
          this.getSEuploadInformation();
        } else {
          this.getDocsFromSharepointFilesAPI(res.DocumentType);
        }
      }
    });
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Document_Center`,
      eventAction: "Upload Documents click",
      eventLable: `Global Elements Upload Documents click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
  returnDate(_date) {
    return moment(_date).isValid() ? moment(_date).format("ll") : "N/A";
  }
  openUploadDialogmain() {
    console.log("rollIddd", this.selectedDocCat);
    if (this.selectedDocCat.id === 7) {
      console.log("selectedDocCat ::", this.selectedDocCat.id);
      const dialogRef = this.dialog.open(UploadCampaignStudiesDialogComponent, {
        minWidth: "500px",
        maxWidth: "700px",
        maxHeight: "580px",
        panelClass: "csUploadDialog",
        data: this.selectedDocCat,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          // this.getDocsFromSharepointFilesAPI(res.DocumentType);
          this.getCampaignStudiesInformation();
        }
      });
    } else {
      const dialogRef = this.dialog.open(DocumentsUploadDialogComponent, {
        minWidth: "500px",
        maxHeight: "700px",
        data: this.selectedDocCat,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.getDocsFromSharepointFilesAPI(res.DocumentType);
        }
      });
    }
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Document_Center`,
      eventAction: "Upload Documents click",
      eventLable: `Global Elements Upload Documents click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  openDetailsDialog(data) {
    const dialogRef = this.dialog.open(CampaignStudyDetailsDialogComponent, {
      minWidth: "500px",
      maxHeight: "700px",
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      // this.getCampaignStudiesInformation();
      // if (res) {
      //   if (this.selectedDocCat.id == 6) {
      //     this.getSEuploadInformation();
      //   } else {
      //     this.getDocsFromSharepointFilesAPI(res.DocumentType);
      //   }
      // }
    });
    // let tagObject = {
    //   event: `custom_${environment.environmentName}_Event`,
    //   eventCategory: `Document_Center`,
    //   eventAction: "Upload Documents click",
    //   eventLable: `Global Elements Upload Documents click`,
    //   customeEvent: `custom_${environment.environmentName}_Event`,
    //   userId: getGpId(),
    //   user_id: getGpId(),
    // };
    // googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
  openUploadCampaignStudies(data) {
    data.isEditMode = true;
    const dialogRef = this.dialog.open(UploadCampaignStudiesDialogComponent, {
      minWidth: "500px",
      maxHeight: "700px",
      maxWidth:"700px",
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getCampaignStudiesInformation();
      }
    });
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Document_Center`,
      eventAction: "Upload Documents click",
      eventLable: `Global Elements Upload Documents click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
  ngOnDestroy(): void {
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Document_Center`,
      eventAction: "PageExit",
      eventLable: `Global Elements Document Center Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
}
