import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { isValidUserAccess } from '../../../utils/common-util';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';

@Component({
  selector: 'app-add-year-dialog',
  templateUrl: './add-year-dialog.component.html',
  styleUrls: ['./add-year-dialog.component.scss']
})
export class AddYearDialogComponent {
showCreateCancelBtn:boolean = true;
showOkBtn:boolean = false;
message:string;
  userInfo;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private GlobalElementObject: GlobalElementsService,
  ) { this.userInfo = JSON.parse(sessionStorage.getItem("user"));}

  ngOnInit(){
    this.message = `Are you sure you want to add a new year ${this.data} to MSP ?`;
  }
  setFutureYear(){
    this.message = `Setting up year ${this.data}. Please wait...`;
      this.showCreateCancelBtn = false;
      this.GlobalElementObject.addNextWorkingYear().subscribe( res => {
        if(res['code'] == 200){
          this.showOkBtn = true;
          this.message = `Year ${this.data} Created Successfully!`;
        }
      })
  }

  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator)
  }

}