import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-cancel-dialog',
    templateUrl: './cancel-dialog.component.html',
    styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent {

    status: boolean = true;
    message: string = 'Are you sure you want to exit the form with out saving the details?'
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CancelDialogComponent>
    ) { }

    onYesClick() {
        this.dialogRef.close({ status: this.status });
    }
}
