import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  message: string = '';
  isErr: boolean = false;
  errMsg: string = ''
  apiResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
  ) { }

  ngOnInit() {
    this.isErr = false;
    this.errMsg = '';
    switch (this.data.type) {

      case 'update':
        this.updateModule();
        break;




    }

  }


  updateModule() {
    this.isErr = false;
    const { apiData, module } = this.data;
    switch (module) {
      case 'readNotification':
        this.message = "Saving Notification status... Please Wait";
        this.globalElementsService.readNotifications(apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saved Notification status."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = 'Error in saving Notification status';
        });
        break;
      default:
        break;
    }
  }
}
