import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IOverview, IPortfolios } from 'app/common/interfaces/common';
import { IOverTableInfo, IOverviewTable, ISectionHeader } from 'app/common/interfaces/overviewSection';
import { IPortfolioGroupBody, IPortfolioGroupResponse, IPortfolioGroups } from 'app/common/interfaces/portfolioGroup';
import { constructOverviewTableData } from 'app/common/utils/overview-util';
import { SECTION_HEADER_INFO, TABLE_HEADERS } from 'app/constants/overview-constants';
import { IBreadcrumb } from './../common/interfaces/common';
import { SharedDataService } from './../common/services/shared-data.service';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../constants/app-constants';
import { PortfolioGroupService } from './services/portfolio-group.service';
@Component({
  selector: 'app-portfolio-group',
  templateUrl: './portfolio-group.component.html',
  styleUrls: ['./portfolio-group.component.scss']
})
export class PortfolioGroupComponent implements OnInit {

  paramsId: number;
  portfolioName: string;
  workingYear: number;
  pathDetails : IBreadcrumb;
  type: string = 'portfolioGroup';
  portfolioGroups: Array<IPortfolioGroups> = [];
  portfolioGroupName: string;
  overviewTableData: IOverTableInfo;
  sectionHeader: ISectionHeader;
  overviewTableObj: IOverviewTable = {
    businessUnit: '',
    totalPlanned: '',
    plannedToDate: '',
    actualToDate: '',
    spenDiff: '',
    plannedROI: '',
    actualROI: '',
    roiDiff: '',
    upfrontPlan: '',
    isGroup: false,
    isList: false,
    id: '',
    path: '',
    brandGrowth: '',
    audienceSeg: '',
    status: '',
    accessible: false
  };
  overviewData: Array<IOverview>;
  finalList: Array<IOverviewTable> = [];
  portfolios: Array<IPortfolios> = [];

  constructor(
    private portfolioGroupService: PortfolioGroupService,
    private route: ActivatedRoute,
    private sharedData: SharedDataService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.route.params.subscribe(params => {
      const portfolioGroupId = params['id'];
      this.paramsId = portfolioGroupId && Number(portfolioGroupId);
    });
    this.sharedData.getWorkingYear.subscribe((res: any) => {
      if (res) {
        this.workingYear = res;
        this.getportfolioGroupData();
      }
    })

  }

  /*........... Service/API calls........ */

  getportfolioGroupData() {
    this.finalList = [];
    const portfolioGroup: IPortfolioGroupBody = {
      portfolioGroupId: this.paramsId,
      year: this.workingYear
    }
    this.portfolioGroupService.getPortfolioGroupData(portfolioGroup).subscribe((res: IPortfolioGroupResponse) => {
      if (res) {
        this.portfolioGroups = res.portfolioGroups[0];
        this.portfolioGroupName = res.portfolioGroups[0].name;
        this.portfolios= res.portfolioGroups[0].portfolios;
        this.overviewData = res.portfolioGroups[0].pfGroupOverview;
        this.sectionHeader = SECTION_HEADER_INFO.PORTFOLIO_GROUP;        
        this.overviewTableData = this.constructOverviewTableData();
        this.pathDetails = {
          lastNodeName: this.portfolioGroupName,
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO_GROUP,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.PORTFOLIO_GROUP
        }
      }
      
    });

  }

  constructOverviewTableData(): IOverTableInfo {

    this.portfolios.forEach((pf: IPortfolios) => {
      const row = constructOverviewTableData({ displayName: pf.displayName, status: pf.status, overview: pf.pfOverview }, this.overviewTableObj);
      row.isList = true;
      row.id = pf.id;
      row.accessible = pf.accessible;
      row.path = `/portfolio/${row.id}`;
      this.finalList.push(row);
    })
    let tableObj: IOverTableInfo = {
      tableMainHeaderRow: TABLE_HEADERS.PORTFOLIO_GROUP.MAIN,
      tableSubHeaderRow: TABLE_HEADERS.PORTFOLIO_GROUP.SUB,
      tableData: this.finalList
    }
    return tableObj;
  }

}
