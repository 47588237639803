import { Router } from "@angular/router";
// Modules.
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
// Misc.
import { environment } from "environments/environment";
import { User } from "app/types";

const BASE_API_URL = `${environment.api}`;

@Injectable({
  providedIn: "root",
})
export class UserService {
  userInfo: BehaviorSubject<object>;
  userInfo1: any;

  constructor(private http: HttpClient, public router: Router) {}

  async get(gpid: string): Promise<User> {
    let userData;
    await this.http
      .get<User>(`${BASE_API_URL}/user/${gpid}`)
      .toPromise()
      .then((user) => {
        userData = user;
        console.log("userinfo service", user);
        this.userInfo = new BehaviorSubject(userData);
        this.userInfo1 = this.userInfo.asObservable();
      })
      .catch((err) => {
        this.router.navigate(["/error"]);
      });

    return userData;
  }
}
