import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl,AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { updateWidgetValueTypeDialog } from 'app/common/components/dialogs/update-widget-value-type-dialog/update-widget-value-type-dialog.component';
import { IWidgetPlanInfo } from 'app/common/interfaces/plan';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Subscription } from '../../../../node_modules/rxjs';
import { take } from '../../../../node_modules/rxjs/operators';
import { IBreadcrumb } from './../../common/interfaces/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {DeleteRtoDashboardDialogComponent} from '../../common/components/dialogs/delete-rto-dashboard-dialog/delete-rto-dashboard-dialog.component';
import { Url } from 'url';
import { SaveRtoDashboardDialogComponent } from 'app/common/components/dialogs/save-rto-dashboard-dialog/save-rto-dashboard-dialog.component';
//import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
export interface PeriodicElement {
  portfolioId: number;
  portfolioName: string;
  RTOLink: string;

}

let ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-manage-portfolio-rtm-dashboard',
  templateUrl: './manage-portfolio-rtm-dashboard.component.html',
  styleUrls: ['./manage-portfolio-rtm-dashboard.component.scss']
})
export class managePortfolioRtmDashboard implements OnInit, OnDestroy {
  
  
  @Output('dirty')  formDirty = new EventEmitter<boolean>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  marketInfo: any [];
  marketNotSelected: false;
  displayedColumns: string[] = ["portfolioName", "RTOLink", "Actions"];
  selectedMarket: string;
  searchkeyword: string;
  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'action'];
  dataSource = new MatTableDataSource<any>();
 
 isLoading = true;
 
 pageNumber: number = 1;
   managePortfolioRtmDashForm: FormGroup;
   isEditableNew: boolean = true;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
   // private fb: FormBuilder,
    private _formBuilder: FormBuilder
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.getMarkets();
    this.managePortfolioRtmDashForm = this._formBuilder.group({
      managePortfolioRtmDashRows: this._formBuilder.array([])
    });
    


  }
  marketChangeEvent(marketId) {
    this.getPortfolioRTMData()
  }
 
  initiateForm() {
    this.searchkeyword = "";
    const reg =  "(http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?"
    this.managePortfolioRtmDashForm = this.fb.group({
              managePortfolioRtmDashRows: this.fb.array(ELEMENT_DATA.map(val => this.fb.group({
                portfolioId: new FormControl(val.portfolioId),
                portfolioName: new FormControl(val.portfolioName),
                RTOLink: new FormControl(val.RTOLink, [Validators.required, Validators.pattern(reg)]),
                action: new FormControl('existingRecord'),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
              })
              )) //end of fb array
            }); // end of form group cretation
    this.isLoading = false;
    this.dataSource = new MatTableDataSource((this.managePortfolioRtmDashForm.get('managePortfolioRtmDashRows') as FormArray).controls);
   // this.dataSource.paginator = this.paginator;

    const filterPredicate = this.dataSource.filterPredicate;
      this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
        return filterPredicate.call(this.dataSource, data.value, filter);
      }
    //Custom filter according to name column
    //this.dataSource.filterPredicate = (data: {name: string}, filterValue: string) =>
    //data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  }

  getMarkets() {
    this.globalElementsService.getAllMarket(this.userInfo.gpid).subscribe(res => {
      if(res['markets'].length > 0){
        this.marketInfo = res['markets'];
      } else {
        this.marketInfo = [];
      }
      const defaultMarketObj = this.marketInfo.find((el) => el.displayName === 'United States');
      this.selectedMarket = defaultMarketObj ? defaultMarketObj.id.toString() : "3";
      this.isLoading = true;
      this.getPortfolioRTMData();
    })

  }

   getPortfolioRTMData() {
    this.globalElementsService.getAllPortfolioRTMData(this.selectedMarket).subscribe(res => {
      if(res && res["data"]) {
        ELEMENT_DATA =  res["data"];
        this.initiateForm();
      } else {
        ELEMENT_DATA = [];
        this.isLoading = false;
      }
    });
   
  }
 

  goToPage() {
      this.paginator.pageIndex = this.pageNumber - 1;
      this.paginator.page.next({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    }
    ngAfterViewInit() {
     // this.dataSource.paginator = this.paginator;
      //this.paginatorList = document.getElementsByClassName('mat-paginator-range-label');
  
     //this.onPaginateChange(this.paginator, this.paginatorList);
  
     //this.paginator.page.subscribe(() => { // this is page change event
      // this.onPaginateChange(this.paginator, this.paginatorList);
     //});
    }
    
     applyFilter(event: Event) {
      //  debugger;
    
      const filterValue = (event.target as HTMLInputElement).value;
      //this.dataSource.filter = filterValue.trim().toLowerCase();
      if(filterValue && filterValue.length > 0) {
        this.isLoading = true;
        const filterData = ELEMENT_DATA.filter(el => el.portfolioName && el.portfolioName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 );

        //const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        const reg =  "(http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?"
        this.managePortfolioRtmDashForm = this.fb.group({
                  managePortfolioRtmDashRows: this.fb.array(filterData.map(val => this.fb.group({
                    portfolioId: new FormControl(val.portfolioId),
                    portfolioName: new FormControl(val.portfolioName),
                    RTOLink: new FormControl(val.RTOLink, [Validators.required, Validators.pattern(reg)]),
                    action: new FormControl('existingRecord'),
                    isEditable: new FormControl(true),
                    isNewRow: new FormControl(false),
                  })
                  )) //end of fb array
                }); // end of form group cretation
        this.isLoading = false;
        this.dataSource = new MatTableDataSource((this.managePortfolioRtmDashForm.get('managePortfolioRtmDashRows') as FormArray).controls);
       // this.dataSource.paginator = this.paginator;
    
        const filterPredicate = this.dataSource.filterPredicate;
          this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
            return filterPredicate.call(this.dataSource, data.value, filter);
          }
    
        
      } else {
        this.initiateForm();
      }

    }
  
  
    // @ViewChild('table') table: MatTable<PeriodicElement>;
    AddNewRow() {
      // this.getBasicDetails();
      const control = this.managePortfolioRtmDashForm.get('managePortfolioRtmDashRows') as FormArray;
      control.insert(0,this.initiatemanagePortfolioRtmDashForm());
      this.dataSource = new MatTableDataSource(control.controls)
      // control.controls.unshift(this.initiatemanagePortfolioRtmDashForm());
      // this.openPanel(panel);
        // this.table.renderRows();
        // this.dataSource.data = this.dataSource.data;
    }
  
    // this function will enabled the select field for editd
    editPortfolioRTMDash(managePortfolioRtmDashFormElement, i) {
  
      // managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('name').disabled(false)
      
        managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('isEditable').patchValue(false);
      
      // this.isEditableNew = true;
  
    }
  
    // On click of correct button in table (after click on edit) this method will call
    SaveVO(managePortfolioRtmDashFormElement, i) {
      // alert('SaveVO')
      if(managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('RTOLink').valid) {
        let _portfolioId = managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('portfolioId').value || -1;
        /*let _index = ELEMENT_DATA.findIndex(el => el.portfolioId == _portfolioId);
        if(_index > -1 &&  ELEMENT_DATA[_index]) {
          ELEMENT_DATA[_index].RTOLink = managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('RTOLink').value;
        }*/
        this.globalElementsService.savePortfolioRTMData(_portfolioId, managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('RTOLink').value).subscribe((res)=> {
          let dialogRef;
          dialogRef = this.dialog.open(SaveRtoDashboardDialogComponent, {
            minWidth: '500px',
          });
            this.isLoading = true;
            this.getPortfolioRTMData();
          
        }, (err) => {
          this.isLoading = true;
          this.getPortfolioRTMData();
        });
        managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('isEditable').patchValue(true);
      }
    }
  
    // On click of cancel button in the table (after click on edit) this method will call and reset the previous data
    CancelSVO(managePortfolioRtmDashFormElement, i) {
      //this.dataSource = this.dataSource
      const priviOusRTOValObj = ELEMENT_DATA.find((el) => {
        return el.portfolioId == managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('portfolioId').value
      }); 
      managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('RTOLink').patchValue(priviOusRTOValObj ? priviOusRTOValObj.RTOLink : "");
      managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('isEditable').patchValue(true);
    }
  
    deletemPRdash(managePortfolioRtmDashFormElement, i) {
      let _portfolioId = managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('portfolioId').value || -1;
      let rtoLink = managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('RTOLink').value;
      let dialogRef;
      dialogRef = this.dialog.open(DeleteRtoDashboardDialogComponent, {
        minWidth: '500px',
        data:
        {
          portfolioId:_portfolioId,
          rtoLink:rtoLink
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        this.getPortfolioRTMData();
        managePortfolioRtmDashFormElement.get('managePortfolioRtmDashRows').at(i).get('isEditable').patchValue(true);
      })
    }
  /*paginatorList: HTMLCollectionOf<Element>;
  idx: number;
  onPaginateChange(paginator: MatPaginator, list: HTMLCollectionOf<Element>) {
       setTimeout((idx) => {
           let from = (paginator.pageSize * paginator.pageIndex) + 1;
  
           let to = (paginator.length < paginator.pageSize * (paginator.pageIndex + 1))
               ? paginator.length
               : paginator.pageSize * (paginator.pageIndex + 1);
  
           let toFrom = (paginator.length == 0) ? 0 : `${from} - ${to}`;
           let pageNumber = (paginator.length == 0) ? `0 of 0` : `${paginator.pageIndex + 1} of ${paginator.getNumberOfPages()}`;
           let rows = `Page ${pageNumber} (${toFrom} of ${paginator.length})`;
  
           if (list.length >= 1)
               list[0].innerHTML = rows; 
  
       }, 0, paginator.pageIndex);
  }*/
  
  
    initiatemanagePortfolioRtmDashForm(): FormGroup {
      return this.fb.group({
  
        position: new FormControl(234),
                  name: new FormControl(''),
                  weight: new FormControl(''),
                  symbol: new FormControl(''),
                  action: new FormControl('newRecord'),
                  isEditable: new FormControl(false),
                  isNewRow: new FormControl(true),
      });
    }
  ngOnDestroy(){
    this.formDirty.emit(false);
  }
}
