import { MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from "@angular/core";
import { GlobalElementsService } from "../../../../global-elements/services/global-elements.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-create-user-dialog",
  templateUrl: "./create-user-dialog.component.html",
  styleUrls: ["./create-user-dialog.component.scss"],
})
export class CreateUserDialogComponent implements OnInit {
  message: string = "Creating User... Please Wait";
  exitCreateUserForm: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService
  ) {}

  ngOnInit() {
    this.createUser();
  }

  createUser() {
    this.globalElementsService.createUser(this.data).subscribe(
      () => {
        this.message = "Successfully created user";
        this.exitCreateUserForm = true;
      },
      (err) => {
        console.log("response", err);
        this.message = err.error.message;
      }
    );
  }

  // Quit the edit form on clicking OK
  handleOk() {
    this.exitCreateUserForm
      ? this.globalElementsService.setSidePanelCancelEvent()
      : null;
  }
}
