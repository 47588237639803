import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import ab2b64 from 'ab2b64';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';
import { getFileBuffer} from 'app/common/utils/common-util';

@Component({
  selector: 'app-bulk-user-upload',
  templateUrl: './bulk-user-upload-dailog.component.html',
  styleUrls: ['./bulk-user-upload-dailog.component.scss']
})
export class BulkUserUploadDailog implements OnInit {
marketId:string;
cancelBtnEnable:boolean = false;
doneBtnEnable:boolean = false;
isUploadErr:boolean =false;
uploadStatus: string;
userInfo = JSON.parse(sessionStorage.getItem('user'));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalElementsService: GlobalElementsService,
    public dialogRef: MatDialogRef<BulkUserUploadDailog>
  ) { }

  ngOnInit() {
    this.marketId = this.data;
  }
  onFileSelected(event){
    let fileList: FileList = event.target.files;
    getFileBuffer(fileList[0]).then(async (fileBufferArray: ArrayBuffer) => {
      let fileName =  fileList[0].name;
      //let path=`${this.tcpDocData.hierarchy}/${this.year}/`;
      let Base64String = ab2b64.ab2b64(fileBufferArray);
      let type = fileList[0].type;
      let marketId = this.marketId
      let docObj = {
        Base64String,
        marketId,
        type
      }
      this.isUploadErr =false;
      this.uploadStatus="Uploading file please wait...";
      this.globalElementsService.uploadCTtemplate(docObj).subscribe((res) =>{
        this.uploadStatus="Uploaded Successfully"
        this.doneBtnEnable = true;
        event.target.value = null;
      }, (error) => {
        this.uploadStatus="Upload Failed"
        this.doneBtnEnable = true;
        this.isUploadErr = true;
        event.target.value = null;
      })
  })
  }
  fileUploadCompleted(){
    this.dialogRef.close();
  }
  downloadChannelTemplate(){
    this.globalElementsService.downloadBulkUploadUserTemplate();
  }

}
