import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';

@Component({
  selector: 'app-avatar-upload-dialog',
  templateUrl: './avatar-upload-dialog.component.html',
  styleUrls: ['./avatar-upload-dialog.component.scss']
})
export class AvatarUploadDialogComponent implements OnInit {
  message: string = "Uploading profile... Please Wait";
  uploadSts=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
    private dialogRef: MatDialogRef<AvatarUploadDialogComponent>
  ) { }

  ngOnInit() {
    this.uploadAvatar();
  }


  uploadAvatar() {
    this.globalElementsService.uploadAvatar(this.data).subscribe((res:any) => {
      this.message = res.message;
      this.uploadSts=true;
    }, () => {
      this.message = "Error uploading profile.";
      this.uploadSts=false;
    })
  }

  close(){
    this.dialogRef.close({ status: this.uploadSts })
  }

}
