import { IBreadcrumb } from './../../../common/interfaces/common';
import { MEDIA_CHANNEL_REFERENCES, ANALYTICS_TYPE, BREADCRUMBS_REFERENCE_TYPES, BREADCRUMBS_LAST_NODE_TYPES } from './../../../constants/app-constants';
import { ScenarioPlanningDialogComponent } from './../../../common/components/dialogs/scenario-planning-dialog/scenario-planning-dialog.component';
import { PlanService } from './../../../plan/services/plan.service';
import { StoreService } from './../../../old/_services/_store.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Component, OnInit, Input, SimpleChanges, ViewChild, TemplateRef } from '@angular/core';
import { ProgramService2 } from '../../services/program.service';
import { GlobalElementsService } from '../../../global-elements/services/global-elements.service';
import { SharedDataService } from '../../../common/services/shared-data.service';

const AUDIENCE_LIST = ['AUDIENCE_1','AUDIENCE_2','AUDIENCE_3','AUDIENCE_4','AUDIENCE_5','AUDIENCE_6'];
const KPIS = ["Spend Pacing", "Cost per Viewable Reach (CPvM)", "Cost per 1,000 Impressions (CPM)", "Maximum imps/spend at 10+/month frequency"];

@Component({
  selector: 'app-create-scenario-ct',
  templateUrl: './create-scenario.component.html',
  styleUrls: ['./create-scenario.component.scss']
})

export class CreateScenarioCTComponent implements OnInit {

  @ViewChild('logoUploadDialog', { static: true }) logoUploadDialog: TemplateRef<any>;
  @Input() elementDetails: any;

  channelForm: FormGroup;
  scenarioName: string;
  isFormInitialized = false;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');
  scenarioId: number = null;
  scenarioAnalytics;
  assets;
  selectedLogoImg={};
  currChannelIdx;
  channelsList = [];
  audienceList = [];
  optyKPIsList = [];
  workingYear;
  isEditMode = false;
  commsTaskId: null;
  curveList;
  isCurveListEmpty = true;
  existingChannels = [];
  selectedCurveVersion = ''
  actionBtnEnableStatus = {
    save: true,
    edit: false,
    remove: false
  }
  btnStatusList: any = [];
  pathDetails : IBreadcrumb;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,    
    private programService : ProgramService2,
    private planService: PlanService,
    private sharedData: SharedDataService,
    private globalElementsService: GlobalElementsService,
    private store: StoreService

  ) { }
  
  /*........... Initialization Methods........ */

  ngOnInit() {

    this.audienceList = [...AUDIENCE_LIST];
    this.optyKPIsList = [...KPIS];

      this.channelForm = this.fb.group({
        channels: this.fb.array([]),
      });
      this.getAllAssets();
  }



  ngOnChanges({ elementDetails }: SimpleChanges) {

    this.sharedData.getPlanningYear.subscribe(res => {
      if (this.workingYear != res) {
        this.workingYear = res;
      }
    });

    this.commsTaskId = this.elementDetails ? this.elementDetails.commsTaskId : null;

    const mediaChannelObj = {
      marketId: this.store.get('market'),
      referenceType: MEDIA_CHANNEL_REFERENCES.MASTERBRAND,
      commsTaskId: this.commsTaskId,
      year:  this.workingYear
    }
    this.pathDetails = {
      lastNodeName: `${this.elementDetails.commsTaskName} >> Scenario`,
      pageId: this.elementDetails.commsTaskId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.COMMSTASK,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.COMMSTASK
    }
      this.programService.getMediaChannelCurves(mediaChannelObj).subscribe(res => {
        this.curveList=res;     
        this.isCurveListEmpty = this.curveList.length == 0;
 
      })

  }

  newChannel(): FormGroup {
    return this.fb.group({
      channelName: ['', Validators.required],
      audience: ['', Validators.required],
      keyInterception: ['', Validators.required],
      optimizationKPIs: ['', Validators.required],
      touchPointName: ['', Validators.required],
      cWMBudget: '',
      startDt: ['', Validators.required],
      endDt: ['', Validators.required],
      cpp: ['', Validators.required],
      mediaChannelCurve: '',
      touchPointContent: '',
    })
  }
 
  /*........... functionality Methods........ */

  channels(): FormArray {
    return this.channelForm.get("channels") as FormArray
  }

  addChannel() {
    this.channels().push(this.newChannel());
  }

  removeChannel(chnlIdx: number) {
    const channelDetails = this.channels().at(chnlIdx).value;
    const { channelId } = channelDetails;

    if (channelId) {
      this.deleteChannel(channelId, chnlIdx);
    } else {
      this.channels().removeAt(chnlIdx);
    }
  }

  deleteChannel(channelId, chnlIdx) {
    const isLastChannel = this.channels().controls.length == 1;

    //If last, send boolean to dialog.
    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'delete',
        module: 'upfront',
        element: 'channel',
        apiData: channelId,
        isLast: isLastChannel
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(() => {
      //Remove the channel from formArray, if in edit mode
      this.channels().removeAt(chnlIdx);
      if (isLastChannel) {
        this.programService.deleteScenario(this.scenarioId).subscribe(() => {
          this.globalElementsService.setSidePanelCancelEvent();   
        });
      }
    });
  }

  openImgUploadDialog(channelIdx) {
    this.currChannelIdx = channelIdx;
    this.dialog.open(this.logoUploadDialog, {
      height: '700px',
      width: '800px',
      disableClose: true
    });
  }

  //close dialog
  closeDialog() {
    this.dialog.closeAll();
  }


  selectLogo(sel: any) {
    this.assets.forEach(element => {
      delete element.selected;
      if (element.webImage == sel.webImage) {
        element.selected = true;
        this.selectedLogoImg[this.currChannelIdx] = sel.webImage;

      }
    });
    this.closeDialog();
  }


  onSubmit(channelIdx) {
    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    const gpid = userInfo.gpid;
    const channelFormInfo = this.channelForm.value.channels[channelIdx];
    channelFormInfo.touchPointContent  = this.selectedLogoImg[channelIdx];
    const chnlName = channelFormInfo.channelName;

    //this.existingChannels.push(chnlName);

    const postObj = this.populatePostObject(channelFormInfo); 


    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'save',
        module:'commsTask',
        apiData: postObj
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(result => {

      if (result && result.scenarioId) {
        this.scenarioId = result.scenarioId;
        this.getAnalyticsData();
      }
    });

  }

  updateChannelnCPP(channelDetails, index){

    this.channels().controls[index].patchValue({
      cpp: channelDetails.cpp,
      channelName: channelDetails.mediaChannel,
      mediaChannelCurve: this.selectedCurveVersion 
    })
  }

  /*........... Service/API calls........ */
  
  getAnalyticsData() {

    const postObj = {
      scenarioId: this.scenarioId,
      marketId: this.store.get('market'),
      type: ANALYTICS_TYPE.COMMSTASK
    }
    //   Fetch the analytics data based on new saved channel
    this.programService.getScenarioAnalytics(postObj).subscribe((res: any) => {
      this.scenarioAnalytics = res;
    })
  }

  getChanelByCurve(curveVersion) {
    this.selectedCurveVersion = curveVersion;

    const mediaChannelObj = {
      marketId: this.store.get('market'),
      referenceType: MEDIA_CHANNEL_REFERENCES.SUB_BRAND,
      commsTaskId: this.commsTaskId,
      year:  this.workingYear,
      curveVersion
    }


    this.programService.getChannelsPerCurve(mediaChannelObj).subscribe((res: any) => {
      this.channelsList = res;
    })
  }


  //get all assets
  getAllAssets() {
    this.globalElementsService.getAllAssetsProgramPage().subscribe((res: any) => {
      this.assets = res;
    },
      (error) => {
        console.error('Error: ', error)
      });
  }
  
  


  populatePostObject(channelFormInfo) {

    const commsTaskId = this.commsTaskId ? Number(this.commsTaskId): this.commsTaskId;

    const marketId = this.store.get('market');

    const scenarioInfo = {
      scenarioId: this.scenarioId, 
      scenarioName: this.scenarioName,
      scenarioType: "COMMSTASK",
      scenarioStatus: "DRAFT",
      scenarioReference: "xyz",
      year: this.workingYear,
      runType: "SAMPLE"
    }

    const channelInfo = {
       channelName: channelFormInfo.channelName,
       audience: channelFormInfo.audience.toString(),
       cpp: channelFormInfo.cpp,
       cWMBudget: channelFormInfo.cWMBudget,
       curve: this.selectedCurveVersion,
       endDt: channelFormInfo.endDt,
       keyInterception: channelFormInfo.keyInterception,
       optimizationKPIs: channelFormInfo.optimizationKPIs.toString(),
       startDt: channelFormInfo.startDt,
       touchPointContent:channelFormInfo.touchPointContent,
       touchPointName:channelFormInfo.touchPointName,      
    }


    const postObj = {
      commsTaskId,
      scenarioInfo,
      channelInfo,
      marketId

    }


    return postObj;


  }

}
